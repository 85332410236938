<div class="form-container">
    <br>
    <br>
    <br>
    <input class="styled-input" type="text" placeholder="Nume" [ngModelOptions]="{standalone: true}" [(ngModel)]="blogtitlu" required> 
    <input class="styled-input" placeholder="sursa" [ngModelOptions]="{standalone: true}" [(ngModel)]="blogsursa" required>
    <select class="styled-input" [(ngModel)]="blogcategorie" [ngModelOptions]="{standalone: true}" required>
        <option value="Real Estate">Real Estate</option>
        <option value="Investment">Investment</option>
        <option value="Partners">Partners</option>
        <option value="News">News</option>
        <option value="Events">Events</option>
    </select>
        <br>
    <div >
        <p>Data articol</p>
        <input class="styled-input" type="date"  [(ngModel)]="Datablog" >
    </div>
    <br>
    <textarea class="styled-input" id="mesaj" placeholder="cuprins" [ngModelOptions]="{standalone: true}" [(ngModel)]="blogcuprins" style="white-space: pre-wrap;"></textarea>

    <br>
    <p>adauga poza blog</p>
    <div  style="width: 40%;">
        <img [src]=PhotoFilePath height="250px;" width="200px;">
        <input type="file" (change)="uploadPhoto($event)" class="mt-2"/>
    </div>

    <br>
    <br>
    <button (click)="addblog()" *ngIf="selectedblog.BuildingId==0" class="btn btn-primary">
        posteaza blog
    </button>
    
    <button (click)="updateBlog()" *ngIf="selectedblog.BuildingId!=0" class="btn btn-primary">
        editeaza blog
    </button>
    <br>
    <br>
    <br>

</div>
