<body >
<div id="body">
    <div class="header-bcground"></div>
    <header class="headerGrid">
        <div class="headerGrid-wrapper">
            <div class="header-titles">
                <div class="header-logos">
                    <img class="tlh" src="../../assets/media/logos/the_lake_logo_white.png"
                        alt="logo of The Lake home - Living Xperience logo" (click)="seehome()">

                    <img class="nvrn" src="../../assets/media/logos/sigla_negative-02.png"
                        alt="logo of Novarion - Xperience the Future" onclick="NewTabNovarion()">
                </div>


                <div class="all-nav-elements">
                    <div class="contact-nav">

                        <div class="flexRow"><!-- 
                            <a (click)="changeRoEn()">EN</a>-->
                        </div>
                    </div> 
                </div>
                <div class="mob-page-title">
                    <h1>Alege casa perfectă cu checklist-ul nostru exclusiv</h1>
                    <h2>Ia o decizie informată cu ușurință și încredere</h2>
 
                    <p>Ești obosit de petrecut ore întregi răsfoind anunțurile cu apartamente de vânzare, nesigur căruia să îi acorzi atenție? Lista noastră exclusivă este concepută pentru a te ajuta să evaluezi potențiale apartamente și să iei o decizie informată.
                        </p>
                </div>
            </div>
        </div>
    </header>

    <!--<header class="headerGrid">
        <div class="headerGrid-wrapper">
            <div class="header-titles">
                <div class="header-logos">
                    <img class="tlh" src="../../assets/media/logos/the_lake_logo_white.png"
                        alt="logo of The Lake home - Living Xperience logo" (click)="seehome()">

                    <img class="nvrn" src="../../assets/media/logos/sigla_negative-02.png"
                        alt="logo of Novarion - Xperience the Future" (click)="seehome()">
                </div>

                <div class="all-nav-elements">
                    <div class="contact-nav">

                        <div class="flexRow"><!-- 
                            <a (click)="changeRoEn()">EN</a>
                        </div>
                    </div> 
                </div>
                <div class="mob-page-title">
                    <h1>Formular de Vizionare</h1>
                    <h2>Alege casa preferată</h2>
                </div>
            </div>
        </div>
    </header>-->

    <section class="all-page-content">
        <div class="page1">
            <app-checklist-pg1>
            </app-checklist-pg1>
        </div>
        <div class="page1">
            <app-checklist-pg2 *ngIf="complexversion">
            </app-checklist-pg2>
        </div>
    </section>
</div>
    <div class="pg1-footer" *ngIf="easyversion">
        <div class="footer-wrapper">
            <h2>Pasul următor</h2>
            <div class="btns">
                <div class="formJoin simple">
                    <input type="text" placeholder="Nume" [ngModelOptions]="{standalone: true}" [(ngModel)]="Name"
                        required>
                    <input type="email" placeholder="Email" [ngModelOptions]="{standalone: true}" [(ngModel)]="Email"
                        required>
                    <input type="tel" placeholder="Telefon" [ngModelOptions]="{standalone: true}" [(ngModel)]="Phone"
                        required>
                    <div class="privacylink3"><input (click)="checked3()" id="checkbox3" type="checkbox" required><label
                            class="form-control3">Sunt de acord cu <a id="ppLink3" (click)="seetermeni()"> Consimțământul de
                                prelucrare a datelor.</a></label></div>
                    <h6 *ngIf="acestCamp2">Acest câmp este obligatoriu</h6>
                    <button id="descarca" class="deactive" (click)="generatePDF()">DESCARCĂ FIȘIERUL</button>
                    <!--<button class="deactive" type="button" (click)="sendFormOne3()">accesează checklist-ul</button>-->
                </div>

                <button class="nextpage" (click)="go_to_complex()">CONTINUĂ CU VERSIUNEA DETALIATĂ</button>               
            </div>
    
        </div>
    
    </div>

    <div class="pg2-footer" *ngIf="complexversion">
        <div class="footer-wrapper">
            <h2>Pasul următor</h2>
            <div class="btns2">
                <button class="inapoi" (click)="go_to_easy()">ÎNAPOI</button>

                <div class="formJoin detailed">
                    <input type="text" placeholder="Nume" [ngModelOptions]="{standalone: true}" [(ngModel)]="Name"
                        required>
                    <input type="email" placeholder="Email" [ngModelOptions]="{standalone: true}" [(ngModel)]="Email"
                        required>
                    <input type="tel" placeholder="Telefon" [ngModelOptions]="{standalone: true}" [(ngModel)]="Phone"
                        required>
                    <div class="privacylink3"><input (click)="checked4()" id="checkbox3" type="checkbox" required><label
                            class="form-control3">Sunt de acord cu <a id="ppLink3" (click)="seetermeni()"> Consimțământul de
                                prelucrare a datelor.</a></label></div>
                    <h6 *ngIf="acestCamp2">Acest câmp este obligatoriu</h6>
                    <button id="descarca2" class="deactive" (click)="generatePDF()">DESCARCĂ FIȘIERUL </button>
                    <!--<button (click)="generatePDF()" class="descarca2">DESCARCĂ FIȘIERUL</button>-->
                </div>
            </div>
    
        </div>
    
    </div>
</body>



<router-outlet></router-outlet>