import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-lightbox',
  templateUrl: './lightbox.component.html',
  styleUrls: ['./lightbox.component.css']
})
export class LightboxComponent {
  @Input() images: any[] = []; // Array of images
  currentImageIndex: number = 0;
  isOpen: boolean = false;

  open(index: number): void {
    this.currentImageIndex = index;
    this.isOpen = true;
  }

  close(): void {
    this.isOpen = false;
  }

  navigate(step: number): void {
    this.currentImageIndex = (this.currentImageIndex + step + this.images.length) % this.images.length;
  }

  get currentImage() {
    return this.images[this.currentImageIndex];
  }
}
