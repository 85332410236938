import { Component, Input, OnInit } from '@angular/core';
import {SharedService} from 'src/app/shared.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-english-a',
  templateUrl: './english-a.component.html',
  styleUrls: ['./english-a.component.css']
})
export class EnglishAComponent implements OnInit {

  constructor(private service:SharedService,  private router: Router, private datePipe: DatePipe) { }

  @Input() adm2:any;
  userselectedroom : any
  userselectedroomdesktop : any

  BuildingListWithoutFilter:any=[];
  BuildingId!: string;
  BuildingName!: string;
  BuildingPhoto!: string;
  BuildingFloor!: string;
  ApartmentNumb!: string;
  ApartmentPhoto!: string;
  ApartmentSurface!: string;
  ApartmentRooms!: string;
  ApartmentPrice!: string;
  ApartmentBalconSurface!: string;
  ApartmentOrentation!: string;
  ApartmentBathrooms!: string;
  SurfaceDormitor!: string;
  SurfaceBaie!: string;
  SurfaceHol!: string;
  SurfaceRoom!: string;
  hollowfloor!: string;
  hollowPhoto!: string;
  ListForFilter:any=[];
  BuildingNameFilter!: string;
  BuildingFloorFilter!: string;
  BuildingRoomsFilter!: string;
  camere!: string;
  ngOnInit(): void {
    window.scrollTo(0, 0)
    this.checkmarckCheck=1
    this.checkmarckCheck1=1
    this.checkmarckCheck2=1
    this.checkmarckCheck3=1

    window.scrollTo(0, 0)
    this.refreshBldList()

    this.userselectedroom = this.service.selectedApartment
    this.userselectedroomdesktop = this.service.selectedApartmenDesktop

    const etaj2=document.querySelector('.db-button button');
    etaj2!.className = "deactive";
    
    if (typeof this.userselectedroom == 'undefined' && typeof this.userselectedroomdesktop == 'undefined'){
      this.seealege();
    }
    else if(typeof this.userselectedroomdesktop == 'object'){
      
      this.BuildingId=this.userselectedroomdesktop.BuildingId;
      this.BuildingName=this.userselectedroomdesktop.BuildingName;
      this.BuildingPhoto=this.userselectedroomdesktop.BuildingPhoto
      this.BuildingFloor=this.userselectedroomdesktop.BuildingFloor;
      this.ApartmentNumb=this.userselectedroomdesktop.ApartmentNumb;
      this.ApartmentPhoto=this.userselectedroomdesktop.ApartmentPhoto
      this.ApartmentSurface=this.userselectedroomdesktop.ApartmentSurface;
      this.ApartmentRooms=this.userselectedroomdesktop.ApartmentRooms;
      this.ApartmentPrice=this.userselectedroomdesktop.ApartmentPrice;
      this.ApartmentBalconSurface=this.userselectedroomdesktop.ApartmentBalconSurface;
      this.ApartmentOrentation=this.userselectedroomdesktop.ApartmentOrentation;
      this.ApartmentBathrooms=this.userselectedroomdesktop.ApartmentBathrooms;
      this.SurfaceDormitor=this.userselectedroomdesktop.SurfaceDormitor;
      this.SurfaceBaie=this.userselectedroomdesktop.SurfaceBaie;
      this.SurfaceHol=this.userselectedroomdesktop.SurfaceHol;
      this.SurfaceRoom=this.userselectedroomdesktop.SurfaceRoom;
      this.hollowPhoto =  "../../../assets/" + this.userselectedroomdesktop.BuildingPhoto    
    }
    else if(typeof this.userselectedroom == 'object'){

      this.userselectedroom = this.service.selectedApartment
      this.BuildingId=this.userselectedroom.BuildingId;
      this.BuildingName=this.userselectedroom.BuildingName;
      this.BuildingPhoto=this.userselectedroom.BuildingPhoto
      this.BuildingFloor=this.userselectedroom.BuildingFloor;
      this.ApartmentNumb=this.userselectedroom.ApartmentNumb;
      this.ApartmentPhoto=this.userselectedroom.ApartmentPhoto
      this.ApartmentSurface=this.userselectedroom.ApartmentSurface;
      this.ApartmentRooms=this.userselectedroom.ApartmentRooms;
      this.ApartmentPrice=this.userselectedroom.ApartmentPrice;
      this.ApartmentBalconSurface=this.userselectedroom.ApartmentBalconSurface;
      this.ApartmentOrentation=this.userselectedroom.ApartmentOrentation;
      this.ApartmentBathrooms=this.userselectedroom.ApartmentBathrooms;
      this.SurfaceDormitor=this.userselectedroom.SurfaceDormitor;
      this.SurfaceBaie=this.userselectedroom.SurfaceBaie;
      this.SurfaceHol=this.userselectedroom.SurfaceHol;
      this.SurfaceRoom=this.userselectedroom.SurfaceRoom;
      this.hollowPhoto =  "../../../assets/" + this.userselectedroom.BuildingPhoto    
    }




    this.ListForFilter = [1,2,3,4,5]
    if(this.BuildingFloor == "0"){
      this.hollowfloor = "parter"
    }
    else{
      this.hollowfloor = this.BuildingFloor
    }
    
    this.BuildingNameFilter="B"
    this.BuildingRoomsFilter="1"
    this.BuildingFloorFilter="0"
    if(this.userselectedroom.ApartmentRooms == 1){
      this.camere="room"
    }
    else{
      this.camere="rooms"
    }

  }

  navSlider(): void{
    const burger = document.querySelector('.burger');
    const nav = document.querySelector('.mob-navbar');
    const navBar = document.querySelectorAll<HTMLElement>('.mob-navbar a');
  //toggle nav regtangle
    nav!.classList.toggle('nav-active');
  //animate linksß
    navBar.forEach((link, index) => {
      if(link.style.animation){
        link.style.animation = '';
      } else {
      link.style.animation = `navLinkFade 0.5s ease forwards ${index / 7 + 0.01}s`;
      }
    });
  
  //burger animation
  burger!.classList.toggle('toggle');
  
  }

  Name!: string;
  Phone!: string;
  Email!: string;
  Message!: string;
  FormLocation!: string;
  DateOfGiving!: any;
  BucketDate!: Date;
  checkmark:boolean=false;
  checkmarckCheck!: number;
  checkmark1:boolean=false;
  checkmarckCheck1!: number;
  checkmark2:boolean=false;
  checkmarckCheck2!: number;
  checkmark3:boolean=false;
  checkmarckCheck3!: number;
  BuildingList:any=[];
  acestCamp:boolean=false;
  acestCamp1:boolean=false;
  acestCamp2:boolean=false;
  
  
  
/*formular 1*/
sendFormOne(){
  if(this.checkmark == true){
  this.FormLocation = "programează vizionare"
  this.BucketDate = new Date();
  this.DateOfGiving = (this.datePipe.transform(this.BucketDate,"yyyy-MM-dd"));
  var val = {BuildingId:this.BuildingId,
    Name:this.Name,
    Phone:this.Phone,
    Email:this.Email,
    Message:this.Message,
    FormLocation:this.FormLocation,
    DateOfGiving:this.DateOfGiving,};

  this.service.addform(val).subscribe(res=>{
    (res.toString());
  });
  alert("Your message has been sent")
} else {

  this.acestCamp=true;
}
}
checked(){
  this.checkmarckCheck = this.checkmarckCheck +1;
  if(this.checkmarckCheck % 2 === 0 ){
  this.checkmark = true;

  const modalbody=document.querySelector('.modal-body button');
  modalbody!.className = "modal-button";

  }
  else{
    this.checkmark = false;

  const modalbody=document.querySelector('.modal-body button');
  modalbody!.className = "deactive modal-button";
  }

}
sendFormOne2(){
  if(this.checkmark1 == true){
  this.FormLocation = "descărca brosura"
  this.BucketDate = new Date();
  this.DateOfGiving = (this.datePipe.transform(this.BucketDate,"yyyy-MM-dd"));
  var val = {BuildingId:this.BuildingId,
    Name:this.Name,
    Phone:this.Phone,
    Email:this.Email,
    Message:this.Message,
    FormLocation:this.FormLocation,
    DateOfGiving:this.DateOfGiving,};

  this.service.addform(val).subscribe(res=>{
    (res.toString());
  });
  alert("Your message has been sent")
  window.open("../../../assets/pdf/Brochure_2024_EN.pdf")
} else {

  this.acestCamp1=true;
}
}
checked2(){
  this.checkmarckCheck1 = this.checkmarckCheck1 +1;
  if(this.checkmarckCheck1 % 2 === 0 ){
  this.checkmark1 = true;

  const modalbody=document.querySelector('.modal-body-brosura button');
  modalbody!.className = "modal-button-brosura";

  }
  else{
    this.checkmark1 = false;

    const modalbody=document.querySelector('.modal-body-brosura button');
    modalbody!.className = "deactive modal-button-brosura";
  }

}

sendFormOne3(){
  if(this.checkmark2 == true){
  this.FormLocation = "te contactam noi"
  this.BucketDate = new Date();
  this.DateOfGiving = (this.datePipe.transform(this.BucketDate,"yyyy-MM-dd"));
  var val = {BuildingId:this.BuildingId,
    Name:this.Name,
    Phone:this.Phone,
    Email:this.Email,
    Message:this.Message,
    FormLocation:this.FormLocation,
    DateOfGiving:this.DateOfGiving,};

  this.service.addform(val).subscribe(res=>{
    (res.toString());
  });
  alert("Your message has been sent")
} else {

  this.acestCamp2=true;

}
}
checked3(){
  this.checkmarckCheck2= this.checkmarckCheck2 +1;
  if(this.checkmarckCheck2 % 2 === 0 ){
  this.checkmark2 = true;

  const modalbody=document.querySelector('.footer3 button');
  modalbody!.className = "";

  }
  else{
    this.checkmark1 = false;

    const modalbody=document.querySelector('.footer3 button');
    modalbody!.className = "deactive";
  }

}

/*------------formular OFERTAS------------*/
  checked4(){

    this.checkmarckCheck3 = this.checkmarckCheck3 +1;
    
    if(this.checkmarckCheck3 % 2 === 0 ){
    this.checkmark3 = true;
    const modalbody=document.querySelector('.modal-body-oferta button');
    modalbody!.className = "modal-button-oferta";
    }
    else{
      this.checkmark3 = false;
      const modalbody=document.querySelector('.modal-body-oferta button');
      modalbody!.className = "deactive modal-button-oferta";
    }
 
  }


openOferta(){
  window.open("https://www.facebook.com/100064165597277/posts/pfbid02NkycefKBWdFZEQXPNBaxK9XJfxLMZ9NieShQ44Y56QFdw1jLVAemNwRS8x7RCLWNl/")
}
sendFormOferta(){
  if(this.checkmark3 == true){
  this.FormLocation = "Promotie Craciun"
  this.BucketDate = new Date();
  this.DateOfGiving = (this.datePipe.transform(this.BucketDate,"yyyy-MM-dd"));

  var val = {BuildingId:this.BuildingId,
    Name:this.Name,
    Phone:this.Phone,
    Email:this.Email,
    Message:this.Message,
    FormLocation:this.FormLocation,
    DateOfGiving:this.DateOfGiving,};

  this.service.addform(val).subscribe(res=>{
    (res.toString());
  });
  alert("Your message has been sent")
} else {

  this.acestCamp=true;
}
}




makeballroomgrey(index: number){
  const etaj2=document.querySelectorAll('.nrCamere li');
  if(etaj2[index].className == ""){
    etaj2[index].className = "deactive";
  }
  else{
    etaj2[index].className = "deactive";
  }
}

resetroomball(){
  this.makeballroomgrey(0);
  this.makeballroomgrey(1);
  this.makeballroomgrey(2);
  this.makeballroomgrey(3);
  this.makeballroomgrey(4);
}

FilterFn6(){
  var BuildingNameFilter = this.BuildingNameFilter;
  var BuildingFloorFilter = this.BuildingFloorFilter;

  this.BuildingList = this.BuildingListWithoutFilter.filter( (el: 
    { BuildingId:
    { toString: () => string; }; BuildingFloor: { toString: () => string; }; 
    BuildingName: { toString: () => string; }  }) =>{
     

    return el.BuildingFloor.toString().toLowerCase().includes(
      BuildingFloorFilter.toString().trim().toLowerCase()
    )&&
      el.BuildingName.toString().toUpperCase().includes(
        BuildingNameFilter.toString().trim().toUpperCase()
      ) 
  });
}

makeballroomnormal(index: number){
  const etaj2=document.querySelectorAll('.nrCamere li');
  if(etaj2[index].className == ""){
    etaj2[index].className = "";
  }
  else{
    etaj2[index].className = "";
  }
}
makeEtajParter(){
  this.BuildingFloorFilter="0"
  this.resetroomball();
  this.FilterFn6()
  let lenght1 = (this.BuildingList.length)
  for ( let step = 0; step<lenght1; step++){
    for (let num of this.ListForFilter) {
      if(this.BuildingList[step]["ApartmentRooms"] == num){
        this.makeballroomnormal(num-1)
      }}
  }

  this.refreshBldList()
  this.makeballblue(0)
  this.makeballnormal(1)
  this.makeballnormal(2)
  this.makeballnormal(3)
  this.makeballnormal(4)
  const etaj2=document.querySelector('.db-button button');
  etaj2!.className = "deactive";
}
makeEtaj1(){
  this.BuildingFloorFilter="1"
  this.resetroomball();
  this.FilterFn6()
  let lenght1 = (this.BuildingList.length)
  for ( let step = 0; step<lenght1; step++){
    for (let num of this.ListForFilter) {
      if(this.BuildingList[step]["ApartmentRooms"] == num){
        this.makeballroomnormal(num-1)
      }}
  }

  this.refreshBldList()

  this.makeballblue(1)
  this.makeballnormal(0)
  this.makeballnormal(2)
  this.makeballnormal(3)
  this.makeballnormal(4)
  const etaj2=document.querySelector('.db-button button');
  etaj2!.className = "deactive";
}
makeEtaj2(){
  this.BuildingFloorFilter="2"
  this.resetroomball();
  this.FilterFn6()
  let lenght1 = (this.BuildingList.length)
  for ( let step = 0; step<lenght1; step++){
    for (let num of this.ListForFilter) {
      if(this.BuildingList[step]["ApartmentRooms"] == num){
        this.makeballroomnormal(num-1)
      }}
  }

  this.refreshBldList()

  this.makeballblue(2)
  this.makeballnormal(1)
  this.makeballnormal(0)
  this.makeballnormal(3)
  this.makeballnormal(4)
  const etaj2=document.querySelector('.db-button button');
  etaj2!.className = "deactive";

}
makeEtaj3(){
  this.BuildingFloorFilter="3"
  this.resetroomball();
  this.FilterFn6()
  let lenght1 = (this.BuildingList.length)
  for ( let step = 0; step<lenght1; step++){
    for (let num of this.ListForFilter) {
      if(this.BuildingList[step]["ApartmentRooms"] == num){
        this.makeballroomnormal(num-1)
      }}
  }

  this.refreshBldList()

  this.makeballblue(3)
  this.makeballnormal(1)
  this.makeballnormal(2)
  this.makeballnormal(0)
  this.makeballnormal(4)
  const etaj2=document.querySelector('.db-button button');
  etaj2!.className = "deactive";

}
makeEtaj4(){
  this.BuildingFloorFilter="4"
  this.resetroomball();
  this.FilterFn6()
  let lenght1 = (this.BuildingList.length)
  for ( let step = 0; step<lenght1; step++){
    for (let num of this.ListForFilter) {
      if(this.BuildingList[step]["ApartmentRooms"] == num){
        this.makeballroomnormal(num-1)
      }}
  }

  this.refreshBldList()

  this.makeballblue(4)
  this.makeballnormal(1)
  this.makeballnormal(2)
  this.makeballnormal(3)
  this.makeballnormal(0)
  const etaj2=document.querySelector('.db-button button');
  etaj2!.className = "deactive";

}
checkballfromactivetonormal(index: number){
  const etaj2=document.querySelectorAll('.nrCamere li');
  if(etaj2[index].className == "active"){
    etaj2[index].className = "";
  }
}
makeroomnormal(){
  this.checkballfromactivetonormal(0);
  this.checkballfromactivetonormal(1);
  this.checkballfromactivetonormal(2);
  this.checkballfromactivetonormal(3);
  this.checkballfromactivetonormal(4);
  
  }
makeballblue1(index: number){
  this.makeroomnormal()
  const etaj0=document.querySelectorAll('.camere-names li');
  if(etaj0[index].className == "active"){
    etaj0[index].className = "";
  }
  else{
    etaj0[index].className = "active";
  }

}

makeballnormal1(index: number){
  const etaj0=document.querySelectorAll('.camere-names li');
  etaj0[index].className = "";
}

makeballnormal2(index: number){
  const etaj1=document.querySelectorAll('.camere-names li');
  etaj1[index].className = "";
}

makerooms1(){
  this.BuildingRoomsFilter="1"
  this.makeballblue1(0)
  const etaj2=document.querySelector('.db-button button');
  etaj2!.className = "";
}
makerooms2(){
  this.BuildingRoomsFilter="2"
  this.makeballblue1(1)
  const etaj2=document.querySelector('.db-button button');
  etaj2!.className = "";

}
makerooms3(){
  this.BuildingRoomsFilter="3"
  this.makeballblue1(2)
  const etaj2=document.querySelector('.db-button button');
  etaj2!.className = "";
}
makerooms4(){
  this.BuildingRoomsFilter="4"
  this.makeballblue1(3)
  const etaj2=document.querySelector('.db-button button');
  etaj2!.className = "";
}
makerooms5(){
  this.BuildingRoomsFilter="5"
  this.makeballblue1(4)
  const etaj2=document.querySelector('.db-button button');
  etaj2!.className = "";
}


makeballblue(index: number){
  const etaj0=document.querySelectorAll('.nrEtaj li');
  if(etaj0[index].className == "active"){
    etaj0[index].className = "";
  }
  else{
    etaj0[index].className = "active";
  }


}

makeballnormal(index: number){
  const etaj0=document.querySelectorAll('.nrEtaj li');
  etaj0[index].className = "";


}

footerlinks(){
  this.service.selectedfloor="0"
  this.service.selectedrooms= "1"
  this.service.selectedbuilding= "B"
  this.router.navigateByUrl('/locuinte-en' )
}
footerlinks1(){
  this.service.selectedfloor="0"
  this.service.selectedrooms= "1"
  this.service.selectedbuilding= "B"
  this.router.navigateByUrl('/locuinte-en' )
}
footerlinks2(){
  this.service.selectedfloor="1"
  this.service.selectedrooms= "2"
  this.service.selectedbuilding= "B"
  this.router.navigateByUrl('/locuinte-en' )
}
footerlinks3(){
  this.service.selectedfloor="3"
  this.service.selectedrooms= "3"
  this.service.selectedbuilding= "B"
  this.router.navigateByUrl('/locuinte-en' )
}
footerlinks4(){
  this.service.selectedfloor="4"
  this.service.selectedrooms= "5"
  this.service.selectedbuilding= "B"
  this.router.navigateByUrl('/locuinte-en' )
}

seemoreapartments(){
  this.service.selectedfloor= this.BuildingFloorFilter
  this.service.selectedrooms= this.BuildingRoomsFilter
  this.service.selectedbuilding= "B"
  this.router.navigateByUrl('/locuinte-en' )
}

seetermeni(){
  this.router.navigateByUrl('/termeni-si-conditii-en')
}

seehome(){
  this.router.navigateByUrl('/en')
  }
seelifestyle(){
  this.router.navigateByUrl('/lifestyle-en')
  }
seemedia(){
  this.router.navigateByUrl('/mediapage-en')
  }

seedezvoltator(){
  this.router.navigateByUrl('/dezvoltator-en')
  }

seecontact(){
  this.router.navigateByUrl('/contact-en')
  }

seelocatie(){
  this.router.navigateByUrl('/locatie-en')
  }

seealege(){
  this.router.navigateByUrl('/locuinte-en')
  }


  FilterFn5(){

    var BuildingNameFilter = this.BuildingNameFilter;
    var BuildingFloorFilter = this.BuildingFloorFilter;
    var BuildingRoomsFilter = this.BuildingRoomsFilter;

    this.BuildingList = this.BuildingListWithoutFilter.filter( (el: 
      { BuildingId:
      { toString: () => string; }; BuildingFloor: { toString: () => string; }; 
      BuildingName: { toString: () => string; }
      ApartmentRooms: { toString: () => string; };  }) =>{
       

      return el.ApartmentRooms.toString().toLowerCase().includes(
        BuildingRoomsFilter.toString().trim().toLowerCase()
        
      )
      && el.BuildingFloor.toString().toLowerCase().includes(
        BuildingFloorFilter.toString().trim().toLowerCase()
      )&&
        el.BuildingName.toString().toUpperCase().includes(
          BuildingNameFilter.toString().trim().toUpperCase()
        ) 
    });
 }
  
 refreshBldList(){
  this.service.getBuildingList().subscribe(data=>{
    this.BuildingList=data;
    this.BuildingListWithoutFilter=data;
    this.FilterFn5(); 
  });
}

changeRoEn(){
  this.service.selectEnRo=true;
  this.router.navigateByUrl('/apartamente')
}
}
  
