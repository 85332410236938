
        <label class="col-sm-2 col-form-label">nume</label>
        <div class="col-sm-10">
            <input type="text" class="form-control" [(ngModel)]="Name"
            placeholder=""> <br>
        </div>

        <label class="col-sm-2 col-form-label">nume</label>
        <div class="col-sm-10">
            <input type="text" class="form-control" [(ngModel)]="Email"
            placeholder=""> <br>
        </div>

        <label class="col-sm-2 col-form-label">nume</label>
        <div class="col-sm-10">
            <input type="text" class="form-control" [(ngModel)]="Phone"
            placeholder=""> <br>
        </div>

        <label class="col-sm-2 col-form-label">nume</label>
        <div class="col-sm-10">
            <input type="text" class="form-control" [(ngModel)]="Message"
            placeholder=""> <br>
        </div>

        <label class="col-sm-2 col-form-label">nume</label>
        <div class="col-sm-10">
            <input type="text" class="form-control" [(ngModel)]="FormLocation"
            placeholder=""> <br>
        </div>



    
    
    <button (click)="addEmployee1()" *ngIf="tol.BuildingId==0" class="btn btn-primary">
        Add
    </button>
    
    <button (click)="updateEmployee1()" *ngIf="tol.BuildingId!=0" class="btn btn-primary">
        Update
    </button>