import { ThisReceiver } from '@angular/compiler';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, HostListener, Renderer2} from '@angular/core';
import {SharedService} from 'src/app/shared.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-vizionare',
  templateUrl: './vizionare.component.html',
  styleUrls: ['./vizionare.component.css']
})
export class VizionareComponent implements OnInit, AfterViewInit {

  constructor(private service:SharedService, private router: Router, private datePipe: DatePipe,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private renderer: Renderer2,
    private elementRef: ElementRef,) { }


  ngAfterViewInit(): void {
    throw new Error('Method not implemented.');
  }


  BuildingId!: string;
  Name!: string;
  Phone!: string;
  Email!: string;
  Message!: string;
  Oras!: string;
  FormLocation!: string;
  DateOfGiving!: any;
  BucketDate!: Date;

  checkmarckCheck!: number;
  checkmark1:boolean=false;
  checkmarckCheck1!: number;
  checkmark2:boolean=false;
  checkmarckCheck2!: number;
  checkmark3:boolean=false;
  checkmarckCheck3!: number;
  BuildingList:any=[];
  acestCamp:boolean=false;
  acestCamp1:boolean=false;
  acestCamp2:boolean=false;
  ApartmentPrice!: string;
  ApartmentRooms!: any;
  adm:any;
  BuildingIdFilter!: string;


  ngOnInit(): void {
  }

/*formular 1*/
sendFormOne(){
  if(this.checkmark == true){
  this.FormLocation = "programează vizionare"
  this.BucketDate = new Date();
  this.DateOfGiving = (this.datePipe.transform(this.BucketDate,"yyyy-MM-dd"));

  var val = {BuildingId:this.BuildingId,
    Name:this.Name,
    Phone:this.Phone,
    Email:this.Email,
    Message:this.Message+this.Oras,
    FormLocation:this.FormLocation,
    DateOfGiving:this.DateOfGiving,};

  this.service.addform(val).subscribe(res=>{
    (res.toString());
  });
  alert("Mesajul tau a fost trimis")
} else {

  this.acestCamp=true;
}
}


  seetermeni(){
    this.router.navigateByUrl('/termeni-si-conditii');
  }

  checkmark: boolean = false; // Start with the checkbox unchecked

  checked() {
      this.checkmark = !this.checkmark;
  }
}
