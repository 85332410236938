
<div *ngIf="roDetalii" class="plans-details">
    <div class="plans-img">
        <img src={{hollowPhoto}} alt="">
    </div>
    <div class="detalii">
        <h6>{{ApartmentPrice.slice(0, -3)}},{{ApartmentPrice.slice(-3)}}<span>€+TVA </span></h6>
        <h5>{{pretlace}}</h5>
        <!--<h6>{{ApartmentPrice.slice(0, -3)}},{{ApartmentPrice.slice(-3)}}<span>€+TVA </span></h6>
        <h5>PRET</h5>-->
        <h4></h4>

        <div class="bloc-etaj">
            <div class="Bloc">
                <h5>Bloc {{BuildingName}}</h5>
                <p>nr. {{ApartmentNumb}}</p>
                <!--<h5>Bloc {{BuildingName}}</h5>
                <p>nr. {{ApartmentNumb}}</p>-->
            </div>
            <div class="etaj">
                <h5>Etaj</h5>
                <p>{{hollowfloor}}</p>
                <!--<p>{{hollowfloor}}</p>-->
            </div>
        </div>

        <div class="detalii-plan">
            <ol>
                <li>Living <p>{{SurfaceRoom}}m&#178;</p></li>
                <li>Dormitor <p>{{SurfaceDormitor}}m&#178;</p></li>
                <li>Baie <p>{{SurfaceBaie}}m&#178;</p></li>
                <li>Hol <p>{{SurfaceHol}}m&#178;</p></li>
                <li>Terasa <p>{{ApartmentBalconSurface}}m&#178;</p></li>
            </ol>
        </div>

        <div class="sUtila-location">
            <div class="sUtila">
                <h5>Suprafața</h5>
                <p>{{ApartmentSurface}}m&#178;</p>
            </div>
            <div class="location">
                <h5>Orientare</h5>
                <p>{{ApartmentOrentation}}</p>
            </div>
        </div>
        <div class="modal-nota">
            <p id="nota-text">Toate informațiile, schițele și imaginile au exclusiv scop de prezentare. Va rugam să ne contactați pentru mai multe detalii.</p>
        </div>
        <div class="desc-plan">
            <button (click)="DescarcaPlan()">Descarcă plan</button>
        </div>
    </div>
</div>


<div *ngIf="enDetalii" class="plans-details">
    <div class="plans-img">
        <img src={{hollowPhoto}} alt="">
    </div>
    <div class="detalii">
        <h6>{{ApartmentPrice.slice(0, -3)}},{{ApartmentPrice.slice(-3)}}<span>€+TVA </span></h6>
        <h5>TURNKEY PRICE</h5>
        <!--<h6>{{ApartmentPrice.slice(0, -3)}},{{ApartmentPrice.slice(-3)}}<span>€+TVA </span></h6>
        <h5>PRET</h5>-->
        <h4></h4>

        <div class="bloc-etaj">
            <div class="Bloc">
                <h5>Block {{BuildingName}}</h5>
                <p>no. {{ApartmentNumb}}</p>
                <!--<h5>Bloc {{BuildingName}}</h5>
                <p>nr. {{ApartmentNumb}}</p>-->
            </div>
            <div class="etaj">
                <h5>Floor</h5>
                <p>{{hollowfloorENG}}</p>
                <!--<p>{{hollowfloor}}</p>-->
            </div>
        </div>

        <div class="detalii-plan">
            <ol>
                <li>Living <p>{{SurfaceRoom}}m&#178;</p></li>
                <li>Bedroom <p>{{SurfaceDormitor}}m&#178;</p></li>
                <li>Bathroom <p>{{SurfaceBaie}}m&#178;</p></li>
                <li>Hall <p>{{SurfaceHol}}m&#178;</p></li>
                <li>Terrace <p>{{ApartmentBalconSurface}}m&#178;</p></li>
            </ol>
        </div>

        <div class="sUtila-location">
            <div class="sUtila">
                <h5>Surface</h5>
                <p>{{ApartmentSurface}}m&#178;</p>
            </div>
            <div class="location">
                <h5>Orientation</h5>
                <p>{{ApartmentOrentation}}</p>
            </div>
        </div>
        <div class="modal-nota">
            <p id="nota-text">All information, sketches and images are for presentation purposes only. Please contact us for more details.</p>
        </div>
        <div class="desc-plan">
            <button (click)="DescarcaPlan()">Download plan</button>
        </div>
    </div>
</div>

<div  class="blu-div-desk"><div class="blu-div"></div></div>