<body>


    <div class="a4">
        <div class="content">


            <div id="pdf">
                <header class="headerGrid">
                    <div class="headerGrid-wrapper">
                        <div class="header-titles">
                            <div class="header-logos">
                                <img class="tlh" src="../../assets/media/logos/the_lake_logo_white.png"
                                    alt="logo of The Lake home - Living Xperience logo" (click)="seehome()">

                                <img class="nvrn" src="../../assets/media/logos/sigla_negative-02.png"
                                    alt="logo of Novarion - Xperience the Future" (click)="seehome()">
                            </div>

                            <div class="all-nav-elements">
                                <div class="contact-nav">

                                    <div class="flexRow">
                                        <!-- 
                                        <a (click)="changeRoEn()">EN</a>-->
                                    </div>
                                </div>
                            </div>
                            <div class="mob-page-title">
                                <h1>Formular de Vizionare</h1>
                                <h2>Alege casa preferată</h2>
                            </div>
                        </div>
                    </div>
                </header>

                <section class="all-page-content">
                    <div class="page1">
                        <div class="wrapper">
                            <div class="intro">
                                <div class="fields">
                                    <label for="date">Data vizionării: </label>
                                    <p>{{date}}</p>
                                </div>

                                <div class="fields">
                                    <label for="">ID apartament:</label>
                                    <p> {{idapto}}</p>
                                </div>

                                <div class="fields">
                                    <label for="">Anunț:</label>
                                    <p>{{anunt}}</p>
                                </div>

                                <div class="fields">
                                    <label for="">Adresa: </label>
                                    <p>{{adresa}}</p>
                                </div>

                                <div class="fields">
                                    <label for="">Preț în €: </label>
                                    <p>{{pret}}</p>
                                </div>

                                <div class="fields">
                                    <label for="">Preț negociabil:</label>
                                    <p> {{pretnegoc}}</p>
                                </div>

                                <div class="fields">
                                    <label for="">Agent / Proprietar: </label>
                                    <p>{{agent}}</p>
                                </div>

                                <div class="fields">
                                    <label for="">Telefon: </label>
                                    <p>{{telefon}}</p>
                                </div> 

                                <div class="fields">
                                    <label for="">Email: </label>
                                    <p>{{email}}</p>
                                </div>

                            </div>

                            <div class="division"></div>
                            <h3>Detalii proprietate</h3>

                            <div class="property">

                                <div class="fields">
                                    <label for="">Mp construiți: <span> {{mpconsttotal}}</span></label>
                                </div>

                                <div class="fields">
                                    <label for="">Mp utili: <span> {{mputiletotal}}</span></label>
                                </div>

                                <div class="twoinputs">
                                    <div class="fields">
                                        <label for="">Etaj: <span>{{etaj}}</span></label>
                                    </div>

                                    <div class="fields">
                                        <label for="">Din: <span>{{dinEtaj}}</span></label>

                                    </div>
                                </div>

                                <div class="fields">
                                    <label for="">Nr. Camere: <span> {{nrcamere}}</span> </label>


                                </div>

                                <div class="fields" id="mobiliat">
                                    <label for="">Mobilat: <span> {{mobiliat}}</span></label>

                                </div>

                                <div class="tag">
                                    <h4>Camera de zi</h4>
                                    <div class="fields">
                                        <label for="">Mp: <span>{{cameraMp}}</span></label>
                                        <p for="">{{balcon_terasa}}</p>
                                        <label for="">Notițe: </label>
                                        <p>{{cameraNote}}</p>
                                    </div>

                                </div>

                                <div class="tag" id="bucatarie">
                                    <h4>Bucătăria</h4>
                                    <div class="buc1">
                                        <div class="fields">
                                            <label for="">Mp: <span>{{bucatariaMp}}</span></label>
                                            <p for="">{{inchisadeschisa}}</p>
                                            <p for="">{{bucatariaMobi}}</p>
                                            <p for="">{{bucatariaElect}}</p>
                                            <label for="">Notițe: </label>
                                            <p>{{bucatariaNote}}</p>
                                        </div>

                                    </div>

                                </div>

                                <div class="tag"  *ngFor="let baie of [].constructor(numBaie); let i = index">
                                    <h4>Baie {{ i + 1 }}</h4>
                                    <div class="fields">
                                        <label for="">Mp: <span>{{baieMp[i]}}</span></label>
                                        <p>{{detaliibaie[i]}}</p>
                                        <p>{{amenajare[i]}}</p>
                                        <label for="">Notițe: </label>
                                        <p>{{baie2Note[i]}}</p>
                                    </div>

                                </div>



                                <div class="tag"  *ngFor="let baie of [].constructor(numroom); let i = index">
                                    <h4>Dormitor {{ i + 1 }}</h4>
                                    <div class="fields">
                                        <label for="">Mp: <span>{{roomMp[i]}}</span></label>
                                        <p>{{dormitor1Amenaj[i]}}</p>
                                        <label for="">Notițe: </label>
                                        <p>{{dormitor1Note[i]}}</p>
                                    </div>
                                </div>


                                <div class="tag">
                                    <h4>Balcon / terasă</h4>
                                    <div class="fields">
                                        <label for="">Mp: <span>{{balconMp}}</span></label>
                                        <p>{{balconDesc}}</p>
                                        <label for="">Notițe: </label>
                                        <p>{{balconNote}}</p>
                                    </div>
                                </div>

                                <div class="tag">
                                    <h4>Curte</h4>
                                    <div class="fields">
                                        <label for="">Mp: <span>{{curteMp}}</span></label> <br>
                                        <label for="">Notițe: </label>
                                        <p>{{curteNote}}</p>
                                    </div>
                                </div>

                            </div>  

                            <div class="division"></div>
                            <h3>Detalii clădire și zonă</h3>

                            <div class="building">

                                <div class="fields">
                                    <label for="">An construcție: <span>{{anconstr}}</span></label>
                                </div>

                                <div class="fields">
                                    <label for="">Numar de scări: <span>{{nrScari}}</span></label>
                                </div>

                                <div class="fields">
                                    <label for="">Număr de apartamente: <span>{{nrApto}}</span></label>
                                </div>

                                <div class="fields">
                                    <label for="">Boxă: <span>{{boxa}}</span>
                                    </label>
                                </div>

                                <div class="fields" id="lift">
                                    <label for="">Lift: <span>{{lift}}</span></label>
                                </div>

                                <div class="tag">
                                    <h4>Locuri parcare</h4>
                                    <div class="twoinputs">
                                        <div class="fields">
                                            <p>{{nrparcare}}</p>
                                            <label for="">Poziție: <span>{{parcPoz}}</span></label> <br>
                                            <label for="">Loc nr.: <span>{{parcLoc}}</span></label> <br>
                                            <label for="">Notițe:</label>
                                            <p>{{parcNote}}</p>
                                        </div>
                                    </div>

                                </div>

                                <div class="tag">
                                    <h4>Condițiile clădirii</h4>
                                    <div class="fields">
                                        <p>{{conditNou}}</p>
                                        <p>{{conditAnv}}</p>
                                        <label for="">Notițe:</label>
                                        <p>{{conditNote}}</p>
                                    </div>

                                </div>

                                <div class="tag">
                                    <h4>Detalii cheltuieli
                                        întreținere bloc</h4>
                                    <div class="fields">
                                        <p>{{detaliiChelt}}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="division"></div>
                            <h3>Document vânzare sau&nbsp;creditare</h3>

                            <div class="documents">

                                <div class="tag" id="intabulat">
                                    <h4>Apartamentul este
                                        întabulat?</h4>
                                    <div class="fields">
                                        <p>{{docs1}}</p>
                                        <label for="">Notițe:</label>
                                        <p>{{docs1nu}}</p>
                                    </div>

                                </div>

                                <div class="tag" id="utilitatile">
                                    <h4>Apartamentul este racordat la toate utilitațile?</h4>
                                    <div class="fields">
                                        <p>{{docs2}}</p>
                                    </div>

                                    <div class="fields care">
                                        <label for="">Care? <span>{{care}}</span></label>
                                    </div>


                                </div>

                                <div class="fields">
                                    <label for="">Pot vedea un extras CF, releveu, certificat energetic? </label>
                                    <p>{{docs3}}</p>
                                </div>

                                <div class="fields">
                                    <label for="">Actele au fost verificate de un profesionist?</label>
                                    <p>{{docs4}}</p>
                                </div>

                                <div class="fields">
                                    <label for="">Este liber de sarcini apartamentul?</label>
                                    <p>{{docs5}}</p>
                                </div>

                                <div class="fields">
                                    <label for="">Se acceptă cumpărarea cu credit ipotecar? </label>
                                    <p>{{docs6}}</p>
                                </div>

                                <div class="fields" id="termenul">
                                    <label for="">Care este termenul în care se <br> poate elibera apartamentul?</label>
                                    <p>{{docs7}}</p>
                                </div>
                            </div>

                            <div class="division"></div>
                            <h3>Versiunea detaliata</h3>
                        </div>
                    </div>

                    <div class="page2">
                        <div class="wrapper2">
                            <div class="tag2" id="locatie">
                                <h4>Locație</h4>

                                <div class="tag-wrapper">
                                    <div class="fields">
                                        <label for="">Evaluează prestigiul și renumele zonei în care se află clădirea:<span> {{advanced1}}</span> </label>
                                    </div>

                                    <div class="fields">
                                        <label for="">Evaluează calitatea peisajului și spațiilor verzi din zonă/cartier:<span> {{advanced2}}</span> </label>

                                    </div>

                                    <div class="fields">
                                        <label for="">Evaluează nivelul de zgomot din zonă/cartier:<span>
                                                {{advanced3}}</span> </label>
                                    </div>

                                    <div class="fields">
                                        <label for="">Evaluează condițiile de trafic din zonă/cartier:<span>
                                                {{advanced4}}</span> </label>
                                    </div>

                                    <div class="fields">
                                        <label for="">Evaluează accesibilitatea mijloacelor de transport:<span>
                                                {{advanced5}}</span> </label>
                                    </div>

                                    <div class="fields">
                                        <label for="">Evaluează accesibilitatea facilităților sociale generale:<span>
                                                {{advanced6}}</span> </label>
                                    </div>
                                </div>



                            </div>

                            <div class="tag2" id="cladExterior">
                                <h4>Clădire - exterioară</h4>

                                <div class="tag-wrapper">
                                    <div class="fields">
                                        <label for="">Evaluează arhitectura clădirii:<span> {{advanced7}}</span> </label>
                                    </div>

                                    <div class="fields">
                                        <label for="">Evaluează schema de culori a clădirii:<span> {{advanced8}}</span>
                                        </label>
                                    </div>

                                    <div class="fields">
                                        <label for="">Evaluează proporționalitatea clădirii:<span> {{advanced9}}</span>
                                        </label>
                                    </div>

                                    <div class="fields">
                                        <label for="">Evaluează calitatea materialelor din care este construită clădirea:<span> {{advanced10}}</span> </label>
                                    </div>

                                    <div class="fields">
                                        <label for="">Evaluează încadrarea exteriorului clădirii în ansamblul ei:<span>
                                                {{advanced11}}</span> </label>
                                    </div>
                                </div>


                            </div>

                            <div class="tag2" id="cladInterior">
                                <h4>Clădire - interior</h4>

                                <div class="tag-wrapper">
                                    <div class="fields">
                                        <label for="">Evaluează aspectul și calitatea ușii de la intrarea în clădire:<span> {{advanced12}}</span> </label>
                                    </div>

                                    <div class="fields">
                                        <label for="">Evaluează aspectul și funcționalitatea holului central:<span>
                                                {{advanced13}}</span> </label>
                                    </div>

                                    <div class="fields">
                                        <label for="">Evaluează înălțimea, lățimea și aspectul general al holurilor:<span> {{advanced14}}</span> </label>
                                    </div>

                                    <div class="fields">
                                        <label for="">Evaluează calitatea iluminatului și luminozitatea în zonele comune:<span> {{advanced15}}</span> </label>
                                    </div>

                                    <div class="fields">
                                        <label for="">Evaluează calitatea elementelor decorative din zonele comune:<span> {{advanced16}}</span> </label>
                                    </div>

                                    <div class="fields">
                                        <label for="">Evaluează calitatea materialelor utilizate în zonele comune:<span>
                                                {{advanced17}}</span> </label>
                                    </div>

                                    <div class="fields">
                                        <label for="">Evaluează orice mirosuri prezente în zonele comune:<span> {{advanced18}}</span> </label>
                                    </div>

                                    <div class="fields">
                                        <label for="">Evaluează aspectul și funcționalitatea biroului de recepție:<span>
                                                {{advanced19}}</span> </label>
                                    </div>
                                </div>



                            </div>

                            <div class="tag2" id="Function">
                                <h4>Funcționalități</h4>

                                <div class="tag-wrapper">
                                    <div class="fields">
                                        <label for="">Evaluează ușurința și conveniența accesului la apartament:<span>
                                                {{advanced20}}</span> </label>
                                    </div>

                                    <div class="fields">
                                        <label for="">Evaluează ușurința și conveniența accesului la clădire:<span>
                                                {{advanced21}}</span> </label>
                                    </div>

                                    <div class="fields">
                                        <label for="">Evaluează ușurința și conveniența accesului la intrarea în clădire:<span> {{advanced22}}</span> </label>
                                    </div>

                                    <div class="fields">
                                        <label for="">Evaluează ușurința și conveniența accesului la scări:<span>
                                                {{advanced23}}</span> </label>
                                    </div>

                                    <div class="fields">
                                        <label for="">Evaluează ușurința și conveniența accesului la lifturi:<span>
                                                {{advanced24}}</span> </label>
                                    </div>

                                    <div class="fields">
                                        <label for="">Evaluează ușurința și conveniența accesului la zona de recepție:<span> {{advanced25}}</span> </label>
                                    </div>

                                    <div class="fields">
                                        <label for="">Evaluează ușurința și conveniența accesului la parcări:<span>
                                                {{advanced26}}</span> </label>
                                    </div>

                                    <div class="fields">
                                        <label for="">Evaluează accesibilitatea facilităților pentru persoanele cu dizabilități:<span> {{advanced27}}</span> </label>
                                    </div>

                                    <div class="fields">
                                        <label for="">Evaluează gradul de accesibilitate al clădirii pentru vizitatori:<span> {{advanced28}}</span> </label>
                                    </div>

                                    <div class="fields">
                                        <label for="">Evaluează utilitatea și calitatea spațiilor comerciale din complex:<span> {{advanced29}}</span></label>
                                    </div>

                                    <div class="fields">
                                        <label for="">Evaluează garanția brand-ului:<span> {{advanced30}}</span> </label>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

                <footer>
                    <div class="footer-wrapper">
                        <div class="footer-logo">
                            <p>Proiect dezvoltat de:</p>
                            <img src="../../assets/media/logos/sigla_negative-02.png" alt="Novarion Logo"
                                onclick="NewTabNovarion()">
                        </div>
                        <div class="footer1">
                            <h5>Birou vânzări</h5>
                            <p class="footer1-bold">The Lake Home</p>
                            <a href="https://goo.gl/maps/u8YrSsReceFjCgq37" target=”_blank”>
                                <p>Strada Tractorului, nr. 12- 28,<br>Sibiu, România</p>
                            </a>
                            <div>
                            </div>
                        </div>
                        <div class="footer2">
                            <h5>Contact</h5>
                            <div class="footer2-rezidentie">
                                <div class="rezidentie1">
                                    <span>T.</span><a href="tel:+40721229999">+40 721 229 999</a> <br>
                                    <span>T.</span><a href="tel:+40756244404">+40 756 244 404</a> <br>
                                    <span>E.</span><a href="mailto:sales@thelake.ro">sales@thelake.ro</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>

            <button class="descarca2" (click)="generatePDF()">DESCARCA FISIERUL</button>


        </div>
    </div>

    <div class="typage">
        <div class="typage-wrapper">
            <div class="idvlogos">
                <img class="tlh" src="../../assets/media/logos/the_lake_logo_white.png"
                    alt="logo of The Lake home - Living Xperience logo" (click)="seehome()">

                <img class="nvrn" src="../../assets/media/logos/sigla_negative-02.png"
                    alt="logo of Novarion - Xperience the Future" (click)="seehome()">
            </div>

            <div class="title">
                <h1>Mulțumim!</h1>
                <p>Descărcarea checklist-ului tău este în curs de desfășurare.
                    <br><br>
                    Dacă întâmpini dificultăți în descărcare, nu ezita să ne contactezi la adresa <a href="mailto:info@novarion.ro">info@novarion.ro</a> pentru asistență. <br><br>
                    De asemenea, dacă încă cauți spațiul de locuit perfect, te rugăm să consultați cele mai recente proiecte ale noastre de pe <a
                    (click)="seehome()">The Lake Home</a>. Apartamentele noastre premium oferă lux, confort și comoditate, și suntem convinși că veți găsi casa perfectă.
                    <br><br>
    
                    <a (click)="seehome()">Vizitează-ne astăzi și descoperiți mai multe!</a>
                </p>
            </div>
        </div>
        

    </div>
</body>