import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {

  // Variable to control the display of the modal
  modaldetalii: boolean = false;
  modalContents: string[] = [];  // Array to hold multiple lines of content
  zonatitle!: string;
  
  constructor() { }

  ngOnInit(): void { 
    console.log("MapComponent initialized");
  }

  // Method to close the modal
  close() {
    console.log("Closing modal");
    this.modaldetalii = false;  
    this.modalContents = [];  // Clear the modal content
    this.zonatitle = '';
  }

  // Method to open the modal for spot1
  spot1() {
    console.log("Opening modal for spot1");
    this.modalContents = [
      '• 12:00 Indoor Cycling la EcoDome Sports',
      '• Petrecere Privata',
      
    ];
    this.modaldetalii = true;  
    this.zonatitle = 'Zona Dinamică';
  }

  // Additional methods for other spots
  spot2() {
    console.log("Opening modal for spot2");
    this.modalContents = [
      '• 9:15 Yoga cu Valeriu Apostol',
      '• 10:30 Atelier de teatru cu Andrei Șerban și Alexandru Bumbeș',
      '• 12:30 Atelier de dans cu Silvana Neda și Claudia Ciofaca',
      '• 14:30 Atelier de teatru cu Cristi Popescu',
      '• Spectacol de teatru “Doi cu pluta” ',
      '• Spectacol de teatru “Karma”'
    ];
    this.modaldetalii = true;  
    this.zonatitle = 'Zona Culturală';
  }

  spot3() {
    console.log("Opening modal for spot3");
    this.modalContents = [
      '• Beachbar',
      '• DJ Dorian',
      '• Concerte cu Paul Radu, Roman Ciuganschi, Madalina Foficaș',
      '• Foc de Tabăra',
    ];
    this.modaldetalii = true;  
    this.zonatitle = 'Zona Relaxare';
  }

  spot4() {
    console.log("Opening modal for spot4");
    this.modalContents = [
      '• Turneu Volei',
      '• 11:00 Clasa de Kids Postural',
      '• Streetball',

    ];
    this.modaldetalii = true;  
    this.zonatitle = 'Zona Competiții';
  }

  spot5() {
    console.log("Opening modal for spot5");
    this.modalContents = [
      '• Castel Gonflabil',
      '• Leagăne',
      '• Jocuri',
    ];
    this.modaldetalii = true;  
    this.zonatitle = 'Zona Divertisment';
  }

  spot6() {
    console.log("Opening modal for spot6");
    this.modalContents = [
      '• Tir de tras la B6 Airsoft',

    ];
    this.modaldetalii = true;  
    this.zonatitle = 'Zona Adrenalina';
  }

  spot7() {
    console.log("Opening modal for spot7");
    this.modalContents = [
      '• Vânzători de produse naturale',
      '• Atelier pentru copii sa faca diferite forme din ceara',
      '• Atelier pentru copii si adulti de aranjamente florale',
      '• Atelier pentru copii de decoratiuni cu pietricele și alte elemente naturale',
      '• Ateliere colorate în baza revistelor',
      '• Atelier de plantat vlăstari pentru adulți și copii',
      '• Atelier machiaj de zi',
      '• Masaj în aer liber',
      '• Tir de tras cu arcul',
      '• Atelier de pirogravură',
      '• Lulu Cosmetice Handmade',
      '• Iaconi Dental Clinic',
      '• Crucea rosie ',
      '• Foodtrucks',
      
    ];
    this.modaldetalii = true;  
    this.zonatitle = 'Zona Targ';
  }

  spot8() {
    console.log("Opening modal for spot8");
    this.modalContents = [
      '• Ateliere de pictură pentru copii',
      '• Atelier de linogravură cu Andra Udrea',
      '• Atelier de colaj analog cu Iulia Ursa & FloRacolaj și Paste-up street art',
      '• Ateliere de pictură părinte-copil ',

    ];
    this.modaldetalii = true;  
    this.zonatitle = 'Zona Artistică';
    
  }
}
