import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SharedService } from 'src/app/shared.service';


@Component({
  selector: 'app-checklist-pg1',
  templateUrl: './checklist-pg1.component.html',
  styleUrls: ['./checklist-pg1.component.css']
})
export class ChecklistPg1Component implements OnInit {

  constructor(
    private service: SharedService,

  ) { }
  private timer: any;

  date!: string;
  idapto!: string;
  anunt!: string;
  adresa!: string;
  pret!: string;
  pretnegoc!: string;
  agent!: string;
  telefon!: string;
  email!: string;
  mpconsttotal!: string;
  mputiletotal!: string;
  etaj!: string;
  dinEtaj!: string;
  nrcamere!: number;
  mobiliat!: string;
  cameraMp!: string;
  balcon_terasa!: string;
  cameraNote!: string;
  bucatariaMp!: string;
  bucatariaMobi!: string;
  bucatariaElect!: string;
  inchisadeschisa!: string;
  bucatariaNote!: string;

  baie1Note!: string;
  baie2Mp!: string;

  dormitor1Mp!: string;
  dormitor2Mp!: string;
  dormitor2Amenaj!: string;
  dormitor2Renov!: string;
  dormitor2Note!: string;

  balconMp!: string;
  balconDesc!: string;
  balconNote!: string;
  curteMp!: string;
  curteGeam!: string;
  curteNote!: string;
  anconstr!: string;
  nrScari!: string;
  nrApto!: string;
  boxa!: string;
  lift!: string;
  nrparcare!: number;

  parcPoz!: string;
  parcLoc!: string;
  parcNote!: string;
  conditNou!: string;
  conditAnv!: string;
  conditNote!: string;
  detaliiChelt!: string;
  docs1!: string;
  docs1nu!: string;
  docs2!: string;
  care!: string;
  docs3!: string;
  docs4!: string;
  docs5!: string;
  docs6!: string;
  docs7!: string;
  
  quest1!: string;
  quest2!: string;
  quest3!: string;







  ngOnInit(): void {

    setInterval(() => {
      this.check1stpart();
    }, 200);



    
    this.isNoteActive = false;
    this.isNoteActive2 = false;
    this.isNoteActive3 = false;
    this.isNoteActive4 = false;
    this.isNoteActive10 = false;
    this.isNoteActive11 = false;
    this.isNoteActive12 = false;

  

  }

  check1stpart(){
    this.service.date = this.date;
    this.service.idapto = this.idapto;
    this.service.anunt = this.anunt;
    this.service.adresa = this.adresa;
    this.service.pret = this.pret;
    this.service.pretnegoc = this.pretnegoc;
    this.service.agent = this.agent;
    this.service.telefon = this.telefon;
    this.service.email = this.email;
    this.service.mpconsttotal = this.mpconsttotal;
    this.service.mputiletotal = this.mputiletotal;
    this.service.etaj = this.etaj;
    this.service.dinEtaj = this.dinEtaj;
    this.service.nrcamere = this.nrcamere;
    this.service.mobiliat = this.mobiliat;
    this.service.cameraMp = this.cameraMp;
    this.service.balcon_terasa = this.balcon_terasa;
    this.service.cameraNote = this.cameraNote;
    this.service.bucatariaMp = this.bucatariaMp;
    this.service.bucatariaMobi = this.bucatariaMobi;
    this.service.bucatariaElect = this.bucatariaElect;
    this.service.inchisadeschisa = this.inchisadeschisa;
    this.service.bucatariaNote = this.bucatariaNote;

    this.service.baie1Note = this.baie1Note;
    this.service.baie2Mp = this.baie2Mp;

    this.service.dormitor1Mp = this.dormitor1Mp;
    this.service.dormitor2Mp = this.dormitor2Mp;
    this.service.dormitor2Amenaj = this.dormitor2Amenaj;
    this.service.dormitor2Renov = this.dormitor2Renov;
    this.service.dormitor2Note = this.dormitor2Note;

    this.service.balconMp = this.balconMp;
    this.service.balconDesc = this.balconDesc;
    this.service.balconNote = this.balconNote;
    this.service.curteMp = this.curteMp;
    this.service.curteGeam = this.curteGeam;
    this.service.curteNote = this.curteNote;
    this.service.anconstr = this.anconstr;
    this.service.nrScari = this.nrScari;
    this.service.nrApto = this.nrApto;
    this.service.boxa = this.boxa;
    this.service.lift = this.lift;
    this.service.nrparcare = this.nrparcare;

    this.service.parcPoz = this.parcPoz;
    this.service.parcLoc = this.parcLoc;
    this.service.parcNote = this.parcNote;
    this.service.conditNou = this.conditNou;
    this.service.conditAnv = this.conditAnv;
    this.service.conditNote = this.conditNote;
    this.service.detaliiChelt = this.detaliiChelt;
    this.service.docs1 = this.docs1;
    this.service.docs1nu = this.docs1nu;
    this.service.docs2 = this.docs2;
    this.service.care = this.care;
    this.service.docs3 = this.docs3;
    this.service.docs4 = this.docs4;
    this.service.docs5 = this.docs5;
    this.service.docs6 = this.docs6;
    this.service.docs7 = this.docs7;
    this.service.quest1 = this.quest1;
    this.service.quest2 = this.quest2;
    this.service.quest3 = this.quest3;

    this.service.checklist1 = (
      this.date + " date" +
      this.idapto + " idapto" +
      this.anunt + " anunt" +
      this.adresa + " adresa" +
      this.pret + " pret" +
      this.pretnegoc + " pretnegoc" +
      this.agent + " agent" +
      this.telefon + " telefon" +
      this.email + " email" +
      this.mpconsttotal + " mpconsttotal" +
      this.mputiletotal + " mputiletotal" +
      this.etaj + " etaj" +
      this.dinEtaj + " dinEtaj" +
      this.nrcamere + " nrcamere" +
      this.mobiliat + " mobiliat" +
      this.cameraMp + " cameraMp" +
      this.balcon_terasa + " balcon_terasa" +
      this.cameraNote + " cameraNote" +
      this.bucatariaMp + " bucatariaMp" +
      this.bucatariaMobi + " bucatariaMobi" +
      this.bucatariaElect + " bucatariaElect" +
      this.inchisadeschisa + " inchisadeschisa" +
      this.bucatariaNote + " bucatariaNote" +
      this.baie1Note + " baie1Note" +
      this.baie2Mp + " baie2Mp" +
      this.dormitor1Mp + " dormitor1Mp" +
      this.dormitor1Amenaj + " dormitor1Amenaj" +
      this.dormitor1Renov + " dormitor1Renov" +
      this.dormitor2Mp + " dormitor2Mp" +
      this.dormitor2Amenaj + " dormitor2Amenaj" +
      this.dormitor2Renov + " dormitor2Renov" +
      this.dormitor2Note + " dormitor2Note" +
      this.balconMp + " balconMp" +
      this.balconDesc + " balconDesc" +
      this.balconNote + " balconNote" +
      this.curteMp + " curteMp" +
      this.curteGeam + " curteGeam" +
      this.curteNote + " curteNote" +
      this.anconstr + " anconstr" +
      this.nrScari + " nrScari" +
      this.nrApto + " nrApto" +
      this.boxa + " boxa" +
      this.lift + " lift" +
      this.parcPoz + " parcPoz" +
      this.parcLoc + " parcLoc" +
      this.parcNote + " parcNote" +
      this.conditNou + " conditNou" +
      this.conditAnv + " conditAnv" +
      this.conditNote + " conditNote" +
      this.detaliiChelt + " detaliiChelt" +
      this.docs1 + " docs1" +
      this.docs1nu + " docs1nu" +
      this.docs2 + " docs2" +
      this.care + " care" +
      this.docs3 + " docs3" +
      this.docs4 + " docs4" +
      this.docs5 + " docs5" +
      this.docs6 + " docs6" +
      this.docs7 + " docs7" +
      this.quest1 + " quest1 " +
      this.quest2 + " quest2 "+
      this.quest3 + " quest3 " )
    
      this.service.numBaie = this.numBaie;
      for (let i = 0; i < this.numBaie; i++) {
        this.service.baieMp[i] = this.baieMp[i];
        this.service.detaliibaie[i] = this.detaliibaie[i];
        this.service.amenajare[i] = this.amenajare[i];
        this.service.baie2Note[i] = this.baie2Note[i];

        this.service.numBaie = this.numBaie;
        
      }
      this.service.numroom = this.numroom;
      for (let i = 0; i < this.numroom; i++) {
        this.service.roomMp[i] = this.roomMp[i];
        this.service.dormitor1Amenaj[i] = this.dormitor1Amenaj[i];
        this.service.dormitor1Renov[i] = this.dormitor1Renov[i];
        this.service.detaliiroom[i] = this.detaliiroom[i];
        this.service.dormitor1Note[i] = this.dormitor1Note[i];        
      }
  }


  makeparking(index: number){
    this.nrparcare = index
    this.makeballparcareblue(index)
    
  }
  makeballparcareblue(index: number){
    this.makeparcarenormal()
    const blueparcare=document.querySelectorAll('.nrParcare li');
    blueparcare[index].className = "active";
    console.log(index)
  
  }
  makeparcarenormal() {
    const parcare = document.querySelectorAll('.nrParcare li');
    for (let i = 0; i < parcare.length; i++) {
      this.checkballparcarefromactivetonormal(i);
    }
  }
  
  checkballparcarefromactivetonormal(index: number) {
    const parcare = document.querySelectorAll('.nrParcare li');
    if (parcare[index].classList.contains("active")) {
      parcare[index].classList.remove("active");
    }
  }
  

  makerooms(index: number){
    this.nrcamere = index
    this.makeballblue1(index-1)
    
  }

  makeballblue1(index: number){
    this.makeroomnormal()
    const blueroom=document.querySelectorAll('.nrCamere li');
    blueroom[index].className = "active";
    console.log(index)
  
  }

  checkballfromactivetonormal(index: number){
    const etaj2=document.querySelectorAll('.nrCamere li');
    if(etaj2[index].className == "active"){
      etaj2[index].className = "";
    }

  }
  makeroomnormal(){
  this.checkballfromactivetonormal(0);
  this.checkballfromactivetonormal(1);
  this.checkballfromactivetonormal(2);
  this.checkballfromactivetonormal(3);
  this.checkballfromactivetonormal(4);
  }

  detaliibaie: string[] = ['', ''];
  numBaie: number =2;
  baieMp: string[] = ['', ''];
  amenajare: string[] = ['', ''];
  baie2Note: string[] = ['', ''];
  addBaie() {
    this.numBaie++;
    this.baieMp.push('');
    this.detaliibaie.push('');
    this.amenajare.push('');
    this.baie2Note.push('');
    this.service.numBaie++;
    this.service.baieMp.push('');
    this.service.detaliibaie.push('');
    this.service.amenajare.push('');
    this.service.baie2Note.push('');
    this.service.baie2Note.push('');



  }

  removeBaie(index: number) {
    if (index > 0) {
      this.numBaie--;
      this.baieMp.splice(index, 1);
      this.detaliibaie.splice(index, 1);
      this.amenajare.splice(index, 1);
      this.baie2Note.splice(index, 1);


      this.service.numBaie--;
      this.service.baieMp.splice(index, 1);
      this.service.detaliibaie.splice(index, 1);
      this.service.amenajare.splice(index, 1);
      this.service.baie2Note.splice(index, 1);
    }
  }
  //tags open and close for baie
  isNoteActiveBaie: boolean[] = Array(10).fill(false);
  opentag5(i: number) {
    this.isNoteActiveBaie[i] = !this.isNoteActiveBaie[i];
    this.service.isNoteActiveBaie[i] = !this.service.isNoteActiveBaie[i];

  }


//tags open and close for some tags
  isNoteActive = false;
  opentag() {
    this.isNoteActive = !this.isNoteActive;
  }
  isNoteActive2 = false;
  opentag2() {
    this.isNoteActive2 = !this.isNoteActive2;
  }
  isNoteActive3 = false;
  opentag3() {
    this.isNoteActive3 = !this.isNoteActive3;
  }
  isNoteActive4 = false;
  opentag4() {
    this.isNoteActive4 = !this.isNoteActive4;
  }
  isNoteActive10 = false;
  opentag10() {
    this.isNoteActive10 = !this.isNoteActive10;
  }
  isNoteActive11 = false;
  opentag11() {
    this.isNoteActive11 = !this.isNoteActive11;
    console.log(!this.isNoteActive11)
  }
  
  isNoteActive12 = false;
  opentag12() {
    this.isNoteActive12 = !this.isNoteActive12;
  }



  numroom: number = 2;
  roomMp: string[] = ['', ''];
  dormitor1Amenaj: string[] = ['', ''];
  dormitor1Renov: string[] = ['', ''];
  detaliiroom: string[] = ['', ''];
  dormitor1Note: string[] = ['', ''];
  addroom() {
    this.numroom++;
    this.roomMp.push('');
    this.detaliibaie.push('');
    this.amenajare.push('');
    this.dormitor1Note.push('');

  this.service.numroom++;
  this.service.roomMp.push('');
  this.service.detaliibaie.push('');
  this.service.amenajare.push('');
  this.service.dormitor1Note.push('');

  }

  removeroom(index: number) {
    if (index > 0) {
      this.numroom--;
      this.roomMp.splice(index, 1);
      this.detaliibaie.splice(index, 1);
      this.amenajare.splice(index, 1);
      this.dormitor1Note.splice(index, 1);


      this.service.numroom--;
  this.service.roomMp.splice(index, 1);
  this.service.detaliibaie.splice(index, 1);
  this.service.amenajare.splice(index, 1);
  this.service.dormitor1Note.splice(index, 1);

    }
  }
//tags open and close for room
isNoteActiveDormitor: boolean[] = Array(10).fill(false);
opentag6(i: number) {
  this.isNoteActiveDormitor[i] = !this.isNoteActiveDormitor[i];
}
}
