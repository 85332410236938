import { ThisReceiver } from '@angular/compiler';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, HostListener} from '@angular/core';
import {SharedService} from 'src/app/shared.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-brosura',
  templateUrl: './brosura.component.html',
  styleUrls: ['./brosura.component.css']
})
export class BrosuraComponent implements OnInit {
  constructor(private router: Router,private service:SharedService) { }


  ngOnInit(): void {

    const pdfPath = '../../assets/pdf/Brochure_2024_RO.pdf';
    window.location.href = pdfPath;


  }

}
