import { Component, OnInit } from '@angular/core';
import {SharedService} from 'src/app/shared.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-support-form',
  templateUrl: './support-form.component.html',
  styleUrls: ['./support-form.component.css']
})
export class SupportFormComponent implements OnInit {

  constructor(private http: HttpClient,private service:SharedService, private router: Router, private datePipe: DatePipe) { }


  BuildingId!: string;
  NameCompany!: string;
  Name!: string;
  Phone!: string;
  Email!: string;
  Message!: string;
  FormLocation!: string;
  DateOfGiving!: any;
  BucketDate!: Date;
  checkmark:boolean=false;
  checkmarckCheck!: number;
  checkmark1:boolean=false;
  checkmarckCheck1!: number;
  checkmark2:boolean=false;
  checkmarckCheck2!: number;
  checkmark3:boolean=false;
  checkmarckCheck3!: number;
  BuildingList:any=[];
  acestCamp:boolean=false;
  acestCamp1:boolean=false;
  acestCamp2:boolean=false;



  ngOnInit(): void {
  }
  checked3(){

    this.checkmarckCheck2= this.checkmarckCheck2 +1;
    if(this.checkmarckCheck2 % 2 === 0 ){
    this.checkmark2 = true;
  
    const modalbody=document.querySelector('.formJoin button');
    modalbody!.className = "";
  
    }
    else{
      this.checkmark1 = false;
  
      const modalbody=document.querySelector('.formJoin button');
      modalbody!.className = "deactive";
    }
  
  }

  seetermeni(){
    this.router.navigateByUrl('/termeni-si-conditii')
  }

  sendFormOne3() {
    // Prepare the current date.
    this.BucketDate = new Date();
    this.DateOfGiving = this.datePipe.transform(this.BucketDate, "yyyy-MM-dd");
  
    // Check if email follows the standard structure.
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(this.Email)) {
      alert("Email-ul trebuie sa urmeze structura exemplu@exemplu.exemplu");
      return;
    }
  
    // Get the list of all forms.
    this.service.getformList().subscribe(data => {
      // Check if the email is already used.
      const emailUsed = data.some(entry => entry.Email === this.Email);
  
      if (emailUsed) {
        alert("Acest email a fost folosit deja.");
        return;
      }
  
      // If the email is not used, proceed with the form submission.
      let formData = {
        BuildingId: this.BuildingId,
        Name: this.Name,
        Phone: this.Phone,
        Email: this.Email,
        Message: "raffle entry business support", 
        FormLocation: 'business support raffle entry',
        DateOfGiving: this.DateOfGiving,
      };
  
      // Submit the form.
      this.service.addform(formData).subscribe(res => {
        console.log(res.toString());
        // Redirect to the desired page after successful submission.
        this.router.navigateByUrl('/multumim');
      });
    });
  }
  
  seeregulament(){
    window.open("../../assets/pdf/REGULAMENTUL-TOMBOLEI-Festival-Lake.pdf")
  }


  isTermsAccepted: boolean = false; // Flag indicating whether terms and conditions are accepted.

  // This method is triggered whenever the checkbox value changes.
onTermsAcceptedChange(event: any) {
  // Directly use the checkbox's checked state.
  this.isTermsAccepted = event.target.checked;

  // Query the button and set its class based on the checkbox state.
  const submitButton = document.querySelector('.formJoin button');
  if (this.isTermsAccepted) {
    // If terms are accepted, remove the 'deactive' class or add active class/style.
    submitButton!.classList.remove('deactive');
    // You can add more styles or classes indicating the active state.
  } else {
    // If terms are not accepted, deactivate the button.
    submitButton!.classList.add('deactive');
  }
}

}
