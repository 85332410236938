<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
	<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"/>	
    <title>Locuințe</title>
</head>
<body>
    <div class="header-bcground"></div>

    <header class="headerGrid">
        <div class="headerGrid-wrapper">
            <div class="header-titles">
                <img src="../../../assets/media/logos/the_lake_logo_white.png" alt="The Lake home - Living Xperience logo" (click)="seehome()" >

                <div class="all-nav-elements">
                    <div class="contact-nav">

                        <div class="flexRow">
                            <a (click)="changeRoEn()">RO</a>
                        </div>
                        
                        <div class="header-phone"><span id="header-icon-phone" class="material-symbols-outlined">phone_iphone <a href="tel:+40721229999">+4 0721 229 999</a> </span></div>
                        <span class="vertical-line"></span>
                        <div class="header-mail"><span id="header-icon-mail" class="material-symbols-outlined">chat_bubble <a href="mailto:sales@thelake.ro">sales@thelake.ro</a></span></div>
                        <!-- Button trigger modal vizionare-->
                        <button class="header-button" type="button" data-toggle="modal" data-target="#brosuraModal">SEE&nbsp;THE&nbsp;BROCHURE</button>  
                        
                        
                          <!-- Modal Vizionare-->
                        <div class="modal fade" id="vizionareModal" tabindex="-1" role="dialog"
                            aria-labelledby="vizionareModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                            aria-hidden="true">
                                            <span aria-hidden="true" class="close-icon material-symbols-outlined">
                                                close
                                            </span>
                                        </button>
                                        <h5 class="modal-title" id="vizionareModalLabel">Schedule a viewing</h5>
                                    </div>
                                    <div class="modal-body">
                                        <div class="p">
                                            <p>Leave your contact details so our team can get in touch with you.</p>
                                        </div>
                                        <form>
                                            <input type="text" class="modal-form" [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="Name" placeholder="Name" required>
                                            <input type="email" class="modal-form" [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="Email" id="exampleFormControlInput1" placeholder="Email"
                                                required>
                                            <input type="tel" class="modal-form" [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="Phone" placeholder="Phone" required>
                                            <textarea id="modal-mesaj" class="modal-form"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Message"
                                                placeholder="Leave us a message" rows="3"></textarea>

                                            <div class="privacylink1"><input (click)="checked()" id="checkbox1"
                                                    type="checkbox" required><label class="form-control1">I agree with the <a id="ppLink" (click)="seetermeni()"
                                                        data-dismiss="modal">data processing policy.</a></label></div>
                                            <h6 *ngIf="acestCamp">This field is required</h6>

                                            <button class="deactive modal-button" type="button" (click)="sendFormOne()"
                                                data-dismiss="modal">SEND</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Modal Brosura-->
                        <div class="modal fade" id="brosuraModal" tabindex="-1" role="dialog"
                            aria-labelledby="brosuraModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                            aria-hidden="true">
                                            <span aria-hidden="true" class="close-icon material-symbols-outlined">
                                                close
                                            </span>
                                        </button>
                                        <h5 class="modal-title" id="brosuraModalLabel">See the brochure</h5>
                                    </div>
                                    <div class="modal-body-brosura">
                                        <div class="p">
                                            <p>Complete the form below to see The Lake Home brochure.</p>
                                        </div>
                                        <form>
                                            <input type="text" class="modal-form" placeholder="Name"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Name" required>
                                            <input type="email" class="modal-form" id="exampleFormControlInput1"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Email"
                                                placeholder="Email" required>
                                            <input type="tel" class="modal-form" placeholder="Phone"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Phone" required>

                                            <div class="privacylink2"><input (click)="checked2()" id="checkbox2"
                                                    type="checkbox" required><label class="form-control2">I agree with the<a id="ppLink2" data-dismiss="modal"
                                                        (click)="seetermeni()">data processing policy.</a></label></div>
                                            <h6 *ngIf="acestCamp1">This field is required</h6>
                                            <button class="deactive modal-button-brosura" type="button"
                                                (click)="sendFormOne2()" data-dismiss="modal">See brochure</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>    

                        <!-- XMAS promo Modal-->
                        <div class="modal fade" id="XmasModal" tabindex="-1" role="dialog"
                            aria-labelledby="XmasModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content XmasContent">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                            aria-hidden="true">
                                            <span aria-hidden="true" class="close-icon material-symbols-outlined">
                                                close
                                            </span>
                                        </button>
                                        <h5 class="modal-title XmasTitle" id="OfertaModalLabel">HO, HO,
                                            <span>HOME</span></h5>
                                    </div>
                                    <div class="modal-body-oferta">
                                        <div class="p">

                                            <p> <span class="xmasSubTitle">Serbează <strong>Crăciunul</strong> acasă și
                                                    fă loc
                                                    sub brad pentru <strong>un cadou în plus.</strong> </span><br><br>

                                                Achiziționează unul din ultimele apartamente disponibile
                                                în blocul C sau achită <strong>un avans de 50% </strong>pentru un
                                                apartament în blocul B1 și primești cadou de casă nouă
                                                un <strong>robot de bucătărie Thermomix TM6.</strong> <br><br>

                                                În plus, îți oferim un<strong> avantaj client</strong> cuprins între
                                                <strong>2,500 și
                                                    38,000 euro </strong>în baza avansului din prețul de listă pentru
                                                noua ta locuință în blocurile B1 și B2.<br><br>

                                                Lasă-ne datele tale și programează o vizionare.</p>
                                        </div>
                                        <form>
                                            <input type="text" class="modal-form" placeholder="Nume"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Name" required>
                                            <input type="email" class="modal-form" id="exampleFormControlInput1"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Email"
                                                placeholder="Email" required>
                                            <input type="tel" class="modal-form" placeholder="Telefon"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Phone" required>
                                            <textarea id="modal-mesaj" class="modal-form"
                                                placeholder="Scrie-ne un mesaj" [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="Message" rows="3"></textarea>

                                                <div class="privacylink2"><input (click)="checked4()" id="checkbox2"
                                                    type="checkbox" required><label class="form-control2">Sunt de acord
                                                    cu <a id="ppLink2" data-dismiss="modal"
                                                        (click)="seetermeni()">Consimțământul de prelucrare a
                                                        datelor.</a></label></div>
                                            <h6 *ngIf="acestCamp1">Acest câmp este obligatoriu</h6>
                                            <button class="deactive modal-button-oferta" type="button"
                                                (click)="sendFormOferta()" data-dismiss="modal">TRIMITE MESAJUL</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="logo-navbar">

                        <div class="mob-navbar">
                            <div class="distance-top-navbar">
                                <a class="blue-border" (click)="seelifestyle()">LIFESTYLE</a>
                                <a class="blue-border" (click)="footerlinks1()">CHOOSE&nbsp;YOUR&nbsp;RESIDENCE</a>
                                <a class="blue-border" (click)="seelocatie()">LOCATION</a>
                                <a class="blue-border" href="https://thelake.ro/galerie-en/">GALLERY</a>
                                <a class="blue-border" (click)="seemedia()">MEDIA</a>
                                <a class="blue-border" (click)="seedezvoltator()">DEVELOPER</a>
                                <a (click)="seecontact()">CONTACT</a>
                            </div>

                        </div>
                        <div class="burger" (click)="navSlider()">
                            <div class="line1"></div>
                            <div class="line2"></div>
                            <div class="line3"></div>
                        </div>

                        <div class="navbar">
                            <a (click)="seelifestyle()">LIFESTYLE</a>
                            <a (click)="footerlinks1()">CHOOSE&nbsp;YOUR&nbsp;RESIDENCE</a>
                            <a (click)="seelocatie()">LOCATION</a>
                            <a href="https://thelake.ro/galerie-en/">GALLERY</a>
                            <a (click)="seemedia()">MEDIA</a>
                            <a (click)="seedezvoltator()">DEVELOPER</a>
                            <a (click)="seecontact()">CONTACT</a>
                        </div>
                    </div>
                </div>
                    <div class="mob-page-title">
                        <h1>Your&nbsp;home</h1>
                    </div>    
            </div>

            <div class="header-slider">
                <div id="carouselExampleControls" class="carousel slide" data-ride="carousel" data-interval="false">
                    <div class="carousel-inner" id="headerSlider-inner">
                      <div class="carousel-item active">
                        <img class="d-block w-100" src="../../../assets/media/headerSliders/locuinte_1n.png" alt="">
                      </div>
                      <div class="carousel-item">
                        <img class="d-block w-100" src="../../../assets/media/headerSliders/locuinte_3.jpg" alt="">
                      </div>
                      <div class="carousel-item">
                        <img class="d-block w-100 testImg" src="../../../assets/media/headerSliders/locuinte_2.jpg" alt="">
                      </div>                      
                      <div class="carousel-item">
                        <img class="d-block w-100" src="../../../assets/media/headerSliders/locuinte_4.jpeg" alt="">
                      </div>
                    </div>
                    <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span id="bloc-prev" class="material-symbols-outlined">arrow_circle_left</span>
                    </a>
                    <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span id="bloc-next" class="material-symbols-outlined">arrow_circle_right</span>
                    </a>
                </div>
            </div>           


            <!-- call to action buttons -->
            <div class="cta-btns">
                <div class="cta-visionare" type="button" data-toggle="modal" data-target="#vizionareModal">
                    <span id="cta-viz-icon" class="material-symbols-outlined">chat_bubble</span><a>SCHEDULE&nbsp;A&nbsp;VIEWING</a>
                </div>
                <div class="cta-brosura" type="button" data-toggle="modal" data-target="#brosuraModal">
                    <img src="../../../assets/media/icons/brosuraIcon.svg" alt="download brosura icon"><a>SEE THE BROCHURE</a>
                </div>
                
            </div>

            <div class="sticky-btn" type="button" data-toggle="modal" data-target="#vizionareModal"><span id="cta-viz-icon" class="material-symbols-outlined">chat_bubble</span>
                <h5>Schedule <br> a viewing</h5></div>


            <!--xmasPromo
            <div class="XMAS-sticky-btn" type="button" data-toggle="modal" data-target="#XmasModal"><img
                src="../../../assets/media/icons/favpng_vector-graphics-gift-clip-art-box.png" alt="">
        </div>-->
        </div>
        <div class="page-title"><h1>Your&nbsp;home</h1></div>
    </header>

    <div class="mob-header-slider">
        <div class="header-slider-bcground"></div>
        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel"> 
            <ol class="carousel-indicators">
              <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
            </ol>
            <div class="carousel-inner" id="mobSlider-inner">
              <div class="carousel-item active">
                <img class="d-block w-100" src="../../../assets/media/headerSliders/locuinte_1n.png" alt="">
              </div>
              <div class="carousel-item">
                <img class="d-block w-100" src="../../../assets/media/headerSliders/locuinte_3.jpg" alt="">
              </div>
              <div class="carousel-item">
                <img class="d-block w-100" src="../../../assets/media/headerSliders/locuinte_2.jpg" alt="">
              </div>
              <div class="carousel-item">
                <img class="d-block w-100" src="../../../assets/media/headerSliders/locuinte_4.jpeg" alt="">
              </div>
            </div>
          </div>
    </div>

    <div class="header-database">
        <div class="dataBase-wrapper">
            <h5>See the plans</h5>

            <div class="db-etaj">
                <div class="etaj-cicle"><span id="db-etaj-arrow" class="material-symbols-outlined">chevron_right</span></div>
                    <div class="etaj-names" id="moreetaj">
                        <h6 class="etaj-arrow">Building</h6>
                        <ul class="nrEtaj1">
                            <li (click)="newmakebuildingC()" id="special">C</li>
                            <li (click)="newmakebuildingB()">B1</li>
                            <li (click)="newmakebuildingB2()" >B2</li>
                        </ul>
                    </div>    
            </div>
            <div class="db-etaj">
                <div class="etaj-cicle"><span id="db-etaj-arrow" class="material-symbols-outlined">chevron_right</span></div>
                    <div class="etaj-names" id="moreetaj">
                        <h6 class="camere-arrow">number of rooms </h6>
                        <ul class="nrEtaj">
                        <li class="deactive" (click)="newmakerooms1()">1</li>
                        <li class="deactive" (click)="newmakerooms2()">2</li>
                        <li class="deactive" (click)="newmakerooms3()">3</li>
                        <li class="deactive" (click)="newmakerooms4()">4</li>
                        <li class="deactive" (click)="newmakerooms5()">5</li>
                        </ul>
                    </div>    
            </div>
            <div class="db-camere">
                <div class="camere-cicle"><span id="db-camere-arrow" class="material-symbols-outlined">chevron_right</span>
                </div>
                <div class="camere-names" id="moreCamere">
                    <h6 class="camere-arrow">maximum price </h6>
                    
                    <select [(ngModel)]="InitialPriceFilter"
                     (change)="pricefilter()"class="pret-range">
                        <option ></option>
                        <option>75.000 €</option>
                        <option>90.000 €</option>
                        <option>110.000 €</option>
                        <option>135.000 €</option>
                        <option>160.000 €</option>
                        <option>210.000 €</option>
                    </select>
                </div>    
            </div>
           <!--  <div class="db-button"><button>VEZI LISTA DE PLANURI</button></div>  -->
        </div>  
    </div>

    <section class="plans-wrapper">
        <h2>Apartments available</h2>
        <div class="plans-tags">
   
            <div class="all-tags-wrapper-Desk" >
                <ul >
                    <li class="plan 1 show" *ngFor="let dataItem of BuildingList"> 
                        <img src="../../../assets/{{dataItem.BuildingPhoto}}" alt="">
                        <div>
                            <h4>{{dataItem.ApartmentRooms}} {{camere}}</h4>
                            <div class="etaj-utila">
                                <div class="etaj">
                                    <h5>floor</h5>
                                    <p>{{dataItem.BuildingFloor}}</p>
                                </div>
                                <div class="utila">
                                    <h5>surface</h5>
                                    <p>{{dataItem.ApartmentSurface}} M²</p>
                                </div>
                            </div>
                            <h5>Building {{dataItem.BuildingName}}</h5>
                            <p>nr. {{dataItem.ApartmentNumb}}</p>
                            <h6>{{dataItem.ApartmentPrice.slice(0, -3)}},{{dataItem.ApartmentPrice.slice(-3)}}<span>€+TVA </span></h6>
                            <p>TURNKEY PRICE</p>
                            <button data-toggle="modal" data-target="#detailModal" (click)="seeApartment2(dataItem)">details</button> 
                        </div>    
                    </li>
                </ul>
            </div> 

            <div class="all-tags-wrapper">
                <ul>
                    <li class="plan 1 show"> 
                        <img src="{{hollowPhoto}}" alt="">
                        <div>
                            <h4>{{hollowNrOfRooms}} </h4>
                            <div class="etaj-utila">
                                <div class="etaj">
                                    <h5>floor</h5>
                                    <p>{{hollowFloor}}</p>
                                </div>
                                <div class="utila">
                                    <h5>surface</h5>
                                    <p>{{hollowSurface}}</p>
                                </div>
                            </div>
                            <h5>Building {{hollowBuilding}}</h5>
                            <p>{{hollowApartmentNumb}}</p>
                            <h6>{{hollowPrice}} <span>€+TVA </span></h6>
                            <p>TURNKEY PRICE</p>
                            <button data-toggle="modal" data-target="#detailModal" (click)="seeApartment()">details</button> 
                        </div>    
                    </li>
                </ul>
            </div>


            <div class="plans-tags-btns">
                <span id="tags-prev1" class="material-symbols-outlined" >arrow_circle_left</span>
                <span id="tags-prev" class="material-symbols-outlined" (click)="selectedPrev()">arrow_circle_left</span>
                <span id="tags-next" class="material-symbols-outlined" (click)="selectedNext()">arrow_circle_right</span>
                <span id="tags-next1" class="material-symbols-outlined" >arrow_circle_right</span>
            </div>
        </div>
    </section>

    <section class="view360">
        <h3>See the virtual tour</h3>
        <div class="view-img" (click)="seeVirtual()">
            <img class="viewIcon" src="../../../assets/media/icons/360-whiteShao-01.svg" alt="">
        </div>
    </section>

    <section class="page-content-wrapper">
        <div class="page-content"> 
            <div id="content-7">
                <h3>The essence of a well-designed environment lies in the finishes</h3>
            </div>

            <img id="content-9" src="../../../assets/media/carousel/carousel5.jpg" alt="Rendering of The Lake Home Spa and Venue">

            <h4 id="content-8">High-quality finishes are the guarantee of a carefree life in your new&nbsp;home.</h4>
    
            <div class="division"></div>

            <div id="content-10">
                <p>All apartments will have high security metal doors for the entrance, underfloor heating, VISSMANN condensing boiler, double glazing with SCHUCO aluminum profile with 3 sheets of glass, white painted walls, sanitary, electrical and gas installations with separate metering.</p>
            </div>
        </div>

        <div class="gray-bckg-wrapper"><div class="gray-bckg"></div></div>

    </section>


    <section class="allpages-bottom">

        <section class="investitia-wrapper" id="INVESTEȘTE">

            <div class="inv-wrapper-wrapper">      
                <div class="investitia">
                    <h2>What is the ideal investment for&nbsp;you?</h2>
                    <div class="investitia-blueBar"></div>
                    <p>Smart real estate investing has been, is, and will be one of the best ways to transport money over time.</p>
                </div>	
                <div class="calculator">
    
                    <div class="bigdiv">
                        <h3>Investment calculator</h3>
                        <p class="suma">Invested amount</p>
                        <input class="calcone" id="first" min="7000" placeholder="amount in €" type="number">
                        <p class="termen">Term <br></p>
                        <div class="labelandimput">
                            <div class="labelandimput2">
                                <input  type="radio" id="age2" name="age" value="15" checked="checked">
                                <label class="tag" for="age2">18&nbsp;months</label>
                            </div>
                                <div class="labelandimput2">
                                <input  type="radio" id="age3" name="age" value="35">
                            <label class="tag" for="age3">36&nbsp;months</label> 
                            </div>
                            <div class="labelandimput2">
                                <input  type="radio" id="age4" name="age" value="55">
                                <label class="tag" for="age4">54&nbsp;months</label>
                            </div>
                        </div>
                        <button onclick="add()" class="calc-btn">Calculate</button>
                    </div>
                    <div class="rectangle">
                        <p class="procentul">TERM PROFIT&nbsp;% 
                        <input class="firstresult" id="answer3" disabled="disabled" placeholder="_ %"></p>
                        
                        <p class="dobanda">TERM PROFIT&nbsp;€
                        <input class="firstresult" id="answer2" disabled="disabled" placeholder="_ €"></p>   
            
                        <p class="return">AMOUNT RETURNED ON TERM&nbsp;€
                        <input class="firstresult" id="answer" disabled="disabled" placeholder="_ €"></p>
                    </div>
            
                </div>
            </div>    
        </section>

        <section class="testemonials">
            <h2>Testimonials</h2>
  
            <app-testimonials></app-testimonials>
               
            <div class="bg-img-testm"></div>
        </section>
    </section>


    <footer>
        <div class="footer-wrapper">
            <div class="footer-logo">
                <p>Developed by</p>
                <img src="../../../assets/media/logos/sigla_negative-02.png" alt="Novarion Logo" onclick="NewTabNovarion()">
            </div>
            <div class="footer1">
                <h5>Sales office</h5>
                <p class="footer1-bold">The Lake Home</p>
                <a href="https://goo.gl/maps/u8YrSsReceFjCgq37" target=”_blank”><p>Tractorului street, 2-12<br>Sibiu, Romania</p></a> 
                <div>
                    <span>T.</span><a href="tel:+40721229999">+40 721 229 999</a> <br><br>
                    <span>T.</span><a href="mailto:sales@thelake.ro">sales@thelake.ro</a>
                </div>
                <img src="../../../assets/media/pattern/brand_icon.svg" alt="">
            </div>
            <div class="footer2">
                <h5>Choose your residence</h5>
                <div class="footer2-rezidentie">
                    <div class="rezidentie1">
                        <a (click)="footerlinks1()">STUDIO</a> <br>
                        <a (click)="footerlinks2()">2 room apartment</a><br>
                        <a (click)="footerlinks3()">3 room apartment</a><br>
                        <a (click)="footerlinks4()">5 room apartment</a><br>
                    </div>
                    <!--
                    <div class="rezidentie2">
                        <a href="">VILA TIP A</a><br>
                        <a href="">VILA TIP B</a><br>
                        <a href="">VILA TIP C</a><br>
                    </div>-->
                </div>
                <div class="footer2-pages">
                    <div class="pages1">
                        <a (click)="seehome()">HOME</a> <br>
                        <a (click)="seelifestyle()">LIFESTYLE</a><br>
                        <a (click)="seelocatie()">LOCATION</a><br>
                    </div>
                    <div class="pages2">
                        <a href="https://thelake.ro/galerie-en/">GALLERY</a><br>
                        <a (click)="seemedia()">MEDIA</a><br>
                        <a (click)="seedezvoltator()">DEVELOPER</a><br>
                    </div>
                </div>
                <div class="footer2-socialMedia">
                    <img src="../../../assets/media/icons/facebook_logo_icon.svg" alt="Facebook link" onclick="NewTabFb()">
                    <img src="../../../assets/media/icons/linkedin_logo_icon.svg" alt="LinkedIn link" onclick="NewTabLi()">
                    <img src="../../../assets/media/icons/instagram_logo_icon.svg" alt="Instagram link" onclick="NewTabIg()">
                    <img src="../../../assets/media/icons/youtube_icon.svg" alt="Youtube link" onclick="NewTabYt()">
                </div>
                <div class="footer2-end">
                    <a (click)="seetermeni()">Privacy policy | Cookies</a>
                    <p>Brand & Web Design : <a href="https://www.mugurelfrincu.ro/" target="_blank"> Mugurel Frîncu</a></p>
                </div>
            </div>
            <div class="footer3">
                <h4>We will get back to you</h4>
                <p>Fill out the form below to get a fast reply from our sales representatives</p>
                <input type="text" placeholder="Name"[ngModelOptions]="{standalone: true}" [(ngModel)]="Name" required> 
                <input type="email" placeholder="Email"[ngModelOptions]="{standalone: true}" [(ngModel)]="Email" required>
                <input type="tel" placeholder="Phone"[ngModelOptions]="{standalone: true}" [(ngModel)]="Phone" required>
                <textarea id="mesaj" placeholder="Leave us a message"[ngModelOptions]="{standalone: true}" [(ngModel)]="Message"></textarea>
                <div class="privacylink3"><input (click)="checked3()" id="checkbox3" type="checkbox" required><label
                        class="form-control3">I agree with the <a id="ppLink3" (click)="seetermeni()"> data processing policy.</a></label></div>
                <h6 *ngIf="acestCamp2">This field is required</h6>
                <button class="deactive" type="button" (click)="sendFormOne3()">SEND</button>
            </div>
        </div>   
    </footer>



    <!-- Modal Detail plan -->
    <div class="modal fade" data-backdrop="static" id="detailModal" tabindex="-1" role="dialog" aria-labelledby="modaldetailModal"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header" id="detailHeader">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModalDetalii()" aria-hidden="true">
                        <span aria-hidden="true" class="close-icon material-symbols-outlined">
                                close
                        </span>
                    </button>
                    <h2 class="modal-title" id="vizionareModalLabel">Apartment {{numarulapartamentuluiselectat}}</h2>
                </div>
    
                <div class="modal-body">
                  <app-detail-modal *ngIf="modaldetalii"></app-detail-modal> 
                </div>
                <div class="modal-footer">
                </div>
            </div>
        </div>
    </div>


<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js"></script>
<script src="locuinte.scripts.js"></script>    
</body>

