import { ThisReceiver } from '@angular/compiler';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, HostListener, Renderer2} from '@angular/core';
import {SharedService} from 'src/app/shared.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-modal-brosura',
  templateUrl: './modal-brosura.component.html',
  styleUrls: ['./modal-brosura.component.css']
})
export class ModalbrosuraComponent implements OnInit, AfterViewInit {

  constructor(private service:SharedService, private router: Router, private datePipe: DatePipe,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private renderer: Renderer2,
    private elementRef: ElementRef,) { }


  ngAfterViewInit(): void {
    throw new Error('Method not implemented.');
  }
  BuildingId!: string;
  Name!: string;
  Phone!: string;
  Email!: string;
  Message!: string;
  Oras!: string;
  FormLocation!: string;
  DateOfGiving!: any;
  BucketDate!: Date;
  checkmark:boolean=false;
  checkmarckCheck!: number;
  checkmark1:boolean=false;
  checkmarckCheck1!: number;
  checkmark2:boolean=false;
  checkmarckCheck2!: number;
  checkmark3:boolean=false;
  checkmarckCheck3!: number;
  BuildingList:any=[];
  acestCamp:boolean=false;
  acestCamp1:boolean=false;
  acestCamp2:boolean=false;
  ApartmentPrice!: string;
  ApartmentRooms!: any;
  adm:any;
  BuildingIdFilter!: string;

  ngOnInit(): void {
  }


  checked2(){
    this.checkmarckCheck1 = this.checkmarckCheck1 +1;
    if(this.checkmarckCheck1 % 2 === 0 ){
    this.checkmark1 = true;

    const modalbody=document.querySelector('.modal-body-brosura button');
    modalbody!.className = "modal-button-brosura";

    }
    else{
      this.checkmark1 = false;

      const modalbody=document.querySelector('.modal-body-brosura button');
      modalbody!.className = "deactive modal-button-brosura";
    }

  }


seetermeni(){
  this.router.navigateByUrl('/termeni-si-conditii');
}

sendFormOne2() {
  if (this.checkboxChecked) {
    this.FormLocation = "descărca brosura"
    this.BucketDate = new Date();
    this.DateOfGiving = (this.datePipe.transform(this.BucketDate,"yyyy-MM-dd"));
      var val = {BuildingId:this.BuildingId,
      Name:this.Name,
      Phone:this.Phone,
      Email:this.Email,
      Message:'A DESCARCAT BROSURA '+this.Oras,
      FormLocation:this.FormLocation,
      DateOfGiving:this.DateOfGiving,};
  
    this.service.addform(val).subscribe(res=>{
      (res.toString());
    });
    alert("Mesajul tau a fost trimis")
    window.open("../../assets/pdf/Brochure_2024_RO.pdf")
  } else {
    this.acestCamp1 = true;
  }
}

  checkboxChecked: boolean = false;

  toggleCheckbox() {
      this.checkboxChecked = !this.checkboxChecked;
  }
}
