<body>  
    <!--<img id="bckgimg" src="../../../assets/media/thank-you.jpg" alt="">-->
    <div class="typage">
        <div class="typage-wrapper">
            <div class="idvlogos">
                <img class="tlh" src="../../assets/media/logos/the_lake_logo_white.png"
                    alt="logo of The Lake home - Living Xperience logo" (click)="seehome()">

                <img class="nvrn" src="../../assets/media/logos/sigla_negative-02.png"
                    alt="logo of Novarion - Xperience the Future" (click)="seehome()">
            </div>

            <div class="title">
                <h1>Mulțumim!</h1>
                <p>Participarea ta la tombola exclusivă a fost înregistrată cu succes.<br>
                Extragerile premiilor vor avea loc vineri, 3 noiembrie, la ora 15:00 (ora București).
                </p>

                <!--<H2>{{raffleNumber}}</H2>-->
                
                
                <p><strong> Cum afli dacă ai câștigat?</strong></p>
                   
                <p>Foarte simplu! Vom anunța câștigătorii pe canalele noastre de social media, așa că asigură-te că urmărești paginile noastre pentru a fi la curent cu ultimele noutăți. În plus, câștigătorul va fi notificat direct prin email. <br> <br>
                Până atunci, îți mulțumim pentru interesul acordat și participarea la tombolă. </p>
                    
            
                <p><strong>Mult succes!</strong></p>

                <!-- Additional content if needed -->
            </div>
        </div>
    </div>
        


</body>