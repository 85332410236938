import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.css']
})
export class RulesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    window.location.href = "../assets/pdf/termeni_si_conditii_organizare_eveniment_.pdf";

  }

}
