import { Component, Input, OnInit } from '@angular/core';
import {SharedService} from 'src/app/shared.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {

  constructor(private service:SharedService,  private router: Router, private datePipe: DatePipe) { }

  BuildingId!: string;
  blogtitlu:string="";
  blogdata!: any;
  BucketDate!: Date;
  Datablog!: Date;

  DateOfGiving!: any;
  blogsursa:string="";
  blogcategorie:string="";
  blogcuprins:string="";
  blogphoto:string="";
  PhotoFilePath!: string;
  selectedblog:any;


  ngOnInit(): void {
    this.selectedblog=this.service.editBlogSelect;


      this.BuildingId=this.selectedblog.BuildingId;
      this.blogtitlu=this.selectedblog.blogtitlu;
      this.blogdata=this.selectedblog.blogdata;
      this.blogsursa=this.selectedblog.blogsursa;
      this.blogcategorie=this.selectedblog.blogcategorie;
      this.blogcuprins=this.selectedblog.blogcuprins;
      this.blogphoto=this.selectedblog.blogphoto;


  }
  addblog(){
    this.BucketDate = new Date();
    this.DateOfGiving = (this.datePipe.transform(this.BucketDate,"yyyy-MM-dd"));
  
    var val = {
      BuildingId:this.BuildingId,
      Name:"blog",
      Phone:"blog",
      Email:"blog",
      Message:"blog",
      FormLocation:"blog",
      DateOfGiving:this.DateOfGiving,
      blogtitlu:this.blogtitlu,
      blogdata:this.Datablog,
      blogsursa:this.blogsursa,
      blogcategorie:this.blogcategorie,
      blogcuprins:this.blogcuprins,
      blogphoto:this.blogphoto,

    };
  
    this.service.addform(val).subscribe(
      res => {
        console.log(res.toString());
        this.router.navigateByUrl('/add-b' )
      },
      err => {
        console.error(err);
        alert('Contact your Ovidiu');
      }
    );
  }  
  uploadPhoto(event:any){
    var file=event.target.files[0];
    const formData:FormData=new FormData();
    formData.append('uploadedFile',file,file.name);
    this.service.UploadPhotoB(formData).subscribe((data:any)=>{
      this.blogphoto=data.toString();
      this.PhotoFilePath=this.service.PhotoUrl+this.blogphoto;
    })
  }
  
  updateBlog(){
    this.BucketDate = new Date();
    this.DateOfGiving = (this.datePipe.transform(this.BucketDate,"yyyy-MM-dd"));
    var val = { BuildingId:this.BuildingId,
      Name:"blog",
      Phone:"blog",
      Email:"blog",
      Message:"blog",
      FormLocation:"blog",
      DateOfGiving:this.DateOfGiving,
      blogtitlu:this.blogtitlu,
      blogdata:this.Datablog,
      blogsursa:this.blogsursa,
      blogcategorie:this.blogcategorie,
      blogcuprins:this.blogcuprins,
      blogphoto:this.blogphoto,};

      this.service.updateform(val).subscribe(
        res => {
          console.log(res.toString());
          this.router.navigateByUrl('/add-b' )

        },
        err => {
          console.error(err);
          alert('Contact your Ovidiu');
        }
      );
  }

}


