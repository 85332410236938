import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-qrredirect',
  templateUrl: './qrredirect.component.html',
  styleUrls: ['./qrredirect.component.css']
})
export class QrredirectComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    this.qrRedirect()
  }


  qrRedirect(){
    window.open("https://locuintamea.novarion.ro/","_self")
  }
}
