
<div *ngIf="pinin">
    <label class="col-sm-2 col-form-label">parola</label>
    <div class="col-sm-2">
        <input type="password" class="form-control" [(ngModel)]="senha"
        placeholder="">
    </div><br>
    
      <button class="btn btn-primary" (click)="adminsenha()">
        acces
    </button>
    </div>
    
    
    
    
    
    <div *ngIf="pingood">

    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <a class="navbar-brand" href="#">ADMIN</a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
      
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">

            <li class="nav-item">
              <a class="nav-link" (click)="seeforms()">Vezi apartamente</a>
            </li>
          </ul>
  
        </div>
      </nav>
<table class="table table-striped">
    <thead>
      <tr>
        <th>Locatie form</th>
        <th>Nume</th>
        <th>email</th>
        <th>telefon</th>
        <th>data</th>
        <th>mesaj</th>
        <th>del</th>

    </tr>
</thead>
<tbody>
    <tr *ngFor="let dataItem of ToolList"  >
        <td>{{dataItem.FormLocation}}</td>
        <td>{{dataItem.Name}}</td>
        <td>{{dataItem.Email}}</td>
        <td>{{dataItem.Phone}}</td>
        <td>{{dataItem.DateOfGiving}}</td>
        <td>{{dataItem.Message}}</td>
        <td>
          <button type="button" class="btn btn-danger mr-1"
              (click)="deleteClick(dataItem)"
              style="margin:5px;">
              Sterge
              </button>
        </td>
        </tr>
    </tbody>
</table>
</div>