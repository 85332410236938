<body>
    <header class="headerGrid">
        <div class="headerGrid-wrapper">
            <div class="header-titles">
                <div class="header-logos">
                    <img class="tlh" src="../../assets/media/logos/Festival_lake_white.svg"
                        alt="logo of The Lake home - Living Xperience logo" (click)="seefestival()">

                    <img class="nvrn" src="../../assets/media/logos/sigla_negative-02.png"
                        alt="logo of Novarion - Xperience the Future" onclick="NewTabNovarion()">
                </div>


                <div class="all-nav-elements">
                    <div class="contact-nav">

                        <div class="flexRow">
                            <!-- 
                            <a (click)="changeRoEn()">EN</a>-->
                        </div>
                    </div>
                </div>
                <div class="mob-page-title">
                    <h1>Premii la Festival Lake</h1>
                    <h2>Intrați în concurs și lăsați-vă purtați de vântul aventurii!</h2>

                    <p>
                        Participă la acest eveniment unic pentru șansa de a câștiga una dintre cele șase premii speciale, oferite de The Lake Home.
                    </p>
                    <button class="lp-cta" (click)="scroll(joinForm)">Înscrie-te acum</button>
                </div>
            </div>
        </div>
    </header>

    <section class="page-content-wrapper"><!--  
        <h3>Festivalul Lake Își Deschide Porțile</h3>
        <div class="about">
            <img class="lifestyle-icon" src="../../assets/media/pattern/Artwork29.svg" alt="">
            <p>Începutul verii vine cu inaugurare a Festivalului Lake! Echipa Novarion și partenerii noștri sunt
                încântați să te invite la debutul acestei sărbători extraordinare, care va avea loc între 1-2 iunie.
                <br><br>
                Ne propunem să transformăm acest eveniment într-o explozie de bucurie și culori, cu focus special pentru
                distracția familiilor și a copiilor, având pregătite o serie de activități interesante și atractive.
                <br><br>
                Și pentru că vrem ca toată lumea să se bucure de această ocazie specială, intrarea la Festival Lake este
                gratuită pentru toți. Așadar, nu ezita să aduci întreaga familie și prietenii pentru a savura această
                experiență unică și atmosfera noastră caldă și primitoare. <br><br>
                Ne-am dedicat să creăm un eveniment memorabil, unde fiecare moment să fie plin de distracție și bucurie.
                Și ca surpriză, pregătim și premii spectaculoase pentru participanți!
        </div>

        <div class="imgs-triowrapper">
            <div class="imgs-trio">
                <img src="../../assets/media/formulartrio_3.jpg" alt="">
                <img src="../../assets/media/tombola_2.jpg" alt="">
                <img src="../../assets/media/tombola_1.jpeg" alt="">
            </div>
            <div class="imgs-trio-bckgnd"></div>
        </div>
<div class="goals-wrapper">
            <h3>Comunitatea The Lake Home</h3>
            <div class="goals">
                <div class="goals-text">
                    <div class="innertext">
                        <img class="lifestyle-icon" src="../../assets/media/pattern/Artwork29.svg" alt="">
                        <p>Cunoști povestea Cartierului cu bune maniere? Ei bine, nu demult, un om așa ca tine, cu visuri și dorințe, a început să transforme în realitate ceea ce și-a imaginat. A crezut în visul lui, a atras oameni care să creadă la fel și a trecut la fapte. Cum ar fi să construim, chiar aici, în inima României, cel mai frumos cartier, un loc cu suflet și cu oameni manierați? Oare ar fi posibilă o comunitate de oameni care să creadă în binele comun și să trăiască în acest spirit, într-un loc care să le asigure multe avantaje? Raul Țărnaru, fondatorul NOVARION și al proiectului The Lake Home a zis „Da!” și, iată, povestea merge mai departe.<br><br>

                        The Lake Home prinde contur și, mai mult decât atât, deschide ușa altor proiecte la fel de faine. Ne-am gândit că ți-ar plăcea un parc de agrement croit exact pe gustul tău. De aceea, cel mai bine este să îl realizăm împreună cu tine.
                        </p>
                    </div>
                    
                </div>
                <img id="goals-bigImg" src="../../assets/media/goals_bigImg.jpg" alt="">
            </div>

            <div class="division"></div>
        </div>-->


<div class="sub-types">
            <h3 id="types-header">Câștigă premii fabuloase la Festival Lake!</h3>
            <!--  <p>Novarion și partenerii noștri sunt încântați să îți ofere aceste premii extraordinare cu ocazia
                inaugurării Festival Lake, acest parc vibrant care face parte din cartierul rezidențial The Lake Home. <br><br>
            </p> -->
            <ul>    <!-- 
                <li><strong>Air Sensation:</strong> o plimbare cu balonul pentru 2 persoane</li>
                <li><strong>The River Chalet: </strong> o tură cu Can-am pentru 3 persoane</li>
                <li><strong>The Lake Home:</strong> o noapte de cazare la The Lake Home</li>
                <li><strong>Iaconi:</strong> 10 igienizări, 10 consultații pentru copii, 3 albiri profesionale și un irigator bucal</li>
                <li><strong>Povestea Calendarului:</strong> un card de acces</li>
                <li><strong>Nexus Paintball:</strong> un voucher pentru 10 persoane</li>
                <li><strong>CodeMaster:</strong> 3 vouchere pentru un curs gratuit pe o lună</li>
                <li><strong>EcoSport:</strong> 5 antrenamente de baschet și 5 de volei</li>
                <li><strong>Novarion:</strong> 10 cărți "De la Idee la Bani"</li>
                <li><strong>Andy Szekely:</strong> 5 cărți pentru copii și 5 pentru adulți</li>
                <li><strong>NFT Tickets:</strong> 5 EGLD</li>
                -->
                <li><strong>1 cursa cu can-am-ul</strong> pe munte</li>
                <li><strong>5 curse cu can-am-ul</strong> in cartier</li>
            </ul>
            <br><br> 
            <p>Aceste premii sunt modalitatea noastră de a te invita să te alături nouă în această aventură unică și de a-ți mulțumi pentru susținerea comunității noastre. Nu rata oportunitatea de a te înscrie la concurs și de
                a câștiga o experiență de neuitat.</p>

            <h5>Vino alături de noi și trăiește aventura la Festival Lake!</h5>
        </div>   
    </section>

    <section class="join-form-wrap" #joinForm>
        <h3>Înscrie-te acum!</h3>
        <div class="join-form">
            <div class="aboutform">
                <ul class="list">
                    <li>&#x2022; Poți să dai <a (click)="seeregulament()">click aici</a> pentru a afla mai multe despre regulile
                        tombolei.</li>
                    <li>&#x2022; Câștigătorii vor fi extrași pe data de 27 aprilie, sâmbătă, la ora 19:00. 
                    </li>
                    <li>&#x2022; Castigatorii vor fi notificați prin email. Te încurajăm să verifici inbox-ul sâmbătă seară pentru a vedea dacă ai câștigat.</li>
                    <li>&#x2022; Mult noroc și abia așteptăm să anunțăm câștigătorii!</li>
                </ul>
                <img class="patternN" src="../../assets/media/pattern/brand_icon.svg" alt="">
            </div>

            <div class="formJoin">
                <input type="text" placeholder="Nume" [ngModelOptions]="{standalone: true}" [(ngModel)]="Name" required>
                <input type="email" placeholder="Email" [ngModelOptions]="{standalone: true}" [(ngModel)]="Email"
                    required>
                <input type="tel" placeholder="Telefon" [ngModelOptions]="{standalone: true}" [(ngModel)]="Phone"
                    required>
                <div class="privacylink3"><input (click)="checked3()" id="checkbox3" type="checkbox" required><label
                        class="form-control3">Sunt de acord cu <a id="ppLink3" (click)="seetermeni()"> Consimțământul de
                            prelucrare a datelor</a> și cu <a (click)="seeregulament()">regulamentu tombolei.</a> </label></div>
                <h6 *ngIf="acestCamp2">Acest câmp este obligatoriu</h6>
                <button class="deactive" type="button" (click)="sendFormOne3()">Trimite</button>
            </div>
        </div>
    </section>

    <footer>
        <div class="footer-wrapper">
            <div class="footer-logo">
                <p>Proiect dezvoltat de:</p>
                <img src="../../assets/media/logos/sigla_negative-02.png" alt="Novarion Logo"
                    onclick="NewTabNovarion()">
            </div>
            <div class="footer1">
                <h5>Birou vânzări</h5>
                <p class="footer1-bold">The Lake Home</p>
                <a href="https://goo.gl/maps/u8YrSsReceFjCgq37" target=”_blank”>
                    <p>Strada Tractorului, nr. 12- 28,<br>Sibiu, România</p>
                </a>
                <div>
                </div>
            </div>
            <div class="footer2">
                <h5>Contact</h5>
                <div class="footer2-rezidentie">
                    <div class="rezidentie1">
                        <span>T.</span><a href="tel:+40721229999">+40 721 229 999</a> <br>
                        <span>T.</span><a href="tel:+40756244404">+40 756 244 404</a> <br>
                        <span>E.</span><a href="mailto:sales@thelake.ro">sales@thelake.ro</a>
                    </div>
                </div>
            </div>
            <div class="footer3">
                <h5>Social media</h5>
                <div class="footer2-socialMedia">
                    <img src="../../assets/media/icons/facebook_logo_icon.svg" alt="Facebook link" onclick="NewTabFb()">
                    <img src="../../assets/media/icons/linkedin_logo_icon.svg" alt="LinkedIn link" onclick="NewTabLi()">
                    <img src="../../assets/media/icons/instagram_logo_icon.svg" alt="Instagram link"
                        onclick="NewTabIg()">
                    <img src="../../assets/media/icons/youtube_icon.svg" alt="Youtube link" onclick="NewTabYt()">
                </div>
                <div class="footer2-end">
                    <a (click)="seetermeni()">Politica de confidențialitate | Cookies</a>
                </div>
            </div>
        </div>
    </footer>
</body>

<router-outlet></router-outlet>