import { Component, OnInit, Input} from '@angular/core';
import {SharedService} from 'src/app/shared.service';

@Component({
  selector: 'app-apreview',
  templateUrl: './apreview.component.html',
  styleUrls: ['./apreview.component.css']
})
export class ApreviewComponent implements OnInit {

  constructor(private service:SharedService) { }


  @Input() adm:any;
  BuildingId!: string;
  BuildingName!: string;
  BuildingPhoto!: string;
  BuildingFloor!: string;
  ApartmentNumb!: string;
  ApartmentPhoto!: string;
  ApartmentSurface!: string;
  ApartmentRooms!: string;
  ApartmentPrice!: string;
  ApartmentBalconSurface!: string;
  ApartmentOrentation!: string;
  ApartmentBathrooms!: string;
  SurfaceDormitor!: string;
  SurfaceBaie!: string;
  SurfaceHol!: string;
  SurfaceRoom!: string;

  hollowApartmentNumb!: string;
  hollowFloor!: string;
  hollowNrOfRooms!: string;
  hollowSurface!: string;
  hollowPrice!: string;
  hollowPhoto!: string;
  hollowBuilding!: string;

  PhotoFilePath!: string;
  ngOnInit(): void {

    this.BuildingId=this.adm.BuildingId;
    this.BuildingName=this.adm.BuildingName;
    this.BuildingPhoto=this.adm.BuildingPhoto
    this.PhotoFilePath=this.service.PhotoUrl+this.PhotoFilePath;
    this.BuildingFloor=this.adm.BuildingFloor;
    this.ApartmentNumb=this.adm.ApartmentNumb;
    this.ApartmentPhoto=this.adm.ApartmentPhoto
    this.ApartmentSurface=this.adm.ApartmentSurface;
    this.ApartmentRooms=this.adm.ApartmentRooms;
    this.ApartmentPrice=this.adm.ApartmentPrice;
    this.ApartmentBalconSurface=this.adm.ApartmentBalconSurface;
    this.ApartmentOrentation=this.adm.ApartmentOrentation;
    this.ApartmentBathrooms=this.adm.ApartmentBathrooms;
    this.SurfaceDormitor=this.adm.SurfaceDormitor;
    this.SurfaceBaie=this.adm.SurfaceBaie;
    this.SurfaceHol=this.adm.SurfaceHol;
    this.SurfaceRoom=this.adm.SurfaceRoom;


    this.hollowApartmentNumb = "nr. " + this.adm.ApartmentNumb
    this.hollowFloor=this.adm.BuildingFloor
    let camere 
    if ( this.adm.ApartmentRooms == 1){
      camere = "cameră"
    }
    else{
      camere ="camere"
    }
    this.hollowNrOfRooms= this.adm.ApartmentRooms +" "+ camere
    this.hollowSurface =  this.adm.ApartmentSurface+" M²"
    this.hollowPhoto =  "../../../assets/" + this.adm.BuildingPhoto
    this.hollowBuilding = this.adm.BuildingName
    this.hollowPrice = this.adm.ApartmentPrice
  
  
    if(this.adm.ApartmentPrice == 0){
      this.hollowApartmentNumb = "indisponibil"
      this.hollowFloor=" indisponibil"
      this.hollowNrOfRooms= " indisponibil"
    
      this.hollowSurface = " indisponibil"
      this.hollowPhoto =  "../../../assets/" + this.adm.BuildingPhoto
      this.hollowBuilding = " indisponibil"
    }

  }




}
