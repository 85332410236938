import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import {SharedService} from 'src/app/shared.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  constructor(private service:SharedService, private router: Router) { }

  BuildingList:any=[];
  sortBuildingList:any=[];
  Filterinitial:boolean=false;

  ModalTitle!: string;
  ActivateAddEditEmpComp:boolean=false;
  adm:any;
  BuildingFloor!: string;
  BuildingIdFilter!: string;
  BuildingNameFilter!: string;
  BuildingPriceFilter!: number;
  InitialPriceFilter!: string;

  ApartmentNumFilter!: string;
  BuildingFloorFilter!: string;
  BuildingRoomsFilter!: string;
  BuildingListWithoutFilter:any=[];
  item:any=[];
  checkBuilding!: number;
  hollowApartmentNumb!: string;
  hollowFloor!: string;
  hollowNrOfRooms!: string;
  hollowSurface!: string;
  hollowPhoto!: string;
  hollowBuilding!: string;
  Vezi!: string;
  veziTrue:boolean=false;
  vezinum!: number;
  ApartmentNumb!: string;
  ApartmentPDF!: string;

  senha!: string;
  pingood:boolean=false;
  pinin:boolean=true;

  ngOnInit(): void {
    window.scrollTo(0, 0)
    this.Vezi="Vezi"

    this.refreshBldList();
    this.checkBuilding = 1;
    this.vezinum=1

  }

  filtersvizibility(){
    this.vezinum++
    if(this.vezinum%2){
      this.Vezi="Ascunde"
      this.veziTrue=true
    }
    else{
      this.Vezi="Vezi"
      this.veziTrue=false
    }
  }

  adminsenha(){
    if(this.senha == "1Novarion2"){
    this.pingood=true
    this.pinin = false}
    else{
      alert("gresit")
    }
  }
  buildingcHECHER(){
    if(this.checkBuilding % 2){
      this.BuildingNameFilter ="B"
      
    }
    else{
      this.BuildingNameFilter ="c"
    }
     
  }
  /* ------ etaj clafdire si numar camere selectors ------- */


  addClick(){
    this.adm={
      BuildingId:0,
      BuildingName:"",
      BuildingPhoto:"anonymous.png",
      BuildingFloor:"",
      ApartmentNumb:"",
      ApartmentPhoto:"anonymous.png",
      ApartmentSurface:"",
      ApartmentRooms:"",
      ApartmentPrice:"",
      ApartmentBalconSurface:"",
      ApartmentOrentation:"",
      ApartmentBathrooms:"",
      SurfaceDormitor:"",
      SurfaceBaie:"",
      SurfaceHol:"",
      SurfaceRoom:"",
      ApartmentPDF:"",
    }
    this.ModalTitle="Add Building";
    this.ActivateAddEditEmpComp=true;

  }

  editClick(item: any){
    this.adm=item;
    this.ModalTitle="Edit Building";
    this.ActivateAddEditEmpComp=true;
  }

  deleteClick(item: { BuildingId: any; }){
    
    if(confirm('Are you sure??')){
      this.service.deleteBuilding(item.BuildingId).subscribe((data: { toString: () => any; })=>{
        alert(data.toString());
        this.refreshBldList();
      })
    }
  }

  closeClick(){
    this.ActivateAddEditEmpComp=false;
    this.refreshBldList();
  }


  refreshBldList(){
    this.service.getBuildingList().subscribe(data=>{
      this.BuildingList=data.sort((a, b) => parseFloat(a.ApartmentNumb) - parseFloat(b.ApartmentNumb));
      this.BuildingListWithoutFilter=data;

    });
  }
  FilterFnP(){

    if(this.InitialPriceFilter == "80,000"){
      this.BuildingPriceFilter = 80000
    }
    var BuildingPriceFilter = this.BuildingPriceFilter;
    this.BuildingList = this.BuildingListWithoutFilter.filter( (el: 
      { BuildingId:
      { toString: () => string; }; ApartmentPrice: { toString: () => string; }; 
        }) =>{
      return Number(el.ApartmentPrice) < Number(BuildingPriceFilter)}
      
      );
 }


  FilterFn5(){

    var BuildingNameFilter = this.BuildingNameFilter;
    var ApartmentNumFilter = this.ApartmentNumFilter;

    this.BuildingList = this.BuildingListWithoutFilter.filter( (el: 
      { BuildingId:
      { toString: () => string; }; ApartmentNumb: { toString: () => string; }; 
      BuildingName: { toString: () => string; };  }) =>{

      return el.ApartmentNumb.toString().toLowerCase().includes(
        ApartmentNumFilter.toString().trim().toLowerCase()
      )
      && el.BuildingName.toString().toUpperCase().includes(
          BuildingNameFilter.toString().trim().toUpperCase()
        ) 
    });
 }
    
 FilterBuildingSimple(){
  var BuildingNameFilter = this.BuildingNameFilter;
  this.BuildingList = this.BuildingListWithoutFilter.filter( (el: 
    { BuildingId:
    { toString: () => string; }; BuildingName: { toString: () => string; }; 
      }) =>{
    return el.BuildingName.toString().toUpperCase().includes(
      BuildingNameFilter.toString().trim().toUpperCase()
    ) 
});
}
FilterRoomSimple(){
  var BuildingRoomsFilter = this.BuildingRoomsFilter;
  this.BuildingList = this.BuildingListWithoutFilter.filter( (el: 
    { BuildingId:
    { toString: () => string; }; ApartmentRooms: { toString: () => string; };
      }) =>{
    return el.ApartmentRooms.toString().toLowerCase().includes(
      BuildingRoomsFilter.toString().trim().toLowerCase()
    ) 
});
}



seeforms(){
  this.router.navigateByUrl('/secretforms1111')
}
}