import { Component, OnInit } from '@angular/core';
import {SharedService} from 'src/app/shared.service';
import { Router } from '@angular/router';
import { Observable, Subscription, timer } from 'rxjs';
import { DatePipe } from '@angular/common'; // Import DatePipe for formatting dates

@Component({
  selector: 'app-ruffle',
  templateUrl: './ruffle.component.html',
  styleUrls: ['./ruffle.component.css']
})
export class RuffleComponent implements OnInit {

  
  constructor(
    private service: SharedService, 
    private router: Router,
    private datePipe: DatePipe
  ) { }

  ToolList:any=[];

  ModalTitle!: string;
  ActivateAddEditTolComp:boolean=false;
  tol:any;

  ToolNameFilter:string="";
  UserFilter:string="";
  ToolIdFilter:string="";

  Pieces:string="";
  MainLocation:string="";

  ToolListWithoutFilter:any=[];
  ToolListWithoutFilterUser:any=[];
  Password:boolean=true;
  brokenTool:boolean=false;
  WorkingTool:boolean=false;
  
  ActivateServiceTolComp:boolean=false;
  ActivateServiceInfoComp:boolean=false;


  ActivateGuveToolToUser:boolean=false;
  ActivateTakeToolFromUser:boolean=false;

  senha!: string;
  pingood:boolean=false;
  pinin:boolean=true;

  chosedNumb!: number;
  fillerList:any=[];
  winnername!: string;
  subscription!: Subscription;

  randomNamea!: string;
  randomNumber!: number;

  chosenNumbers: number[] = [];

  choosing: boolean = false;

  

  ngOnInit(): void {
    window.scrollTo(0, 0);
  
    this.refreshTolList().subscribe(() => {
      const slowSource = timer(0, 100);
      const fastSource = timer(0, 10);
    
      this.subscription = slowSource.subscribe(val => this.randomName());
      this.subscription.add(
        fastSource.subscribe(val => {
          if (this.choosing) {
            this.randomName();
          }
        })
      );
    });
    this.refreshTolList().subscribe();
  }
  
  
  chose() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  
    this.choosing = true;
    const source = timer(0, 10);
    this.subscription = source.subscribe(val => {
      this.randomName();
    });
  
    setTimeout(() => {
      this.subscription.unsubscribe(); // to stop the timer after 3 seconds
      this.choosing = false;
      this.chooseWinner();
    }, 2000);
  }
  
  chooseWinner() {
    // Ensure there are participants in the fillerList
    if (this.fillerList.length > 0) {
      let randomIndex = Math.floor(Math.random() * this.fillerList.length);
      let winner = this.fillerList[randomIndex]; // Pick a random winner
      
      // Assign the winner details to component properties
      this.winnername = winner.Name;
      console.log('Winner:', this.winnername); // Log the winner
  
      // Remove the winner from the fillerList
      this.fillerList.splice(randomIndex, 1);
  
      // If you need to update the UI or take other actions, do that here
      // For example, if you have a list of participants on the UI, you may need to update that list
      // this.refreshParticipantsUI();
  
    } else {
      alert("All participants have been chosen.");
    }
  }
  


randomName() {
  let randomIndex = Math.floor(Math.random() * this.ToolList.length);
  let participant = this.ToolList[randomIndex];
  if(participant) {
    this.randomNamea = participant.Name.replace(/(\d+\.\d+\.\d+\.\d+)$/g, '').trim();
    this.randomNumber = Number(participant.Message);
  } else {
    this.randomNamea = '';
    this.randomNumber = 0;
  }
}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  deleteClick(item: { BuildingId: any; }){
    if(confirm('Are you sure??')){
    this.service.deleteform(item.BuildingId).subscribe(data=>{
      (data.toString());
        this.refreshTolList();
      })
      this.refreshTolList();
    }
  }

  closeClick(){
    this.ActivateAddEditTolComp=false;
    this.ActivateGuveToolToUser=false;
    this.ActivateServiceTolComp=false;
    this.ActivateServiceInfoComp=false;
    this.ActivateTakeToolFromUser=false;
    this.refreshTolList();
  }

  refreshTolList() {
    return new Observable(observer => {
      this.service.getformList().subscribe(data => {
        // Only include entries with 'business support raffle entry' in FormLocation
        this.ToolList = data.filter(entry => entry.FormLocation === 'business support raffle entry');
        this.fillerList = [...this.ToolList]; // Make a copy of the ToolList for fillerList
        observer.next();
        observer.complete();
      });
    });
  }
  
  
  

  

  FilterFn(){
    
    var ToolNameFilter = this.ToolNameFilter;

    this.ToolList = this.ToolListWithoutFilter.filter(function (el: { ToolId: 
      { toString: () => string; }; ToolName: { toString: () => string; }; }){
      return el.ToolName.toString().toLowerCase().includes(
        ToolNameFilter.toString().trim().toLowerCase()
      )
    });
  }

  seeforms(){
    this.router.navigateByUrl('/1admin1')
  }

  
  seehome(){
    this.router.navigateByUrl('/')
  }
  
  seefestival(){
    this.router.navigateByUrl('/festivallake')
  }
}

