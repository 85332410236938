import { Component, OnInit, Input} from '@angular/core';
import {SharedService} from 'src/app/shared.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-edit-admin',
  templateUrl: './edit-admin.component.html',
  styleUrls: ['./edit-admin.component.css']
})
export class EditAdminComponent implements OnInit {

  constructor(private service:SharedService) { }

  @Input() tol:any;

  BuildingId!: string;
  Name!: string;
  Phone!: string;
  Email!: string;
  Message!: string;
  FormLocation!: string;
  DateOfGiving!: any;
  BucketDate!: Date;

  ngOnInit(): void {
    window.scrollTo(0, 0)

    this.BuildingId=this.tol.BuildingId;
    this.Name=this.tol.Name;
    this.Phone=this.tol.Phone
    this.Email=this.tol.Email
    this.Message=this.tol.Message;
    this.FormLocation=this.tol.FormLocation;
    this.DateOfGiving=this.tol.DateOfGiving

    this.DateOfGiving="1111-01-01"}

  addEmployee1(){
    
    var val = {BuildingId:this.BuildingId,
      Name:this.Name,
      Phone:this.Phone,
      Email:this.Email,
      Message:this.Message,
      FormLocation:this.FormLocation,
      DateOfGiving:this.DateOfGiving,};

    this.service.addform(val).subscribe(res=>{
      alert(res.toString());
    });
  }
  updateEmployee1(){
    var val = {BuildingId:this.BuildingId,
      Name:this.Name,
      Phone:this.Phone,
      Email:this.Email,
      Message:this.Message,
      FormLocation:this.FormLocation,
      DateOfGiving:this.DateOfGiving,};

    this.service.updateform(val).subscribe(res=>{
    alert(res.toString());
    });
  }


}
