<!--<div *ngIf="isOpen" class="lightbox-overlay" (click)="close()">
    <div class="lightbox-content" (click)="$event.stopPropagation()">
      <button class="prev-btn" (click)="navigate(-1)">&lt;</button>
      <img [src]="currentImage.src" [alt]="currentImage.alt">
      <button class="next-btn" (click)="navigate(1)">&gt;</button>
      <button class="close-btn" (click)="close()">&times;</button>
    </div>
</div>-->


<div id="mobile" *ngIf="isOpen" class="lightbox-overlay" (click)="close()">
  <div class="lightbox-content"  (click)="$event.stopPropagation()">
    
    <img [src]="currentImage.src" [alt]="currentImage.alt">
    
    <div class="btns">
      <button class="prev-btn" (click)="navigate(-1)">
        <span id="bloc-prev" class="material-symbols-outlined">arrow_circle_left</span>
      </button>
      <button class="next-btn" (click)="navigate(1)">
        <span id="bloc-next" class="material-symbols-outlined">arrow_circle_right</span>
      </button>
    </div>
    

    <!--<button class="close-btn" (click)="close()">
      <span aria-hidden="true" class="close-icon material-symbols-outlined">
        close
      </span>
    </button>-->

  </div>
</div>



<div id="desktop" *ngIf="isOpen" class="lightbox-overlay" (click)="close()">
  <div class="lightbox-content"  (click)="$event.stopPropagation()">
    <button class="prev-btn" (click)="navigate(-1)">
      <span id="bloc-prev" class="material-symbols-outlined">arrow_circle_left</span>
    </button>

    <img [src]="currentImage.src" [alt]="currentImage.alt">

    <button class="next-btn" (click)="navigate(1)">
      <span id="bloc-next" class="material-symbols-outlined">arrow_circle_right</span>
    </button>

    <!--<button class="close-btn" (click)="close()">
      <span aria-hidden="true" class="close-icon material-symbols-outlined">
        close
      </span>
    </button>-->

  </div>
</div>