  
                <li class="plan-Desk" > 
                    <img src="{{hollowPhoto}}" alt="">
                    <div>
                        <h4>{{hollowNrOfRooms}}</h4>
                        <div class="etaj-utila">
                            <div class="etaj-Desk">
                                <h5>etaj</h5>
                                <p>{{hollowFloor}}</p>
                            </div>
                            <div class="utila-Desk">
                                <h5>suprafata</h5>
                                <p>{{hollowSurface}}</p>
                            </div>
                        </div>
                        <h5>Bloc {{hollowBuilding}}</h5>
                        <p>{{hollowApartmentNumb}}</p>
                        <h6>{{hollowPrice}} <span>€+TVA </span></h6>
                        <button >vezi detalii</button> 
                    </div>    
                </li>