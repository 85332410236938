<div class="modal-body">
    <div class="p">
        <p>Trimite-ne datele tale de contact pentru a putea fi preluate de către
            echipa noastră.</p>
    </div>
    <form>
        <input type="text" class="modal-form" [ngModelOptions]="{standalone: true}"
            [(ngModel)]="Name" placeholder="Nume" required>
        <input type="email" class="modal-form" [ngModelOptions]="{standalone: true}"
            [(ngModel)]="Email" id="exampleFormControlInput1" placeholder="Email"
            required>
        <input type="tel" class="modal-form" [ngModelOptions]="{standalone: true}"
            [(ngModel)]="Phone" placeholder="Telefon" required>
        <input type="text" class="modal-form" placeholder="În ce oraș locuiești?"
            [ngModelOptions]="{standalone: true}" [(ngModel)]="Oras" required>    
        <textarea id="modal-mesaj" class="modal-form"
            [ngModelOptions]="{standalone: true}" [(ngModel)]="Message"
            placeholder="Scrie-ne un mesaj" rows="3"></textarea>
        
        <!-- Inside your form -->
        <div class="privacylink1">
            <input (click)="checked()" id="checkbox1" type="checkbox" required>
            <label class="form-control1">Sunt de acord cu 
                <a id="ppLink" (click)="seetermeni()" data-dismiss="modal">Consimțământul de prelucrare a datelor.</a>
            </label>
        </div>
        <h6 *ngIf="acestCamp"> Acest câmp este obligatoriu</h6>
        <button [ngClass]="{'deactive': !checkmark}" class="modal-button" type="button" (click)="sendFormOne()" data-dismiss="modal">TRIMITE MESAJUL</button>

    </form>
</div>