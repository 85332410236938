import { Component, OnInit } from '@angular/core';
import {SharedService} from 'src/app/shared.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';

interface IpifyResponse {
  ip: string;
}

@Component({
  selector: 'app-lp-checklist-main',
  templateUrl: './lp-checklist-main.component.html',
  styleUrls: ['./lp-checklist-main.component.css']
})
export class LpChecklistMainComponent implements OnInit {

  constructor(private http: HttpClient,private service:SharedService, private router: Router, private datePipe: DatePipe) { }

  BuildingId!: string;
  NameCompany!: string;
  Name!: string;
  Phone!: string;
  Email!: string;
  Message!: string;
  FormLocation!: string;
  DateOfGiving!: any;
  BucketDate!: Date;
  checkmark:boolean=false;
  checkmarckCheck!: number;
  checkmark1:boolean=false;
  checkmarckCheck1!: number;
  checkmark2:boolean=false;
  checkmarckCheck2!: number;
  checkmark3:boolean=false;
  checkmarckCheck3!: number;
  BuildingList:any=[];
  acestCamp:boolean=false;
  acestCamp1:boolean=false;
  acestCamp2:boolean=false;
  ipAddress!: string;

  
  ngOnInit(): void {
    this.router.navigateByUrl('/festival-map');
    this.service.qrchecklistdeactive=true

    window.scrollTo(0, 0)

    this.checkmarckCheck3=1;

    this.checkmarckCheck2=1

    this.http.get<IpifyResponse>('https://api.ipify.org?format=json')
    .subscribe(response => {
      this.ipAddress = response.ip;
    });

    this.http.get<{ ip: string }>('https://api.ipify.org?format=json')
    .subscribe(data => {
      this.ipAddress = data.ip;
  

  
      // Get all form entries
      this.service.getformList().subscribe(entries => {
        // Check if IP exists in any entry
        const ipExists = entries.some(entry => entry.Name.includes(this.ipAddress));
  
        
      });
    });
  
    
  }
  






checked3(){

  this.checkmarckCheck2= this.checkmarckCheck2 +1;
  if(this.checkmarckCheck2 % 2 === 0 ){
  this.checkmark2 = true;

  const modalbody=document.querySelector('.formJoin button');
  modalbody!.className = "";

  }
  else{
    this.checkmark1 = false;

    const modalbody=document.querySelector('.formJoin button');
    modalbody!.className = "deactive";
  }

}





scroll(el: HTMLElement) {
  el.scrollIntoView({behavior: 'smooth'});
}

  seetermeni(){
    this.router.navigateByUrl('/termeni-si-conditii')
  }

  seeregulament(){
    window.open("../../assets/pdf/REGULAMENTUL-TOMBOLEI-Festival-Lake.pdf")
  }

  seehome(){
  this.router.navigateByUrl('/')
  }
  seefestival(){
    this.router.navigateByUrl('/festivallake')
    }

  changeRoEn(){
    this.service.selectEnRo=true;
    this.router.navigateByUrl('/contact-en')
  }




  sendFormOne3() {
    this.BucketDate = new Date();
    this.DateOfGiving = (this.datePipe.transform(this.BucketDate, "yyyy-MM-dd"));
  
    // Check if phone is exactly 10 digits
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(this.Phone)) {
      alert("Numărul de telefon trebuie sa aibă 10 numere");
      return;
    }
  
    // Check if email follows the structure something@something.something
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(this.Email)) {
      alert("Email-ul trebuie sa urmeze structura exemplu@exemplu.exemplu");
      return;
    }
  
    // Fetch the list of all forms
    this.service.getformList().subscribe(data => {
  
      // Count the number of entries with the same phone or email
      const phoneCount = data.filter(entry => entry.Phone === this.Phone).length;
      const emailCount = data.filter(entry => entry.Email === this.Email).length;
  
      if (phoneCount >= 2 || emailCount >= 2) {
        alert("Acest număr de telefon sau email a fost folosit deja de două ori.");
        return;
      }
  
      // Filter data for 'TheLakePark' only
      let lakeParkData = data.filter(form => form.FormLocation === 'TheLakePark');
  
      // Sort lakeParkData in descending order of Message as a number
      lakeParkData.sort((a, b) => {
        let aMessageNumber = !isNaN(Number(a.Message)) ? Number(a.Message) : -Infinity;
        let bMessageNumber = !isNaN(Number(b.Message)) ? Number(b.Message) : -Infinity;
        return bMessageNumber - aMessageNumber;
      });
  
      // Initialize the raffle number
      let raffleNumber = 1;
  
      // If the first message is a number, update the raffle number
      if (!isNaN(Number(lakeParkData[0].Message))) {
        raffleNumber = Number(lakeParkData[0].Message) + 1;
      }
  
      // Now raffleNumber contains the last number + 1, or 1 if no number was found
      // You can use raffleNumber in your form submission
      var val = {
        BuildingId: this.BuildingId,
        Name: `${this.Name} ${this.ipAddress}`,
        Phone: this.Phone,
        Email: this.Email,
        Message: raffleNumber.toString(),
        FormLocation: 'TheLakePark',
        DateOfGiving: this.DateOfGiving,
      };
  
      this.service.addform(val).subscribe(res => {
        console.log(res.toString());
        this.service.numarTombola = raffleNumber.toString();
        this.router.navigateByUrl('/redirect');
      });
    });
  }
  
  
  
  
}
