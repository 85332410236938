<head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
</head>
<body>
    <div class="header-bcground"></div>

    <header class="headerGrid">
        <div class="headerGrid-wrapper">
            <div class="header-titles">
                <img src="../../../assets/media/logos/the_lake_logo_white.png" alt="The Lake home - Living Xperience logo" (click)="seehome()" >

                <div class="all-nav-elements">
                    <div class="contact-nav">

                        <div class="flexRow">
                            <a (click)="changeRoEn()">RO</a>
                        </div>

                        <div class="header-phone"><span id="header-icon-phone" class="material-symbols-outlined">phone_iphone <a href="tel:+40721229999">+4 0721 229 999</a> </span></div>
                        <span class="vertical-line"></span>
                        <div class="header-mail"><span id="header-icon-mail" class="material-symbols-outlined">chat_bubble <a href="mailto:sales@thelake.ro">sales@thelake.ro</a></span></div>
                        <!-- Button trigger modal vizionare-->
                        <button class="header-button" type="button" data-toggle="modal" data-target="#brosuraModal">SEE&nbsp;THE&nbsp;BROCHURE</button>  
                        
                        <!-- Modal Vizionare-->
                        <div class="modal fade" id="vizionareModal" tabindex="-1" role="dialog"
                            aria-labelledby="vizionareModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                            aria-hidden="true">
                                            <span aria-hidden="true" class="close-icon material-symbols-outlined">
                                                close
                                            </span>
                                        </button>
                                        <h5 class="modal-title" id="vizionareModalLabel">Schedule a viewing</h5>
                                    </div>
                                    <div class="modal-body">
                                        <div class="p">
                                            <p>Leave your contact details so our team can get in touch with you.</p>
                                        </div>
                                        <form>
                                            <input type="text" class="modal-form" [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="Name" placeholder="Name" required>
                                            <input type="email" class="modal-form" [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="Email" id="exampleFormControlInput1" placeholder="Email"
                                                required>
                                            <input type="tel" class="modal-form" [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="Phone" placeholder="Phone" required>
                                            <textarea id="modal-mesaj" class="modal-form"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Message"
                                                placeholder="Leave us a message" rows="3"></textarea>

                                            <div class="privacylink1"><input (click)="checked()" id="checkbox1"
                                                    type="checkbox" required><label class="form-control1">I agree with the <a id="ppLink" (click)="seetermeni()"
                                                        data-dismiss="modal">data processing policy.</a></label></div>
                                            <h6 *ngIf="acestCamp">This field is required</h6>

                                            <button class="deactive modal-button" type="button" (click)="sendFormOne()"
                                                data-dismiss="modal">SEND</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Modal Brosura-->
                        <div class="modal fade" id="brosuraModal" tabindex="-1" role="dialog"
                            aria-labelledby="brosuraModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                            aria-hidden="true">
                                            <span aria-hidden="true" class="close-icon material-symbols-outlined">
                                                close
                                            </span>
                                        </button>
                                        <h5 class="modal-title" id="brosuraModalLabel">See the brochure</h5>
                                    </div>
                                    <div class="modal-body-brosura">
                                        <div class="p">
                                            <p>Complete the form below to see The Lake Home brochure.</p>
                                        </div>
                                        <form>
                                            <input type="text" class="modal-form" placeholder="Name"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Name" required>
                                            <input type="email" class="modal-form" id="exampleFormControlInput1"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Email"
                                                placeholder="Email" required>
                                            <input type="tel" class="modal-form" placeholder="Phone"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Phone" required>

                                            <div class="privacylink2"><input (click)="checked2()" id="checkbox2"
                                                    type="checkbox" required><label class="form-control2">I agree with the<a id="ppLink2" data-dismiss="modal"
                                                        (click)="seetermeni()">data processing policy.</a></label></div>
                                            <h6 *ngIf="acestCamp1">This field is required</h6>
                                            <button class="deactive modal-button-brosura" type="button"
                                                (click)="sendFormOne2()" data-dismiss="modal">See brochure</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>   

                        <!-- XMAS promo Modal-->
                        <div class="modal fade" id="XmasModal" tabindex="-1" role="dialog"
                            aria-labelledby="XmasModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content XmasContent">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                            aria-hidden="true">
                                            <span aria-hidden="true" class="close-icon material-symbols-outlined">
                                                close
                                            </span>
                                        </button>
                                        <h5 class="modal-title XmasTitle" id="OfertaModalLabel">HO, HO,
                                            <span>HOME</span></h5>
                                    </div>
                                    <div class="modal-body-oferta">
                                        <div class="p">

                                            <p> <span class="xmasSubTitle">Serbează <strong>Crăciunul</strong> acasă și
                                                    fă loc
                                                    sub brad pentru <strong>un cadou în plus.</strong> </span><br><br>

                                                Achiziționează unul din ultimele apartamente disponibile
                                                în blocul C sau achită <strong>un avans de 50% </strong>pentru un
                                                apartament în blocul B1 și primești cadou de casă nouă
                                                un <strong>robot de bucătărie Thermomix TM6.</strong> <br><br>

                                                În plus, îți oferim un<strong> avantaj client</strong> cuprins între
                                                <strong>2,500 și
                                                    38,000 euro </strong>în baza avansului din prețul de listă pentru
                                                noua ta locuință în blocurile B1 și B2.<br><br>

                                                Lasă-ne datele tale și programează o vizionare.</p>
                                        </div>
                                        <form>
                                            <input type="text" class="modal-form" placeholder="Nume"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Name" required>
                                            <input type="email" class="modal-form" id="exampleFormControlInput1"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Email"
                                                placeholder="Email" required>
                                            <input type="tel" class="modal-form" placeholder="Telefon"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Phone" required>
                                            <textarea id="modal-mesaj" class="modal-form"
                                                placeholder="Scrie-ne un mesaj" [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="Message" rows="3"></textarea>

                                                <div class="privacylink2"><input (click)="checked4()" id="checkbox2"
                                                    type="checkbox" required><label class="form-control2">Sunt de acord
                                                    cu <a id="ppLink2" data-dismiss="modal"
                                                        (click)="seetermeni()">Consimțământul de prelucrare a
                                                        datelor.</a></label></div>
                                            <h6 *ngIf="acestCamp1">Acest câmp este obligatoriu</h6>
                                            <button class="deactive modal-button-oferta" type="button"
                                                (click)="sendFormOferta()" data-dismiss="modal">TRIMITE MESAJUL</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="logo-navbar">

                        <div class="mob-navbar">
                            <div class="distance-top-navbar">
                                <a class="blue-border" (click)="seelifestyle()">LIFESTYLE</a>
                                <a class="blue-border" (click)="footerlinks1()">CHOOSE&nbsp;YOUR&nbsp;RESIDENCE</a>
                                <a class="blue-border" (click)="seelocatie()">LOCATION</a>
                                <a class="blue-border" href="https://thelake.ro/galerie-en/">GALLERY</a>
                                <a class="blue-border" (click)="seemedia()">MEDIA</a>
                                <a class="blue-border" (click)="seedezvoltator()">DEVELOPER</a>
                                <a (click)="seecontact()">CONTACT</a>
                            </div>
                            

                        </div>
                        <div class="burger" (click)="navSlider()">
                            <div class="line1"></div>
                            <div class="line2"></div>
                            <div class="line3"></div>
                        </div>

                        <div class="navbar">
                            <a (click)="seelifestyle()">LIFESTYLE</a>
                            <a (click)="footerlinks1()">CHOOSE&nbsp;YOUR&nbsp;RESIDENCE</a>
                            <a (click)="seelocatie()">LOCATION</a>
                            <a href="https://thelake.ro/galerie-en/">GALLERY</a>
                            <a (click)="seemedia()">MEDIA</a>
                            <a (click)="seedezvoltator()">DEVELOPER</a>
                            <a (click)="seecontact()">CONTACT</a>
                        </div>
                    </div>
                </div>
                    <div class="mob-page-title">
                        <h1>Lifestyle</h1>
                    </div>    
            </div>

            <div class="header-slider">
                <div id="carouselExampleControls" class="carousel slide" data-ride="carousel" data-interval="false">
                    <div class="carousel-inner" id="headerSlider-inner">
                      <div class="carousel-item active">
                        <img class="d-block w-100" src="../../../assets/media/headerSliders/lifestyle_1.jpg" alt="">
                      </div>
                      <div class="carousel-item">
                        <img class="d-block w-100 testImg" src="../../../assets/media/headerSliders/lifestyle_2.jpg" alt="">
                      </div>
                      <div class="carousel-item">
                        <img class="d-block w-100" src="../../../assets/media/headerSliders/lifestyle_3.jpg" alt="">
                      </div>
                      <div class="carousel-item">
                        <img class="d-block w-100" src="../../../assets/media/headerSliders/lifestyle_4.jpg" alt="">
                      </div>
                    </div>
                    <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span id="bloc-prev" class="material-symbols-outlined">arrow_circle_left</span>
                    </a>
                    <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span id="bloc-next" class="material-symbols-outlined">arrow_circle_right</span>
                    </a>
                </div>
            </div>            


            <!-- call to action buttons -->
            <div class="cta-btns">
                <div class="cta-visionare" type="button" data-toggle="modal" data-target="#vizionareModal">
                    <span id="cta-viz-icon" class="material-symbols-outlined">chat_bubble</span><a>SCHEDULE&nbsp;A&nbsp;VIEWING</a>
                </div>
                <div class="cta-brosura" type="button" data-toggle="modal" data-target="#brosuraModal">
                    <img src="../../../assets/media/icons/brosuraIcon.svg" alt="download brosura icon"><a>SEE THE BROCHURE</a>
                </div>
                
            </div>

            <div class="sticky-btn" type="button" data-toggle="modal" data-target="#vizionareModal"><span id="cta-viz-icon" class="material-symbols-outlined">chat_bubble</span>
                <h5>Schedule <br> a viewing</h5></div>

            <!--xmasPromo
            <div class="XMAS-sticky-btn" type="button" data-toggle="modal" data-target="#XmasModal"><img
                src="../../../assets/media/icons/favpng_vector-graphics-gift-clip-art-box.png" alt="">
        </div>-->
        </div>
        <div class="page-title"><h1>Lifestyle</h1></div>
    </header>

    <div class="mob-header-slider">
        <div class="header-slider-bcground"></div>
        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel"> 
            <ol class="carousel-indicators">
              <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
            </ol>
            <div class="carousel-inner" id="mobSlider-inner">
              <div class="carousel-item active">
                <img class="d-block w-100" src="../../../assets/media/headerSliders/lifestyle_1.jpg" alt="">
              </div>
              <div class="carousel-item">
                <img class="d-block w-100" src="../../../assets/media/headerSliders/lifestyle_2.jpg" alt="">
              </div>
              <div class="carousel-item">
                <img class="d-block w-100" src="../../../assets/media/headerSliders/lifestyle_3.jpg" alt="">
              </div>
              <div class="carousel-item">
                <img class="d-block w-100" src="../../../assets/media/headerSliders/lifestyle_4.jpg" alt="">
              </div>
            </div>
          </div>
    </div>

    <section class="page-content-wrapper">
        <div class="page-content">
            <h2 id="content-1">The joy of life captured and integrated architecturally.
            </h2>
    
            <img id="content-2" class="lifestyle-icon" src="../../../assets/media/pattern/Artwork29.svg" alt="">
    
            <p id="content-3">The residential spaces intertwine harmoniously in a "one to one" relationship with the common ones - streets, squares and parks - both types having a common purpose: to delight your eyes, in the wonderful reflection of the sun's rays on the shine of the lake.  <br><br> The design criteria have been aligned with great care, to offer you a habitat perfectly integrated into the specifics of the place.</p>
    
            <img id="content-4" src="../../../assets/media/lifestyle-img1.jpg" alt="Picture of two kids looks at the lake">
    
            <h2 id="content-5">Designed to instill a new spirit to the&nbsp;place</h2>
    
            <div class="division"></div>
            <p id="content-6">The concept of parity between the inhabited and common spaces makes you feel "at home" from the first step in the neighborhood.</p>
    
            <div id="content-7">
                <h3>20,000 square meters of green spaces dedicated entirely to 494 apartments, 34 houses and 3 restaurants, as testimony to the fact that we are not only builders, but also creators of experiences.
                </h3>
            </div>
    
            <div class="division"></div>
            <p id="content-8">Starting with the first breath of fresh air in the morning, while enjoying a cup of coffee, to the relaxing evening walk on the alleys, every day at home ends with a sense of fulfillment.</p>
    
            <img id="content-9" src="../../../assets/media/Spa-rendering.jpg" alt="Rendering of The Lake Home Spa and Venue">
    
            <div id="content-10">
                <p>A public park that will continue the lake promenade brings with it restaurants, sport facilities and spa services, transforming the entire residential complex into a neighborhood of relaxation and harmony.</p>
            </div>
            
            <h2 id="content-11">The experience factor is the basis of our construction philosophy            </h2>
    
            <img id="content-12" class="lifestyle-icon" src="../../../assets/media/pattern/Artwork32.svg" alt="">
    
            <h3 id="content-13">After choosing any of the 494 apartments or 34 houses, you are left with the pleasant feeling that the place is created by someone who has known you all your life.</h3>
            
            <div class="division division3"></div>
            <p id="content-14">The division of the apartments into night and day areas, the Premium finishes and the interior design concept create a familiar, practical and comfortable environment.</p>
        </div>

        <div class="blue-bckg"></div>
        <div class="gray-bckg-wrapper"><div class="gray-bckg"></div></div>
        
    </section>


    <section class="allpages-bottom">

        <div class="photo-slider">
            <!--<div class="banner-slider">
    
                <ul class="bxslider">
                    <li class="elements">
                        <img id="carouselimg1" src="../../../assets/media/carousel/carousel.png" alt="Render of the Apart Hotel building.">
                    </li>
                    <li class="elements">
                        <img src="../../../assets/media/carousel/Carousel1a.png" alt="Render of a living room from our buildings.">
                    </li>
                    <li class="elements">
                        <img src="../../../assets/media/carousel/carousel1.png" alt="Render of the common area and garden between buildings.">
                    </li>
                    <li class="elements">
                        <img src="../../../assets/media/carousel/carousel2.jpg" alt="Close up picture of the bedroom lamp with the closet in the background.">
                    </li>
                    <li class="elements">
                        <img src="../../../assets/media/carousel/carousel3.jpg" alt="Smart Home control on the wall, displaying the hour and all its features.">
                    </li>
                    <li class="elements">
                        <img src="../../../assets/media/carousel/carousel4.jpg" alt="Photograph of the exterior of our new building, depicting the balconies and garden.">
                    </li>
                    <li class="elements">
                        <img src="../../../assets/media/carousel/carousel5.jpg" alt="Render of the master bedroom of a big vila house.">
                    </li>
                    <li class="elements">
                        <img src="../../../assets/media/carousel/carousel6.jpg" alt="Render of the living room and kitchen of a small vila house.">
                    </li>
                    <li class="elements">
                        <img src="../../../assets/media/carousel/carousel7.jpg" alt="Render of the kitchen and dining room of a small vila house.">
                    </li>
                    <li class="elements">
                        <img src="../../../assets/media/carousel/carousel8.jpg" alt="Render of the master bedroom of a small vila house.">
                    </li>
                    <li class="elements">
                        <img src="../../../assets/media/carousel/carousel9.jpg" alt="Render of the dining room table and living room of a big vila house.">
                    </li>
                    <li class="elements">
                        <img src="../../../assets/media/carousel/carousel10.jpg" alt="Render of the balcony view of a big vila house.">
                    </li>
                    <li class="elements">
                        <img src="../../../assets/media/carousel/carousel11.jpg" alt="Render of the terrace of the building C.">
                    </li>
                    <li class="elements">
                        <img src="../../../assets/media/carousel/carousel12.jpg" alt="External render of the Venue & Spa building.">
                    </li>
                    <li class="elements">
                        <img src="../../../assets/media/carousel/carousel13.jpg" alt="Close up picture of the bedroom lamp and the smart home controls on the wall.">
                    </li>
                    <li class="elements">
                        <img src="../../../assets/media/carousel/carousel14.jpg" alt="Render of the dining room and kitchen of a two room apartment.">
                    </li>
                    <li class="elements">
                        <img src="../../../assets/media/carousel/carousel15.jpg" alt="Render of the exterior of a medium vila house.">
                    </li>
                    <li class="elements">
                        <img src="../../../assets/media/carousel/carousel16.jpg" alt="Render of the exterior of building D.">
                    </li>
                    <li class="elements">
                        <img src="../../../assets/media/carousel/carousel17.jpg" alt="Render of the ground floor business area.">
                    </li>
                    <li class="elements">
                        <img src="../../../assets/media/carousel/carousel18.jpg" alt="Render of the business area and the restaurant on the background.">
                    </li>
                    <li class="elements">
                        <img src="../../../assets/media/carousel/carousel19.jpg" alt="Render of the exterior building with business ground floor.">
                    </li>
                    <li class="elements">
                        <img src="../../../assets/media/carousel/carousel20.png" alt="Render of one side of a building, depicting the balconies and garden.">
                    </li>
    
                </ul>
    
                <div class="slider-controls">
                    <span id="slide-prev" class="material-symbols-outlined">arrow_circle_left</span>
                    <span id="slide-next" class="material-symbols-outlined">arrow_circle_right</span>
                </div>
    
            </div>-->
            
            
            <div id="carouselExampleControls1" class="carousel slide" data-ride="carousel" data-interval="false">
                <div class="carousel-inner" id="photoSlider-inner">
                  <div class="carousel-item active">
                    <img class="d-block w-100" src="../../../assets/media/carousel/Carousel1a.png" alt="">
                  </div>
                  <div class="carousel-item">
                    <img class="d-block w-100 testImg" src="../../../assets/media/carousel/carousel6.jpg" alt="">
                  </div>
                  <div class="carousel-item">
                    <img class="d-block w-100" src="../../../assets/media/carousel/carousel11.jpg" alt="">
                  </div>
                  <div class="carousel-item">
                    <img class="d-block w-100" src="../../../assets/media/carousel/carousel5.jpg" alt="">
                  </div>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleControls1" role="button" data-slide="prev">
                    <span id="photo-prev" class="material-symbols-outlined">arrow_circle_left</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleControls1" role="button" data-slide="next">
                    <span id="photo-next" class="material-symbols-outlined">arrow_circle_right</span>
                </a>
            </div>
        </div>

        <section class="investitia-wrapper" id="INVESTEȘTE">
            <div class="blue-bloc"></div>

            <div class="inv-wrapper-wrapper">      
                <div class="investitia">
                    <h2>What is the ideal investment for&nbsp;you?</h2>
                    <div class="investitia-blueBar"></div>
                    <p>Smart real estate investing has been, is, and will be one of the best ways to transport money over time.</p>
                </div>	
                <div class="calculator">
    
                    <div class="bigdiv">
                        <h3>Investment calculator</h3>
                        <p class="suma">Invested amount</p>
                        <input class="calcone" id="first" min="7000" placeholder="amount in €" type="number">
                        <p class="termen">Term <br></p>
                        <div class="labelandimput">
                            <div class="labelandimput2">
                                <input  type="radio" id="age2" name="age" value="15" checked="checked">
                                <label class="tag" for="age2">18&nbsp;months</label>
                            </div>
                                <div class="labelandimput2">
                                <input  type="radio" id="age3" name="age" value="35">
                            <label class="tag" for="age3">36&nbsp;months</label> 
                            </div>
                            <div class="labelandimput2">
                                <input  type="radio" id="age4" name="age" value="55">
                                <label class="tag" for="age4">54&nbsp;months</label>
                            </div>
                        </div>
                        <button onclick="add()" class="calc-btn">Calculate</button>
                    </div>
                    <div class="rectangle">
                        <p class="procentul">TERM PROFIT&nbsp;% 
                        <input class="firstresult" id="answer3" disabled="disabled" placeholder="_ %"></p>
                        
                        <p class="dobanda">TERM PROFIT&nbsp;€
                        <input class="firstresult" id="answer2" disabled="disabled" placeholder="_ €"></p>   
            
                        <p class="return">AMOUNT RETURNED ON TERM&nbsp;€
                        <input class="firstresult" id="answer" disabled="disabled" placeholder="_ €"></p>
                    </div>
            
                </div>
            </div>    
        </section>

        <section class="testemonials">
            <h2>Testimonials</h2>
  
            <app-testimonials></app-testimonials>
               
            <div class="bg-img-testm"></div>
        </section>
    </section>

    
    <footer>
        <div class="footer-wrapper">
            <div class="footer-logo">
                <p>Developed by</p>
                <img src="../../../assets/media/logos/sigla_negative-02.png" alt="Novarion Logo" onclick="NewTabNovarion()">
            </div>
            <div class="footer1">
                <h5>Sales office</h5>
                <p class="footer1-bold">The Lake Home</p>
                <a href="https://goo.gl/maps/u8YrSsReceFjCgq37" target=”_blank”><p>Tractorului street, 2-12<br>Sibiu, Romania</p></a> 
                <div>
                    <span>T.</span><a href="tel:+40721229999">+40 721 229 999</a> <br><br>
                    <span>T.</span><a href="mailto:sales@thelake.ro">sales@thelake.ro</a>
                </div>
                <img src="../../../assets/media/pattern/brand_icon.svg" alt="">
            </div>
            <div class="footer2">
                <h5>Choose your residence</h5>
                <div class="footer2-rezidentie">
                    <div class="rezidentie1">
                        <a (click)="footerlinks1()">STUDIO</a> <br>
                        <a (click)="footerlinks2()">2 room apartment</a><br>
                        <a (click)="footerlinks3()">3 room apartment</a><br>
                        <a (click)="footerlinks4()">5 room apartment</a><br>
                    </div>
                    <!--
                    <div class="rezidentie2">
                        <a href="">VILA TIP A</a><br>
                        <a href="">VILA TIP B</a><br>
                        <a href="">VILA TIP C</a><br>
                    </div>-->
                </div>
                <div class="footer2-pages">
                    <div class="pages1">
                        <a (click)="seehome()">HOME</a> <br>
                        <a (click)="seelifestyle()">LIFESTYLE</a><br>
                        <a (click)="seelocatie()">LOCATION</a><br>
                    </div>
                    <div class="pages2">
                        <a href="https://thelake.ro/galerie-en/">GALLERY</a><br>
                        <a (click)="seemedia()">MEDIA</a><br>
                        <a (click)="seedezvoltator()">DEVELOPER</a><br>
                    </div>
                </div>
                <div class="footer2-socialMedia">
                    <img src="../../../assets/media/icons/facebook_logo_icon.svg" alt="Facebook link" onclick="NewTabFb()">
                    <img src="../../../assets/media/icons/linkedin_logo_icon.svg" alt="LinkedIn link" onclick="NewTabLi()">
                    <img src="../../../assets/media/icons/instagram_logo_icon.svg" alt="Instagram link" onclick="NewTabIg()">
                    <img src="../../../assets/media/icons/youtube_icon.svg" alt="Youtube link" onclick="NewTabYt()">
                </div>
                <div class="footer2-end">
                    <a (click)="seetermeni()">Privacy policy | Cookies</a>
                    <p>Brand & Web Design : <a href="https://www.mugurelfrincu.ro/" target="_blank"> Mugurel Frîncu</a></p>
                </div>
            </div>
            <div class="footer3">
                <h4>We will get back to you</h4>
                <p>Fill out the form below to get a fast reply from our sales representatives</p>
                <input type="text" placeholder="Name"[ngModelOptions]="{standalone: true}" [(ngModel)]="Name" required> 
                <input type="email" placeholder="Email"[ngModelOptions]="{standalone: true}" [(ngModel)]="Email" required>
                <input type="tel" placeholder="Phone"[ngModelOptions]="{standalone: true}" [(ngModel)]="Phone" required>
                <textarea id="mesaj" placeholder="Leave us a message"[ngModelOptions]="{standalone: true}" [(ngModel)]="Message"></textarea>
                <div class="privacylink3"><input (click)="checked3()" id="checkbox3" type="checkbox" required><label
                        class="form-control3">I agree with the <a id="ppLink3" (click)="seetermeni()"> data processing policy.</a></label></div>
                <h6 *ngIf="acestCamp2">This field is required</h6>
                <button class="deactive" type="button" (click)="sendFormOne3()">SEND</button>
            </div>
        </div>  
    </footer>

      
    





<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js"></script>
<script src="lifestyle.scripts.js"></script>    
</body>

<router-outlet></router-outlet>