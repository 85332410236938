import { Component, ElementRef, OnInit,ViewChild } from '@angular/core';
import {SharedService} from 'src/app/shared.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {

  constructor(private service:SharedService, private router: Router, private datePipe: DatePipe) { }

  BuildingId!: string;
  NameCompany!: string;
  Name!: string;
  Phone!: string;
  Email!: string;
  Message!: string;
  CompleateMessage!: string;
  FormLocation!: string;
  DateOfGiving!: any;
  BucketDate!: Date;
  checkmark:boolean=false;
  checkmarckCheck!: number;
  checkmark1:boolean=false;
  checkmarckCheck1!: number;
  checkmark2:boolean=false;
  checkmarckCheck2!: number;
  checkmark3:boolean=false;
  checkmarckCheck3!: number;
  BuildingList:any=[];
  acestCamp:boolean=false;
  acestCamp1:boolean=false;
  acestCamp2:boolean=false;

  ngOnInit(): void {
    window.scrollTo(0, 0)

    this.checkmarckCheck3=1;
    this.countPermaneta=1;
    this.countTemporara=1;
    this.checkmarckCheck2=1
    

         //buton modal festival

  }
  

  @ViewChild('xmasButton', { static: true }) xmasButton!: ElementRef;
  triggerButtonClick() {
    this.xmasButton.nativeElement.click();
  }
  modalfestival(event: MouseEvent) {
    if (this.isCloseButton(event.target)) {
      return;
    }
    window.open("https://fb.me/e/3D7mAv0YU");
  }
    
  isCloseButton(target: EventTarget | null): boolean {
    if (!target) {
      return false;
    }
  
    if ((target as HTMLElement).classList.contains('close') ||
        (target as HTMLElement).classList.contains('close-icon') ||
        (target as HTMLElement).classList.contains('material-symbols-outlined')) {
      return true;
    }
  
    return false;
  }



checked3(){

  this.checkmarckCheck2= this.checkmarckCheck2 +1;
  console.log(this.checkmarckCheck2)
  if(this.checkmarckCheck2 % 2 === 0 ){
  this.checkmark2 = true;

  const modalbody=document.querySelector('.formJoin button');
  modalbody!.className = "";

  }
  else{
    this.checkmark1 = false;

    const modalbody=document.querySelector('.formJoin button');
    modalbody!.className = "deactive";
  }

}

countPermaneta!: number;
clickPermanenta(){
  this.countPermaneta++
  this.checkWhatOption()
}

countTemporara!: number;
clickTemporara(){
  this.countTemporara++
  this.checkWhatOption()
}



scroll(el: HTMLElement) {
  el.scrollIntoView({behavior: 'smooth'});
}

  seetermeni(){
    this.router.navigateByUrl('/termeni-si-conditii')
  }

  seehome(){
  this.router.navigateByUrl('/')
  }

  changeRoEn(){
    this.service.selectEnRo=true;
    this.router.navigateByUrl('/contact-en')
  }

  TypeOfParticipation!: string;
  optionChosen:boolean=false;
  checkWhatOption(){
    if(this.countPermaneta%2==0 && this.countTemporara%2==0){
      this.FormLocation="Participare permanenta sau Participare temporara"
      this.optionChosen=true;
    }
    else if(this.countPermaneta%2==0){
      this.FormLocation="Participare permanenta"
      this.optionChosen=true;
    }
    else if(this.countTemporara%2==0){
      this.FormLocation="Participare temporara"
      this.optionChosen=true;
    }
    else{
      this.optionChosen=false;
    }
    console.log(this.FormLocation)
  }

  sendFormOne3(){
    this.CompleateMessage="Propunere tip: " + this.FormLocation+" Mesajul este: "+ this.Message
    this.checkWhatOption()
    if(this.checkmark2 == true && this.optionChosen == true){
  
    this.BucketDate = new Date();
    this.DateOfGiving = (this.datePipe.transform(this.BucketDate,"yyyy-MM-dd"));  
    var val = {BuildingId:this.BuildingId,
      Name:this.Name,
      Phone:this.Phone,
      Email:this.Email,
      Message:this.CompleateMessage,
      FormLocation:'TheLakePark-deals',
      DateOfGiving:this.DateOfGiving,};
  
    this.service.addform(val).subscribe(res=>{
      console.log(res.toString());
    });
    alert("Mesajul tau a fost trimis")
    } else {
  
    this.acestCamp2=true;
  
    }
  }
}
