<div class="wrapper">
    <div class="tag" id="locatie">
        <h4>Locație</h4>

        <div class="tag-wrapper">
            <div class="fields">
                <label for="">Evaluează prestigiul și renumele zonei în care se află clădirea.</label>
                <ul class="nrCamere-1">
                    <li (click)="makeparking(1, 0)">0</li>
                    <li (click)="makeparking(1, 1)">1</li>
                    <li (click)="makeparking(1, 2)">2</li>
                    <li (click)="makeparking(1, 3)">3</li>
                    <li (click)="makeparking(1, 4)">4</li>
                    <li (click)="makeparking(1, 5)">5</li>
                </ul>
            </div>
    
            <div class="fields">
                <label for="">Evaluează calitatea peisajului și spațiilor verzi din zonă/cartier.</label>
                <ul class="nrCamere-2">
                    <li (click)="makeparking(2, 0)">0</li>
                    <li (click)="makeparking(2, 1)">1</li>
                    <li (click)="makeparking(2, 2)">2</li>
                    <li (click)="makeparking(2, 3)">3</li>
                    <li (click)="makeparking(2, 4)">4</li>
                    <li (click)="makeparking(2, 5)">5</li>
                </ul>
            </div>
    
            <div class="fields">
                <label for="">Evaluează nivelul de zgomot din zonă/cartier.</label>
                <ul class="nrCamere-3">
                    <li (click)="makeparking(3, 0)">0</li>
                    <li (click)="makeparking(3, 1)">1</li>
                    <li (click)="makeparking(3, 2)">2</li>
                    <li (click)="makeparking(3, 3)">3</li>
                    <li (click)="makeparking(3, 4)">4</li>
                    <li (click)="makeparking(3, 5)">5</li>
                </ul>
            </div>
    
            <div class="fields">
                <label for="">Evaluează condițiile de trafic din zonă/cartier.</label>
                <ul class="nrCamere-4">
                    <li (click)="makeparking(4, 0)">0</li>
                    <li (click)="makeparking(4, 1)">1</li>
                    <li (click)="makeparking(4, 2)">2</li>
                    <li (click)="makeparking(4, 3)">3</li>
                    <li (click)="makeparking(4, 4)">4</li>
                    <li (click)="makeparking(4, 5)">5</li>
                </ul>
            </div>
    
            <div class="fields">
                <label for="">Evaluează accesibilitatea mijloacelor de transport.</label>
                <ul class="nrCamere-5">
                    <li (click)="makeparking(5, 0)">0</li>
                    <li (click)="makeparking(5, 1)">1</li>
                    <li (click)="makeparking(5, 2)">2</li>
                    <li (click)="makeparking(5, 3)">3</li>
                    <li (click)="makeparking(5, 4)">4</li>
                    <li (click)="makeparking(5, 5)">5</li>
                </ul>
            </div>
    
            <div class="fields">
                <label for="">Evaluează accesibilitatea facilităților sociale generale.</label>
                <ul class="nrCamere-6">
                    <li (click)="makeparking(6, 0)">0</li>
                    <li (click)="makeparking(6, 1)">1</li>
                    <li (click)="makeparking(6, 2)">2</li>
                    <li (click)="makeparking(6, 3)">3</li>
                    <li (click)="makeparking(6, 4)">4</li>
                    <li (click)="makeparking(6, 5)">5</li>
                </ul>
            </div>
        </div>
        


    </div>   

    <div class="tag" id="cladExterior">
        <h4>Clădire - exterioară</h4>

        <div class="tag-wrapper">
            <div class="fields">
                <label for="">Evaluează arhitectura clădirii.</label>
                <ul class="nrCamere-7">
                    <li (click)="makeparking(7, 0)">0</li>
                    <li (click)="makeparking(7, 1)">1</li>
                    <li (click)="makeparking(7, 2)">2</li>
                    <li (click)="makeparking(7, 3)">3</li>
                    <li (click)="makeparking(7, 4)">4</li>
                    <li (click)="makeparking(7, 5)">5</li>
                </ul>
            </div>
    
            <div class="fields">
                <label for="">Evaluează schema de culori a clădirii.</label>
                <ul class="nrCamere-8">
                    <li (click)="makeparking(8, 0)">0</li>
                    <li (click)="makeparking(8, 1)">1</li>
                    <li (click)="makeparking(8, 2)">2</li>
                    <li (click)="makeparking(8, 3)">3</li>
                    <li (click)="makeparking(8, 4)">4</li>
                    <li (click)="makeparking(8, 5)">5</li>
                </ul>
            </div>
    
            <div class="fields">
                <label for="">Evaluează proporționalitatea clădirii.</label>
                <ul class="nrCamere-9">
                    <li (click)="makeparking(9, 0)">0</li>
                    <li (click)="makeparking(9, 1)">1</li>
                    <li (click)="makeparking(9, 2)">2</li>
                    <li (click)="makeparking(9, 3)">3</li>
                    <li (click)="makeparking(9, 4)">4</li>
                    <li (click)="makeparking(9, 5)">5</li>
                </ul>
            </div>
    
            <div class="fields">
                <label for="">Evaluează calitatea materialelor din care este construită clădirea.</label>
                <ul class="nrCamere-10">
                    <li (click)="makeparking(10, 0)">0</li>
                    <li (click)="makeparking(10, 1)">1</li>
                    <li (click)="makeparking(10, 2)">2</li>
                    <li (click)="makeparking(10, 3)">3</li>
                    <li (click)="makeparking(10, 4)">4</li>
                    <li (click)="makeparking(10, 5)">5</li>
                </ul>
            </div>
    
            <div class="fields">
                <label for="">Evaluează încadrarea exteriorului clădirii în ansamblul ei.</label>
                <ul class="nrCamere-11">
                    <li (click)="makeparking(11, 0)">0</li>
                    <li (click)="makeparking(11, 1)">1</li>
                    <li (click)="makeparking(11, 2)">2</li>
                    <li (click)="makeparking(11, 3)">3</li>
                    <li (click)="makeparking(11, 4)">4</li>
                    <li (click)="makeparking(11, 5)">5</li>
                </ul>
            </div>
        </div>
        

    </div>   

    <div class="tag" id="cladInterior">
        <h4>Clădire - interior</h4>

        <div class="tag-wrapper">
            <div class="fields">
                <label for="">Evaluează aspectul și calitatea ușii de la intrarea în clădire.</label>
                <ul class="nrCamere-12">
                    <li (click)="makeparking(12, 0)">0</li>
                    <li (click)="makeparking(12, 1)">1</li>
                    <li (click)="makeparking(12, 2)">2</li>
                    <li (click)="makeparking(12, 3)">3</li>
                    <li (click)="makeparking(12, 4)">4</li>
                    <li (click)="makeparking(12, 5)">5</li>
                </ul>
            </div>
    
            <div class="fields">
                <label for="">Evaluează aspectul și funcționalitatea holului central.</label>
                <ul class="nrCamere-13">
                    <li (click)="makeparking(13, 0)">0</li>
                    <li (click)="makeparking(13, 1)">1</li>
                    <li (click)="makeparking(13, 2)">2</li>
                    <li (click)="makeparking(13, 3)">3</li>
                    <li (click)="makeparking(13, 4)">4</li>
                    <li (click)="makeparking(13, 5)">5</li>
                </ul>
            </div>
    
            <div class="fields">
                <label for="">Evaluează înălțimea, lățimea și aspectul general al holurilor.</label>
                <ul class="nrCamere-14">
                    <li (click)="makeparking(14, 0)">0</li>
                    <li (click)="makeparking(14, 1)">1</li>
                    <li (click)="makeparking(14, 2)">2</li>
                    <li (click)="makeparking(14, 3)">3</li>
                    <li (click)="makeparking(14, 4)">4</li>
                    <li (click)="makeparking(14, 5)">5</li>
                </ul>
            </div>
    
            <div class="fields">
                <label for="">Evaluează calitatea iluminatului și luminozitatea în zonele comune.</label>
                <ul class="nrCamere-15">
                    <li (click)="makeparking(15, 0)">0</li>
                    <li (click)="makeparking(15, 1)">1</li>
                    <li (click)="makeparking(15, 2)">2</li>
                    <li (click)="makeparking(15, 3)">3</li>
                    <li (click)="makeparking(15, 4)">4</li>
                    <li (click)="makeparking(15, 5)">5</li>
                </ul>
            </div>
    
            <div class="fields">
                <label for="">Evaluează calitatea elementelor decorative din zonele comune.</label>
                <ul class="nrCamere-16">
                    <li (click)="makeparking(16, 0)">0</li>
                    <li (click)="makeparking(16, 1)">1</li>
                    <li (click)="makeparking(16, 2)">2</li>
                    <li (click)="makeparking(16, 3)">3</li>
                    <li (click)="makeparking(16, 4)">4</li>
                    <li (click)="makeparking(16, 5)">5</li>
                </ul>
            </div>
    
            <div class="fields">
                <label for="">Evaluează calitatea materialelor utilizate în zonele comune.</label>
                <ul class="nrCamere-17">
                    <li (click)="makeparking(17, 0)">0</li>
                    <li (click)="makeparking(17, 1)">1</li>
                    <li (click)="makeparking(17, 2)">2</li>
                    <li (click)="makeparking(17, 3)">3</li>
                    <li (click)="makeparking(17, 4)">4</li>
                    <li (click)="makeparking(17, 5)">5</li>
                </ul>
            </div>
    
            <div class="fields">
                <label for="">Evaluează orice mirosuri prezente în zonele comune.</label>
                <ul class="nrCamere-18">
                    <li (click)="makeparking(18, 0)">0</li>
                    <li (click)="makeparking(18, 1)">1</li>
                    <li (click)="makeparking(18, 2)">2</li>
                    <li (click)="makeparking(18, 3)">3</li>
                    <li (click)="makeparking(18, 4)">4</li>
                    <li (click)="makeparking(18, 5)">5</li>
                </ul>
            </div>
    
            <div class="fields">
                <label for="">Evaluează aspectul și funcționalitatea biroului de recepție.</label>
                <ul class="nrCamere-19">
                    <li (click)="makeparking(19, 0)">0</li>
                    <li (click)="makeparking(19, 1)">1</li>
                    <li (click)="makeparking(19, 2)">2</li>
                    <li (click)="makeparking(19, 3)">3</li>
                    <li (click)="makeparking(19, 4)">4</li>
                    <li (click)="makeparking(19, 5)">5</li>
                </ul>
            </div>
        </div>

        

    </div>   

    <div class="tag" id="Function">
        <h4>Funcționalități</h4>

        <div class="tag-wrapper">
            <div class="fields">
                <label for="">Evaluează ușurința și conveniența accesului la apartament.</label>
                <ul class="nrCamere-20">
                    <li (click)="makeparking(20, 0)">0</li>
                    <li (click)="makeparking(20, 1)">1</li>
                    <li (click)="makeparking(20, 2)">2</li>
                    <li (click)="makeparking(20, 3)">3</li>
                    <li (click)="makeparking(20, 4)">4</li>
                    <li (click)="makeparking(20, 5)">5</li>
                </ul>
            </div>
    
            <div class="fields">
                <label for="">Evaluează ușurința și conveniența accesului la clădire.</label>
                <ul class="nrCamere-21">
                    <li (click)="makeparking(21, 0)">0</li>
                    <li (click)="makeparking(21, 1)">1</li>
                    <li (click)="makeparking(21, 2)">2</li>
                    <li (click)="makeparking(21, 3)">3</li>
                    <li (click)="makeparking(21, 4)">4</li>
                    <li (click)="makeparking(21, 5)">5</li>
                </ul>
            </div>
    
            <div class="fields">
                <label for="">Evaluează ușurința și conveniența accesului la intrarea în clădire.</label>
                <ul class="nrCamere-22">
                    <li (click)="makeparking(22, 0)">0</li>
                    <li (click)="makeparking(22, 1)">1</li>
                    <li (click)="makeparking(22, 2)">2</li>
                    <li (click)="makeparking(22, 3)">3</li>
                    <li (click)="makeparking(22, 4)">4</li>
                    <li (click)="makeparking(22, 5)">5</li>
                </ul>
            </div>
    
            <div class="fields">
                <label for="">Evaluează ușurința și conveniența accesului la scări.</label>
                <ul class="nrCamere-23">
                    <li (click)="makeparking(23, 0)">0</li>
                    <li (click)="makeparking(23, 1)">1</li>
                    <li (click)="makeparking(23, 2)">2</li>
                    <li (click)="makeparking(23, 3)">3</li>
                    <li (click)="makeparking(23, 4)">4</li>
                    <li (click)="makeparking(23, 5)">5</li>
                </ul>
            </div>
    
            <div class="fields">
                <label for="">Evaluează ușurința și conveniența accesului la lifturi.</label>
                <ul class="nrCamere-24">
                    <li (click)="makeparking(24, 0)">0</li>
                    <li (click)="makeparking(24, 1)">1</li>
                    <li (click)="makeparking(24, 2)">2</li>
                    <li (click)="makeparking(24, 3)">3</li>
                    <li (click)="makeparking(24, 4)">4</li>
                    <li (click)="makeparking(24, 5)">5</li>
                </ul>
            </div>
    
            <div class="fields">
                <label for="">Evaluează ușurința și conveniența accesului la zona de recepție.</label>
                <ul class="nrCamere-25">
                    <li (click)="makeparking(25, 0)">0</li>
                    <li (click)="makeparking(25, 1)">1</li>
                    <li (click)="makeparking(25, 2)">2</li>
                    <li (click)="makeparking(25, 3)">3</li>
                    <li (click)="makeparking(25, 4)">4</li>
                    <li (click)="makeparking(25, 5)">5</li>
                </ul>
            </div>
    
            <div class="fields">
                <label for="">Evaluează ușurința și conveniența accesului la parcări.</label>
                <ul class="nrCamere-26">
                    <li (click)="makeparking(26, 0)">0</li>
                    <li (click)="makeparking(26, 1)">1</li>
                    <li (click)="makeparking(26, 2)">2</li>
                    <li (click)="makeparking(26, 3)">3</li>
                    <li (click)="makeparking(26, 4)">4</li>
                    <li (click)="makeparking(26, 5)">5</li>
                </ul>
            </div>
    
            <div class="fields">
                <label for="">Evaluează accesibilitatea facilităților pentru persoanele cu dizabilități.</label>
                <ul class="nrCamere-27">
                    <li (click)="makeparking(27, 0)">0</li>
                    <li (click)="makeparking(27, 1)">1</li>
                    <li (click)="makeparking(27, 2)">2</li>
                    <li (click)="makeparking(27, 3)">3</li>
                    <li (click)="makeparking(27, 4)">4</li>
                    <li (click)="makeparking(27, 5)">5</li>
                </ul>
            </div>
    
            <div class="fields">
                <label for="">Evaluează gradul de accesibilitate al clădirii pentru vizitatori.</label>
                <ul class="nrCamere-28">
                    <li (click)="makeparking(28, 0)">0</li>
                    <li (click)="makeparking(28, 1)">1</li>
                    <li (click)="makeparking(28, 2)">2</li>
                    <li (click)="makeparking(28, 3)">3</li>
                    <li (click)="makeparking(28, 4)">4</li>
                    <li (click)="makeparking(28, 5)">5</li>
                </ul>
            </div>
    
            <div class="fields">
                <label for="">Evaluează utilitatea și calitatea spațiilor comerciale din complex.</label>
                <ul class="nrCamere-29">
                    <li (click)="makeparking(29, 0)">0</li>
                    <li (click)="makeparking(29, 1)">1</li>
                    <li (click)="makeparking(29, 2)">2</li>
                    <li (click)="makeparking(29, 3)">3</li>
                    <li (click)="makeparking(29, 4)">4</li>
                    <li (click)="makeparking(29, 5)">5</li>
                </ul>
            </div>
    
            <div class="fields">
                <label for="">Evaluează garanția brand-ului.</label>
                <ul class="nrCamere-30">
                    <li (click)="makeparking(30, 0)">0</li>
                    <li (click)="makeparking(30, 1)">1</li>
                    <li (click)="makeparking(30, 2)">2</li>
                    <li (click)="makeparking(30, 3)">3</li>
                    <li (click)="makeparking(30, 4)">4</li>
                    <li (click)="makeparking(30, 5)">5</li>
                </ul>
            </div>
        </div>



    </div>  

    <div class="pointerwrapper">
        <div class="points">    
            <h3 id="ScorulTitlu">Scorul&nbsp;total</h3><h6>{{totalsum}}</h6>
            
        </div>
        <p>*Scorul maxim este 150</p>
    </div>
    
    
</div>