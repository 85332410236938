import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {SharedService} from 'src/app/shared.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-english-rez',
  templateUrl: './english-rez.component.html',
  styleUrls: ['./english-rez.component.css']
})
export class EnglishRezComponent implements OnInit {

  constructor(private service:SharedService, private router: Router, private datePipe: DatePipe) { }

  apSlideCounter!: number;
  whatApFilter!: number;
  adm:any;
  adm2:any;

  BuildingFloor!: string;
  BuildingIdFilter!: string;
  BuildingNameFilter!: string;
  BuildingFloorFilter!: string;
  BuildingRoomsFilter!: string;
  admApartmentPage!: number;
  BuildingListWithoutFilter:any=[];
  checkBuilding!: number;
  hollowApartmentNumb!: string;
  hollowFloor!: string;
  hollowNrOfRooms!: string;
  hollowSurface!: string;
  hollowPrice!: string;
  hollowPhoto!: string;
  hollowBuilding!: string;
  checkmarckCheck2!: number;
  checkmark3:boolean=false;
  checkmarckCheck3!: number;
  BuildingList:any=[];
  BuildingId!: string;
  BuildingName!: string;
  BuildingPhoto!: string;
  ApartmentNumb!: string;
  ApartmentPhoto!: string;
  ApartmentSurface!: string;
  ApartmentRooms!: string;
  ApartmentPrice!: string;
  ApartmentBalconSurface!: string;
  ApartmentOrentation!: string;
  ApartmentBathrooms!: string;
  SurfaceDormitor!: string;
  SurfaceBaie!: string;
  SurfaceHol!: string;
  SurfaceRoom!: string;
  ScrollBuilding!: number;
  checkScrollBuilding!: number;
  userseletcedrooms !: string;
  userselectedfloor !: string;
  userselectedbuilding!: string;
  camere!: string;
  etaj!: string;

  BuildingPriceFilter!: string;
  InitialPriceFilter!: string;

  ListForFilter:any=[];

  ngOnInit(): void {
    window.scrollTo(0, 0)

    this.ListForFilter = [1,2,3,4,5]
    this.checkmarckCheck=1
    this.checkmarckCheck1=1
    this.checkmarckCheck2=1
    this.checkmarckCheck3=1
    
    this.apSlideCounter = 0

    this.userseletcedrooms =   this.service.selectedrooms
    this.userselectedfloor =  this.service.selectedfloor
    this.userselectedbuilding =  this.service.selectedbuilding 

    this.BuildingPriceFilter = ''

    this.BuildingNameFilter=this.userselectedbuilding
    this.BuildingRoomsFilter=this.userseletcedrooms
    this.BuildingFloorFilter=this.userselectedfloor

    if (typeof this.userselectedfloor == 'undefined'){
      this.refreshBldList2();
    }
    else{
      this.BuildingNameFilter=this.userselectedbuilding
      this.BuildingRoomsFilter=this.userseletcedrooms
      this.BuildingFloorFilter=this.userselectedfloor
      this.refreshBldList();
    }
    


 
    this.checkBuilding = 1;
    this.ScrollBuilding = 0;

    this.whatApFilter= 0;

  


  this.checkbuttonsPREV1()

  

  }

  seemoreapartments(){
    this.service.selectedfloor= "0"
    this.service.selectedrooms= "1"
    this.service.selectedbuilding= "B"
    this.router.navigateByUrl('locuinte-en' )
  }

/*formular 1*/
buildingcHECHER(){
  if(this.checkBuilding % 2){
    this.BuildingNameFilter ="C"
    document.getElementById("blC-hover-btns")!.style.display = 'none';
    document.getElementById("blB1-hover-btns")!.style.display = '';

  }
  else{
    this.BuildingNameFilter ="B"
    document.getElementById("blB1-hover-btns")!.style.display = 'none';
    document.getElementById("blC-hover-btns")!.style.display = '';
  }
  this.FilterFn5()
  this.refreshBldList()

}
/* ------ etaj clafdire si numar camere selectors ------- */
addNumber(){
  this.checkBuilding++;
  this.FilterFn5()
  this.refreshBldList()


}
substractNumber(){
  this.checkBuilding--;
  this.FilterFn5()
  this.refreshBldList()

}


makeballbluebuilding(index: number){
  const etaj0=document.querySelectorAll('.nrEtaj1 li');
  if(etaj0[index].className == "active"){
    etaj0[index].className = "";
    this.cevabuilding=false
    this.checkAllPressings()
  }
  else{
    etaj0[index].className = "active";
    this.cevabuilding=true
  }
}
makeballnormalbuilding(index: number){
  const etaj0=document.querySelectorAll('.nrEtaj1 li');
  etaj0[index].className = "";
}


makeballbluefloor(index: number){
  const etaj0=document.querySelectorAll('.nrEtaj li');
  if(etaj0[index].className == "active"){
    etaj0[index].className = "";
  }
  else{
    etaj0[index].className = "active";
  }
}
makeballnormalfloor(index: number){
  const etaj0=document.querySelectorAll('.nrEtaj li');
  etaj0[index].className = "";
}

checkballfromactivetonormal(index: number){
  const etaj2=document.querySelectorAll('.nrCamere li');
  if(etaj2[index].className == "active"){
    etaj2[index].className = "";
  }
}

makeroomnormal(){
  this.checkballfromactivetonormal(0);
  this.checkballfromactivetonormal(1);
  this.checkballfromactivetonormal(2);
  this.checkballfromactivetonormal(3);
  this.checkballfromactivetonormal(4);
  
  }

makeballbluerooms(index: number){
  this.makeroomnormal()
  const etaj0=document.querySelectorAll('.db-camere li');
  if(etaj0[index].className == "active"){
    etaj0[index].className = "";
  }
  else{
    etaj0[index].className = "active";
  }
}
makeballnormalrooms(index: number){
  const etaj0=document.querySelectorAll('.db-camere li');
  etaj0[index].className = "";
}
//new presing filter for all
checkAllPressings(){
  if (this.cevabuilding==true &&this.cevaroom==true &&this.BuildingPriceFilter != ""){
    this.FilterBuildingandRoomandPrice()

  }

  else if (this.cevabuilding==false &&this.cevaroom==true &&this.BuildingPriceFilter != ""){
    this.FilterRoomandPrice()
  }
  else if (this.cevabuilding==true &&this.cevaroom==false &&this.BuildingPriceFilter != ""){
    this.FilterBuildingandPrice()
  }
  else if (this.cevabuilding==true &&this.cevaroom==true &&this.BuildingPriceFilter == ""){
    this.FilterBuildingandRoom()
  }
  else if (this.cevabuilding==true &&this.cevaroom==false &&this.BuildingPriceFilter == ""){
    this.FilterBuildingSimple()

  }
  else if (this.cevabuilding==false &&this.cevaroom==true &&this.BuildingPriceFilter == ""){
    this.FilterRoomSimple()
  }
  else if (this.cevabuilding==false &&this.cevaroom==false &&this.BuildingPriceFilter != ""){
    this.FilterPriceSimple()
  }
  else if (this.cevabuilding==false &&this.cevaroom==false &&this.BuildingPriceFilter == ""){
    this.refreshBldList2();
    
  }
  this.selected(this.BuildingList[0])
  this.apSlideCounter=0
  document.getElementById("tags-next1")!.style.display = 'none';
  document.getElementById("tags-next")!.style.display = 'flex';
  document.getElementById("tags-prev")!.style.display = 'none';
  document.getElementById("tags-prev1")!.style.display = 'flex';
}

//new building filters and the buttons
newmakebuildingB2(){
  this.BuildingNameFilter ="B2"
  this.makeballbluebuilding(2)
  this.makeballnormalbuilding(0)
  this.makeballnormalbuilding(1)
  this.checkAllPressings()
}

newmakebuildingB(){
  this.BuildingNameFilter ="B"
  this.makeballbluebuilding(1)
  this.makeballnormalbuilding(0)
  this.makeballnormalbuilding(2)
  this.checkAllPressings()

}

newmakebuildingC(){

  this.BuildingNameFilter ="C"
  this.makeballbluebuilding(0)
  this.makeballnormalbuilding(1)
  this.makeballnormalbuilding(2)
  this.checkAllPressings()

}

checkWhenPressingBuilding(){
  if(this.cevaroom==false && this.cevabuilding==false && this.BuildingPriceFilter == ''){
    this.refreshBldList2();
  }
  else if (this.BuildingPriceFilter == '' && this.cevaroom==false){
    this.FilterBuildingSimple()
  }
  else if(this.cevaroom==true){
    this.FilterBuildingandRoom()
  }
  else if(this.BuildingPriceFilter != ''){
    this.FilterBuildingandPrice()
  }
  else{
    this.FilterBuildingandRoomandPrice()
  }

}

FilterBuildingSimple(){
  var BuildingNameFilter = this.BuildingNameFilter;
  this.BuildingList = this.BuildingListWithoutFilter.filter( (el: 
    { BuildingId:
    { toString: () => string; }; BuildingName: { toString: () => string; }; 
      }) =>{
    return el.BuildingName.toString().toUpperCase().includes(
      BuildingNameFilter.toString().trim().toUpperCase()
    ) 
});
}
FilterBuildingandRoom(){
  var BuildingNameFilter = this.BuildingNameFilter;
  var BuildingRoomsFilter = this.BuildingRoomsFilter;

  this.BuildingList = this.BuildingListWithoutFilter.filter( (el: 
    { BuildingId:
    { toString: () => string; };  BuildingName: { toString: () => string; }
    ApartmentRooms: { toString: () => string; };  }) =>{

    return el.ApartmentRooms.toString().toLowerCase().includes(
      BuildingRoomsFilter.toString().trim().toLowerCase()
      
    )&&
      el.BuildingName.toString().toUpperCase().includes(
        BuildingNameFilter.toString().trim().toUpperCase()
      ) 
  });
}


FilterBuildingandPrice(){
  var BuildingNameFilter = this.BuildingNameFilter;
  var BuildingPriceFilter = this.BuildingPriceFilter;


  this.BuildingList = this.BuildingListWithoutFilter.filter( (el: 
    { BuildingId:
    { toString: () => string; };  BuildingName: { toString: () => string; }
    ApartmentPrice: { toString: () => string; };  }) =>{

    return el.BuildingName.toString().toUpperCase().includes(
        BuildingNameFilter.toString().trim().toUpperCase()
      ) && Number(el.ApartmentPrice) < Number(BuildingPriceFilter)
  });
}

FilterBuildingandRoomandPrice(){
  var BuildingNameFilter = this.BuildingNameFilter;
  var BuildingPriceFilter = this.BuildingPriceFilter;
  var BuildingRoomsFilter = this.BuildingRoomsFilter;

  this.BuildingList = this.BuildingListWithoutFilter.filter( (el: 
    { BuildingId:
    { toString: () => string; };  BuildingName: { toString: () => string; }
    ApartmentPrice: { toString: () => string; };  ApartmentRooms: { toString: () => string; }; }) =>{

    return el.BuildingName.toString().toUpperCase().includes(
        BuildingNameFilter.toString().trim().toUpperCase()
      ) && Number(el.ApartmentPrice) < Number(BuildingPriceFilter)
      && el.ApartmentRooms.toString().toLowerCase().includes(
        BuildingRoomsFilter.toString().trim().toLowerCase())
  });
}

//new rooms filters and buttons

makeballblueroomnew(index: number){
  const etaj0=document.querySelectorAll('.nrEtaj li');
  if(etaj0[index].className == "active"){
    etaj0[index].className = "";
    this.cevaroom=false

  }
  else{
    this.cevaroom=true;
    etaj0[index].className = "active";
    
  }
}
makeballnormalroomnew(index: number){
  const etaj0=document.querySelectorAll('.nrEtaj li');
  etaj0[index].className = "";
}


newmakerooms1(){
  this.BuildingRoomsFilter="1"


 this.makeballblueroomnew(0)
 this.makeballnormalroomnew(1)
 this.makeballnormalroomnew(2)
 this.makeballnormalroomnew(3)
 this.makeballnormalroomnew(4)
 this.checkAllPressings()
}

newmakerooms2(){
  this.BuildingRoomsFilter="2"


  this.makeballblueroomnew(1)
  this.makeballnormalroomnew(0)
  this.makeballnormalroomnew(2)
  this.makeballnormalroomnew(3)
  this.makeballnormalroomnew(4)
  this.checkAllPressings()
 }

 newmakerooms3(){
  this.BuildingRoomsFilter="3"

  this.makeballblueroomnew(2)
  this.makeballnormalroomnew(1)
  this.makeballnormalroomnew(0)
  this.makeballnormalroomnew(3)
  this.makeballnormalroomnew(4)
  this.checkAllPressings()
 }

 newmakerooms4(){
  this.BuildingRoomsFilter="4"

  this.makeballblueroomnew(3)
  this.makeballnormalroomnew(1)
  this.makeballnormalroomnew(2)
  this.makeballnormalroomnew(0)
  this.makeballnormalroomnew(4)
  this.checkAllPressings()
 }

 newmakerooms5(){
  this.BuildingRoomsFilter="5"

  this.makeballblueroomnew(4)
  this.makeballnormalroomnew(1)
  this.makeballnormalroomnew(2)
  this.makeballnormalroomnew(3)
  this.makeballnormalroomnew(0)
  this.checkAllPressings()
 }

FilterRoomSimple(){
  var BuildingRoomsFilter = this.BuildingRoomsFilter;
  this.BuildingList = this.BuildingListWithoutFilter.filter( (el: 
    { BuildingId:
    { toString: () => string; }; ApartmentRooms: { toString: () => string; };
      }) =>{
    return el.ApartmentRooms.toString().toLowerCase().includes(
      BuildingRoomsFilter.toString().trim().toLowerCase()
    ) 
});
}

FilterRoomandPrice(){
  var BuildingRoomsFilter = this.BuildingRoomsFilter;
  var BuildingPriceFilter = this.BuildingPriceFilter;


  this.BuildingList = this.BuildingListWithoutFilter.filter( (el: 
    { BuildingId:
    { toString: () => string; }; ApartmentRooms: { toString: () => string; };
    ApartmentPrice: { toString: () => string; };  }) =>{

    return el.ApartmentRooms.toString().toUpperCase().includes(
      BuildingRoomsFilter.toString().trim().toUpperCase()
      ) && Number(el.ApartmentPrice) < Number(BuildingPriceFilter)
  });
}



//price filter bar

pricefilter(){
  if(this.InitialPriceFilter=="75.000 €"){
    this.BuildingPriceFilter="75000"
  }
  else if(this.InitialPriceFilter=="90.000 €"){
    this.BuildingPriceFilter="90000"
  }
  else if(this.InitialPriceFilter=="110.000 €"){
    this.BuildingPriceFilter="110000"
  }
  else if(this.InitialPriceFilter=="135.000 €"){
    this.BuildingPriceFilter="135000"
  }
  else if(this.InitialPriceFilter=="160.000 €"){
    this.BuildingPriceFilter="160000"
  }
  else if(this.InitialPriceFilter=="210.000 €"){
    this.BuildingPriceFilter="210000"
  }
  else if(this.InitialPriceFilter==""){
    this.BuildingPriceFilter=""
  }
  this.checkAllPressings()
   
}
SeeWhatFilterAppliesPrice(){
  if(this.cevaroom==false && this.cevabuilding==false && this.BuildingPriceFilter == ''){
    this.refreshBldList2();
  }
  else if (this.cevaroom==false && this.cevabuilding==false){
    this.FilterPriceSimple()
  }
  else if(this.cevabuilding==true){
    this.FilterBuildingandPrice()
  }
  else if(this.cevaroom=true){
    this.FilterRoomandPrice()
  }
  else{
    this.FilterBuildingandRoomandPrice()
  }
}
FilterPriceSimple(){
  var BuildingPriceFilter = this.BuildingPriceFilter;
  this.BuildingList = this.BuildingListWithoutFilter.filter( (el: 
    { BuildingId:
    { toString: () => string; }; ApartmentPrice: { toString: () => string; }; 
      }) =>{
    return Number(el.ApartmentPrice) < Number(BuildingPriceFilter)}
    
    );
}
///new filter

FilterFn6(){
  var BuildingNameFilter = this.BuildingNameFilter;
  var BuildingFloorFilter = this.BuildingFloorFilter;

  this.BuildingList = this.BuildingListWithoutFilter.filter( (el: 
    { BuildingId:
    { toString: () => string; }; BuildingFloor: { toString: () => string; }; 
    BuildingName: { toString: () => string; }  }) =>{
     

    return el.BuildingFloor.toString().toLowerCase().includes(
      BuildingFloorFilter.toString().trim().toLowerCase()
    )&&
      el.BuildingName.toString().toUpperCase().includes(
        BuildingNameFilter.toString().trim().toUpperCase()
      ) 
  });
}
resetroomball(){
  this.makeballroomgrey(0);
  this.makeballroomgrey(1);
  this.makeballroomgrey(2);
  this.makeballroomgrey(3);
  this.makeballroomgrey(4);

}
resetapartmentwhite(index: number){
  const etaj2=document.querySelectorAll('.nrEtaj li');
    etaj2[index].className = "";
  
}
resetapartment(){
  this.resetapartmentwhite(0);
  this.resetapartmentwhite(1);
  this.resetapartmentwhite(2);
  this.resetapartmentwhite(3);
  this.resetapartmentwhite(4);
}
makeballroomgrey(index: number){
  const etaj2=document.querySelectorAll('.nrCamere li');
  if(etaj2[index].className == ""){
    etaj2[index].className = "deactive";
  }
  else{
    etaj2[index].className = "deactive";
  }
}



makeEtajParter(){
  this.apSlideCounter=0;
  this.resetroomball();
  this.BuildingFloorFilter="0"
  this.FilterFn6();

  let lenght1 = (this.BuildingList.length)
  for ( let step = 0; step<lenght1; step++){
    for (let num of this.ListForFilter) {
      if(this.BuildingList[step]["ApartmentRooms"] == num){
        const etaj2=document.querySelectorAll('.nrCamere li');
        if(etaj2[num-1].className == ""){
          etaj2[num-1].className = "";
      }  else{
        etaj2[num-1].className = "";
      }
    }
  }}



  this.refreshBldList()

  this.makeballbluefloor(0)
  this.makeballnormalfloor(1)
  this.makeballnormalfloor(2)
  this.makeballnormalfloor(3)
  this.makeballnormalfloor(4)
  this.deactiveslider();
}
makeEtaj1(){
  this.apSlideCounter=0;
  this.BuildingFloorFilter="1"
  this.FilterFn6();
  this.resetroomball()
  let lenght1 = (this.BuildingList.length)
  for ( let step = 0; step<lenght1; step++){
    for (let num of this.ListForFilter) {
      if(this.BuildingList[step]["ApartmentRooms"] == num){
        const etaj2=document.querySelectorAll('.nrCamere li');
        if(etaj2[num-1].className == ""){
          etaj2[num-1].className = "";
      }  else{
        etaj2[num-1].className = "";
      }
    }
  }}
  
  this.refreshBldList()

  this.makeballbluefloor(1)
  this.makeballnormalfloor(0)
  this.makeballnormalfloor(2)
  this.makeballnormalfloor(3)
  this.makeballnormalfloor(4)
  this.deactiveslider();
}
makeEtaj2(){
  this.apSlideCounter=0;
  this.BuildingFloorFilter="2"
  this.FilterFn6();
  this.resetroomball()
  let lenght1 = (this.BuildingList.length)
  for ( let step = 0; step<lenght1; step++){
    for (let num of this.ListForFilter) {
      if(this.BuildingList[step]["ApartmentRooms"] == num){
        const etaj2=document.querySelectorAll('.nrCamere li');
        if(etaj2[num-1].className == ""){
          etaj2[num-1].className = "";
      }  else{
        etaj2[num-1].className = "";
      }
    }
  }}
  this.refreshBldList()

  this.makeballbluefloor(2)
  this.makeballnormalfloor(1)
  this.makeballnormalfloor(0)
  this.makeballnormalfloor(3)
  this.makeballnormalfloor(4)
  this.deactiveslider();
}
makeEtaj3(){
  this.apSlideCounter=0;
  this.BuildingFloorFilter="3"
  this.FilterFn6();
  this.resetroomball()
  let lenght1 = (this.BuildingList.length)
  for ( let step = 0; step<lenght1; step++){
    for (let num of this.ListForFilter) {
      if(this.BuildingList[step]["ApartmentRooms"] == num){
        const etaj2=document.querySelectorAll('.nrCamere li');
        if(etaj2[num-1].className == ""){
          etaj2[num-1].className = "";
      }  else{
        etaj2[num-1].className = "";
      }
    }
  }}
  this.refreshBldList()

  this.makeballbluefloor(3)
  this.makeballnormalfloor(1)
  this.makeballnormalfloor(2)
  this.makeballnormalfloor(0)
  this.makeballnormalfloor(4)
  this.deactiveslider();
}
makeEtaj4(){
  this.apSlideCounter=0;
  this.BuildingFloorFilter="4"
  this.FilterFn6();
  this.resetroomball()
  let lenght1 = (this.BuildingList.length)
  for ( let step = 0; step<lenght1; step++){
    for (let num of this.ListForFilter) {
      if(this.BuildingList[step]["ApartmentRooms"] == num){
        const etaj2=document.querySelectorAll('.nrCamere li');
        if(etaj2[num-1].className == ""){
          etaj2[num-1].className = "";
      }  else{
        etaj2[num-1].className = "";
      }
    }
  }}
  this.refreshBldList()

  this.makeballbluefloor(4)
  this.makeballnormalfloor(1)
  this.makeballnormalfloor(2)
  this.makeballnormalfloor(3)
  this.makeballnormalfloor(0)
  this.deactiveslider()
}

deactiveslider(){
  document.getElementById("tags-next")!.style.display = 'none';
  document.getElementById("tags-next1")!.style.display = 'flex';

  document.getElementById("tags-prev")!.style.display = 'none';
  document.getElementById("tags-prev1")!.style.display = 'flex';
}
checkLenghList(){
  if(this.BuildingList.length == 1){
    this.deactiveslider()
  }
  else{
    document.getElementById("tags-next")!.style.display = 'flex';
    document.getElementById("tags-next1")!.style.display = 'none';

    document.getElementById("tags-prev")!.style.display = 'none';
    document.getElementById("tags-prev1")!.style.display = 'flex';
  }
}
makerooms1(){

  this.apSlideCounter=0;
  this.BuildingRoomsFilter="1"
  this.FilterFn5()
  this.refreshBldList()

  this.makeballbluerooms(0)
  this.checkLenghList()
}
makerooms2(){

  this.apSlideCounter=0;
  this.BuildingRoomsFilter="2"
  this.FilterFn5()
  this.refreshBldList()

  this.makeballbluerooms(1)
  this.checkLenghList()
}
makerooms3(){

  this.apSlideCounter=0;
  this.BuildingRoomsFilter="3"
  this.FilterFn5()
  this.refreshBldList()

  this.makeballbluerooms(2)
  this.checkLenghList()
}
makerooms4(){

  this.apSlideCounter=0;
  this.BuildingRoomsFilter="4"
  this.FilterFn5()
  this.refreshBldList()

  this.makeballbluerooms(3)
  this.checkLenghList()
}

makerooms5(){

  this.apSlideCounter=0;
  this.BuildingRoomsFilter="5"
  this.FilterFn5()
  this.refreshBldList()

  this.makeballbluerooms(4)
  this.checkLenghList()
}

selected(item: any){
  this.adm=item;
  this.hollowApartmentNumb = "nr. " + this.adm.ApartmentNumb


  if ( this.adm.ApartmentRooms == 1){
    this.camere = "room"
  }
  else{
    this.camere ="rooms"
  }

  if ( this.adm.BuildingFloor == 0){
    this.etaj = "ground floor"
  }
  else{
    this.etaj = this.etaj
  }
  if ( this.adm.BuildingFloor == 0){
    this.hollowFloor= "ground floor"
  }
  else{
    this.hollowFloor=this.adm.BuildingFloor
  }

  this.hollowNrOfRooms= this.adm.ApartmentRooms +" "+ this.camere
  this.hollowSurface =  this.adm.ApartmentSurface+" M²"
  this.hollowPhoto =  "../../../assets/" + this.adm.BuildingPhoto
  this.hollowBuilding = this.adm.BuildingName
  this.hollowPrice =  this.adm.ApartmentPrice.slice(0, -3)+","+this.adm.ApartmentPrice.slice(-3);


  if(this.adm.ApartmentPrice == 0){
    this.hollowApartmentNumb = "indisponibil"
    this.hollowFloor=" indisponibil"
    this.hollowNrOfRooms= " indisponibil"
  
    this.hollowSurface = " indisponibil"
    this.hollowPhoto =  "../../../assets/" + this.adm.BuildingPhoto
    this.hollowBuilding = " indisponibil"
  }


}



checkbuttons1(){
  if(this.ScrollBuilding <=  this.checkScrollBuilding){

    document.getElementById("tags-next")!.style.display = 'none';
    document.getElementById("tags-next1")!.style.display = 'flex';
    }


}


checkbuttonsPREV1(){
  if(this.ScrollBuilding < 1){
    document.getElementById("tags-prev")!.style.display = 'none';
    document.getElementById("tags-prev1")!.style.display = 'flex';
    }
  else{
    document.getElementById("tags-prev")!.style.display = 'flex';
    document.getElementById("tags-prev1")!.style.display = 'none';
  }
}

selectedNext(){
  this.apSlideCounter ++
  if(this.BuildingList.length - 1 == this.apSlideCounter){
    document.getElementById("tags-next")!.style.display = 'none';
    document.getElementById("tags-next1")!.style.display = 'flex';
  }
  
  this.selected(this.BuildingList[this.apSlideCounter])
  document.getElementById("tags-prev")!.style.display = 'flex';
  document.getElementById("tags-prev1")!.style.display = 'none';
}



selectedPrev(){
    this.apSlideCounter --

    document.getElementById("tags-next1")!.style.display = 'none';
    document.getElementById("tags-next")!.style.display = 'flex';

    if(this.apSlideCounter==0){
      document.getElementById("tags-prev")!.style.display = 'none';
      document.getElementById("tags-prev1")!.style.display = 'flex';
    }
    this.selected(this.BuildingList[this.apSlideCounter])
}






checkarrowfiltre(){
  if(this.ScrollBuilding ==  this.checkScrollBuilding){
    document.getElementById("tags-next")!.style.display = 'none';
    document.getElementById("tags-next1")!.style.display = 'flex';
    }
}

refreshBldList(){
  this.service.getBuildingList().subscribe(data=>{
    this.BuildingList=data;
    this.BuildingListWithoutFilter=data;
    this.FilterFn5(); 
    this.selected(this.BuildingList[0])
    
     // 👉️ here u have the comma solution: const withoutLast3 = this.adm.ApartmentPrice.slice(0, -3)+","+this.adm.ApartmentPrice.slice(-3);;
    this.checkScrollBuilding=(this.BuildingList.length)
    this.service.selectedApartment=this.BuildingList[this.whatApFilter]
  });
}

refreshBldList2(){
  this.service.getBuildingList().subscribe(data=>{
    this.BuildingList=data;
    this.BuildingListWithoutFilter=data;
    this.selected(this.BuildingList[0])
    
     // 👉️ here u have the comma solution: const withoutLast3 = this.adm.ApartmentPrice.slice(0, -3)+","+this.adm.ApartmentPrice.slice(-3);;
    this.checkScrollBuilding=(this.BuildingList.length)
    this.service.selectedApartment=this.BuildingList[this.whatApFilter]
  });
}


  FilterFn5(){

    var BuildingNameFilter = this.BuildingNameFilter;
    var BuildingFloorFilter = this.BuildingFloorFilter;
    var BuildingRoomsFilter = this.BuildingRoomsFilter;

    this.BuildingList = this.BuildingListWithoutFilter.filter( (el: 
      { BuildingId:
      { toString: () => string; }; BuildingFloor: { toString: () => string; }; 
      BuildingName: { toString: () => string; }
      ApartmentRooms: { toString: () => string; };  }) =>{
       

      return el.ApartmentRooms.toString().toLowerCase().includes(
        BuildingRoomsFilter.toString().trim().toLowerCase()
        
      )
      && el.BuildingFloor.toString().toLowerCase().includes(
        BuildingFloorFilter.toString().trim().toLowerCase()
      )&&
        el.BuildingName.toString().toUpperCase().includes(
          BuildingNameFilter.toString().trim().toUpperCase()
        ) 
    });
 }

 navSlider(): void{
  const burger = document.querySelector('.burger');
  const nav = document.querySelector('.mob-navbar');
  const navBar = document.querySelectorAll<HTMLElement>('.mob-navbar a');
//toggle nav regtangle
  nav!.classList.toggle('nav-active');
//animate linksß
  navBar.forEach((link, index) => {
    if(link.style.animation){
      link.style.animation = '';
    } else {
    link.style.animation = `navLinkFade 0.5s ease forwards ${index / 7 + 0.01}s`;
    }
  });

//burger animation
burger!.classList.toggle('toggle');

}


Name!: string;
Phone!: string;
Email!: string;
Message!: string;
FormLocation!: string;
DateOfGiving!: any;
BucketDate!: Date;
checkmark:boolean=false;

cevaroom:boolean=false;
cevabuilding:boolean=false;

checkmarckCheck!: number;
checkmark1:boolean=false;
checkmarckCheck1!: number;
checkmark2:boolean=false;
acestCamp:boolean=false;
acestCamp1:boolean=false;
acestCamp2:boolean=false;

/*formular 1*/
sendFormOne(){
  if(this.checkmark == true){
  this.FormLocation = "programează vizionare"
  this.BucketDate = new Date();
  this.DateOfGiving = (this.datePipe.transform(this.BucketDate,"yyyy-MM-dd"));
    var val = {BuildingId:this.BuildingId,
    Name:this.Name,
    Phone:this.Phone,
    Email:this.Email,
    Message:this.Message,
    FormLocation:this.FormLocation,
    DateOfGiving:this.DateOfGiving,};

  this.service.addform(val).subscribe(res=>{
    (res.toString());
  });
  alert("Your message has been sent")
} else {

  this.acestCamp=true;
}
}
checked(){
  this.checkmarckCheck = this.checkmarckCheck +1;
  if(this.checkmarckCheck % 2 === 0 ){
  this.checkmark = true;

  const modalbody=document.querySelector('.modal-body button');
  modalbody!.className = "modal-button";

  }
  else{
    this.checkmark = false;

  const modalbody=document.querySelector('.modal-body button');
  modalbody!.className = "deactive modal-button";
  }

}
sendFormOne2(){
  if(this.checkmark1 == true){
    this.FormLocation = "descărca brosura"
    this.BucketDate = new Date();
    this.DateOfGiving = (this.datePipe.transform(this.BucketDate,"yyyy-MM-dd"));
      var val = {BuildingId:this.BuildingId,
      Name:this.Name,
      Phone:this.Phone,
      Email:this.Email,
      Message:'A DESCARCAT BROSURA',
      FormLocation:this.FormLocation,
      DateOfGiving:this.DateOfGiving,};
  
    this.service.addform(val).subscribe(res=>{
      (res.toString());
    });
    alert("Your message has been sent")
    window.open("../../../assets/pdf/Brochure_2024_EN.pdf")
  } else {
  
    this.acestCamp1=true;
  }
}
checked2(){
  this.checkmarckCheck1 = this.checkmarckCheck1 +1;
  if(this.checkmarckCheck1 % 2 === 0 ){
  this.checkmark1 = true;

  const modalbody=document.querySelector('.modal-body-brosura button');
  modalbody!.className = "modal-button-brosura";

  }
  else{
    this.checkmark1 = false;

    const modalbody=document.querySelector('.modal-body-brosura button');
    modalbody!.className = "deactive modal-button-brosura";
  }

}

sendFormOne3(){
  if(this.checkmark2 == true){
  this.FormLocation = "te contactam noi"
  this.BucketDate = new Date();
  this.DateOfGiving = (this.datePipe.transform(this.BucketDate,"yyyy-MM-dd"));
    var val = {BuildingId:this.BuildingId,
    Name:this.Name,
    Phone:this.Phone,
    Email:this.Email,
    Message:this.Message,
    FormLocation:this.FormLocation,
    DateOfGiving:this.DateOfGiving,};

  this.service.addform(val).subscribe(res=>{
    (res.toString());
  });
  alert("Your message has been sent")
} else {

  this.acestCamp2=true;

}
}
checked3(){
  this.checkmarckCheck2= this.checkmarckCheck2 +1;
  if(this.checkmarckCheck2 % 2 === 0 ){
  this.checkmark2 = true;

  const modalbody=document.querySelector('.footer3 button');
  modalbody!.className = "";

  }
  else{
    this.checkmark1 = false;

    const modalbody=document.querySelector('.footer3 button');
    modalbody!.className = "deactive";
  }

}
/*------------formular OFERTAS------------*/
checked4(){

  this.checkmarckCheck3 = this.checkmarckCheck3 +1;
  
  if(this.checkmarckCheck3 % 2 === 0 ){
  this.checkmark3 = true;
  const modalbody=document.querySelector('.modal-body-oferta button');
  modalbody!.className = "modal-button-oferta";
  }
  else{
    this.checkmark3 = false;
    const modalbody=document.querySelector('.modal-body-oferta button');
    modalbody!.className = "deactive modal-button-oferta";
  }

}


openOferta(){
window.open("https://www.facebook.com/100064165597277/posts/pfbid02NkycefKBWdFZEQXPNBaxK9XJfxLMZ9NieShQ44Y56QFdw1jLVAemNwRS8x7RCLWNl/")
}
sendFormOferta(){
if(this.checkmark3 == true){
this.FormLocation = "Promotie Craciun"
this.BucketDate = new Date();
this.DateOfGiving = (this.datePipe.transform(this.BucketDate,"yyyy-MM-dd"));

var val = {BuildingId:this.BuildingId,
  Name:this.Name,
  Phone:this.Phone,
  Email:this.Email,
  Message:this.Message,
  FormLocation:this.FormLocation,
  DateOfGiving:this.DateOfGiving,};

this.service.addform(val).subscribe(res=>{
  (res.toString());
});
alert("Your message has been sent")
} else {

this.acestCamp=true;
}
}


checkUndifined(){

  if(this.adm.ApartmentPrice == "0"){
    document.getElementById("tags-prev")!.style.display = 'none';
    document.getElementById("tags-prev1")!.style.display = 'flex';

    document.getElementById("tags-next")!.style.display = 'none';
    document.getElementById("tags-next1")!.style.display = 'flex';


  }
  else{
      document.getElementById("tags-prev1")!.style.display = 'flex';
      document.getElementById("tags-prev")!.style.display = 'none';
  
      document.getElementById("tags-next")!.style.display = 'flex';
      document.getElementById("tags-next1")!.style.display = 'none';


  }
}
seeVirtual(){
  window.open("https://my.matterport.com/show/?m=oz7L1ckczHp")
}
numarulapartamentuluiselectat!: string;
modaldetalii:boolean=false;

seeApartment2(item: any){
  this.service.apartamentdinMobile=false;
  this.service.apartamentdinDesktop=true;



  this.service.selectedApartmenDesktop=item;
  console.log(item.ApartmentPrice)
  this.modaldetalii= true;  
  this.numarulapartamentuluiselectat = item.ApartmentNumb
  this.service.serviceRoDetalii=false;
  this.service.serviceEnDetalii=true;
}

seeApartment(){
  this.service.apartamentdinDesktop=false;
  this.service.apartamentdinMobile=true;


  this.modaldetalii= true;  
  this.numarulapartamentuluiselectat = this.BuildingList[this.whatApFilter].ApartmentNumb

  this.service.selectedApartment=this.BuildingList[this.whatApFilter]
  this.service.serviceRoDetalii=false;
  this.service.serviceEnDetalii=true;
}

closeModalDetalii(){
  this.modaldetalii= false;
}


seetermeni(){
  this.router.navigateByUrl('/termeni-si-conditii-en')
}

footerlinks(){
  this.service.selectedfloor="0"
  this.service.selectedrooms= "1"
  this.service.selectedbuilding= "B"
  this.router.navigateByUrl('/locuinte-en' )
}
footerlinks1(){
  this.makeEtajParter();
  this.makerooms1()
  window.scrollTo(0,0)
}

footerlinks2(){
  this.makeEtaj1();
  this.makerooms2()
  window.scrollTo(0,0)
}

footerlinks3(){
  this.makeEtaj3();
  this.makerooms3()
  window.scrollTo(0,0)
}

footerlinks4(){
  this.makeEtaj4();
  this.makerooms4()
  window.scrollTo(0,0)
}


seehome(){
  this.router.navigateByUrl('/en')
}
seelifestyle(){
  this.router.navigateByUrl('/lifestyle-en')
  }
seemedia(){
  this.router.navigateByUrl('/mediapage-en')
  }

seedezvoltator(){
  this.router.navigateByUrl('/dezvoltator-en')
  }

seecontact(){
  this.router.navigateByUrl('/contact-en')
  }

seelocatie(){
  this.router.navigateByUrl('/locatie-en')
  }

seealege(){
  this.router.navigateByUrl('/locuinte-en')
  }

  lifestyle(){
    this.router.navigateByUrl('/lifestyle-en')
  }

  locatie(){
    this.router.navigateByUrl('/locatie-en')
  }

  changeRoEn(){
    this.service.selectEnRo=true;
    this.router.navigateByUrl('/locuinte')
  }

}

