import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

import { ScreenshotService } from 'src/app/screenshot.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-checklist-final',
  templateUrl: './checklist-final.component.html',
  styleUrls: ['./checklist-final.component.css']
})
export class ChecklistFinalComponent implements OnInit {

  constructor(
    private service: SharedService,
    private router: Router,
    private datePipe: DatePipe,
    private screenshotService: ScreenshotService
  ) { }

  nrparcare!: number;
  date!: string;
  idapto!: string;
  anunt!: string;
  adresa!: string;
  pret!: string;
  pretnegoc!: string;
  agent!: string;
  telefon!: string;
  email!: string;
  mpconsttotal!: string;
  mputiletotal!: string;
  etaj!: string;
  dinEtaj!: string;
  nrcamere!: number;
  mobiliat!: string;
  cameraMp!: string;
  balcon_terasa!: string;
  cameraNote!: string;
  bucatariaMp!: string;
  bucatariaMobi!: string;
  bucatariaElect!: string;
  inchisadeschisa!: string;
  bucatariaNote!: string;

  baie1Note!: string;
  baie2Mp!: string;

  dormitor1Mp!: string;
  dormitor2Mp!: string;
  dormitor2Amenaj!: string;
  dormitor2Renov!: string;
  dormitor2Note!: string;

  balconMp!: string;
  balconDesc!: string;
  balconNote!: string;
  curteMp!: string;
  curteGeam!: string;
  curteNote!: string;
  anconstr!: string;
  nrScari!: string;
  nrApto!: string;
  boxa!: string;
  lift!: string;
  parcPoz!: string;
  parcLoc!: string;
  parcNote!: string;
  conditNou!: string;
  conditAnv!: string;
  conditNote!: string;
  detaliiChelt!: string;
  docs1!: string;
  docs1nu!: string;
  docs2!: string;
  care!: string;
  docs3!: string;
  docs4!: string;
  docs5!: string;
  docs6!: string;
  docs7!: string;
  
//2nd page
advanced1!: number;
advanced2!: number;
advanced3!: number;
advanced4!: number;
advanced5!: number;
advanced6!: number;
advanced7!: number;
advanced8!: number;
advanced9!: number;
advanced10!: number;
advanced11!: number;
advanced12!: number;
advanced13!: number;
advanced14!: number;
advanced15!: number;
advanced16!: number;
advanced17!: number;
advanced18!: number;
advanced19!: number;
advanced20!: number;
advanced21!: number;
advanced22!: number;
advanced23!: number;
advanced24!: number;
advanced25!: number;
advanced26!: number;
advanced27!: number;
advanced28!: number;
advanced29!: number;
advanced30!: number;

detaliibaie: string[] = ['', ''];
numBaie!: number;
baieMp: string[] = ['', ''];
amenajare: string[] = ['', ''];
baie2Note: string[] = ['', ''];

numroom: number = 2;
roomMp: string[] = ['', ''];
dormitor1Amenaj: string[] = ['', ''];
dormitor1Renov: string[] = ['', ''];
detaliiroom: string[] = ['', ''];
dormitor1Note: string[] = ['', ''];

ngOnInit(): void {
    Object.entries(this.service).forEach(([key, value]) => {
      (this as any)[key] = (value === 'undefined' || value === '') ? -1 : value;
    });
  this.numBaie=this.service.numBaie
  for (let i = 0; i < this.numBaie; i++) {
    this.baieMp[i] =  this.assignValueWithDefault(this.service.baieMp[i] , '-');
    this.detaliibaie[i] = this.assignValueWithDefault(this.service.detaliibaie[i] , '-');
    this.amenajare[i] = this.assignValueWithDefault(this.service.amenajare[i] , '-');
    this.baie2Note[i] = this.assignValueWithDefault(this.service.baie2Note[i] , '-');
  }
  this.numroom = this.service.numroom;
for (let i = 0; i < this.numroom; i++) {
this.roomMp[i] = this.assignValueWithDefault(this.service.roomMp[i], '-');
this.dormitor1Amenaj[i] = this.assignValueWithDefault(this.service.dormitor1Amenaj[i], '-');
this.dormitor1Renov[i] = this.assignValueWithDefault(this.service.dormitor1Renov[i], '-');
this.detaliiroom[i] = this.assignValueWithDefault(this.service.detaliiroom[i], '-');
this.dormitor1Note[i] = this.assignValueWithDefault(this.service.dormitor1Note[i], '-');
}

    this.date = this.assignValueWithDefault(this.service.date, '-');
    this.idapto = this.assignValueWithDefault(this.service.idapto, '-');
    this.anunt = this.assignValueWithDefault(this.service.anunt, '-');
    this.adresa = this.assignValueWithDefault(this.service.adresa, '-');
    this.pret = this.assignValueWithDefault(this.service.pret, '-');
    this.pretnegoc = this.assignValueWithDefault(this.service.pretnegoc, '-');
    this.agent = this.assignValueWithDefault(this.service.agent, '-');
    this.telefon = this.assignValueWithDefault(this.service.telefon, '-');
    this.email = this.assignValueWithDefault(this.service.email, '-');
    this.mpconsttotal = this.assignValueWithDefault(this.service.mpconsttotal, '-');
    this.mputiletotal = this.assignValueWithDefault(this.service.mputiletotal, '-');
    this.etaj = this.assignValueWithDefault(this.service.etaj, '-');
    this.dinEtaj = this.assignValueWithDefault(this.service.dinEtaj, '-');
    this.nrcamere = this.assignValueWithDefault(this.service.nrcamere, '-');
    this.mobiliat = this.assignValueWithDefault(this.service.mobiliat, '-');
    this.cameraMp = this.assignValueWithDefault(this.service.cameraMp, '-');
    this.balcon_terasa = this.assignValueWithDefault(this.service.balcon_terasa, '-');
    this.cameraNote = this.assignValueWithDefault(this.service.cameraNote, '-');
    this.bucatariaMp = this.assignValueWithDefault(this.service.bucatariaMp, '-');
    this.bucatariaMobi = this.assignValueWithDefault(this.service.bucatariaMobi, '-');
    this.bucatariaElect = this.assignValueWithDefault(this.service.bucatariaElect, '-');
    this.inchisadeschisa = this.assignValueWithDefault(this.service.inchisadeschisa, '-');
    this.bucatariaNote = this.assignValueWithDefault(this.service.bucatariaNote, '-');
    this.baie1Note = this.assignValueWithDefault(this.service.baie1Note, '-');
    this.baie2Mp = this.assignValueWithDefault(this.service.baie2Mp, '-');
    this.dormitor1Mp = this.assignValueWithDefault(this.service.dormitor1Mp, '-');
    this.dormitor1Amenaj = this.assignValueWithDefault(this.service.dormitor1Amenaj, '-');
    this.dormitor1Renov = this.assignValueWithDefault(this.service.dormitor1Renov, '-');
    this.dormitor1Note = this.assignValueWithDefault(this.service.dormitor1Note, '-');
    this.dormitor2Mp = this.assignValueWithDefault(this.service.dormitor2Mp, '-');
    this.dormitor2Amenaj = this.assignValueWithDefault(this.service.dormitor2Amenaj, '-');
    this.dormitor2Renov = this.assignValueWithDefault(this.service.dormitor2Renov, '-');
    this.dormitor2Note = this.assignValueWithDefault(this.service.dormitor2Note, '-');
    this.balconMp = this.assignValueWithDefault(this.service.balconMp, '-');
    this.balconDesc = this.assignValueWithDefault(this.service.balconDesc, '-');
    this.balconNote = this.assignValueWithDefault(this.service.balconNote, '-');
    this.curteMp = this.assignValueWithDefault(this.service.curteMp, '-');
    this.curteGeam = this.assignValueWithDefault(this.service.curteGeam, '-');
  this.curteNote = this.assignValueWithDefault(this.service.curteNote, '-');
  this.anconstr = this.assignValueWithDefault(this.service.anconstr, '-');
  this.nrScari = this.assignValueWithDefault(this.service.nrScari, '-');
  this.nrApto = this.assignValueWithDefault(this.service.nrApto, '-');
  this.boxa = this.assignValueWithDefault(this.service.boxa, '-');
  this.lift = this.assignValueWithDefault(this.service.lift, '-');
  this.parcPoz = this.assignValueWithDefault(this.service.parcPoz, '-');
  this.parcLoc = this.assignValueWithDefault(this.service.parcLoc, '-');
  this.parcNote = this.assignValueWithDefault(this.service.parcNote, '-');
  this.conditNou = this.assignValueWithDefault(this.service.conditNou, '-');
  this.conditAnv = this.assignValueWithDefault(this.service.conditAnv, '-');
  this.conditNote = this.assignValueWithDefault(this.service.conditNote, '-');
  this.detaliiChelt = this.assignValueWithDefault(this.service.detaliiChelt, '-');
  this.docs1 = this.assignValueWithDefault(this.service.docs1, '-');
  this.docs1nu = this.assignValueWithDefault(this.service.docs1nu, '-');
  this.docs2 = this.assignValueWithDefault(this.service.docs2, '-');
  this.care = this.assignValueWithDefault(this.service.care, '-');
  this.docs3 = this.assignValueWithDefault(this.service.docs3, '-');
  this.docs4 = this.assignValueWithDefault(this.service.docs4, '-');
  this.docs5 = this.assignValueWithDefault(this.service.docs5, '-');
  this.docs6 = this.assignValueWithDefault(this.service.docs6, '-');
  this.docs7 = this.assignValueWithDefault(this.service.docs7, '-');
  this.nrparcare = this.assignValueWithDefault(this.service.nrparcare, '-');
  
  //page 2 variables
  
    //page 2 variables
  this.advanced1 = this.assignValueWithDefault(this.service.advanced1, '-');
  this.advanced2 = this.assignValueWithDefault(this.service.advanced2, '-');
  this.advanced3 = this.assignValueWithDefault(this.service.advanced3, '-');
  this.advanced4 = this.assignValueWithDefault(this.service.advanced4, '-');
  this.advanced5 = this.assignValueWithDefault(this.service.advanced5, '-');
  this.advanced6 = this.assignValueWithDefault(this.service.advanced6, '-');
  this.advanced7 = this.assignValueWithDefault(this.service.advanced7, '-');
  this.advanced8 = this.assignValueWithDefault(this.service.advanced8, '-');
  this.advanced9 = this.assignValueWithDefault(this.service.advanced9, '-');
  this.advanced10 = this.assignValueWithDefault(this.service.advanced10, '-');
  this.advanced11 = this.assignValueWithDefault(this.service.advanced11, '-');
  this.advanced12 = this.assignValueWithDefault(this.service.advanced12, '-');
  this.advanced13 = this.assignValueWithDefault(this.service.advanced13, '-');
  this.advanced14 = this.assignValueWithDefault(this.service.advanced14, '-');
  this.advanced15 = this.assignValueWithDefault(this.service.advanced15, '-');
  this.advanced16 = this.assignValueWithDefault(this.service.advanced16, '-');
  this.advanced17 = this.assignValueWithDefault(this.service.advanced17, '-');
  this.advanced18 = this.assignValueWithDefault(this.service.advanced18, '-');
  this.advanced19 = this.assignValueWithDefault(this.service.advanced19, '-');
  this.advanced20 = this.assignValueWithDefault(this.service.advanced20, '-');
  this.advanced21 = this.assignValueWithDefault(this.service.advanced21, '-');
  this.advanced22 = this.assignValueWithDefault(this.service.advanced22, '-');
  this.advanced23 = this.assignValueWithDefault(this.service.advanced23, '-');
  this.advanced24 = this.assignValueWithDefault(this.service.advanced24, '-');
  this.advanced25 = this.assignValueWithDefault(this.service.advanced25, '-');
  this.advanced26 = this.assignValueWithDefault(this.service.advanced26, '-');
  this.advanced27 = this.assignValueWithDefault(this.service.advanced27, '-');
  this.advanced28 = this.assignValueWithDefault(this.service.advanced28, '-');
  this.advanced29 = this.assignValueWithDefault(this.service.advanced29, '-');
  this.advanced30 = this.assignValueWithDefault(this.service.advanced30, '-');
  
  
  //print(
  this.generatePDF()


  }

  assignValueWithDefault(value: any, defaultValue: any): any {
    return value === undefined ? defaultValue : value;
  }
  

  seehome(): void {
    this.router.navigateByUrl('/');
  }
  seetermeni(){
    this.router.navigateByUrl('/termeni-si-conditii')
  }

  generatePDF(): void {
    setTimeout(() => {
      const sectionToConvert = document.getElementById('pdf');
      if (!sectionToConvert) {
        console.error('Could not find element with ID "section-to-pdf"');
        return;
      }
  
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: [800, 6050], // set the height of the page to be very long
      });
  
      html2canvas(sectionToConvert).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
  
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        pdf.save('formular-de-vizionare.pdf');
      });
    },900);
  }
  


  
}
