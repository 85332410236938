import { Component, OnInit } from '@angular/core';
import {SharedService} from 'src/app/shared.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  constructor(private service:SharedService,  private router: Router, private datePipe: DatePipe) { }

  BuildingId!: string;
  Name!: string;
  Phone!: string;
  Email!: string;
  Message!: string;
  FormLocation!: string;
  DateOfGiving!: any;
  BucketDate!: Date;
  checkmark:boolean=false;
  checkmarckCheck!: number;
  checkmark1:boolean=false;
  checkmarckCheck1!: number;
  checkmark2:boolean=false;
  checkmarckCheck2!: number;
  checkmark3:boolean=false;
  checkmarckCheck3!: number;
  BuildingList:any=[];
  acestCamp:boolean=false;
  acestCamp1:boolean=false;
  acestCamp2:boolean=false;
  BuildingRoomsFilter!: string;
  BuildingNameFilter!: string;
  BuildingFloorFilter!: string;
  BlogList:any=[];
  lastblog:any;

  ngOnInit(): void {
    window.scrollTo(0, 0)
    this.checkmarckCheck=1
    this.checkmarckCheck1=1
    this.checkmarckCheck2=1
    this.checkmarckCheck3=1

    this.refreshBldList();
  }
  refreshBldList(){
    this.service.getformList().subscribe(data=>{
        let blogList = data.filter(item => item.Name === "blog");

        // sort the blogs by date, newest first
        blogList.sort((a, b) => {
            let dateA = new Date(a.blogdata);
            let dateB = new Date(b.blogdata);

            // in case of a failure in Date parsing, the values will be NaN and we return 0 to avoid sorting these items
            if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
                return 0;
            }
            
            // dateA - dateB will sort in ascending order, for descending order, we use dateB - dateA
            return dateB.getTime() - dateA.getTime();
        });

        // Save the newest blog for preview
        this.lastblog = blogList[0];

        // The slice() method will exclude the newest blog from the BlogList
        this.BlogList = blogList.slice(1, blogList.length);
    });
}
  
  
  footerlinks(){
    this.service.selectedfloor="0"
    this.service.selectedrooms= "1"
    this.service.selectedbuilding= "B"
    this.router.navigateByUrl('/locuinte' )
  }
  footerlinks1(){
    this.service.selectedfloor="0"
    this.service.selectedrooms= "2"
    this.service.selectedbuilding= "B"
    this.router.navigateByUrl('/locuinte' )
  }
  footerlinks2(){
    this.service.selectedfloor="1"
    this.service.selectedrooms= "2"
    this.service.selectedbuilding= "B"
    this.router.navigateByUrl('/locuinte' )
  }
  footerlinks3(){
    this.service.selectedfloor="3"
    this.service.selectedrooms= "3"
    this.service.selectedbuilding= "B"
    this.router.navigateByUrl('/locuinte' )
  }
  footerlinks4(){
    this.service.selectedfloor="4"
    this.service.selectedrooms= "5"
    this.service.selectedbuilding= "B"
    this.router.navigateByUrl('/locuinte' )
  }

  seemoreapartments(){
    this.service.selectedfloor= "0"
    this.service.selectedrooms= "1"
    this.service.selectedbuilding= "B"
    this.router.navigateByUrl('/../../assets/locuinte/locuinte.index.html' )
  }
  
  navSlider(): void{
    const burger = document.querySelector('.burger');
    const nav = document.querySelector('.mob-navbar');
    const navBar = document.querySelectorAll<HTMLElement>('.mob-navbar a');
  //toggle nav regtangle
    nav!.classList.toggle('nav-active');
  //animate linksß
    navBar.forEach((link, index) => {
      if(link.style.animation){
        link.style.animation = '';
      } else {
      link.style.animation = `navLinkFade 0.5s ease forwards ${index / 7 + 0.01}s`;
      }
    });

  //burger animation
  burger!.classList.toggle('toggle');

  }

/*formular 1*/
sendFormOne(){
  if(this.checkmark == true){
  this.FormLocation = "programează vizionare"
  this.BucketDate = new Date();
  this.DateOfGiving = (this.datePipe.transform(this.BucketDate,"yyyy-MM-dd"));
  var val = {BuildingId:this.BuildingId,
    Name:this.Name,
    Phone:this.Phone,
    Email:this.Email,
    Message:this.Message,
    FormLocation:this.FormLocation,
    DateOfGiving:this.DateOfGiving,};

  this.service.addform(val).subscribe(res=>{
    (res.toString());
  });
  alert("Mesajul tau a fost trimis")
} else {

  this.acestCamp=true;
}
}
checked(){
  this.checkmarckCheck = this.checkmarckCheck +1;
  if(this.checkmarckCheck % 2 === 0 ){
  this.checkmark = true;

  const modalbody=document.querySelector('.modal-body button');
  modalbody!.className = "modal-button";

  }
  else{
    this.checkmark = false;

  const modalbody=document.querySelector('.modal-body button');
  modalbody!.className = "deactive modal-button";
  }

}
sendFormOne2(){
  if(this.checkmark1 == true){
    this.FormLocation = "descărca brosura"
    this.BucketDate = new Date();
    this.DateOfGiving = (this.datePipe.transform(this.BucketDate,"yyyy-MM-dd"));
      var val = {BuildingId:this.BuildingId,
      Name:this.Name,
      Phone:this.Phone,
      Email:this.Email,
      Message:'A DESCARCAT BROSURA',
      FormLocation:this.FormLocation,
      DateOfGiving:this.DateOfGiving,};
  
    this.service.addform(val).subscribe(res=>{
      (res.toString());
    });
    alert("Mesajul tau a fost trimis")
    window.open("../../assets/pdf/Brochure_2024_RO.pdf")
  } else {
  
    this.acestCamp1=true;
  }
}
checked2(){
  this.checkmarckCheck1 = this.checkmarckCheck1 +1;
  if(this.checkmarckCheck1 % 2 === 0 ){
  this.checkmark1 = true;

  const modalbody=document.querySelector('.modal-body-brosura button');
  modalbody!.className = "modal-button-brosura";

  }
  else{
    this.checkmark1 = false;

    const modalbody=document.querySelector('.modal-body-brosura button');
    modalbody!.className = "deactive modal-button-brosura";
  }

}

sendFormOne3(){
  if(this.checkmark2 == true){
  this.FormLocation = "te contactam noi"
  this.BucketDate = new Date();
  this.DateOfGiving = (this.datePipe.transform(this.BucketDate,"yyyy-MM-dd"));  var val = {BuildingId:this.BuildingId,
    Name:this.Name,
    Phone:this.Phone,
    Email:this.Email,
    Message:this.Message,
    FormLocation:this.FormLocation,
    DateOfGiving:this.DateOfGiving,};

  this.service.addform(val).subscribe(res=>{
    (res.toString());
  });
  alert("Mesajul tau a fost trimis")
} else {

  this.acestCamp2=true;

}
}
checked3(){
  this.checkmarckCheck2= this.checkmarckCheck2 +1;
  if(this.checkmarckCheck2 % 2 === 0 ){
  this.checkmark2 = true;

  const modalbody=document.querySelector('.footer3 button');
  modalbody!.className = "";

  }
  else{
    this.checkmark1 = false;

    const modalbody=document.querySelector('.footer3 button');
    modalbody!.className = "deactive";
  }

}
seetermeni(){
  this.router.navigateByUrl('/termeni-si-conditii')
}

seehome(){
this.router.navigateByUrl('/')
}
seelifestyle(){
this.router.navigateByUrl('/lifestyle')
}

seemedia(){
this.router.navigateByUrl('/mediapage')
}

seeBlog(){
  this.router.navigateByUrl('/blog')
}

seedezvoltator(){
this.router.navigateByUrl('/dezvoltator')
}

seecontact(){
this.router.navigateByUrl('/contact')
}

seelocatie(){
this.router.navigateByUrl('/locatie')
}

seealege(){
this.router.navigateByUrl('/locuinte')
}

changeRoEn(){
  this.service.selectEnRo=true;
  this.router.navigateByUrl('/blog-en')
}


seeblog(item: any) {


  this.service.selectedBlog = item;
  this.router.navigateByUrl('/blog-article')
  }

seelastblog() {
  this.service.selectedBlog = this.lastblog;
  this.router.navigateByUrl('/blog-article')
  }
}
