<div class="modal-body-brosura">
    <div class="p">
        <p>Completează formularul de mai jos pentru a descărca broșura The Lake
            Home.</p>
    </div>
    <form>
        <input type="text" class="modal-form" placeholder="Nume"
            [ngModelOptions]="{standalone: true}" [(ngModel)]="Name" required>
        <input type="email" class="modal-form" id="exampleFormControlInput1"
            [ngModelOptions]="{standalone: true}" [(ngModel)]="Email"
            placeholder="Email" required>
        <input type="tel" class="modal-form" placeholder="Telefon"
            [ngModelOptions]="{standalone: true}" [(ngModel)]="Phone" required>
        <input type="text" class="modal-form" placeholder="În ce oraș locuiești?"
            [ngModelOptions]="{standalone: true}" [(ngModel)]="Oras" required>    

        <!-- Inside your form -->
        <div class="privacylink2">
            <input id="checkbox2" type="checkbox" (click)="toggleCheckbox()" required>
            <label class="form-control2">Sunt de acord cu 
                <a id="ppLink2" data-dismiss="modal" (click)="seetermeni()">Consimțământul de prelucrare a datelor.</a>
            </label>
        </div>
        <h6 *ngIf="acestCamp1">Acest câmp este obligatoriu</h6>
        <button [ngClass]="{'deactive': !checkboxChecked}" class="modal-button-brosura" type="button" (click)="sendFormOne2()" data-dismiss="modal">Vezi broșura</button>

    </form>
</div>
