import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

import { ScreenshotService } from 'src/app/screenshot.service';



@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./thanks.component.css']
})
export class ThanksComponent implements OnInit {

  constructor(
    private service: SharedService,
    private router: Router,
    private datePipe: DatePipe,
    private screenshotService: ScreenshotService
  ) { }
  
  raffleNumber!: string;

  ngOnInit(): void {
    this.raffleNumber=this.service.numarTombola

    
  }

  seehome(): void {
    this.router.navigateByUrl('/');
  }
  goEvent(): void {
    window.open("https://www.facebook.com/events/546530964092556?acontext=%7B%22event_action_history%22%3A[%7B%22mechanism%22%3A%22search_results%22%2C%22surface%22%3A%22bookmark_search%22%7D]%2C%22ref_notif_type%22%3Anull%7D");
}

  seetermeni(){
    this.router.navigateByUrl('/termeni-si-conditii')
  }


}
