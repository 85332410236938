<!-- Your Copy Button -->
<div style="width: 100%; margin: 40px auto; display: flex; justify-content: end;">
  <button (click)="copyContent()"
    style="margin-right: 60px; background-color: #2e862e; border-radius: 5px; border: none; color: #fff; font: 16px/20px Verdana; padding: 15px;">Copy
    to Clipboard from Mozilla</button>
</div>


<!-- Your Copy Button -->
<button (click)="copyContent2()">Copy from chrome</button>


<!-- Your HTML Content -->
<div #contentToCopy>




  <body style="font-family: Arial, sans-serif; margin: 0; padding: 40px 20px; background-color: #f4f4f4;">

    <div
      style="max-width: 600px; margin: auto; background: #ffffff; padding: 20px 20px 40px 20px; border-radius: 8px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);">

      <img style="max-width: 100%;" src="../../assets/media/email/header3.png" alt="">


      <div class="content" style="padding: 10px 10px">


        <h1 style="color: #2BAED8; font: 22px Verdana">Dragă explorator al vieții premium,</h1>

        <p style="color: #707070; font: 14px/20px Verdana; padding-top: 20px;">Speram ca acest email va gaseste bine si
          ne bucuram de atentia acordata cartierului nostru din Sibiu. <br><br>
          <span class="bold" style="color: #707070; font-weight: 600;">The Lake Home</span>
          este o comunitate exclusivistă de apartamente și vile premium, amplasată în jurul Lacului Binder. Locuințele
          noastre sunt concepute având în vedere confortul și stilul modern, fiind construite cu materiale de înaltă
          calitate care rezistă în timp.<br><br>

          Vă invităm în comunitatea noastră să experimentați un stil de viață premium în forma sa cea mai pură. Cu o
          pasiune pentru crearea unor experiențe memorabile, am dedicat 20.000 de metri pătrați catre spații verzi,
          primitoare, ce completează armonios ansamblul format din cele 494 de apartamente, 32 de vile, 3 restaurante
          încântătoare, o sală de sport modernă și un spa si un aparthotel.
        </p>

        <table border="0" cellpadding="0" cellspacing="0" width="100%" style="margin: 40px 0 20px;">
          <tr>
            <td align="center" width="50%">
              <img src="../../assets/media/email/2picstest1a.png" alt=""
                style="max-width:540px; width: 100%; margin: 0 auto;">
            </td>
          </tr>
        </table>


        <p style="color: #707070; font:  14px/20px Verdana; padding-top: 20px; margin: 0;">Suntem încântați să vă
          prezentăm un portofoliu diversificat de locuințe ce așteaptă să fie descoperite. Găsiți în atașamente o
          selecție specială a apartamentelor disponibile și ofertelor noastre financiare, concepute să se potrivească
          unei game largi de nevoi și așteptări.<br><br>

          Doriți să simțiți atmosfera și să vedeți detaliile care fac <span class="bold"
            style="color: #707070; font-weight: 600;">The Lake Home</span> atât de special? Nu ratați șansa de a
          programa o vizionare sau de a face un tur virtual folosind linkul de mai jos.
        <p>

          <!-- Images -->
        <table width="100%" cellspacing="0" cellpadding="0" border="0" align="center" style="margin: 40px 0;">
          <tr>
            <td width="100%" align="center" valign="top">
              <img src="../../assets/media/email/2picstest.png" alt=""
                style="max-width:540px; width: 100%; margin: 0 auto;">
            </td>
          </tr>
        </table>

        <!-- Button -->
        <table width="225px" cellspacing="0" cellpadding="0" border="0" align="center" style="margin: 20px auto;">
          <tr>
            <td align="center" valign="middle" height="60px" bgcolor="#2BAED8"
              style="border-radius: 5px; cursor: pointer;">
              <a href="https://my.matterport.com/show/?m=oz7L1ckczHp" target="_blank"
                style="font: 14px Verdana; color: #fff; text-decoration: none; line-height: 60px; display: block;">Tur
                virtual</a>
            </td>
          </tr>
        </table>


        <p style="color: #707070; font: 14px/20px Verdana; padding-top: 20px; margin: 0;">Mai mult, dacă sunteți
          interesați de planul nostru de investiții, avem plăcerea de a vă îndruma către următoarele resurse: </p>
        <br>

        <table width="100%" cellspacing="0" cellpadding="0" border="0" align="center" style="margin: 20px 0;">
          <tr>
            <td align="center">
              <img src="../../assets/media/email/The Lake Home_outdoor investment_RO.jpg" alt="" style="max-height: 365px; width: 100%;">
            </td>
          </tr>
        </table>



        <div class="btn1"
          style="display: flex; text-align: center; width: 225px; height: 60px; margin: 20px auto; cursor: pointer;">
          <a href="https://thelake.ro/optiuni-de-investitie/ro"
            style="background-color: #2BAED8; border-radius: 5px; border: none; color: #fff; font: 14px Verdana; width: 225px; height: 60px; display: block; line-height: 60px; text-align: center; text-decoration: none;">Vezi
            detalii</a>
        </div>




        <p style="color: #707070; font: 14px/20px Verdana; padding-top: 20px; margin: 0;">Ce mai așteptați? Veniți să
          experimentați în persoană ceea ce înseamnă să fii parte a Cartierului Bunelor Maniere. Programează acum o
          vizionare și trăiește experiența vieții premium la <span class="bold"
            style="color: #707070; font-weight: 600;">The Lake Home</span>.
          <br><br>
          O zi excelentă vă dorim!
        </p>

        <div class="1img" style="margin: 20px auto; width: 100%;" align="center">
          <img src="../../assets/media/email/locuinte_2a.png" alt="" style="width: 100%; margin: 0 auto;">
        </div>

        <table width="100%" cellspacing="0" cellpadding="0" border="0" align="center" style="margin: 20px 0;">
          <tr>
            <td align="right" width="50%" style="padding-right: 12.5%;">
              <a href="https://goo.gl/maps/u8YrSsReceFjCgq37" target="_blank"
                style="color: #707070; font: 12px/22px Verdana; cursor: pointer; text-decoration: none;">
                Str. Tractorului,<br>nr. 2-12, Sibiu
              </a>
            </td>
            <td align="left" width="50%" style="padding-left: 12.5%;">
              <a href="tel:+40721229999"
                style="color: #707070; font: 12px/22px Verdana; cursor: pointer; text-decoration: none;">
                +40 721 229 999
              </a>
              <br>
              <a href="mailto:sales@thelake.ro"
                style="color: #707070; font: 12px/22px Verdana; cursor: pointer; text-decoration: none;">
                sales@thelake.ro
              </a>
            </td>
          </tr>
        </table>



        <footer style="margin-top: 20px auto; border-top: 2px solid #2BAED8; padding-top: 10px; text-align: center;">

          <div class="sm" style="width: 80%; margin: 20px auto 0;" align="center">
            <a href="https://www.facebook.com/thelakehome" style="width: 32.44px; cursor: pointer; margin-right: 15px;">
              <img src="../../../assets/media/email/facebook_logo_icon.png" alt="Facebook link" onclick="NewTabFb()">
            </a>
            <a href="https://www.linkedin.com/company/the-lake-home-living-xperience/"
              style="width: 32.44px; cursor: pointer; margin-right: 15px;">
              <img src="../../../assets/media/email/linkedin_logo_icon.png" alt="LinkedIn link" onclick="NewTabLi()">
            </a>
            <a href="https://www.instagram.com/thelakehomelx/"
              style="width: 32.44px; cursor: pointer; margin-right: 15px;">
              <img src="../../../assets/media/email/instagram_logo_icon.png" alt="Instagram link" onclick="NewTabIg()">
            </a>
            <a href="https://www.youtube.com/@thelakehome-livingxperienc6927" style="width: 32.44px; cursor: pointer;">
              <img src="../../../assets/media/email/youtube_icon.png" alt="Youtube link" onclick="NewTabYt()">
            </a>
          </div>

          <p style="color: #707070; font: 12px/22px Verdana; padding-top: 20px; margin: 0 auto;" align="center">Aflați
            mai multe despre
            proiectul nostru pe <a href="https://www.thelake.ro" target="_blank"
              style="color: #707070; font: 12px/22px Verdana; cursor: pointer; font-weight: 600; text-decoration: none;">www.thelake.ro</a>
          </p>
        </footer>

      </div>


      <!--<table width="60%" border="0" align="center" style="margin-top: 30px;">
        <tr>
          <td align="center">
            <a href="mailto:sales@thelake.ro?subject=Sunt%20interesat&body=Voi%20verifica%20și%20vă%20trimit%20un%20răspuns%20în%20curând"
              style="text-decoration: none;">
              <div
                style="color: #707070; font: 12px/22px Verdana; background-color: #cccccc; border-radius: 10px; padding: 10px; position: relative;">
                Voi verifica și vă trimit un răspuns în&nbsp;curând
                <div
                  style="position: absolute; bottom: -8px; left: 10px; width: 0; height: 0; border-left: 0 solid transparent; border-right: 16px solid transparent; border-top: 8px solid #cccccc;">
                </div>
              </div>
            </a>
          </td>
        </tr>
        <tr>
          <td style="height: 30px;"></td>
        </tr>
        <tr>
          <td align="center">
            <a href="mailto:sales@thelake.ro?subject=Sunt%20interesat&body=Doresc%20să%20fiu%20contactat%20telefonic%20în%20următoarele%2024%20ore"
              style="text-decoration: none;">
              <div
                style="color: #707070; font: 12px/22px Verdana; background-color: #cccccc; border-radius: 10px; padding: 10px; position: relative;">
                Doresc să fiu contactat telefonic în următoarele 24&nbsp;ore
                <div
                  style="position: absolute; bottom: -8px; left: 10px; width: 0; height: 0; border-left: 0 solid transparent; border-right: 16px solid transparent; border-top: 8px solid #cccccc;">
                </div>
              </div>
            </a>
          </td>
        </tr>
        <tr>
          <td style="height: 30px;"></td>
        </tr>
        <tr>
          <td align="center">
            <a href="mailto:sales@thelake.ro?subject=Sunt%20interesat&body=Doresc%20sa%20să%20programez%20o%20vizionare"
              style="text-decoration: none;">
              <div
                style="color: #707070; font: 12px/22px Verdana; background-color: #cccccc; border-radius: 10px; padding: 10px; position: relative;">
                Doresc sa să programez o&nbsp;vizionare
                <div
                  style="position: absolute; bottom: -8px; left: 10px; width: 0; height: 0; border-left: 0 solid transparent; border-right: 16px solid transparent; border-top: 8px solid #cccccc;">
                </div>
              </div>
            </a>
          </td>
        </tr>
      </table>-->

      <table width="90%" border="0" align="center" style="margin-top: 30px;">
        <tr>
          <td align="center">
            <a href="mailto:sales@thelake.ro?subject=Sunt%20interesat&body=Voi%20verifica%20și%20vă%20trimit%20un%20răspuns%20în%20curând"
              style="text-decoration: none;">
              <div
                style="color: #707070; font: 12px/22px Verdana; background-color: #cccccc; border-radius: 10px; padding: 10px; position: relative;">
                Voi verifica și vă trimit un răspuns în&nbsp;curând
                <div
                  style="position: absolute; bottom: -8px; left: 10px; width: 0; height: 0; border-left: 0 solid transparent; border-right: 16px solid transparent; border-top: 8px solid #cccccc;">
                </div>
              </div>
            </a>
          </td>
          <td style="width: 20px;"></td>
          <td align="center">
            <a href="mailto:sales@thelake.ro?subject=Sunt%20interesat&body=Doresc%20să%20fiu%20contactat%20telefonic%20în%20următoarele%2024%20ore"
              style="text-decoration: none;">
              <div
                style="color: #707070; font: 12px/22px Verdana; background-color: #cccccc; border-radius: 10px; padding: 10px; position: relative;">
                Doresc să fiu contactat telefonic în următoarele 24&nbsp;ore
                <div
                  style="position: absolute; bottom: -8px; left: 10px; width: 0; height: 0; border-left: 0 solid transparent; border-right: 16px solid transparent; border-top: 8px solid #cccccc;">
                </div>
              </div>
            </a>
          </td>
          <td style="width: 20px;"></td>
          <td align="center">
            <a href="mailto:sales@thelake.ro?subject=Sunt%20interesat&body=Doresc%20sa%20să%20programez%20o%20vizionare"
              style="text-decoration: none;">
              <div
                style="color: #707070; font: 12px/22px Verdana; background-color: #cccccc; border-radius: 10px; padding: 10px; position: relative;">
                Doresc sa să programez o&nbsp;vizionare
                <div
                  style="position: absolute; bottom: -8px; left: 10px; width: 0; height: 0; border-left: 0 solid transparent; border-right: 16px solid transparent; border-top: 8px solid #cccccc;">
                </div>
              </div>
            </a>
          </td>
        </tr>
        <tr>
          <td style="height: 30px;"></td>
        </tr>
        <tr>

        </tr>
        <tr>
          <td style="height: 30px;"></td>
        </tr>
        <tr>

        </tr>
      </table>

    </div>

  </body>


</div>