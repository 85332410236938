<div class="wrapper">
    <div class="intro">
        <div class="fields">
            <label for="date" >Data vizionării </label><br><input [(ngModel)]="date" type="date">
        </div>

        <div class="fields">
            <label for="" >ID apartament</label><br><input [(ngModel)]="idapto" type="text" name="" id="">
        </div>

        <div class="fields">
            <label for="" >Anunț</label> <br><input [(ngModel)]="anunt" type="text" name="" id="">
        </div>

        <div class="fields">
            <label for="" >Adresa</label> <br><input [(ngModel)]="adresa" type="text" name="" id="">
        </div>

        <div class="fields">
            <label for="" >Preț în €</label> <br><input [(ngModel)]="pret" type="text" name="" id="">
        </div>

        <div class="fields">
            <label for="">Preț negociabil</label> <br>
            <div class="radiorow">
                <div class="radiobox">
                    <input type="radio" name="negociabil"  [(ngModel)]="pretnegoc" value="Da" id="Da"><span>Da</span>
                </div>
                <div class="radiobox">
                    <input type="radio" name="negociabil" [(ngModel)]="pretnegoc" value="Nu" id="Nu"><span>Nu</span>
                </div>
            </div>
        </div>

        <div class="fields">
            <label for="" >Agent / Proprietar</label> <br><input [(ngModel)]="agent" type="text" name="" id="">
        </div>

        <div class="fields">
            <label for="" >Telefon</label> <br><input type="text" [(ngModel)]="telefon" name="" id="">
        </div>

        <div class="fields">
            <label for="" >Email</label> <br><input type="text" [(ngModel)]="email" name="" id="">
        </div>

    </div>

    <div class="division"></div>
    <h3>Detalii proprietate</h3>

    <div class="property">

        <div class="fields">
            <label for="" >Mp construiți</label> <br><input [(ngModel)]="mpconsttotal" type="text" name="" id="">
        </div>

        <div class="fields">
            <label for="" >Mp utili</label> <br><input type="text" [(ngModel)]="mputiletotal" name="" id="">
        </div>

        <div class="twoinputs">
            <div class="fields">
                <label for="" >Etaj</label> <br><input [(ngModel)]="etaj" type="text" name="" id="">
            </div>

            <div class="fields">
                <label for="" >Din</label> <br><input [(ngModel)]="dinEtaj" type="text" name="" id="">
            </div>
        </div>

        <div class="fields">
            <label for="" >Nr. Camere</label>
            <ul class="nrCamere" >
                <li (click)="makerooms(1)" >1</li>
                <li (click)="makerooms(2)" >2</li>
                <li (click)="makerooms(3)" >3</li>
                <li (click)="makerooms(4)" >4</li>
                <li (click)="makerooms(5)" >5</li>
            </ul>
        </div>

        <div class="fields" id="mobiliat">
            <label for="" >Mobilat</label> <br>
            <div class="radiorow">
                <div class="radiobox">
                    <input type="radio"[(ngModel)]="mobiliat" name="Mobilat" value="Da" id="Da"><span>Da</span>
                </div>
                <div class="radiobox">
                    <input type="radio"[(ngModel)]="mobiliat" name="Nemobilat" value="Nu" id="Nu"><span>Nu</span>
                </div>
            </div>
        </div>

        <div class="tag">
            <h4>Camera de zi</h4>
            <div class="fields">
                <label for="" >Mp</label> <br><input [(ngModel)]="cameraMp" type="text" name="" id="">
            </div>

            <div class="radiorowlong">
                <div class="radiobox">
                    <input type="radio" name="balcon" [(ngModel)]="balcon_terasa" value="Cu balcon" id="balcon"><span>Cu&nbsp;balcon</span>
                </div>
                <div class="radiobox">
                    <input type="radio" name="balcon" value="Cu terasa" [(ngModel)]="balcon_terasa" id="balcon"><span>Cu&nbsp;terasă</span>
                </div>
            </div>
            <div class="addNote">
                <p (click)="opentag()">+ Adăugați notițe</p>
                <div class="fields notes" [ngClass]="{'active': isNoteActive}">
                  <label for="">Notițe</label>
                  <br>
                  <textarea name="" id="" [(ngModel)]="cameraNote" cols="30" rows="5"></textarea>
                </div>
              </div>        
        </div>

        <div class="tag" id="bucatarie">
            <h4>Bucătăria</h4>
            <div class="buc1">
                <div class="fields">
                    <label for="" >Mp</label> <br><input type="text" [(ngModel)]="bucatariaMp" name="" id="">
                </div>

                <div class="radiorowlong">
                    <div class="radiobox">
                        <input type="radio" [(ngModel)]="inchisadeschisa" name="Deschisă" value="Deschisă" id="Deschisă"><span>Deschisă</span>
                    </div>
                    <div class="radiobox">
                        <input type="radio" [(ngModel)]="inchisadeschisa" name="Deschisă" value="Închisă" id="Deschisă"><span>Închisă</span>
                    </div>
                </div>
            </div>

            <div class="buc2">
                <div class="radiorowlong">
                    <div class="radiobox">
                        <input type="radio" [(ngModel)]="bucatariaMobi" name="Mobiliat" value="Mobilat" id="Mobiliat"><span>Mobilat</span>
                    </div>
                    <div class="radiobox">
                        <input type="radio" [(ngModel)]="bucatariaMobi" name="Mobiliat" value="Nemobilat" id="Mobiliat"><span>Nemobilat</span>
                    </div>
                </div>

                <div class="radiorowlong">
                    <div class="radiobox">
                        <input type="radio" [(ngModel)]="bucatariaElect" name="Electrocasnice" value="Electrocasnice incluse" id="Electrocasnice"><span>Electrocasnice
                            incluse</span>
                    </div>
                </div>
            </div>

            <div class="addNote">
                <p (click)="opentag2()">+ Adăugați notițe</p>
                <div class="fields notes" [ngClass]="{'active': isNoteActive2}">
                  <label for="">Notițe</label>
                  <br>
                  <textarea name="" id="" [(ngModel)]="bucatariaNote" cols="30" rows="5"></textarea>
                </div>
              </div>

        </div>

        <div class="tag" *ngFor="let baie of [].constructor(numBaie); let i = index">
            <div class="closebtn" *ngIf="i > 0" (click)="removeBaie(i)"></div> 
                <h4>Baie {{ i + 1 }}</h4>
                <div class="fields">
                    <label for="" >Mp</label> <br><input [(ngModel)]="baieMp[i]" type="text" name="" id="">
                </div>
    
                <div class="radiorowlong">
                    <div class="radiobox">
                        <input type="radio" name="CuGeam{{i}}"  [(ngModel)]="detaliibaie[i]" value="Cu Geam" id="GEAM?"><span>Cu&nbsp;geam</span>
                    </div>
                    <div class="radiobox">
                        <input type="radio" name="CuGeam{{i}}"  [(ngModel)]="detaliibaie[i]" value="Fără Geam" id="GEAM?"><span>Fără&nbsp;geam</span>                    </div>
                </div>
    
                <div class="radiorowlong">
                    <div class="radiobox">
                        <input type="radio" name="Amenajari{{i}}" value="Necesită amenajări"  [(ngModel)]="amenajare[i]" id="Amenajari"><span>Necesită amenajări</span>
                    </div>
                </div>
    
                <div class="radiorowlong">
                    <div class="radiobox">
                        <input type="radio" name="Amenajari{{i}}" value="Necesită renovare" [(ngModel)]="amenajare[i]" id="renovare"><span>Necesită renovare</span>
                    </div>
                </div>
    
            <div class="addNote">
                <p (click)="opentag5(i)">+ Adăugați notițe</p>
                <div class="fields notes" [ngClass]="{'active': isNoteActiveBaie[i]}">
                  <label for="">Notițe</label>
                  <br>
                  <textarea name="" id="" [(ngModel)]="baie2Note[i]"  cols="30" rows="5"></textarea>
                </div>
            </div>
    

        </div>





        <div class="tag adauga" (click)="addBaie()">
            <div class="chooseMore">
              <div class="addbcg">
                <div class="add"></div>
              </div>
              <span>Adaugă Baie</span>
            </div>
          </div>
          

        <div class="tag" *ngFor="let dormitor of [].constructor(numroom); let i = index">
            <div class="closebtn" *ngIf="i > 0" (click)="removeroom(i)"></div> 
            <h4>Dormitor {{ i + 1 }}</h4>
            <div class="fields">
                <label for="" >Mp</label> <br><input [(ngModel)]="roomMp[i]" type="text" name="" id="">
            </div>

            <div class="radiorowlong">
                <div class="radiobox">
                    <input type="radio" [(ngModel)]="dormitor1Amenaj[i]" name="DAmenajari{{i}}" value="Necesită amenajări" id="DAmenajari"><span>Necesită amenajări</span>
                </div>
            </div>

            <div class="radiorowlong">
                <div class="radiobox">
                    <input type="radio" [(ngModel)]="dormitor1Amenaj[i]" name="Drenovare{{i}}" value="Necesită renovare" id="Drenovare"><span>Necesită renovare</span>
                </div>
            </div>


            <div class="addNote">
                <p (click)="opentag6(i)">+ Adăugați notițe</p>
                <div class="fields notes" [ngClass]="{'active': isNoteActiveDormitor[i]}">
                  <label for="">Notițe</label>
                  <br>
                  <textarea name="" id="" [(ngModel)]="dormitor1Note[i]" cols="30" rows="5"></textarea>
                </div>
            </div>
        </div>



        <div class="tag adauga" (click)="addroom()">
            <div class="chooseMore">
                <div class="addbcg">
                    <div class="add"></div>
                </div>
                <span>Adaugă Dormitor</span>
            </div>
        </div>

        <div class="tag">
            <h4>Balcon / terasă</h4>
            <div class="fields">
                <label for="" >Mp</label> <br><input [(ngModel)]="balconMp" type="text" name="" id="">
            </div>

            <div class="radiorowlong">
                <div class="radiobox">
                    <input type="radio" [(ngModel)]="balconDesc" name="Deschisa" value="Deschisa" id="Deschisa"><span>Deschisă</span>
                </div>
                <div class="radiobox">
                    <input type="radio" [(ngModel)]="balconDesc" name="Deschisa" value="Închisă" id="Deschisa"><span>Închisă</span>
                </div>
            </div>

            <div class="addNote">
                <p (click)="opentag3()">+ Adăugați notițe</p>
                <div class="fields notes" [ngClass]="{'active': isNoteActive3}">
                  <label for="">Notițe</label>
                  <br>
                  <textarea name="" id="" [(ngModel)]="balconNote" cols="30" rows="5"></textarea>
                </div>
              </div>

        </div>

        <div class="tag">
            <h4>Curte</h4>
            <div class="fields">
                <label for="" >Mp</label> <br><input [(ngModel)]="curteMp" type="text" name="" id="">
            </div>

            <div class="addNote">
                <p (click)="opentag4()">+ Adăugați notițe</p>
                <div class="fields notes" [ngClass]="{'active': isNoteActive4}">
                  <label for="">Notițe</label>
                  <br>
                  <textarea name="" id="" [(ngModel)]="curteNote" cols="30" rows="5"></textarea>
                </div>
              </div>

        </div>

    </div>

    <div class="division"></div>
    <h3>Detalii clădire și zonă</h3>

    <div class="building">

        <div class="fields">
            <label for="" >An construcție</label> <br><input [(ngModel)]="anconstr"type="text" name="" id="">
        </div>

        <div class="fields">
            <label for="" >Număr de scări</label> <br><input [(ngModel)]="nrScari" type="text" name="" id="">
        </div>

        <div class="fields">
            <label for="" >Număr de apartamente</label> <br><input [(ngModel)]="nrApto" type="text" name="" id="">
        </div>

        <div class="fields">
            <label for="" >Boxă</label> <br>
            <div class="radiorow">
                <div class="radiobox">
                    <input type="radio" [(ngModel)]="boxa" name="Boxa" value="Da" id="Da"><span>Da</span>
                </div>
                <div class="radiobox">
                    <input type="radio" [(ngModel)]="boxa" name="Boxa" value="Nu" id="Nu"><span>Nu</span>
                </div>
            </div>
        </div>

        <div class="fields" id="lift">
            <label for="" >Lift</label> <br>
            <div class="radiorow">
                <div class="radiobox">
                    <input type="radio" [(ngModel)]="lift" name="Lift" value="Da" id="Da"><span>Da</span>
                </div>
                <div class="radiobox">
                    <input type="radio" [(ngModel)]="lift" name="Lift" value="Nu" id="Nu"><span>Nu</span>
                </div>
            </div>
        </div>

        <div class="tag">
            <h4>Locuri parcare</h4>

            <ul class="nrParcare">
                <li (click)="makeparking(0)">0</li>
                <li (click)="makeparking(1)">1</li>
                <li (click)="makeparking(2)">2</li>
            </ul>

            <div class="twoinputs">
                <div class="fields">
                    <label for="" >Poziție</label> <br><input [(ngModel)]="parcPoz"type="text" name="" id="">
                </div>

                <div class="fields">
                    <label for="" >Loc nr.</label> <br><input [(ngModel)]="parcLoc"type="text" name="" id="">
                </div>
            </div>

            <div class="addNote">
                <p (click)="opentag10()">+ Adăugați notițe</p>
                <div class="fields notes" [ngClass]="{'active': isNoteActive10}">
                  <label for="">Notițe</label>
                  <br>
                  <textarea name="" id="" [(ngModel)]="parcNote" cols="30" rows="5"></textarea>
                </div>
              </div>

        </div>

        <div class="tag">
            <h4>Condițiile clădirii</h4>

            <div class="radiorow">
                <div class="radiobox">
                    <input type="radio" [(ngModel)]="conditNou" name="conditNou" value="Nou" id="conditNou"><span>Nou</span>
                </div>
                <div class="radiobox">
                    <input type="radio" [(ngModel)]="conditNou" name="conditNou" value="Vechi" id="conditNou"><span>Vechi</span>
                </div>
            </div>

            <div class="radiorowlong">
                <div class="radiobox">
                    <input type="radio" [(ngModel)]="conditAnv" name="conditAnv" value="Anvelopat" id="conditAnv"><span>Anvelopat</span>
                </div>
                <div class="radiobox">
                    <input type="radio" [(ngModel)]="conditAnv" name="conditAnv" value="Izolat" id="conditAnv"><span>Izolat</span>
                </div>
            </div>


            <div class="addNote">
                <p (click)="opentag11()">+ Adăugați notițe</p>
                <div class="fields notes" [ngClass]="{'active': isNoteActive11}">
                  <label for="">Notițe</label>
                  <br>
                  <textarea name="" id="" [(ngModel)]="conditNote" cols="30" rows="5"></textarea>
                </div>
              </div>

        </div>

        <div class="tag">
            <h4>Detalii cheltuieli
                întreținere bloc</h4>

            <textarea name="" id="" cols="30" rows="5" [(ngModel)]="detaliiChelt"></textarea>

        </div>
    </div>

    <div class="division"></div>
    <h3>Document vânzare sau&nbsp;creditare</h3>

    <div class="documents">

        <div class="tag">
            <h4>Apartamentul este întabulat?</h4>

            <div class="radiorow">
                <div class="radiobox">
                    <input type="radio" [(ngModel)]="docs1" name="docs1" value="Da" id="docs1"><span>Da</span>
                </div>
                <div class="radiobox">
                    <input type="radio" [(ngModel)]="docs1" name="docs1" value="Nu" id="docs1"><span>Nu</span>
                </div>
            </div>

            <div class="addNote">
                <p (click)="opentag12()">+ Adăugați notițe</p>
                <div class="fields notes" [ngClass]="{'active': isNoteActive12}">
                  <label for="">Notițe</label>
                  <br>
                  <textarea name="" id="" [(ngModel)]="docs1nu" cols="30" rows="5"></textarea>
                </div>
              </div>

        </div>

        <div class="tag" id="utilitatile">
            <h4>Apartamentul este racordat la toate utilitațile?</h4>

            <div class="radiorow">
                <div class="radiobox">
                    <input type="radio" [(ngModel)]="docs2" name="docs2" value="Da" id="docs2"><span>Da</span>
                </div>
                <div class="radiobox">
                    <input type="radio" [(ngModel)]="docs2" name="docs2" value="Nu" id="docs2"><span>Nu</span>
                </div>
            </div>

            <div class="fields care">
                <label for="" >Care?</label> <br><input [(ngModel)]="care" type="text" name="" id="">
            </div>


        </div>

        <div class="fields">
            <label for="" id="potvedea" >Pot vedea un extras CF, releveu, certificat energetic? </label> <br>
            <div class="radiorow long">
                <div class="radiobox">
                    <input [(ngModel)]="docs3" type="radio" name="docs3" value="Da" id="docs3"><span>Da</span>
                </div>
                <div class="radiobox">
                    <input type="radio" [(ngModel)]="docs3" name="docs3" value="Nu" id="docs3"><span>Nu</span>
                </div>
            </div>
        </div>

        <div class="fields">
            <label for="" >Actele au fost verificate de un profesionist?</label> <br>
            <div class="radiorow long">
                <div class="radiobox">
                    <input type="radio" [(ngModel)]="docs4" name="docs4" value="Da" id="docs4"><span>Da</span>
                </div>
                <div class="radiobox">
                    <input type="radio" [(ngModel)]="docs4" name="docs4" value="Nu" id="docs4"><span>Nu</span>
                </div>
            </div>
        </div>

        <div class="fields">
            <label for="" >Este liber de sarcini apartamentul?</label> <br>
            <div class="radiorow long">
                <div class="radiobox">
                    <input type="radio" [(ngModel)]="docs5" name="docs5" value="Da" id="docs5"><span>Da</span>
                </div>
                <div class="radiobox">
                    <input type="radio" [(ngModel)]="docs5" name="docs5" value="Nu" id="docs5"><span>Nu</span>
                </div>
            </div>
        </div>

        <div class="fields">
            <label for="" >Se acceptă cumpărarea cu credit ipotecar? </label> <br>
            <div class="radiorow long">
                <div class="radiobox">
                    <input type="radio" [(ngModel)]="docs6" name="docs6" value="Da" id="docs6"><span>Da</span>
                </div>
                <div class="radiobox">
                    <input type="radio" [(ngModel)]="docs6" name="docs6" value="Nu" id="docs6"><span>Nu</span>
                </div>
            </div>
        </div>

        <div class="fields" id="termenul">
            <label for="" >Care este termenul în care se <br> poate elibera apartamentul?</label> <br><input [(ngModel)]="docs7" type="text"
                name="" id="">
        </div>
    </div>

    <div class="division"></div>
    <h3>Spune-ne părerea ta</h3>

    <div class="finalQuestions">
        <div class="tag" id="Qsttag">
            <h4>Spune-ne un lucru care te-a impresionat</h4>

            <textarea name="" id="quest1" cols="30" rows="5" [(ngModel)]="quest1"></textarea>
            
            <h4 style="margin-top:60px">Spune-ne ceva ce trebuie îmbunătăţit</h4>

            <textarea name="" id="quest2" cols="30" rows="5" [(ngModel)]="quest2"></textarea>

            <h4 style="margin-top:60px">Ce te-ar determina sa iei o decizie de achiziţie chiar în momentul acesta?</h4>

            <textarea name="" id="quest3" cols="30" rows="5"[(ngModel)]="quest3" ></textarea>

        </div>
    </div>
</div>



