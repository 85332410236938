import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-airbnb',
  templateUrl: './airbnb.component.html',
  styleUrls: ['./airbnb.component.css']
})
export class AirbnbComponent implements OnInit {
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      let lang = params['lang'];
      let pdfPath = '../../assets/pdf/Airbnb proposal_';

      if(lang == 'en') {
        pdfPath += 'EN_s.pdf';
      } else {
        pdfPath += 'RO_s.pdf';
      }
      window.location.href = pdfPath;
    });
  }
}