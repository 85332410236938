import { Component, OnInit } from '@angular/core';
import {SharedService} from 'src/app/shared.service';
import { Router } from '@angular/router';
import { Observable, Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-ruffle2',
  templateUrl: './ruffle2.component.html',
  styleUrls: ['./ruffle2.component.css']
})
export class Ruffle2Component implements OnInit {

  constructor(private service:SharedService, private router: Router) { }

  ToolList:any=[];

  ModalTitle!: string;
  ActivateAddEditTolComp:boolean=false;
  tol:any;

  ToolNameFilter:string="";
  UserFilter:string="";
  ToolIdFilter:string="";

  Pieces:string="";
  MainLocation:string="";

  ToolListWithoutFilter:any=[];
  ToolListWithoutFilterUser:any=[];
  Password:boolean=true;
  brokenTool:boolean=false;
  WorkingTool:boolean=false;
  
  ActivateServiceTolComp:boolean=false;
  ActivateServiceInfoComp:boolean=false;


  ActivateGuveToolToUser:boolean=false;
  ActivateTakeToolFromUser:boolean=false;

  senha!: string;
  pingood:boolean=false;
  pinin:boolean=true;

  chosedNumb!: number;
  fillerList:any=[];
  winnername!: string;
  subscription!: Subscription;

  randomNamea!: string;
  randomNumber!: number;

  chosenNumbers: number[] = [];

  choosing: boolean = false;

  ngOnInit(): void {
    window.scrollTo(0, 0);
  
    this.refreshTolList().subscribe(() => {
      const slowSource = timer(0, 100);
      const fastSource = timer(0, 10);
    
      this.subscription = slowSource.subscribe(val => this.randomName());
      this.subscription.add(
        fastSource.subscribe(val => {
          if (this.choosing) {
            this.randomName();
          }
        })
      );
    });
  }
  
  
  chose() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  
    this.choosing = true;
    const source = timer(0, 10);
    this.subscription = source.subscribe(val => {
      this.randomName();
    });
  
    setTimeout(() => {
      this.subscription.unsubscribe(); // to stop the timer after 3 seconds
      this.choosing = false;
      this.chooseWinner();
    }, 2000);
  }
  
  chooseWinner() {
    if(this.fillerList.length > 0) {
      let randomIndex = Math.floor(Math.random() * this.fillerList.length);
      this.chosedNumb = this.fillerList[randomIndex];
      this.fillerList.splice(randomIndex, 1);
  
      // Add the chosen number to the array
      this.chosenNumbers.push(this.chosedNumb);
      console.log(this.chosenNumbers);  // Log the array
  
      // Find the corresponding winner in the ToolList
      let winner = this.ToolList.find((participant: { Message: number; }) => participant.Message == this.chosedNumb);
  
      // If a winner is found, assign the name to winnername (after removing the IP)
      if(winner) {
        this.winnername = winner.Name.replace(/(\d+\.\d+\.\d+\.\d+)$/g, '').trim();
      } else {
        // If no winner is found for the chosen number, show an alert
        alert(`No participant found for number ${this.chosedNumb}`);
      }
    } else {
      alert("All numbers have been chosen.");
    }
  }
  


randomName() {
  let randomIndex = Math.floor(Math.random() * this.ToolList.length);
  let participant = this.ToolList[randomIndex];
  if(participant) {
    this.randomNamea = participant.Name.replace(/(\d+\.\d+\.\d+\.\d+)$/g, '').trim();
    this.randomNumber = Number(participant.Message);
  } else {
    this.randomNamea = '';
    this.randomNumber = 0;
  }
}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  deleteClick(item: { BuildingId: any; }){
    if(confirm('Are you sure??')){
    this.service.deleteform(item.BuildingId).subscribe(data=>{
      (data.toString());
        this.refreshTolList();
      })
      this.refreshTolList();
    }
  }

  closeClick(){
    this.ActivateAddEditTolComp=false;
    this.ActivateGuveToolToUser=false;
    this.ActivateServiceTolComp=false;
    this.ActivateServiceInfoComp=false;
    this.ActivateTakeToolFromUser=false;
    this.refreshTolList();
  }

  refreshTolList() {
    // Array of numbers to be removed
    const numbersToRemove = [55, 40, 22, 33, 45, 9, 58, 80];
    
    // Date and message number threshold
    const dateThreshold = new Date('2023-06-01');
    const messageNumberThreshold = 100;
    
    return new Observable(observer => {
      this.service.getformList().subscribe(data => {
        // Only keep the entries that have the FormLocation set to 'TheLakePark'
        this.ToolList = data.filter(entry => entry.FormLocation === 'TheLakePark');
        this.ToolListWithoutFilter = this.ToolList;
        this.ToolListWithoutFilterUser = this.ToolList;
        
        // Filter out the test entries with Message of 0 and 1 and create the fillerList
        let validEntries = this.ToolList.filter((entry: { Message: number; DateOfGiving: string; }) => {
          // Only include the entry if its Message is greater than 1 and it's not in the numbersToRemove array
          // and its date is after the dateThreshold or its Message is greater than the messageNumberThreshold
          let entryDate = new Date(entry.DateOfGiving);
          return entry.Message > 1 
            && !numbersToRemove.includes(entry.Message) 
            && (entryDate > dateThreshold || entry.Message > messageNumberThreshold);
        });
        this.fillerList = validEntries.map((entry: { Message: any; }) => entry.Message);
        
        // Signal that we've finished refreshing the list
        observer.next();
        observer.complete();
      });
    });
  }
  
  
  

  

  FilterFn(){
    
    var ToolNameFilter = this.ToolNameFilter;

    this.ToolList = this.ToolListWithoutFilter.filter(function (el: { ToolId: 
      { toString: () => string; }; ToolName: { toString: () => string; }; }){
      return el.ToolName.toString().toLowerCase().includes(
        ToolNameFilter.toString().trim().toLowerCase()
      )
    });
  }

  seeforms(){
    this.router.navigateByUrl('/1admin1')
  }

  
  seehome(){
    this.router.navigateByUrl('/')
  }
  
  seefestival(){
    this.router.navigateByUrl('/festivallake')
  }
}

