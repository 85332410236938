<head>
    <link rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
</head>

<body>
    <div class="header-bcground"></div>

    <header class="headerGrid">
        <div class="headerGrid-wrapper">
            <div class="header-titles">
                <div class="header-logos">
                    <img class="tlh" src="../../assets/media/logos/Festival_lake_white.svg"
                        alt="logo of The Lake home - Living Xperience logo" (click)="seehome()">

                    <img class="nvrn" src="../../assets/media/logos/sigla_negative-02.png"
                        alt="logo of Novarion - Xperience the Future" (click)="seehome()">
                </div>


                <div class="all-nav-elements">
                    <div class="contact-nav">

                        <div class="flexRow"><!-- 
                            <a (click)="changeRoEn()">EN</a>-->
                        </div>
                    </div> 
                </div>
                <div class="mob-page-title">
                    <h1>Festival Lake</h1>
                    <h2>Tu aduci ideea, noi te primim în comunitatea noastră</h2>
 
                    <p><!--
                        Hai cu planul tău de business în comunitatea noastră. <br> 
                        Noi iti oferim locul perfect in care să o dezvolți. <br>-->
                        </p>
                    <button class="lp-cta" (click)="scroll(joinForm)">Vreau și eu</button>
                </div>
            </div>
        </div>
    </header>

    <div class="XMAS-sticky-btn" type="button" data-toggle="modal" data-target="#XmasModal" #xmasButton>
    </div>


    <div class="modal fade" id="XmasModal" tabindex="-1" role="dialog"
        aria-labelledby="XmasModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content XmasContent " (click)="modalfestival($event)"
                style="cursor: pointer;">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        aria-hidden="true">
                        <span aria-hidden="true" class="close-icon material-symbols-outlined">
                            close
                        </span>
                    </button>
                
                    
                            <img  id="background-modal-promo" src="../../assets/media/Banner.png" alt="">
                            <img  id="background-modal-promo-phone" src="../../assets/media/Poster.png" alt="">

                </div>
            </div>
        </div>
    </div>

    <!--<div class="mob-header-slider">
        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" data-interval="2500"> 
            <ol class="carousel-indicators">
              <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
            </ol>
            <div class="carousel-inner" id="mobSlider-inner">
              <div class="carousel-item active">
                <img class="d-block w-100" src="../../assets/media/headerSliders/landingpage_1.jpg" alt="">
              </div>
              <div class="carousel-item">
                <img class="d-block w-100" src="../../assets/media/headerSliders/landingpage_2.jpg" alt="">
              </div>
              <div class="carousel-item">
                <img class="d-block w-100" src="../../assets/media/headerSliders/landingpage_3.jpg" alt="">
              </div>
              <div class="carousel-item">
                <img class="d-block w-100" src="../../assets/media/headerSliders/landingpage_4.jpg" alt="">
              </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                <span id="bloc-prev" class="material-symbols-outlined">arrow_circle_left</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                <span id="bloc-next" class="material-symbols-outlined">arrow_circle_right</span>
            </a>
          </div>
    </div>-->

    <section class="page-content-wrapper">
        <h3>Ne simțim mai bine atunci <br>când suntem ÎMPREUNĂ!</h3>
        <div class="about">
            <img class="lifestyle-icon" src="../../assets/media/pattern/Artwork29.svg" alt="">
            <p>Ai un proiect sau o afacere frumoasă, care poate aduce plus valoare orașului și comunității? Organizezi activități recreaționale, educaționale, sportive, muzicale, culturale, pentru cei mari și pentru cei mici? Se pot desfășura într-un parc, în aer liber sau în interior? Atunci, Festival Lake este locul potrivit. Tu aduci ideea, noi îți dăm ocazia.<br><br>
                            
            Cum se întâmplă lucrurile? Extrem de simplu. Avem la dispoziție 27 000 ( douăzeci și șapte de mii !! ) de metri pătrați de teren, din care îți poți alege. <span class="innertext-bold">Oferim spre folosință limitată, în mod gratuit, spații indoor și outdoor în această zonă,</span> situată pe malul lacului Binder <a href="https://goo.gl/maps/jAf1FVNhpYWfgLHH6" target="_blank">(vezi harta).</a> Fie că ai nevoie să îți desfășori activitatea o zi, un weekend, o lună sau chiar ani, te invităm să ocupi un loc. Pentru noi este o bucurie!
            </p>
        </div>

        <div class="imgs-triowrapper">
            <div class="imgs-trio">
                <img src="../../assets/media/trioImgs_1.jpg" alt="">
                <img src="../../assets/media/trioImgs_2.jpg" alt="">
                <img src="../../assets/media/trioImgs_3.jpg" alt="">
            </div>
            <div class="imgs-trio-bckgnd"></div>
        </div>

        <div class="goals-wrapper">
            <h3>Comunitatea The Lake Home</h3>
            <div class="goals">
                <div class="goals-text">
                    <div class="innertext">
                        <img class="lifestyle-icon" src="../../assets/media/pattern/Artwork29.svg" alt="">
                        <p>Cunoști povestea Cartierului cu bune maniere? Ei bine, nu demult, un om așa ca tine, cu visuri și dorințe, a început să transforme în realitate ceea ce și-a imaginat. A crezut în visul lui, a atras oameni care să creadă la fel și a trecut la fapte. Cum ar fi să construim, chiar aici, în inima României, cel mai frumos cartier, un loc cu suflet și cu oameni manierați? Oare ar fi posibilă o comunitate de oameni care să creadă în binele comun și să trăiască în acest spirit, într-un loc care să le asigure multe avantaje? Raul Țărnaru, fondatorul NOVARION și al proiectului The Lake Home a zis „Da!” și, iată, povestea merge mai departe.<br><br>

                        The Lake Home prinde contur și, mai mult decât atât, deschide ușa altor proiecte la fel de faine. Ne-am gândit că ți-ar plăcea un parc de agrement croit exact pe gustul tău. De aceea, cel mai bine este să îl realizăm împreună cu tine.
                        </p>
                    </div>
                    
                </div>
                <img id="goals-bigImg" src="../../assets/media/goals_bigImg.jpg" alt="">
            </div>
        </div>

        <div class="sub-types">
            <div class="division"></div>
            <h3 id="types-header">Hai să creăm experiențe împreună!</h3>
            <p>Vrem să trăim împreună cu tine cele mai frumoase momente, care să devină amintiri dragi, de suflet.<br>
            Vrem să aducem muzica și sportul mai aproape, iar educația și cultura primesc, la fel, un loc special.<br>
            Proiectele pe care le vom găzdui pot fi de 2 tipuri: temporare și permanente, după cum îți dorești tu.
            </p>
            <div class="types-tags">
                <div class="tag t1">
                    <h6>Activitate tip eveniment</h6>
                    <p>Avem posibilitatea să îți oferim spațiul necesar pentru activitatea ta temporară. Chiar dacă vorbim despre o perioadă scurtă, efectul va fi, cu siguranță, apreciat. Dorim să ai o experiență plăcută în spațiile noastre, iar clienții pe care tu îi primești să se simtă excelent în comunitatea The Lake Home.<br> <br>Angajamentul ferm față de excelență se reflectă în valorile noastre: materiale și servicii de calitate, standarde înalte, transparență, integritate, inovație și oferirea unei experiențe de viață de neegalat. Orice proiect are nevoie de oameni, dar și de spațiu. Hai să vedem ce putem face, în acest sens. Te invităm să vorbim despre dorința ta arzătoare, despre contribuția ta la comunitate. Credem în visul tău!</p>
                </div>
                <div class="tag t2">
                    <h6>Activitate permanentă</h6>
                    <p>Fie că îți deschizi prima dată porțile sau te afli în căutarea unei locații mai bune, ne-am bucura să te cunoaștem. Vino în comunitatea The Lake Home și hai să consolidăm împreună visul tău. Pe fiecare dintre noi ne motivează ceva. Dacă ești motivat de bucuria celor care îți sunt clienți, atunci bine ai venit în mijlocul nostru.<br> <br>Suntem dedicați să oferim familiilor un mediu de viață sigur, sofisticat și de înaltă calitate, care promovează educația, bunăstarea și relaxarea. Afacerea ta se încadrează în acest loc al armoniei, de aceea vrem să facem un plan de acțiune pe termen lung. Tu ne ajuți să oferim oamenilor momente și experiențe de neuitat, iar noi te ajutăm să te dezvolți în cele mai bune condiții.</p>
                </div>
            </div>
        </div>
    </section>

    <!--<section class="testemonials">
        <h2>Partenerii</h2>

        <div id="carouselExampleControls" class="carousel slide" data-ride="carousel" data-interval="false">
            <div class="carousel-inner" id="testm-inner">
                <div class="carousel-item active">
                    <img class="testimonial-img" src="../../assets/media/Spa-rendering.jpg" alt="">
                    <p class="testimonial-text">Consider că este o alegere perfectă pentru mine, având în vedere toate
                        avantajele. Îmi place priveliștea, munții se văd frumos în depărtare și lacul este fain de
                        aproape. Este suprinzător de simplu să ajungi în diverse puncte din oraș, conectivitatea e bună.
                    </p>
                </div>
                <div class="carousel-item">
                    <p class="testimonial-text">Consider că este o alegere perfectă pentru mine, având în vedere toate
                        avantajele. Îmi place priveliștea, munții se văd frumos în depărtare și lacul este fain de
                        aproape. Este suprinzător de simplu să ajungi în diverse puncte din oraș, conectivitatea e bună.
                    </p>
                    <p class="name-testimonial">George Vesa</p>
                    <p class="age-city">24 ANI - SIBIU</p>
                </div>
                <div class="carousel-item">
                    <p class="testimonial-text">Construcția este realizată la o calitate superioară față de alte
                        blocuri, finisajele exterioare și interioare sunt de apreciat. Îmi place spațiul extins, faptul
                        că apartamentul este luminos, și că totul te face să te simți bine. Până și casa scărilor este
                        minuțios realizată, e senzațională.</p>
                    <p class="name-testimonial">Răzvan Staroste</p>
                    <p class="age-city">38 ANI - SIBIU</p>
                </div>
            </div>
            <div class="slider-controls-Testm">
                <span id="testm-slide-prev" class="material-symbols-outlined left-btn-testm"
                    href="#carouselExampleControls" role="button" data-slide="prev">arrow_circle_left</span>
                <span id="testm-slide-next" class="material-symbols-outlined right-btn-testm"
                    href="#carouselExampleControls" role="button" data-slide="next">arrow_circle_right</span>
            </div>
        </div>
    </section>-->

    <section class="join-form-wrap" #joinForm>
        <h3>Participă la experiența<br> Festival Lake</h3>
        <div class="join-form">
            <div class="aboutform">
                <p>Trimite-ne ideea ta frumoasă, abia așteptăm să trecem la treabă :)<br>
                Lasă-ne datele tale de contact în formularul alăturat sau scrie-ne la: <a href="mailto:festival@thelake.ro">festival@thelake.ro</a> 
                </p>
                <img class="patternN" src="../../assets/media/pattern/brand_icon.svg" alt="">
            </div>
            <div class="formJoin">
                <input type="text" placeholder="Nume" [ngModelOptions]="{standalone: true}" [(ngModel)]="Name"
                    required>
                <input type="email" placeholder="Email" [ngModelOptions]="{standalone: true}" [(ngModel)]="Email"
                    required>
                <input type="tel" placeholder="Telefon" [ngModelOptions]="{standalone: true}" [(ngModel)]="Phone"
                    required>
                <div class="EnrollType">
                    <div class="temporary" (click)="clickTemporara()">
                        <input class="active" type="checkbox" id="temporaryCheck"><label for="temporaryCheck" class="TempForm-control">Activitate tip eveniment</label>
                    </div>
                    <div class="permanent" (click)="clickPermanenta()">
                        <input type="checkbox" id="permanentCheck"><label for="temporaryCheck" class="PermForm-control">Activitate permanentă</label>
                    </div>
                </div>
                <textarea id="mesaj" placeholder="Trimite-ne propunerea ta" [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="Message"></textarea>
                <div class="privacylink3"><input (click)="checked3()" id="checkbox3" type="checkbox" required><label
                        class="form-control3">Sunt de acord cu <a id="ppLink3" (click)="seetermeni()"> Consimțământul de
                            prelucrare a datelor.</a></label></div>
                <h6 *ngIf="acestCamp2">Acest câmp este obligatoriu</h6>
                <button class="deactive" type="button" (click)="sendFormOne3()">TRIMITE MESAJUL</button>
            </div>
        </div>
    </section>

    <footer>
        <div class="footer-wrapper">
            <div class="footer-logo">
                <p>Proiect dezvoltat de:</p>
                <img src="../../assets/media/logos/sigla_negative-02.png" alt="Novarion Logo"
                    onclick="NewTabNovarion()">
            </div>
            <div class="footer1">
                <h5>Birou vânzări</h5>
                <p class="footer1-bold">The Lake Home</p>
                <a href="https://goo.gl/maps/u8YrSsReceFjCgq37" target=”_blank”>
                    <p>Strada Tractorului, nr. 12- 28,<br>Sibiu, România</p>
                </a>
                <div>
                </div>
            </div>
            <div class="footer2">
                <h5>Contact</h5>
                <div class="footer2-rezidentie">
                    <div class="rezidentie1">
                        <span>T.</span><a href="tel:+40723101694">+40 723 101 694</a> <br>
                        <span>T.</span><a href="tel:+40721229999">+40 721 229 999</a> <br>                        
                        <span>E.</span><a href="mailto:festival@thelake.ro">festival@thelake.ro</a>
                    </div>
                </div>
            </div>
            <div class="footer3">
                <h5>Social media</h5>
                <div class="footer2-socialMedia">
                    <img src="../../assets/media/icons/facebook_logo_icon.svg" alt="Facebook link" onclick="NewTabFb()">
                    <img src="../../assets/media/icons/linkedin_logo_icon.svg" alt="LinkedIn link" onclick="NewTabLi()">
                    <img src="../../assets/media/icons/instagram_logo_icon.svg" alt="Instagram link"
                        onclick="NewTabIg()">
                    <img src="../../assets/media/icons/youtube_icon.svg" alt="Youtube link" onclick="NewTabYt()">
                </div>
                <div class="footer2-end">
                    <a (click)="seetermeni()">Politica de confidențialitate | Cookies</a>
                </div>
            </div>
        </div>
    </footer>
</body>

<router-outlet></router-outlet>