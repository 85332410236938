<head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
</head>
<body>
    <div class="header-bcground"></div>

    <header class="headerGrid">
        <div class="headerGrid-wrapper">
            <div class="header-titles">
                <img src="../../../assets/media/logos/the_lake_logo_white.png" alt="The Lake home - Living Xperience logo" (click)="seehome()" >

                <div class="all-nav-elements">
                    <div class="contact-nav">

                        <div class="flexRow">
                            <a (click)="changeRoEn()">RO</a>
                        </div>

                        <div class="header-phone"><span id="header-icon-phone" class="material-symbols-outlined">phone_iphone <a href="tel:+40721229999">+4 0721 229 999</a> </span></div>
                        <span class="vertical-line"></span>
                        <div class="header-mail"><span id="header-icon-mail" class="material-symbols-outlined">chat_bubble <a href="mailto:sales@thelake.ro">sales@thelake.ro</a></span></div>
                        <!-- Button trigger modal vizionare-->
                        <button class="header-button" type="button" data-toggle="modal" data-target="#brosuraModal">SEE&nbsp;THE&nbsp;BROCHURE</button>  
                        

                        <!-- Modal Vizionare-->
                        <div class="modal fade" id="vizionareModal" tabindex="-1" role="dialog"
                            aria-labelledby="vizionareModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                            aria-hidden="true">
                                            <span aria-hidden="true" class="close-icon material-symbols-outlined">
                                                close
                                            </span>
                                        </button>
                                        <h5 class="modal-title" id="vizionareModalLabel">Schedule a viewing</h5>
                                    </div>
                                    <div class="modal-body">
                                        <div class="p">
                                            <p>Leave your contact details so our team can get in touch with you.</p>
                                        </div>
                                        <form>
                                            <input type="text" class="modal-form" [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="Name" placeholder="Name" required>
                                            <input type="email" class="modal-form" [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="Email" id="exampleFormControlInput1" placeholder="Email"
                                                required>
                                            <input type="tel" class="modal-form" [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="Phone" placeholder="Phone" required>
                                            <textarea id="modal-mesaj" class="modal-form"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Message"
                                                placeholder="Leave us a message" rows="3"></textarea>

                                            <div class="privacylink1"><input (click)="checked()" id="checkbox1"
                                                    type="checkbox" required><label class="form-control1">I agree with the <a id="ppLink" (click)="seetermeni()"
                                                        data-dismiss="modal">data processing policy.</a></label></div>
                                            <h6 *ngIf="acestCamp">This field is required</h6>

                                            <button class="deactive modal-button" type="button" (click)="sendFormOne()"
                                                data-dismiss="modal">SEND</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Modal Brosura-->
                        <div class="modal fade" id="brosuraModal" tabindex="-1" role="dialog"
                            aria-labelledby="brosuraModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                            aria-hidden="true">
                                            <span aria-hidden="true" class="close-icon material-symbols-outlined">
                                                close
                                            </span>
                                        </button>
                                        <h5 class="modal-title" id="brosuraModalLabel">See the brochure</h5>
                                    </div>
                                    <div class="modal-body-brosura">
                                        <div class="p">
                                            <p>Complete the form below to see The Lake Home brochure.</p>
                                        </div>
                                        <form>
                                            <input type="text" class="modal-form" placeholder="Name"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Name" required>
                                            <input type="email" class="modal-form" id="exampleFormControlInput1"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Email"
                                                placeholder="Email" required>
                                            <input type="tel" class="modal-form" placeholder="Phone"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Phone" required>
                                        
                                            <div class="privacylink2"><input (click)="checked2()" id="checkbox2"
                                                    type="checkbox" required><label class="form-control2">I agree with the<a id="ppLink2" data-dismiss="modal"
                                                        (click)="seetermeni()">data processing policy.</a></label></div>
                                            <h6 *ngIf="acestCamp1">This field is required</h6>
                                            <button class="deactive modal-button-brosura" type="button"
                                                (click)="sendFormOne2()" data-dismiss="modal">See brochure</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>      

                        <!-- XMAS promo Modal-->
                        <div class="modal fade" id="XmasModal" tabindex="-1" role="dialog"
                            aria-labelledby="XmasModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content XmasContent">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                            aria-hidden="true">
                                            <span aria-hidden="true" class="close-icon material-symbols-outlined">
                                                close
                                            </span>
                                        </button>
                                        <h5 class="modal-title XmasTitle" id="OfertaModalLabel">HO, HO,
                                            <span>HOME</span></h5>
                                    </div>
                                    <div class="modal-body-oferta">
                                        <div class="p">

                                            <p> <span class="xmasSubTitle">Serbează <strong>Crăciunul</strong> acasă și
                                                    fă loc
                                                    sub brad pentru <strong>un cadou în plus.</strong> </span><br><br>

                                                Achiziționează unul din ultimele apartamente disponibile
                                                în blocul C sau achită <strong>un avans de 50% </strong>pentru un
                                                apartament în blocul B1 și primești cadou de casă nouă
                                                un <strong>robot de bucătărie Thermomix TM6.</strong> <br><br>

                                                În plus, îți oferim un<strong> avantaj client</strong> cuprins între
                                                <strong>2,500 și
                                                    38,000 euro </strong>în baza avansului din prețul de listă pentru
                                                noua ta locuință în blocurile B1 și B2.<br><br>

                                                Lasă-ne datele tale și programează o vizionare.</p>
                                        </div>
                                        <form>
                                            <input type="text" class="modal-form" placeholder="Nume"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Name" required>
                                            <input type="email" class="modal-form" id="exampleFormControlInput1"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Email"
                                                placeholder="Email" required>
                                            <input type="tel" class="modal-form" placeholder="Telefon"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Phone" required>
                                            <textarea id="modal-mesaj" class="modal-form"
                                                placeholder="Scrie-ne un mesaj" [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="Message" rows="3"></textarea>

                                                <div class="privacylink2"><input (click)="checked4()" id="checkbox2"
                                                    type="checkbox" required><label class="form-control2">Sunt de acord
                                                    cu <a id="ppLink2" data-dismiss="modal"
                                                        (click)="seetermeni()">Consimțământul de prelucrare a
                                                        datelor.</a></label></div>
                                            <h6 *ngIf="acestCamp1">Acest câmp este obligatoriu</h6>
                                            <button class="deactive modal-button-oferta" type="button"
                                                (click)="sendFormOferta()" data-dismiss="modal">TRIMITE MESAJUL</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="logo-navbar">

                        <div class="mob-navbar">
                            <div class="distance-top-navbar">
                                <a class="blue-border" (click)="seelifestyle()">LIFESTYLE</a>
                                <a class="blue-border" (click)="footerlinks1()">CHOOSE&nbsp;YOUR&nbsp;RESIDENCE</a>
                                <a class="blue-border" (click)="seelocatie()">LOCATION</a>
                                <a class="blue-border" href="https://thelake.ro/galerie-en/">GALLERY</a>
                                <a class="blue-border" (click)="seemedia()">MEDIA</a>
                                <a class="blue-border" (click)="seedezvoltator()">DEVELOPER</a>
                                <a (click)="seecontact()">CONTACT</a>
                            </div>
                            

                        </div>
                        <div class="burger" (click)="navSlider()">
                            <div class="line1"></div>
                            <div class="line2"></div>
                            <div class="line3"></div>
                        </div>

                        <div class="navbar">
                            <a (click)="seelifestyle()">LIFESTYLE</a>
                            <a (click)="footerlinks1()">CHOOSE&nbsp;YOUR&nbsp;RESIDENCE</a>
                            <a (click)="seelocatie()">LOCATION</a>
                            <a href="https://thelake.ro/galerie-en/">GALLERY</a>
                            <a (click)="seemedia()">MEDIA</a>
                            <a (click)="seedezvoltator()">DEVELOPER</a>
                            <a (click)="seecontact()">CONTACT</a>
                        </div>
                    </div>
                </div>
                    <div class="mob-page-title">
                        <h1>Privacy policy</h1>
                    </div>    
            </div>    

            <!-- call to action buttons -->
            <div class="cta-btns">
                <div class="cta-visionare" type="button" data-toggle="modal" data-target="#vizionareModal">
                    <span id="cta-viz-icon" class="material-symbols-outlined">chat_bubble</span><a>SCHEDULE&nbsp;A&nbsp;VIEWING</a>
                </div>
                <div class="cta-brosura" type="button" data-toggle="modal" data-target="#brosuraModal">
                    <img src="../../../assets/media/icons/brosuraIcon.svg" alt="download brosura icon"><a>SEE THE BROCHURE</a>
                </div>
                
            </div>

            <div class="sticky-btn" type="button" data-toggle="modal" data-target="#vizionareModal"><span id="cta-viz-icon" class="material-symbols-outlined">chat_bubble</span>
                <h5>Schedule <br> a viewing</h5></div>

           <!--xmasPromo
           <div class="XMAS-sticky-btn" type="button" data-toggle="modal" data-target="#XmasModal"><img
            src="../../../assets/media/icons/favpng_vector-graphics-gift-clip-art-box.png" alt="">
            </div>-->
        </div>
    </header>

    <section class="page-content-wrapper">
        <div class="page-content">
            <h2 id="content-1">Overview</h2>
        
            <p>Regulation 2016/679 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data (General Data Protection Regulation, in this document - GDPR, Regulation or RGPD) was adopted by the European Parliament and the Council of the European Union on April 27, 2016, its provisions being directly applicable starting from May 25, 2018. This Regulation expressly repeals Directive 95/46/CE, thus replacing the provisions of Law no. 677/2001 (currently repealed).<br><br>

            The regulation is directly applicable in all member states, protecting the rights of all natural persons on the territory of the European Union. From a material point of view, the Regulation applies to all operators who process personal data. The Regulation does not apply to the processing of personal data concerning legal entities and, in particular, enterprises with legal personality, including the name and type of legal entity and the contact details of the legal entity.<br><br>
                
            Personal data is defined as any information regarding an identified or identifiable natural person ("data subject"); an identifiable natural person is a person who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or one or more specific elements, specific to his physical, physiological, genetic, psychological, economic, cultural or social identity.<br><br>
                
            The processing of personal data involves any operation or set of operations performed on data or sets of personal data, with or without the use of automated means, such as collection, registration, organization, structuring, storage, adaptation or modification, extraction , consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, deletion or destruction.</p>
    
            <h2 id="content-1">Operator identity</h2>
            <p>Considering article 4 point 7 of the Regulation, which defines the notion of "operator" as the natural or legal person, public authority, agency or other body that, alone or together with others, establishes the purposes and means of personal data processing , the operator that processes personal data through this website is XUX INVESTMENT SRL, based in Sibiu, Strada Tractorului 12, registered at the Commercial Registry Office J32/712/2013, having CUI 32102114, legally represented by Tarnaru Raul-Nicusor, with contact details office@thelake.ro, 0371306055.</p>

            <h2 id="content-1">Collection of personal data</h2>
            <h3>What personal data is collected</h3>
            <p>The operator of this website collects, stores and processes the following personal data of / about you:</p>
            <ul>
                <li>Name surname</li>
                <li>Contact details (such as email, phone, fax)</li>
                <li>IP</li>
            </ul>

            <h2 id="content-1">Obtaining Consent</h2>
            <h3>Overview</h3>
            <p>In order for the processing of personal data to be legal, the GDPR requires that it be carried out on the basis of a legitimate reason, such as the execution or conclusion of a contract, the fulfillment of a legal obligation, or on the basis of the valid consent previously expressed by the data subject. In the latter case, the operator is required to be able to prove that the person in question has given his consent for the respective processing. The consent expressed under the rule of Directive 95/46/EC remains valid if it meets the conditions provided by the GDPR.<br><br>

            Consent must be given through a statement or through an unequivocal action that constitutes a freely expressed, specific, informed and clear manifestation of the data subject's consent to the processing of his personal data. If the data subject's consent is given in the context of a statement, in electronic form or in writing, which also relates to other matters, the request for consent must be presented in a form that clearly differentiates it from the other matters, can be achieved even by ticking a box. In order for the processing of personal data to be legal, the GDPR requires that it be carried out on the basis of a legitimate reason, such as the execution or conclusion of a contract, the fulfillment of a legal obligation, or based on the valid consent previously expressed by the data subject. In the latter case, the operator is required to be able to prove that the person in question has given his consent for the respective processing. The consent expressed under the rule of Directive 95/46/EC remains valid if it meets the conditions provided by the GDPR.</p>
            
            <h3>Cookies</h3>
            <p>Cookies are used on this site. They do not harm your computer and do not contain viruses, but have the role of contributing to an easier, more efficient and safer use of the site. They are small text files that are saved on your computer and are saved by the browser you use. <br><br>

            Many of the cookies used are called "session cookies", which are automatically deleted after visiting this site. Others remain in your computer's memory until you delete them, making it possible for your browser to recognize them on a subsequent visit.<br><br>
                
            You can configure your browser to inform you about the use of cookies so that you can decide on a case-by-case basis whether to accept or reject a cookie. Alternatively, your browser can be configured to automatically accept cookies under certain conditions or to always reject them, or to automatically delete cookies when you close your browser. Disabling cookies may limit the functionality of this website.<br><br>
                
            Cookies that are necessary to enable electronic communications or to provide certain functions that you want to use (such as the shopping cart) are stored in accordance with the provisions of art. 6 paragraph 1 lit. f) of the GDPR, according to which the processing is legal only if and to the extent it is necessary for the purposes of the legitimate interests pursued by the operator or a third party. Therefore, the operator of this website has a legitimate interest in storing certain cookies, to ensure an optimization without technical errors. Other cookies (such as, for example, those used to analyze your browsing behavior) are also stored and will be treated separately in this document.</p>

            <h3>Server log files           </h3>
            <p>The provider of this website automatically collects and stores the information that your browser automatically transmits to us through log files. These are:</p>
            <ul>
                <li>Browser type and version</li>
                <li>Operating system used</li>
                <li>The URL of the page that originally generated the request to display the current page or object (Referrer URL)</li>
                <li>The host name of the accessing computer</li>
                <li>Temporal data regarding server access</li>
                <li>IP adress</li>
            </ul>
            <p>The legal basis for the processing of such data is represented by art. 6 para. 1 lit. b) GDPR, which allows data processing when it is necessary for the execution of a contract to which the data subject is a party or to take steps, at the request of the data subject, before concluding a contract.</p>

            <h3>Contact form            </h3>

            <p>If you send us questions via the contact form, we will collect the data entered in the form, including the contact data you provide, in order to answer your and others' subsequent questions. We do not transmit this information without your permission. Therefore, we will process all the data you enter in the contact form only with your consent [in accordance with the provisions of art. 6 para. 1 lit. a) GDPR1]. You can revoke your consent at any time, an informal email to this effect is sufficient. Data processed before receiving your request may be processed lawfully. We will keep the data you provide on the contact form until:</p>

            <ul><li>request data deletion;</li><li>revoke your consent to their storage or if;</li><li>the purpose for storing it is no longer valid.</li></ul>
            
            <p>Any mandatory legal provisions, in particular those relating to mandatory data retention periods, are not affected by the above.</p>
            
            
            
            <h3>Contact by e-mail, phone or fax</h3>
            
            <p>If you contact us by e-mail, telephone or fax, your request, including all the personal data you will provide, will be stored and processed by us for the purpose of solving your request, based on your consent.</p>
                             
            <p>Therefore, we will process all the data you provide based on the following legal provisions contained in the GDPR, respectively:</p>
            
            <ul><li>only with your consent - in accordance with the provisions of art. 6 para. 1 lit. a) GDPR</li><li>for the execution of a contract or in the pre-contractual stage - in accordance with the provisions of art. 6 para. 1 lit. b) GDPR</li><li>for the fulfillment of the purpose and legitimate interest pursued by us, namely that of efficient processing of the requests sent by you - in accordance with the provisions of art. 6 para. 1 lit. f) GDPR.
            </li></ul>
        
            <p>We will keep the data you provide in this way until:</p>
                    
            
            <ul><li>request data deletion;</li><li>revoke your consent to their storage or if </li><li>the purpose for its storage is no longer valid, in all situations except for the mandatory data retention periods.</li></ul>
            
            
            
            <h3>Registration on the website</h3>       
                        
            <p>You can register on this website to access additional functions and services offered by our company. In this sense, the data entered by you will be used and processed for the purpose of using the respective service or functions for which you have registered. The mandatory data requested during registration must be provided by you in full, otherwise the registration operation will be rejected.</p>
            
            
            
            <p>To inform you about important changes, such as those in the scope of our site or about technical changes, we will use the email address you specified at the time of registration.</p>
            
            
            
            <p>The processing of personal data, provided in the registration procedure, is done only with your consent and in compliance with the provisions of art. 6 para. 1 lit. a) GDPR. You can revoke your consent at any time, an informal email to this effect is sufficient. We will continue to store the data collected during registration for as long as you remain registered on this website, but the mandatory storage periods remain valid and will be respected.</p>
            
            
            
            <h3>Login via Facebook</h3>
            
            
            
            <p>If you do not want to register directly on this site, you can connect via Facebook (Connect). This service is provided by Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Ireland.</p>                     
            
            <p>To log in using Facebook, press "Login with facebook" or "Connect with Facebook" and you will be automatically redirected to this platform, where you will be asked for your username and password details and thus you will connect to this website with your profile by Facebook. In this way, we will have access to all the data stored on Facebook, for example (and not limited to):</p>
            
            
            
            <ul><li>Name</li><li>Profile photo</li><li>Email Address</li><li>Facebook ID</li><li>The network of friends</li><li>Facebook likes</li><li>Birthday</li><li>Gender/Sex</li><li>Country</li><li>Language</li></ul>
            
            
            
            <p>This data will be used to create and customize your account on this website.</p>
            
            
            
            <p>For more information, you can access the Facebook Terms and Conditions, as well as the Privacy Policy, available at <a href="https://www.facebook.com/privacy" target="_blank" rel="noreferrer noopener">https://www.facebook.com/privacy</a> and <a href="https://www.facebook.com/legal/terms" target="_blank" rel="noreferrer noopener">https://www.facebook.com/legal/terms</a>.</p>
            
            
            
            <p>Considering the Judgment of July 16, 2020 (pronounced in case C-311/18 – Data Protection Commissioner/Facebook Ireland Limited, Maximillian Schrems) , the European Court of Justice ruled that the protection offered by the Privacy Shield EU – US does not have an appropriate character.</p> <br>
            
            
            
            <p>Therefore, the transmission of personal data to the USA and other countries outside the European Economic Area (EEA) is based on the Standard Contractual Clauses (SCC) of the European Commission. The Commission has issued two sets of standard contractual clauses for data transfers from EU data controllers to data controllers established outside the EU or the European Economic Area (EEA). It also issued a set of contractual clauses for data transfers from EU operators to processors established outside the EU or EEA. For more information on these Clauses, we recommend accessing<a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_ro" target="_blank" rel="noreferrer noopener">https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_ro</a>.</p>
            
            
            
            <p>Facebook uses Standard Contractual Clauses as an adequate data protection guarantee, in accordance with the level of protection guaranteed by the GDPR.</p>
            
            
            
            <p>For more details, go to: <a href="https://www.facebook.com/legal/EU_data_transfer_addendum" target="_blank" rel="noreferrer noopener">https://www.facebook.com/legal/EU_data_transfer_addendum</a></p>
            
            
            
            <h3>Comments section           </h3>
            
            
            
            <p>By accessing the Comments section, certain personal data (such as, but not limited to, email address, username, IP address) will be processed and stored, some of them being necessary from the perspective of preventing illegal actions or defamatory content.
            </p>
            
            
            
            <p>EThere is also the possibility to sign up/subscribe to this site in order to receive the comments via the provided email, so that:</p>
            
            
            
            <ul><li>It is possible that the email address will be verified by a confirmation email;</li><li>You can unsubscribe at any time by accessing the link in the content of the e-mails, and the data provided by you will be deleted immediately, with the exception of the data provided as a result of accessing other sections (for example, when signing up for the newsletter) which will remain stored ;</li></ul>
            
            
            
            <h2>The purpose of processing the collected data</h2>                     
            
            <p>Part of the data collected on this site is used to:</p>           
            
            
            <ul><li>Providing the services we offer for your benefit (for example, to solve problems of any nature related to our products and services, to provide support services, etc.)</li><li>Optimum operation and optimization of this site (statistical and analytical) – We always want to give you the best experience on our site, which is why we may collect and use certain information related to the degree of satisfaction you have had while browsing this site, we may invite you to fill out feedback surveys or the like.</li><li>Advertising and promotion activities in the online environment. You can ask us at any time, through the means described in this document, to stop processing your personal data for marketing purposes, and we will comply with your request as soon as possible.</li><li>Periodic user information – We want to keep you informed about our offers. In this regard, we may send you any type of message containing general and topical information, information regarding offers or promotions, as well as other commercial communications such as market research and opinion polls. For communications of this type, we have as a basis the consent previously obtained from you. You can change your mind and withdraw your consent at any time.</li><li>To defend our legitimate interests. There may be situations where we will use or share information to protect our rights and business. These may include: measures to protect the website and the user of our website from cyber attacks; measures to prevent and detect fraud attempts, including the transmission of information to the competent public authorities; measures to manage other types of risks.</li></ul>
            <br><br>
            
            
            <p>The processing of personal data is carried out in accordance with the provisions of the General Regulation on Data Protection, based on both the consent of the data subject and reasons for the compliant execution of contracts or the realization of the legitimate interests of the operator (unless the interests prevail or the fundamental rights and freedoms of the data subject, which require the protection of personal data, especially when the data subject is a child).</p>
            
            
            
            <h2>User rights</h2>
            
            
            
            <p>Your rights regarding personal data and the means of exercising them are: The right to information, The right to access, The right to rectification, The right to delete data, The right to restrict processing, The right to data portability, The right to opposition, The right not to is the subject of a decision based exclusively on automatic data processing, Right to lodge a complaint and address to the courts, Right to withdraw consent.</p>
            
            
            
            <ul><li><strong>The right to information</strong> – you can request information on the processing activities of your personal data, on the identity of the operator and its representative or on the recipients of your data;</li>
            <li><strong>Right of access</strong> – you can obtain from the operator a confirmation that personal data concerning you is being processed or not and, if so, access to the respective data and the following information: the purposes of the processing; the categories of personal data concerned; recipients or categories of recipients to whom the personal data have been or will be disclosed, especially recipients from third countries or international organizations; where possible, the period for which the personal data is expected to be stored or, if this is not possible, the criteria used to establish this period;</li>
            <li><strong>The right to rectification</strong> – you can rectify inaccurate personal data or complete them;</li>
            <li><strong>The right to delete data</strong> – you can obtain the deletion of data, if their processing was not legal or in other cases provided by law;</li>
            <li><strong>The right to restrict processing</strong> – you can request the restriction of processing if you dispute the accuracy of the data, as well as in other cases provided by law;</li>
            <li><strong>The right to data portability</strong> – you can receive, under certain conditions, the personal data you have provided to us in a machine-readable format or you can request that said data be transmitted to another operator;</li>
            <li><strong>The right to opposition</strong> – you can object, in particular, to data processing based on the legitimate interest of the operator;</li>
            <li><strong>The right not to be subject to a decision based solely on automatic data processing</strong> – you can ask for and obtain human intervention with respect to said processing or express your own point of view regarding this type of processing;</li>
            <li><strong>The right to lodge a complaint and to address the courts</strong> – you can lodge a complaint against the way of processing personal data to the National Authority for the Supervision of Personal Data Processing and / or you can address the courts for the respect of your rights;</li>
            <li><strong>The right to withdraw consent</strong> – ​​where the processing is based on your consent, you can withdraw it at any time. The withdrawal of consent will only have effects for the future, the processing carried out prior to the withdrawal still remaining valid.
            </li></ul>
            
            
            
            <h2>Obligations of the data controller</h2>
            
            
            
            <h3>Hosting</h3>
            
            
            
            <p>Personal data registered on this website are stored on GLOBEHOSTING servers. The processing of the data provided and stored complies with the following legal provisions:</p>
            
            
            
            <ul><li>Art. 6 para. 1 lit. a) GDPR - data processing by GLOBEHOSTING is carried out based on your consent, obtained after correct and complete information;</li><li>Art. 6 para. 1 lit. b) GDPR – data processing by GLOBEHOSTING takes place with the purpose of fulfilling the assumed contractual obligations;</li><li>Art. 6 para. 1 lit. f) GDPR – data processing by GLOBEHOSTING is carried out for the purposes of the legitimate interests pursued by the operator</li></ul>
            
            
            
            <p>Regardless of the purpose for which personal data is processed, the principles of legality, fairness and transparency are respected, but also that the personal data processed are adequate, relevant and limited to what is necessary in relation to the purposes for which are processed.</p>
            
            
            
            <p>For more information on the processing of personal data by GLOBEHOSTING, go to <a href="https://www.globehosting.ro/politica-de-confidentialitate.html" target="_blank" rel="noreferrer noopener">https://www.globehosting.ro/politica-de-confidentialitate.html</a></p>
            
            
            
            <p>We have a contract/a convention/legal act (including the possibility of including and agreeing to the clauses of the Website Terms and Conditions) concluded with GLOBEHOSTING to ensure the processing of personal data in accordance with the legal regulations in the field. We comply with our obligations according to Article 28 of the GDPR, by choosing an external service provider that offers sufficient guarantees for the implementation of appropriate technical and organizational measures, so that the processing complies with the requirements set out in the regulation and ensures the protection of your rights.</p>
            
            
            
            <h3>Data encryption</h3>
            
            
            
            <p>This site uses SSL encryption for security reasons and to protect the transmission of confidential information. This encryption can be recognized by you by the lock window ("lock icon") that appears in the browser bar and by changing the address of the respective browser from http:// to https://. Once encryption of this type is activated, the transmitted or transferred data will not be able to be seen by third parties.</p>
            
            
            
            <p>According to the GDPR, if the breach of the security of personal data is likely to generate a high risk for your rights and freedoms, the operator of this website will inform you, without undue delay, about this breach, unless the supplementary provisions become incident from the same Regulation (art. 34 paragraph 3).</p>
            
            
            
            <h3>The data protection officer</h3>
            
            
            
            <p>As the provisions of the GDPR are not applicable (art. 37 paragraph 1 - according to which the Operator and the person authorized by the operator appoint a data protection officer whenever:</p>
            
            
            
            <ol><li>the processing is carried out by a public authority or body, with the exception of courts acting in the exercise of their jurisdictional function;</li><li>the main activities of the operator or the person authorized by the operator consist of processing operations which, by their nature, scope and/or purposes, require a periodic and systematic monitoring of the persons concerned on a large scale; or</li><li>the main activities of the operator or the person authorized by the operator consist in the large-scale processing of special categories of data pursuant to Article 9 or of personal data relating to criminal convictions and offences, referred to in Article 10)</li></ol>
            
            
            
            <p>Regarding the obligation to appoint a Data Protection Officer, for any information or clarifications regarding the operation of this website, please contact us on the following dates:</p>
            
            
            
            <ul><li>Name: XUX INVESTMENT</li><li>E-mail: office@thelake.ro</li><li>Tel: 0371306055</li><li>Fax:</li><li>Mailing address: Strada Lector 1-3 Floor 1 RE/MAX Office</li></ul>
            
            
            
            <h3>Records of processing activities</h3>
            
            
            
            <p>According to the GDPR Regulation, the operator or the person authorized by the operator should keep, for a reasonable period, records of the processing activities under his responsibility. Thus, these records will include all the following information:</p>
            
            
            
            <ul><li>the name and contact details of the operator;</li><li>the purposes of the processing;</li><li>description of categories of data subjects and categories of personal data;</li><li>the categories of recipients to whom the personal data were or will be disclosed;</li><li>dif applicable/possible:<ul><li>transfers of personal data</li><li>the expected deadlines for the deletion of different categories of data</li><li>a general description of technical and organizational security measures</li></ul></li></ul>
            
            
            
            <p>The obligation detailed above does not apply to an enterprise or organization with less than 250 employees, unless the processing it carries out is likely to generate a risk for the rights and freedoms of the data subjects, the processing is not occasional or the processing includes special categories of data or personal data relating to criminal convictions and offences.</p>
            
            
            
            <h3>Adequate technical and organizational measures</h3>
            
            
            
            <p>Taking into account the current state of technology, the context and purposes of the processing, as well as the risks to the rights and freedoms of natural persons, the operator implements appropriate technical and organizational measures to ensure that, by default, only personal data that are necessary for each specific purpose of the processing.</p>
            
            
            
            <h3>Notification of the supervisory authority in case of personal data security breach</h3>
            
            
            
            <p>According to art. 33 para. 1 of the GDPR, if there is a breach of personal data security, we will notify the National Authority for the Supervision of the Processing of Personal Data without undue delay and, if possible, within 72 hours at most from the date we became aware of it, unless it is unlikely to generate a risk for the rights and freedoms of natural persons.</p>
            
            
            
            <h3>Informing the data subject about the data security breach of personal data</h3>
            
            
            
            <p>Related to the provisions of art. 34 of the GDPR, if the breach of the security of personal data is likely to generate a high risk for the rights and freedoms of natural persons, we will inform the data subject without undue delay about this breach, except in situations where:</p>
            
            
            
            <ul><li>appropriate technical and organizational safeguards have been implemented and these measures have been applied to personal data affected by the personal data breach, in particular measures to ensure that the personal data becomes unintelligible to anyone who does not is authorized to access them, such as encryption;</li><li>further measures have been taken to ensure that the high risk for the rights and freedoms of the previously mentioned data subjects is no longer likely to materialize;</li><li>it would require a disproportionate effort. In this situation, a public information is carried out instead or a similar measure is taken by which the persons concerned are informed in an equally effective way.</li></ul>
            
            
            
            <h2>Social Media</h2>
            
            
            
            <h3>Facebook plugins (Like &amp; Share Button)</h3>
            
            
            
            <p>This service uses social plugins ("plugins") managed by the social network facebook.com. Plugins can be identified by a Facebook logo (a white "f" on a blue board or a "thumbs up" sign) or are labeled by adding the phrase "Facebook Social Plugin". The list and layout of Facebook plugins can be seen here: &nbsp;<a href="https://developers.facebook.com/docs/plugins/">https://developers.facebook.com/docs/plugins/</a>.&nbsp; As long as you use the Like extension, you will like our website's Facebook page without having to leave it. To the extent that you use the Share extension, you will share our site or certain content from it on your personal Facebook page without having to leave the site. &nbsp;</p>
            
            
            
            <p>Through the plugin, Facebook receives the information that you access on our website. If you are also logged in to Facebook at the same time, Facebook can attribute the actions taken on the page to your account and, implicitly, to you personally. When you interact with the plugins, for example by clicking the Like button or sharing certain content from the website, the corresponding information is transferred directly from your browser to Facebook and stored there. Even if you are not a Facebook member, it is still possible for the social network to obtain and store your IP address. &nbsp;</p>
            
            
            
            <p>By clicking on one of these buttons, you agree to the use of this plugin and therefore to the transfer of personal data to Facebook. We have no control over the nature and purpose of this transmitted data, as well as over its subsequent processing. Regarding the purpose and extent of data collection, processing and further use of data by Facebook, as well as permissions and settings to protect privacy.
            </p>
            
            
            
            <p>If you do not want Facebook to associate your visit to this website with your Facebook account information, you can log out.</p>
            
            
            
            <h3>Instagram Plugin</h3>
            
            
            
            <p>This service uses social plugins ("plugins") managed by the Instagram social network, functions provided by Instagram Inc., with headquarters at 1601 Willow Road, Menlo Park, CA 94025, USA. Plugins can be identified by an Instagram logo or are labeled by adding the phrase "Instagram Social Plugin". &nbsp;</p>
            
            
            
            <p>Via the plugin, Instagram is informed about the actions you take on our page. If you are also connected to your personal account on the social network at the same time, it can attribute the actions taken on your Instagram account page and, implicitly, to you personally. When you access the plugins, the corresponding information is transferred from your browser to the social network and stored there. Even if you are not a member of Instagram, it is still possible for it to obtain and store your IP address. &nbsp;</p>
            
            
            
            <p>By clicking on one of these buttons, you agree to the use of this plugin and therefore to the transfer of personal data to Instagram. We have no control over the nature and purpose of this transmitted data, as well as over its subsequent processing. Regarding the purpose and scope of data collection, processing and further use of data by Instagram, as well as permissions and settings to protect user privacy, you can consult Instagram's privacy policies at:&nbsp;<a href="https://help.instagram.com/155833707900388">https://help.instagram.com/155833707900388</a>.&nbsp;</p>
            
            
            
            <p>If you are a member of Instagram and do not want it to collect your data through the plugin and link it to the data already stored on Instagram, you must log out of the social network before visiting this site.</p>
            
            
            
            <h3>Google+ plugin</h3>
            
            
            
            <p>This service uses social plugins ("plugins") managed by the social network Google+. Plugins can be identified by a Google+ logo. &nbsp;</p>
            
            
            
            <p>Through the plugin, Google receives the information that you access on our page. If you are also connected to the social network at the same time, Google can assign the actions performed on the page to your Google+ account and, implicitly, to you personally. When you interact with the plugins, the corresponding information is transferred directly from your browser to Google+ and stored there. Even if you are not a member of Google+, it is still possible for it to obtain and store your IP address. &nbsp;</p>
            
            
            
            <p>By clicking on one of the plugin buttons, you can express your consent to their use and therefore to the transfer of personal data to Google+. We have no control over the nature and purpose of this transmitted data, as well as over their subsequent processing. Regarding the purpose and extent of data collection, processing and further use of data by Google+, as well as permissions and settings to protect user privacy, you can consult the Google+ privacy policies at:&nbsp;<a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a>.&nbsp;</p>
            
            
            
            <p>If you are a member of Google+ and do not want it to collect your data through the plugin and link it to the data already stored on Google+, you must log out of the social network before visiting the site.</p>
            
            
            
            <h2>Newsletter</h2>
            
            
            
            <p>To receive a newsletter, it is necessary to indicate a valid e-mail address, along with specific information that can identify the owner of this address. Also, your consent is required for sending the newsletter and, therefore, we inform you that any other personal data will be collected and stored only based on your consent. The data thus collected are processed only for the purpose of sending the newsletter and will not be transmitted to third parties.</p>
            
            
            
            <p>Therefore, we will process any data you enter in the contact form only with your consent, in accordance with the provisions of art. 6 para. 1 lit. of the GDPR. &nbsp;</p>
            
            
            
            <h2>Plugins and Tools</h2>
            
            
            
            <h3>Youtube</h3>
            
            
            
            <p>Our website uses plugins of the YouTube platform, which is operated by Google. The operator of the website is YouTube, LLC, 901 Cherry Ave, San Bruno, CA 94066, USA.</p>
            
            
            
            <p>If you visit a page on our website where a YouTube plug-in has been integrated, a connection to the YouTube servers will be established. Consequently, the YouTube server will be notified, which of the pages have been visited by you.</p>
            
            
            
            <p>In addition, YouTube will be able to insert different cookies, with the help of which it will be possible to obtain information about the visitors of our website. Among other things, this information will be used to generate video statistics with the aim of improving the ease of use of the site and preventing fraud attempts. &nbsp;</p>
            
            
            
            <p>If you are signed in to your YouTube account while visiting our website, you allow YouTube to directly assign your browsing patterns to your personal profile. You have the option to prevent this by signing out of your YouTube account.</p>
            
            
            
            <p>The use of YouTube is based on our interest in presenting online content to you in an attractive manner. According to art. 6 para. 1 lit. f) GDPR, this is a legitimate interest.</p>
            
            
            
            <p>Considering the Judgment of July 16, 2020 (pronounced in case C-311/18 – Data Protection Commissioner/Facebook Ireland Limited, Maximillian Schrems) , the European Court of Justice ruled that the protection offered by the EU – US Privacy Shield (Privacy Shield) does not have an appropriate character. Therefore, the transmission of personal data to the USA and other countries outside the European Economic Area (EEA) should be based on the Standard Contractual Clauses (SCC) of the European Commission.</p>
            
            
            
            <p>For more information on how YouTube handles user data, see YouTube's Data Privacy Policy at: &nbsp;<a href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noreferrer noopener">https://policies.google.com/privacy?hl=en</a>.</p>
            
            
            
            <h3>Vimeo</h3>
            
            
            
            <p>Our website uses plugins of the video portal Vimeo. The provider is Vimeo Inc., 555 West 18th Street, New York, New York 10011, USA.</p>
            
            
            
            <p>If you visit one of the pages on our website where a Vimeo plug-in has been integrated, a connection to the Vimeo servers will be established. Consequently, the Vimeo server will receive information about the pages you have visited. In addition, Vimeo will receive the IP address. This will also happen if you are not signed in to Vimeo or do not have a Vimeo account. The information recorded by Vimeo will be transmitted to the Vimeo server in the United States.</p>
            
            
            
            <p>If you are signed in to your Vimeo account, you allow Vimeo to directly assign your browsing patterns to your personal profile. You can prevent this by signing out of your Vimeo account.</p>
            
            
            
            <p>The use of Vimeo is based on our interest in presenting your online content in an attractive manner. According to art. 6 para. 1 lit. f) GDPR, this is a legitimate interest</p>
            
            
            
            <p>Considering the Judgment of July 16, 2020 (pronounced in case C-311/18 – Data Protection Commissioner/Facebook Ireland Limited, Maximillian Schrems) , the European Court of Justice ruled that the protection offered by the EU – US Privacy Shield (Privacy Shield) does not have an appropriate character.</p>
            
            
            
            <p>Therefore, the transmission of personal data to the USA and other countries outside the European Economic Area (EEA) is based on the Standard Contractual Clauses (SCC) of the European Commission. The Commission has issued two sets of Standard Contractual Clauses for data transfers from EU data controllers to data controllers established outside the EU or the European Economic Area (EEA). It also issued a set of contractual clauses for data transfers from EU operators to processors established outside the EU or EEA. For more information on these Clauses, we recommend accessing <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_ro" target="_blank" rel="noreferrer noopener">https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_ro</a>.&nbsp;</p>
            
            
            
            <p>Vimeo uses Standard Contractual Clauses as an adequate data protection guarantee, in accordance with the level of protection guaranteed by the GDPR. For more information, go to <a href="https://www.activecampaign.com/legal/scc" target="_blank" rel="noreferrer noopener">https://www.activecampaign.com/legal/scc</a> and the full privacy policy available here: <a href="https://vimeo.com/privacy" target="_blank" rel="noreferrer noopener">https://vimeo.com/privacy</a>.</p>
            
            
            
            <h3>Google Web Fonts</h3>
            
            
            
            <p>This site uses Web Fonts provided by Google to ensure consistent use of fonts on this site.&nbsp;</p>
            
            
            
            <p>When you access a page on this website, your browser will load, as a result of establishing a connection with Google's servers, the web fonts necessary for the correct display of text and fonts. So, &nbsp;</p>
            
            
            
            <p>The use of Google Web Fonts is based on Art. 6 para. 1 lit. f) GDPR, there is a legitimate interest in the uniform presentation of the font on this website. If there is a consent expressed in this regard (for example, consent to the archiving of cookies), the data will be processed exclusively on the basis of art. 6 para. 1 lit. a) GDPR.</p>
            
            
            
            <p>For more information on how Google Web Fonts handles user data, see the Privacy Policy available at:  <a href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noreferrer noopener">https://policies.google.com/privacy?hl=en</a>.</p>
            
            
            
            <h3>Google Maps</h3>
            
            
            
            <p>This site uses Google Maps, a mapping and location service, through an API. The provider is Google Inc., 1600 Amphitheater Parkway Mountain View, CA 94043, United States of America.</p>
            
            
            
            <p>To guarantee data protection on our website, you will find that Google Maps has been disabled when you visit our website for the first time. A direct connection to the Google servers will not be established before the autonomous activation of Google Maps, i.e. with your consent in accordance with Article 6 para. 1 lit. a) GDPR. This will prevent the transfer of data to Google during the first visit to our website. After you have activated the service, Google Maps will store your IP address. As a rule, it is then transferred to a Google server in the United States, where it is stored. The provider of this website has no control over this data transfer once Google Maps has been activated.</p>
            
            
            
            <p>Considering the Judgment of July 16, 2020 (pronounced in case C-311/18 – Data Protection Commissioner/Facebook Ireland Limited, Maximillian Schrems) , the European Court of Justice ruled that the protection offered by the EU – US Privacy Shield (Privacy Shield) does not have an appropriate character.</p>
            
            
            
            <p>Therefore, the transmission of personal data to the USA and other countries outside the European Economic Area (EEA) is based on the Standard Contractual Clauses (SCC) of the European Commission. The Commission has issued two sets of Standard Contractual Clauses for data transfers from EU data controllers to data controllers established outside the EU or the European Economic Area (EEA). It also issued a set of contractual clauses for data transfers from EU operators to processors established outside the EU or EEA. For more information on these Clauses, we recommend accessing <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_ro" target="_blank" rel="noreferrer noopener">https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_ro</a>.&nbsp;</p>
            
            
            
            <p>Google Maps uses Standard Contractual Clauses as an adequate data protection guarantee, in accordance with the level of protection guaranteed by the GDPR. For more information, see Google's Data Privacy Statement at the following address: <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer noopener">https://policies.google.com/privacy</a></p>
            
            
            
            <h3>Google reCaptcha</h3>
            
            
            
            <p>We use "Google reCAPTCHA" (hereinafter referred to as "reCAPTCHA") on our website. The provider is Google Inc., located at 1600 Amphitheater Parkway, Mountain View, CA 94043, USA ("Google"). The purpose of reCAPTCHA is to determine whether data entered on our site (for example, information entered in a contact form) is provided by a human user or an automated program. To determine this, reCAPTCHA analyzes the behavior of website visitors based on a variety of parameters. This analysis is automatically triggered as soon as the website visitor enters the website. For this analysis, reCAPTCHA evaluates a variety of data (eg IP address, time the website visitor spent on the website or user-initiated cursor movements). The data tracked during these analyzes is sent to Google. reCAPTCHA analyzes run entirely in the background. Site visitors are not alerted that an analysis is in progress. The data are processed based on art. 6 para. 1 lit. f) GDPR. Website operators have a legitimate interest in protecting the operator's web content against misuse by automated industrial espionage systems and against SPAM. &nbsp;</p>
            
            
            
            <p>Considering the Judgment of July 16, 2020 (pronounced in case C-311/18 – Data Protection Commissioner/Facebook Ireland Limited, Maximillian Schrems) , the European Court of Justice ruled that the protection offered by the EU – US Privacy Shield (Privacy Shield) does not have an appropriate character.</p>
            
            
            
            <p>Therefore, the transmission of personal data to the USA and other countries outside the European Economic Area (EEA) is based on the Standard Contractual Clauses (SCC) of the European Commission. The Commission has issued two sets of Standard Contractual Clauses for data transfers from EU data controllers to data controllers established outside the EU or the European Economic Area (EEA). It also issued a set of contractual clauses for data transfers from EU operators to processors established outside the EU or EEA. For more information on these Clauses, we recommend accessing <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_ro" target="_blank" rel="noreferrer noopener">https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_ro</a>.&nbsp;</p>
            
            
            
            <p>Google reCatpcha uses Standard Contractual Clauses as an adequate data protection guarantee, in accordance with the level of protection guaranteed by the GDPR. For more information, see Google's Data Privacy Statement available here: <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer noopener">https://policies.google.com/privacy</a>&nbsp; and here: <a href="https://policies.google.com/terms?hl=en" target="_blank" rel="noreferrer noopener">https://policies.google.com/terms?hl=en</a></p>
            
            
            
            <h2>Chat Online</h2>
            
            
            
            <h3>Online Chat Platform </h3>
            
            
            
            <h3>Facebook Messenger</h3>
            
            
            
            <p>On this website we use Facebook Messenger, a free instant messaging application, thus ensuring the instant exchange of text messages with one or even more people or computers at once. It is an American messaging app and platform developed by Facebook, Inc. Originally developed as Facebook Chat in 2008, the company revamped its messaging service in 2010.</p>
            
            
            
            <p>Through Facebook Messenger, we can provide you with fast support by allowing you to interact with us, including by tracking purchases, receiving notifications and initiating personal conversations with the company's customer service representatives.</p>
            
            
            
            <p>The legal basis for the processing of personal data through Facebook Messenger is represented by art. 6 para. 1 lit. f) of the Regulation, based on our legitimate interest in the legality of the processing. In relation to the processing of personal data, Facebook Ireland can be contacted online or by post at the following address Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2 Ireland.</p>
            
            
            
            <p>The data collected through Facebook Messenger is used, among other things, to provide, personalize and improve the chat, to enable the provision of analysis services, but also to communicate with you. &nbsp;</p>
            
            
            
            <p>Considering the Judgment of July 16, 2020 (pronounced in case C-311/18 – Data Protection Commissioner/Facebook Ireland Limited, Maximillian Schrems) , the European Court of Justice ruled that the protection offered by the EU – US Privacy Shield (Privacy Shield) does not have an appropriate character.</p>
            
            
            
            <p>Therefore, the transmission of personal data to the USA and other countries outside the European Economic Area (EEA) is based on the Standard Contractual Clauses (SCC) of the European Commission. The Commission has issued two sets of Standard Contractual Clauses for data transfers from EU data controllers to data controllers established outside the EU or the European Economic Area (EEA). It also issued a set of contractual clauses for data transfers from EU operators to processors established outside the EU or EEA. For more information on these Clauses, we recommend accessing <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_ro" target="_blank" rel="noreferrer noopener">https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_ro</a>.&nbsp;</p>
            
            
            
            <p>Facebook Messenger complies with the provisions of the GDPR and the Standard Contractual Clauses (SSC) approved by the European Commission, constantly taking into account its decisions regarding data transfers to the United States and other countries. The European Commission has recognized countries such as Andorra, Argentina, Canada (trade organisations), Faroe Islands, Guernsey, Israel, Isle of Man, Japan, Jersey, New Zealand, Switzerland and Uruguay as providing adequate protection. &nbsp;</p>
            
            
            
            <p>More information is available here <a href="https://www.facebook.com/privacy/explanation" target="_blank" rel="noreferrer noopener">https://www.facebook.com/privacy/explanation</a> and here&nbsp; <a href="https://www.facebook.com/legal/EU_data_transfer_addendum" target="_blank" rel="noreferrer noopener">https://www.facebook.com/legal/EU_data_transfer_addendum</a></p>
            
            
            
            <h3>WhatsApp</h3>
            
            
            
            <p>Through WhatsApp, we ensure effective communication with our customers. For those who live in a country in the European Economic Area (which includes the European Union) and any other country or territory included (collectively the "European Region"), Whatsapp is operated by WhatsApp Ireland Limited, based at 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland.</p>
            
            
            
            <p>According to the WhatsApp policy, which can be consulted and analyzed here: <a href="https://www.whatsapp.com/legal/#privacy-policy">https://www.whatsapp.com/legal/#privacy-policy</a>, Whatsapp Ltd. being part of the Facebook Companies, through this service personal data is collected and processed personally in compliance with the security and privacy principles applicable at European level (in particular, RGPD) or at international level (if we are talking about services provided by Whatsapp Inc. – EU-US Privacy Shield Framework and the Swiss-US Privacy Shield Framework).</p>
            
            
            
            <p>Through Whastapp, the following are processed:</p>
            
            
            
            <ul><li>data provided by the users themselves (such as those related to the users account – phone number, profile name, photo – user connections. As for the messages sent, they are not stored on the WhatsApp servers, except for those not could be sent (for example, to an offline user) and which are stored for a period of 30 days before being deleted.</li><li>automatically collected data (information on the user's "last seen status", user preferences stored through cookies, IP address, browser-related information, some information related to transactions and payments - for the terms and conditions regarding payments, we recommend see <a href="https://www.whatsapp.com/legal/?eea=0#payments-in">https://www.whatsapp.com/legal/?eea=0#payments-in</a>).</li></ul>
            
            
            
            <p>The legal basis for the processing of personal data through Whatsapp is represented by art. 6 lit. f) of the Regulation, based on our legitimate interest in the legality of the processing.</p>
            
            
            
            <p>Considering the Judgment of July 16, 2020 (pronounced in case C-311/18 – Data Protection Commissioner/Facebook Ireland Limited, Maximillian Schrems) , the European Court of Justice ruled that the protection offered by the EU – US Privacy Shield (Privacy Shield) does not have an appropriate character.</p>
            
            
            
            <p>Therefore, the transmission of personal data to the USA and other countries outside the European Economic Area (EEA) is based on the Standard Contractual Clauses (SCC) of the European Commission. The Commission has issued two sets of Standard Contractual Clauses for data transfers from EU data controllers to data controllers established outside the EU or the European Economic Area (EEA). It also issued a set of contractual clauses for data transfers from EU operators to processors established outside the EU or EEA. For more information on these Clauses, we recommend accessing  <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_ro" target="_blank" rel="noreferrer noopener">https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_ro</a>.</p>
            
            
            
            <p>WhatsApp uses Standard Contractual Clauses as an adequate data protection guarantee, in accordance with the level of protection guaranteed by the GDPR, according to the information available here <a href="https://www.whatsapp.com/legal/#privacy-policy-our-global-operations" target="_blank" rel="noreferrer noopener">https://www.whatsapp.com/legal/#privacy-policy-our-global-operations</a></p>
            
            
            
            <h2>Conclusion</h2>
            
            
            
            <p>This policy regarding the processing of personal data is generated in accordance with the provisions of Regulation no. 679/2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, but also with the other applicable national legal provisions.</p>
            
            
            
            <p>We reserve the right to make any additions or changes to this policy. We recommend consulting the Policy regularly for correct and up-to-date information regarding the processing of personal data.</p>
            
            
            
            <p>For more details regarding this GDPR Policy, as well as to exercise any of the aforementioned rights, a written notification can be sent to the contact details indicated above.</p>




            </div>

            
    </section>



    <footer>
        <div class="footer-wrapper">
            <div class="footer-logo">
                <p>Developed by</p>
                <img src="../../../assets/media/logos/sigla_negative-02.png" alt="Novarion Logo" onclick="NewTabNovarion()">
            </div>
            <div class="footer1">
                <h5>Sales office</h5>
                <p class="footer1-bold">The Lake Home</p>
                <a href="https://goo.gl/maps/u8YrSsReceFjCgq37" target=”_blank”><p>Tractorului street, 2-12<br>Sibiu, Romania</p></a> 
                <div>
                    <span>T.</span><a href="tel:+40721229999">+40 721 229 999</a> <br><br>
                    <span>T.</span><a href="mailto:sales@thelake.ro">sales@thelake.ro</a>
                </div>
                <img src="../../../assets/media/pattern/brand_icon.svg" alt="">
            </div>
            <div class="footer2">
                <h5>Choose your residence</h5>
                <div class="footer2-rezidentie">
                    <div class="rezidentie1">
                        <a (click)="footerlinks1()">STUDIO</a> <br>
                        <a (click)="footerlinks2()">2 room apartment</a><br>
                        <a (click)="footerlinks3()">3 room apartment</a><br>
                        <a (click)="footerlinks4()">5 room apartment</a><br>
                    </div>
                    <!--
                    <div class="rezidentie2">
                        <a href="">VILA TIP A</a><br>
                        <a href="">VILA TIP B</a><br>
                        <a href="">VILA TIP C</a><br>
                    </div>-->
                </div>
                <div class="footer2-pages">
                    <div class="pages1">
                        <a (click)="seehome()">HOME</a> <br>
                        <a (click)="seelifestyle()">LIFESTYLE</a><br>
                        <a (click)="seelocatie()">LOCATION</a><br>
                    </div>
                    <div class="pages2">
                        <a href="https://thelake.ro/galerie-en/">GALLERY</a><br>
                        <a (click)="seemedia()">MEDIA</a><br>
                        <a (click)="seedezvoltator()">DEVELOPER</a><br>
                    </div>
                </div>
                <div class="footer2-socialMedia">
                    <img src="../../../assets/media/icons/facebook_logo_icon.svg" alt="Facebook link" onclick="NewTabFb()">
                    <img src="../../../assets/media/icons/linkedin_logo_icon.svg" alt="LinkedIn link" onclick="NewTabLi()">
                    <img src="../../../assets/media/icons/instagram_logo_icon.svg" alt="Instagram link" onclick="NewTabIg()">
                    <img src="../../../assets/media/icons/youtube_icon.svg" alt="Youtube link" onclick="NewTabYt()">
                </div>
                <div class="footer2-end">
                    <a (click)="seetermeni()">Privacy policy | Cookies</a>
                    <p>Brand & Web Design : <a href="https://www.mugurelfrincu.ro/" target="_blank"> Mugurel Frîncu</a></p>
                </div>
            </div>
            <div class="footer3">
                <h4>We will get back to you</h4>
                <p>Fill out the form below to get a fast reply from our sales representatives</p>
                <input type="text" placeholder="Name"[ngModelOptions]="{standalone: true}" [(ngModel)]="Name" required> 
                <input type="email" placeholder="Email"[ngModelOptions]="{standalone: true}" [(ngModel)]="Email" required>
                <input type="tel" placeholder="Phone"[ngModelOptions]="{standalone: true}" [(ngModel)]="Phone" required>
                <textarea id="mesaj" placeholder="Leave us a message"[ngModelOptions]="{standalone: true}" [(ngModel)]="Message"></textarea>
                <div class="privacylink3"><input (click)="checked3()" id="checkbox3" type="checkbox" required><label
                        class="form-control3">I agree with the <a id="ppLink3" (click)="seetermeni()"> data processing policy.</a></label></div>
                <h6 *ngIf="acestCamp2">This field is required</h6>
                <button class="deactive" type="button" (click)="sendFormOne3()">SEND</button>
            </div>
        </div> 
    </footer>

      
    





<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js"></script>
<script src="lifestyle.scripts.js"></script>    
</body>

<router-outlet></router-outlet>