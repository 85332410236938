<!-- Your Copy Button -->
<div style="width: 100%; margin: 40px auto; display: flex; justify-content: end;">
  <button (click)="copyContent()"
    style="margin-right: 60px; background-color: #2e862e; border-radius: 5px; border: none; color: #fff; font: 16px/20px Verdana; padding: 15px;">Copy
    to Clipboard from Mozilla</button>
</div>


<!-- Your Copy Button -->
<button (click)="copyContent2()">Copy from chrome</button>


<!-- Your HTML Content -->
<div #contentToCopy>




  <body style="font-family: Arial, sans-serif; margin: 0; padding: 40px 20px; background-color: #f4f4f4;">

    <div
      style="max-width: 600px; margin: auto; background: #ffffff; padding: 20px 20px 40px 20px; border-radius: 8px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);">

      <img style="max-width: 100%;" src="../../assets/media/email/headerBS2.png" alt="">


      <div class="content" style="padding: 10px 10px">


        <h1 style="color: #2BAED8; font: 22px Verdana"></h1>

        <p style="color: #707070; font: 14px/22px Verdana; padding-top: 20px;">
          Dragă ......., <br><br>
          
          Vă mulțumim sincer pentru participarea dvs. la tombola noastră exclusivă pentru un sejur la <span class="bold"
          style="color: #707070; font-weight: 600;">The&nbsp;Lake&nbsp;Home</span> în Sibiu. Deși nu ați fost selectat/ă ca unul dintre câștigătorii marelui premiu, apreciem angajamentul și interesul dvs. pentru proiectul nostru.<br><br>


          Dorim să vă oferim o altă formă de recunoștință pentru timpul dvs. Astfel, vă venim în întâmpinare cu un cupon special de <span class="bold"
          style="color: #707070; font-weight: 600;">100 de euro</span>, care echivalează cu aproximativ două zile de cazare la The Lake Home, pentru a vă putea bucura de ospitalitatea și frumusețea regiunii noastre.<br><br>

        </p>

        <div class="1img" style="margin: 20px auto; width: 100%;" align="center">
          <img src="../../assets/media/email/Voucher-TLH.png" alt="" style="width: 100%; margin: 0 auto;">
        </div>

        <p style="color: #707070; font: 14px/22px Verdana; padding-top: 20px;">Pentru a beneficia de această ofertă exclusivă, vă rugăm să ne contactați la numărul <a href="tel:+40759587024" style="color: #2BAED8; font: 14px/22px Verdana; cursor: pointer;">+40759587024 </a>pentru a stabili detaliile vizitei dvs.<br><br>


          În plus, suntem încântați să vă prezentam un voucher din partea partenerului nostru <span class="bold"
          style="color: #707070; font-weight: 600;">Iaconi Dental Clinic</span>, ce include 30% discount pentru o consultație și analiza funcțională a ocluziei și ATM, igienizare profesională, precum și albire profesională.<br><br>
</p>

        <!--<table border="0" cellpadding="0" cellspacing="0" width="100%" style="margin: 40px 0 20px;">
          <tr>
            <td align="center" width="100%" height="max-content">
              <!-- Replace 'thumbnail_image_url' with your hosted image URL and 'video_url' with the actual URL to the video.
              <a href="https://youtu.be/yXGSmjpsB2o" target="_blank">
                <img src="../../assets/media/email/thumbnailTest.png" alt="Video"
                  style="max-width:540px; width: 100%; height: auto; border:0; margin:0 auto; display:block;" />
              </a>
            </td>
          </tr>
        </table>

        <table border="0" cellpadding="0" cellspacing="0" width="100%" style="margin: 40px 0 20px;">
            <tr>
              <td align="center" width="50%">
                <img src="../../assets/media/email/2picstest1a.png" alt=""
                  style="max-width:540px; width: 100%; margin: 0 auto;">
              </td>
            </tr>
          </table>-->
          <div class="1img" style="margin: 20px auto; width: 100%;" align="center">
            <img src="../../assets/media/email/Voucher-Iaconi.png" alt="" style="width: 100%; margin: 0 auto;">
          </div>

        <p style="color: #707070; font:  14px/22px Verdana; padding-top: 20px; margin: 0;"> 

          Vă încurajăm să profitați de această oportunitate pentru a investi în sănătatea și strălucirea zâmbetului dvs., purtând amprenta profesionalismului oferit de <span class="bold"
          style="color: #707070; font-weight: 600;">Iaconi Dental Clinic.</span> Pentru a vă programa vizita la clinică, prezentând acest voucher, nu ezitați să luați legătura direct cu echipa lor de specialiști.<br><br>


          Ne dorim să vă vedem în curând, bucurându-vă de tot ce are de oferit <span class="bold"
          style="color: #707070; font-weight: 600;">The Lake Home</span> și partenerii noștri.<br><br>
          
          
          Cu respect și recunoștință, <br> Echipa The&nbsp;Lake&nbsp;Home <br><br>
        <p>


          

        <!--<table width="225px" cellspacing="0" cellpadding="0" border="0" align="center" style="margin: 40px auto 20px;">
          <tr>
            <td align="center" valign="middle" height="60px" bgcolor="#2BAED8"
              style="border-radius: 5px; cursor: pointer;">
              <a href="https://thelake.ro/event-form" target="_blank"
                style="font: 14px Verdana; color: #fff; text-decoration: none; line-height: 60px; display: block;">Vreau
                sa particip!</a>
            </td>
          </tr>
        </table>

        <!-- OLD CONTENT 
            
          <p style="color: #707070; font:  14px/20px Verdana; padding-top: 20px; margin: 0;">Suntem încântați să vă
            prezentăm un portofoliu diversificat de locuințe ce așteaptă să fie descoperite. Găsiți în atașamente o
            selecție specială a apartamentelor disponibile și ofertelor noastre financiare, concepute să se potrivească
            unei game largi de nevoi și așteptări.<br><br>
  
            Doriți să simțiți atmosfera și să vedeți detaliile care fac <span class="bold"
              style="color: #707070; font-weight: 600;">The Lake Home</span> atât de special? Nu ratați șansa de a
            programa o vizionare sau de a face un tur virtual folosind linkul de mai jos.
          <p>
  
            <!-- Images 
          <table width="100%" cellspacing="0" cellpadding="0" border="0" align="center" style="margin: 40px 0;">
            <tr>
              <td width="100%" align="center" valign="top">
                <img src="../../assets/media/email/2picstest.png" alt=""
                  style="max-width:540px; width: 100%; margin: 0 auto;">
              </td>
            </tr>
          </table>
  
          <!-- Button 
          <table width="225px" cellspacing="0" cellpadding="0" border="0" align="center" style="margin: 20px auto;">
            <tr>
              <td align="center" valign="middle" height="60px" bgcolor="#2BAED8"
                style="border-radius: 5px; cursor: pointer;">
                <a href="https://my.matterport.com/show/?m=oz7L1ckczHp" target="_blank"
                  style="font: 14px Verdana; color: #fff; text-decoration: none; line-height: 60px; display: block;">Tur
                  virtual</a>
              </td>
            </tr>
          </table>
  
  
          <p style="color: #707070; font: 14px/20px Verdana; padding-top: 20px; margin: 0;">Mai mult, dacă sunteți
            interesați de planul nostru de investiții, avem plăcerea de a vă îndruma către următoarele resurse: </p>
          <br>
  
          <table width="100%" cellspacing="0" cellpadding="0" border="0" align="center" style="margin: 20px 0;">
            <tr>
              <td align="center">
                <img src="../../assets/media/email/airbnbBillboard.png" alt="" style="max-height: 365px; width: 100%;">
              </td>
            </tr>
          </table>
  
  
  
          <div class="btn1"
            style="display: flex; text-align: center; width: 225px; height: 60px; margin: 20px auto; cursor: pointer;">
            <a href="https://thelake.ro/optiuni-de-investitie/ro"
              style="background-color: #2BAED8; border-radius: 5px; border: none; color: #fff; font: 14px Verdana; width: 225px; height: 60px; display: block; line-height: 60px; text-align: center; text-decoration: none;">Vezi
              detalii</a>
          </div>




        <p style="color: #707070; font: 14px/20px Verdana; padding-top: 20px; margin: 0;"> Nu ratați șansa de a vă
          bucura de ospitalitatea noastră și de frumusețea regiunii Sibiu.

          Vă mulțumim pentru suportul și angajamentul continuu față de Novarion și sperăm să vă vedem curând la The Lake
          Home! Mult succes tuturor participanților!


          <br><br>
          O zi excelentă vă dorim!
        </p>-->

        <div class="1img" style="margin: 20px auto; width: 100%; " align="center">
          <img src="../../assets/media/email/sibiu-footer.png" alt="" style="width: 100%; margin: 0 auto; border-radius: 0 30px;">
        </div>

        <table width="100%" cellspacing="0" cellpadding="0" border="0" align="center" style="margin: 20px 0;">
          <tr>
            <td align="right" width="50%" style="padding-right: 12.5%;">
              <a href="https://goo.gl/maps/u8YrSsReceFjCgq37" target="_blank"
                style="color: #707070; font: 12px/22px Verdana; cursor: pointer; text-decoration: none;">
                Str. Tractorului,<br>nr. 2-12, Sibiu
              </a>
            </td>
            <td align="left" width="50%" style="padding-left: 12.5%;">
              <a href="tel:+40721229999"
                style="color: #707070; font: 12px/22px Verdana; cursor: pointer; text-decoration: none;">
                +40 721 229 999
              </a>
              <br>
              <a href="mailto:sales@thelake.ro"
                style="color: #707070; font: 12px/22px Verdana; cursor: pointer; text-decoration: none;">
                sales@thelake.ro
              </a>
            </td>
          </tr>
        </table>



        <footer style="margin-top: 20px auto; border-top: 2px solid #2BAED8; padding-top: 10px; text-align: center;">

          <div class="sm" style="width: 80%; margin: 20px auto 0;" align="center">
            <a href="https://www.facebook.com/thelakehome" style="width: 32.44px; cursor: pointer; margin-right: 15px;">
              <img src="../../../assets/media/email/facebook_logo_icon.png" alt="Facebook link" onclick="NewTabFb()">
            </a>
            <a href="https://www.linkedin.com/company/the-lake-home-living-xperience/"
              style="width: 32.44px; cursor: pointer; margin-right: 15px;">
              <img src="../../../assets/media/email/linkedin_logo_icon.png" alt="LinkedIn link" onclick="NewTabLi()">
            </a>
            <a href="https://www.instagram.com/thelakehomelx/"
              style="width: 32.44px; cursor: pointer; margin-right: 15px;">
              <img src="../../../assets/media/email/instagram_logo_icon.png" alt="Instagram link" onclick="NewTabIg()">
            </a>
            <a href="https://www.youtube.com/@thelakehome-livingxperienc6927" style="width: 32.44px; cursor: pointer;">
              <img src="../../../assets/media/email/youtube_icon.png" alt="Youtube link" onclick="NewTabYt()">
            </a>
          </div>

          <p style="color: #707070; font: 12px/22px Verdana; padding-top: 20px; margin: 0 auto;" align="center">Aflați
            mai multe despre
            proiectul nostru pe <a href="https://www.thelake.ro" target="_blank"
              style="color: #707070; font: 12px/22px Verdana; cursor: pointer; font-weight: 600; text-decoration: none;">www.thelake.ro</a>
          </p>
        </footer>

      </div>


      <!--<table width="60%" border="0" align="center" style="margin-top: 30px;">
          <tr>
            <td align="center">
              <a href="mailto:sales@thelake.ro?subject=Sunt%20interesat&body=Voi%20verifica%20și%20vă%20trimit%20un%20răspuns%20în%20curând"
                style="text-decoration: none;">
                <div
                  style="color: #707070; font: 12px/22px Verdana; background-color: #cccccc; border-radius: 10px; padding: 10px; position: relative;">
                  Voi verifica și vă trimit un răspuns în&nbsp;curând
                  <div
                    style="position: absolute; bottom: -8px; left: 10px; width: 0; height: 0; border-left: 0 solid transparent; border-right: 16px solid transparent; border-top: 8px solid #cccccc;">
                  </div>
                </div>
              </a>
            </td>
          </tr>
          <tr>
            <td style="height: 30px;"></td>
          </tr>
          <tr>
            <td align="center">
              <a href="mailto:sales@thelake.ro?subject=Sunt%20interesat&body=Doresc%20să%20fiu%20contactat%20telefonic%20în%20următoarele%2024%20ore"
                style="text-decoration: none;">
                <div
                  style="color: #707070; font: 12px/22px Verdana; background-color: #cccccc; border-radius: 10px; padding: 10px; position: relative;">
                  Doresc să fiu contactat telefonic în următoarele 24&nbsp;ore
                  <div
                    style="position: absolute; bottom: -8px; left: 10px; width: 0; height: 0; border-left: 0 solid transparent; border-right: 16px solid transparent; border-top: 8px solid #cccccc;">
                  </div>
                </div>
              </a>
            </td>
          </tr>
          <tr>
            <td style="height: 30px;"></td>
          </tr>
          <tr>
            <td align="center">
              <a href="mailto:sales@thelake.ro?subject=Sunt%20interesat&body=Doresc%20sa%20să%20programez%20o%20vizionare"
                style="text-decoration: none;">
                <div
                  style="color: #707070; font: 12px/22px Verdana; background-color: #cccccc; border-radius: 10px; padding: 10px; position: relative;">
                  Doresc sa să programez o&nbsp;vizionare
                  <div
                    style="position: absolute; bottom: -8px; left: 10px; width: 0; height: 0; border-left: 0 solid transparent; border-right: 16px solid transparent; border-top: 8px solid #cccccc;">
                  </div>
                </div>
              </a>
            </td>
          </tr>
        </table>
  
        <table width="90%" border="0" align="center" style="margin-top: 30px;">
          <tr>
            <td align="center">
                <a href="https://doodle.com/bp/raultarnaru/investitii-imobiliare-the-lake-home-sibiu" target="_blank"
                style="text-decoration: none;">
                <div
                  style="color: #707070; font: 12px/22px Verdana; background-color: #cccccc; border-radius: 10px; padding: 10px; position: relative;">
                  Programați o întâlnire cu Raul Țărnaru
                  <div
                    style="position: absolute; bottom: -8px; left: 10px; width: 0; height: 0; border-left: 0 solid transparent; border-right: 16px solid transparent; border-top: 8px solid #cccccc;">
                  </div>
                </div>
              </a>
            </td>
            <td style="width: 20px;"></td>
            <td align="center">
              <a href="https://thelake.ro/invest"
                style="text-decoration: none;">
                <div
                  style="color: #707070; font: 12px/22px Verdana; background-color: #cccccc; border-radius: 10px; padding: 10px; position: relative;">
                  Investește Inteligent - descoperă oferta
                  <div
                    style="position: absolute; bottom: -8px; left: 10px; width: 0; height: 0; border-left: 0 solid transparent; border-right: 16px solid transparent; border-top: 8px solid #cccccc;">
                  </div>
                </div>
              </a>
            </td>
            <td style="width: 20px;"></td>
            <td align="center">
              <a href="https://thelake.ro/"
                style="text-decoration: none;">
                <div
                  style="color: #707070; font: 12px/22px Verdana; background-color: #cccccc; border-radius: 10px; padding: 10px; position: relative;">
                  Descoperă The Lake Home
                  <div
                    style="position: absolute; bottom: -8px; left: 10px; width: 0; height: 0; border-left: 0 solid transparent; border-right: 16px solid transparent; border-top: 8px solid #cccccc;">
                  </div>
                </div>
              </a>
            </td>
          </tr>
          <tr>
            <td style="height: 30px;"></td>
          </tr>
          <tr>
  
          </tr>
          <tr>
            <td style="height: 30px;"></td>
          </tr>
          <tr>
  
          </tr>
        </table>-->

    </div>

  </body>


</div>