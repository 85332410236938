import { Component, OnInit } from '@angular/core';
import {SharedService} from 'src/app/shared.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.css']
})
export class FormsComponent implements OnInit {

  
  constructor(private service:SharedService, private router: Router) { }

  ToolList:any=[];

  ModalTitle!: string;
  ActivateAddEditTolComp:boolean=false;
  tol:any;

  ToolNameFilter:string="";
  UserFilter:string="";
  ToolIdFilter:string="";

  Pieces:string="";
  MainLocation:string="";

  ToolListWithoutFilter:any=[];
  ToolListWithoutFilterUser:any=[];
  Password:boolean=true;
  brokenTool:boolean=false;
  WorkingTool:boolean=false;
  
  ActivateServiceTolComp:boolean=false;
  ActivateServiceInfoComp:boolean=false;


  ActivateGuveToolToUser:boolean=false;
  ActivateTakeToolFromUser:boolean=false;

  senha!: string;
  pingood:boolean=false;
  pinin:boolean=true;




  ngOnInit(): void {
    window.scrollTo(0, 0)

    this.refreshTolList();
  }

  adminsenha(){
    if(this.senha == "1Novarion2"){
    this.pingood=true
    this.pinin = false}
    else{
      alert("gresit")
    }
  }

  addClick(){
    this.tol={
      BuildingId:0,
      Name:"",
      Email:"",
      Phone:"",
      Message:"",
      FormLocation:"",
      DateOfGiving:"",
    }
    this.ModalTitle="Adauga";
    this.ActivateAddEditTolComp=true;

  }


  

  deleteClick(item: { BuildingId: any; }){
    let input = prompt('Please enter the id ');
  
    // check if input matches BuildingId
    if(input == item.BuildingId){
      if(confirm('Are you sure??')){
        this.service.deleteform(item.BuildingId).subscribe(data=>{
          (data.toString());
          this.refreshTolList();
        })
        this.refreshTolList();
      }
    }
    // if input does not match BuildingId, check if it matches the last Name
    else {
      this.service.getformList().subscribe(data=>{
        let lastForm = data[data.length - 1];
        if(input == lastForm.Name) {
          let matchingForms = data.filter(i => i.Name === input);
          matchingForms.sort((a, b) => a.BuildingId - b.BuildingId);
          console.log(matchingForms.map(i => i.BuildingId));
        }
        else {
          console.log('succes')
        }
      });
    }
  }
  
  

  closeClick(){
    this.ActivateAddEditTolComp=false;
    this.ActivateGuveToolToUser=false;
    this.ActivateServiceTolComp=false;
    this.ActivateServiceInfoComp=false;
    this.ActivateTakeToolFromUser=false;
    this.refreshTolList();
  }

  refreshTolList(){
    this.service.getformList().subscribe(data=>{
      this.ToolList=data.filter(item => item.Name !== 'blog');
      this.ToolListWithoutFilter=data.filter(item => item.Name !== 'blog');
      this.ToolListWithoutFilterUser=data.filter(item => item.Name !== 'blog');
    });
  }
  

  

  FilterFn(){
    
    var ToolNameFilter = this.ToolNameFilter;

    this.ToolList = this.ToolListWithoutFilter.filter(function (el: { ToolId: 
      { toString: () => string; }; ToolName: { toString: () => string; }; }){
      return el.ToolName.toString().toLowerCase().includes(
        ToolNameFilter.toString().trim().toLowerCase()
      )
    });
  }

  seeforms(){
    this.router.navigateByUrl('/1admin1')
  }
}

