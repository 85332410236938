<div id="carouselExampleControls3" class="carousel slide" data-ride="carousel" data-interval="false">
    <!--<div class="carousel-inner" id="testm-inner">
        <div class="carousel-item active">
            <p class="testimonial-text">Am fost atras de caracteristicile acestui proiect imobiliar, faptul că
                există multă grijă față de locatari, le sunt oferite multiple variante de recreere. Potențialul
                acestui proiect este imens, de aceea am ales să achiziționez un apartament cu scop de investiție
                în primul bloc.</p>
            <p class="name-testimonial">Ștefan Savu</p>
            <p class="age-city">22 ANI - SIBIU</p>
        </div>
        <div class="carousel-item">
            <p class="testimonial-text">Consider că este o alegere perfectă pentru mine, având în vedere toate
                avantajele. Îmi place priveliștea, munții se văd frumos în depărtare și lacul este fain de
                aproape. Este suprinzător de simplu să ajungi în diverse puncte din oraș, conectivitatea e bună.
            </p>
            <p class="name-testimonial">George Vesa</p>
            <p class="age-city">24 ANI - SIBIU</p>
        </div>
        <div class="carousel-item">
            <p class="testimonial-text">Construcția este realizată la o calitate superioară față de alte
                blocuri, finisajele exterioare și interioare sunt de apreciat. Îmi place spațiul extins, faptul
                că apartamentul este luminos, și că totul te face să te simți bine. Până și casa scărilor este
                minuțios realizată, e senzațională.</p>
            <p class="name-testimonial">Răzvan Staroste</p>
            <p class="age-city">38 ANI - SIBIU</p>
        </div>
    </div>-->
    <div class="carousel-inner" id="testm-inner">
        <div class="carousel-item active">
            <iframe src="https://www.youtube.com/embed/E-GwAN8k8Tc?si=McN8n5MNYc82HLZ6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="carousel-item">
            <iframe src="https://www.youtube.com/embed/sunYGHgGG0A?si=rMrhuaTuZD2ezsPq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="carousel-item">
            <iframe src="https://www.youtube.com/embed/6FEBHJVh8uk?si=Geygc9XLRrQKiXqk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="carousel-item">
            <iframe src="https://www.youtube.com/embed/0VaWq5bbiDA?si=6siku2Z8OcWDY7sw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="carousel-item">
            <iframe src="https://www.youtube.com/embed/dGN0ISBpzBk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="carousel-item">
            <iframe src="https://www.youtube.com/embed/WMvSXo4AM9U" title="YouTube video player" frameborder="0" allow="encrypted-media; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="carousel-item">
            <iframe src="https://www.youtube.com/embed/9kgNFTFkvRo" title="YouTube video player" frameborder="0" allow="encrypted-media; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="carousel-item">
            <iframe src="https://youtube.com/embed/Kqv1PDt_4Gg?feature=share" title="YouTube video player" frameborder="0" allow="encrypted-media; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="carousel-item">
            <iframe src="https://youtube.com/embed/8_GXUjstEw8?feature=share" title="YouTube video player" frameborder="0" allow="encrypted-media; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <!-- <div class="carousel-item">
            <iframe src="https://www.youtube.com/embed/BNACvumbcl8" title="YouTube video player" frameborder="0" allow="encrypted-media; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>  --> 
    </div>
    <div class="slider-controls-Testm">
        <span id="testm-slide-prev" class="material-symbols-outlined left-btn-testm"
            href="#carouselExampleControls3" role="button" data-slide="prev">arrow_circle_left</span>
        <span id="testm-slide-next" class="material-symbols-outlined right-btn-testm"
            href="#carouselExampleControls3" role="button" data-slide="next">arrow_circle_right</span>
    </div>
</div>