<div class="d-flex flex-row bd-highlight mb-3">

    <div class="form-froup row" style="width: 60%;">
    
        <label class="col-sm-2 col-form-label">Ce cladire</label>
        <div class="col-sm-10">
            <input type="text" class="form-control" [(ngModel)]="BuildingName"
            placeholder=""> <br>
        </div>


    
        <label class="col-sm-2 col-form-label">etaj</label>
        <div class="col-sm-10">
            <input type="text" class="form-control" [(ngModel)]="BuildingFloor"
            placeholder="">
        </div><br>
    


        <label class="col-sm-2 col-form-label">numar apartament</label>
        <div class="col-sm-10">
            <input type="text" class="form-control" [(ngModel)]="ApartmentNumb"
            placeholder="">
        </div><br>
    


        <label class="col-sm-2 col-form-label">suprafata</label>
        <div class="col-sm-10">
            <input type="text" class="form-control" [(ngModel)]="ApartmentSurface"
            placeholder="">
        </div><br>

        <label class="col-sm-2 col-form-label">camere</label>
        <div class="col-sm-10">
            <input type="text" class="form-control" [(ngModel)]="ApartmentRooms"
            placeholder="">
        </div><br>

        <label class="col-sm-2 col-form-label">pret</label>
        <div class="col-sm-10">
            <input type="text" class="form-control" [(ngModel)]="ApartmentPrice"
            placeholder="">
        </div><br>
        <label class="col-sm-2 col-form-label">suprafata balcon</label>
        <div class="col-sm-10">
            <input type="text" class="form-control" [(ngModel)]="ApartmentBalconSurface"
            placeholder="">
        </div><br>
        <label class="col-sm-2 col-form-label">orientare</label>
        <div class="col-sm-10">
            <input type="text" class="form-control" [(ngModel)]="ApartmentOrentation"
            placeholder="">
        </div><br>
        <label class="col-sm-2 col-form-label">numar de bai</label>
        <div class="col-sm-10">
            <input type="text" class="form-control" [(ngModel)]="ApartmentBathrooms"
            placeholder="">
        </div><br>
    


        <label class="col-sm-2 col-form-label">suprafata dormitor</label>
        <div class="col-sm-10">
            <input type="text" class="form-control" [(ngModel)]="SurfaceDormitor"
            placeholder="">
        </div><br>
        <label class="col-sm-2 col-form-label">sup. baie</label>
        <div class="col-sm-10">
            <input type="text" class="form-control" [(ngModel)]="SurfaceBaie"
            placeholder="">
        </div><br>
        <label class="col-sm-2 col-form-label">sup. hol</label>
        <div class="col-sm-10">
            <input type="text" class="form-control" [(ngModel)]="SurfaceHol"
            placeholder="">
        </div><br>
        <label class="col-sm-2 col-form-label">sup room?</label>
        <div class="col-sm-10">
            <input type="text" class="form-control" [(ngModel)]="SurfaceRoom"
            placeholder="">
        </div><br>
        <label class="col-sm-2 col-form-label">Nume PDF</label>
        <div class="col-sm-10">
            <input type="text" class="form-control" [(ngModel)]="ApartmentPDF"
            placeholder="">
        </div><br>



    <p>adauga poza plan apartament</p>
    <div  style="width: 40%;">
        <img [src]=PhotoFilePath height="250px;" width="200px;">
        <input type="file" (change)="uploadPhoto($event)" class="mt-2"/>
    </div>


    
    
    
    <button (click)="addEmployee()" *ngIf="adm.BuildingId==0" class="btn btn-primary">
        Add
    </button>
    
    <button (click)="updateEmployee()" *ngIf="adm.BuildingId!=0" class="btn btn-primary">
        Update
    </button>