import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

import { ScreenshotService } from 'src/app/screenshot.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import { ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-lp-checklist',
  templateUrl: './lp-checklist.component.html',
  styleUrls: ['./lp-checklist.component.css']
})
export class LpChecklistComponent implements OnInit {

  constructor(
    private service: SharedService,
    private router: Router,
    private datePipe: DatePipe,
    private screenshotService: ScreenshotService
  ) { }

  easyversion = true;
  complexversion = false;

  Name!: string;
  Name2!: string;
  Phone!: string;
  Phone2!: string;
  Email!: string;
  Email2!: string;
  acestCamp2:boolean=false;
  checkmark:boolean=false;
  checkmarckCheck!: number;
  checkmark1:boolean=false;
  checkmarckCheck1!: number;
  checkmark2:boolean=false;
  checkmarckCheck2!: number;
  checkmark3:boolean=false;
  checkmarckCheck3!: number;

  ngOnInit(): void {
    /*window.scrollTo(0, 0);
   if(this.service.previewChecklistData == false){
      this.router.navigateByUrl('/alegecasaperfecta')
    }*/

  this.checkmarckCheck2=1
  this.checkmarckCheck3=1

  }

  seehome(): void {
    this.router.navigateByUrl('/');
  }

  scroll(el: HTMLElement): void {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  //FORM 
  seetermeni(){
    this.router.navigateByUrl('/termeni-si-conditii')
  }
  checked3(){

    this.checkmarckCheck2= this.checkmarckCheck2 +1;

    if(this.checkmarckCheck2 % 2 === 0 ){
    this.checkmark2 = true;
  
    const modalbody=document.querySelector('#descarca');
    modalbody!.className = "";
    console.log(modalbody!.className)
    }
    else{
      this.checkmark1 = false;
  
      const modalbody=document.querySelector('#descarca');
      modalbody!.className = "deactive";
      console.log(modalbody!.className)
    }

  }
  checked4(){

    this.checkmarckCheck3= this.checkmarckCheck3 +1;

    if(this.checkmarckCheck3 % 2 === 0 ){
    this.checkmark3 = true;
  
    const modalbody=document.querySelector('#descarca2');
    modalbody!.className = "";
    console.log(modalbody!.className)
    }
    else{
      this.checkmark3 = false;
  
      const modalbody=document.querySelector('#descarca2');
      modalbody!.className = "deactive";
      console.log(modalbody!.className)
    }

  }




  go_to_complex(): void {
    this.easyversion = false;
    this.complexversion = true;
    console.log(this.easyversion, this.complexversion);
  }
  go_to_easy(): void {
    this.easyversion = true;
    this.complexversion = false;
    console.log(this.easyversion, this.complexversion);
  }

   generatePDF() {
    this.BucketDate = new Date();
    this.DateOfGiving = (this.datePipe.transform(this.BucketDate,"yyyy-MM-dd"));  
    var val = {BuildingId:this.BuildingId,
      Name:this.Name,
      Phone:this.Phone,
      Email:this.Email,
      Message:'alege casa favorita',
      FormLocation:'alege casa favorita',
      DateOfGiving:this.DateOfGiving,};
  
    this.service.addform(val).subscribe(res=>{
      console.log(res.toString());

    });

    this.sendFormOne3();
    this.router.navigateByUrl('/descarcaformular' )
    
  }
  BuildingId!: string;
  intari!: string;
  DateOfGiving!: any;
  BucketDate!: Date;

  sendFormOne3(){
    this.BucketDate = new Date();
    this.DateOfGiving = (this.datePipe.transform(this.BucketDate,"yyyy-MM-dd"));  
    this.intari=this.service.checklist1 + this.service.checklist2;
    console.log(this.DateOfGiving)
    var val = {BuildingId:this.BuildingId,
      Name:'formular',
      Phone:'000',
      Email:' completat de cineva pe pagina noastra',
      Message: this.intari,
      FormLocation:'alege casa favorita',
      DateOfGiving:this.DateOfGiving,};
  
    this.service.addform(val).subscribe(res=>{
      console.log(res.toString());
      this.service.previewChecklistData = true;


    });
    
  }

  


  

  
}
