import { ThisReceiver } from '@angular/compiler';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, HostListener } from '@angular/core';
import { SharedService } from 'src/app/shared.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { PDFDocument } from 'pdf-lib';
import { rgb } from 'pdf-lib'; // Import the rgb function from pdf-lib

@Component({
  selector: 'app-oferta',
  templateUrl: './oferta.component.html',
  styleUrls: ['./oferta.component.css']
})
export class OfertaComponent implements OnInit {

  constructor(private service: SharedService, private router: Router, private datePipe: DatePipe) { }

  public expiryDate!: Date;

  price: number = 0;  // initializing to ensure it's a number
  finalPrice: number = 0;

  BuildingList: any = [];
  public username: string = "";
  public password: string = "";
  public isLoggedIn: boolean = false;

  public selectedBuilding: string = '';
  public apartmentNumber: string = '';
  public personName: string = '';
  public paymentMethod: string = '';
  public parking: string = '';

  public observations: string = '';

  paymentMethods: number[] = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100];
  selectedPayment: number = 0; // Default value

  parkingOptions = [
    { label: 'da', value: 5000 },
    { label: 'nu', value: 0 },
    { label: ' ', value: 1 },
  ];

  parcaredanu: string = "nu";
  selectedParkingValue: number = 1;  // Default value
  
  selectedPaymentValue: number = 0;  // Default for Metodă plată
  discount: number = 0;  // Default value for Discount

  tvaOptions = [
    { label: 'NO', value: 0 },
    { label: 'YES 5%', value: 5 },
    { label: 'YES 19%', value: 19 }
  ];
  selectedTvaValue: number = 0;  // Default value
  public parcare: string = "";

  allApartments: any[] = [];  // This will store all the apartments
  filteredApartments: any[] = [];
  
  public ApartmentPDF: string = '';

  avansPercentage: number = 0; // to store the avans percentage
  plataRamasa: number = 0; // to store the calculated remaining amount


  ngOnInit(): void {
    this.refreshBldList();
    this.refreshApartmentList();
    this.calculateFinalPrice();
  }
  downPaymentAmount: number = 0;

  updateDiscount() {
    if (this.price > 0) {
      // Calculate the down payment percentage
      const downPaymentPercentage = (this.downPaymentAmount / this.price) * 100;
  
      // Calculate the discount based on this percentage
      this.discount = parseFloat((downPaymentPercentage / 10).toFixed(2));  // rounded to two decimal places  // you can adjust this formula as needed
    }
  }
  



  createOffer(): void {
    // Logic for creating and submitting the offer
    console.log({
      selectedBuilding: this.selectedBuilding,
      apartmentNumber: this.apartmentNumber,
      personName: this.personName,
      price: this.price,
      paymentMethod: this.paymentMethod,
      parking: this.parking,
      discount: this.discount,
      finalPrice: this.finalPrice,
      observations: this.observations
    });

    // You can integrate API calls or any logic here to process the offer.
  }
  refreshApartmentList() {
    this.service.getBuildingList().subscribe(data => {
        this.allApartments = data;
    });
}
  refreshBldList(): void {
    this.service.getBuildingList().subscribe(data => {
      this.BuildingList = data;

      // Assuming that the last building in the list is the most recent one.
      // Check if the list is not empty and log the ApartmentPrice of the last building.
      if (this.BuildingList.length > 0) {
        console.log("ApartmentPrice of the last building:", this.BuildingList[this.BuildingList.length - 1].ApartmentPrice);
      }
    });
  }
  onSubmit(): void {
    // Check for hardcoded username and password
    if (this.username === "raul.tarnaru" && this.password === "1234") {
      this.isLoggedIn = true; // Update the logged-in status
    } else {
      alert("Invalid credentials! Please try again.");
    }
  }



// Add this function to filter apartments based on selected building
filterBuildingApartments() {
  console.log("Filtering for building: ", this.selectedBuilding);
  console.log("All apartments: ", this.allApartments);
  this.filteredApartments = this.allApartments.filter(apartment => 
    apartment.BuildingName === this.selectedBuilding
  );
  console.log("Filtered apartments: ", this.filteredApartments);
}

// Update the existing function
filterApartments() {
  if (this.selectedBuilding && this.apartmentNumber) {
    const foundApartment = this.filteredApartments.find(apartment => 
      apartment.ApartmentNumb === this.apartmentNumber
    );
    
    if (foundApartment) {
      this.price = foundApartment.ApartmentPrice;
      this.ApartmentPDF = foundApartment.ApartmentPDF;  // Set the ApartmentPDF property here
    
      // Update the discount based on the down payment
      this.updateDiscount();
    
      // Update the final price
      this.calculateFinalPrice();
    
    } else {
      this.price = 0;  // Reset if not found
      this.ApartmentPDF = '';  // Reset if not found
    }
  }
}
  
roundToTwoDecimals(num: number): number {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}


calculateFinalPrice() {
    
  // Ensure all values are treated as numbers
  const basePrice = +this.price;
  const parkingValue = +this.selectedParkingValue;
  
  // Update the value of this.parking based on selectedParkingValue
  this.parking = this.parkingOptions.find(opt => opt.value === this.selectedParkingValue)?.label || '';

  // First, add the parking value to the base price
  const totalPriceWithoutDiscount = this.roundToTwoDecimals(basePrice + parkingValue);
  
  // Calculate the discount amount on this total
  const discountAmount = this.roundToTwoDecimals((this.discount / 100) * totalPriceWithoutDiscount);
  
  // Now subtract the discount
  const priceAfterDiscount = this.roundToTwoDecimals(totalPriceWithoutDiscount - discountAmount);
  
  // Now calculate TVA on this amount after discount
  const tvaValue = +this.selectedTvaValue;
  let tvaAmount = 0;
  if (tvaValue) {
    tvaAmount = this.roundToTwoDecimals((tvaValue / 100) * priceAfterDiscount);
  }
  
  // Assign calculated price to finalPrice
  this.finalPrice = this.roundToTwoDecimals(priceAfterDiscount + tvaAmount);
  
  // Calculate avans (down payment) amount based on the selectedPayment
  const avansAmount = this.roundToTwoDecimals((this.selectedPayment / 100) * this.finalPrice);

  // Check if the down payment is 100%
  if (this.selectedPayment === 100) {
    this.plataRamasa = 0;
  } else {
    // Calculate plata ramasa (remaining payment)
    this.plataRamasa = this.roundToTwoDecimals(this.finalPrice - avansAmount);
  }
  
  if(this.selectedParkingValue==5000){
    this.parcare='da'
  }
  else{
    this.parcare='nu'
  }
  console.log(this.parcare);
}






async DescarcaPlanRate() {
  
  if(this.selectedParkingValue === 5000){
    this.parcaredanu='da'
  }
  else{
    this.parcaredanu='nu'
  }

  const existingPdfBytes = await fetch("../../../assets/pdf/ApartmentsPDF/" + this.ApartmentPDF).then(res => res.arrayBuffer());

  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const pages = pdfDoc.getPages();
  const firstPage = pages[0];
  const { width, height } = firstPage.getSize();

  // Add new page with the content

  
  const newPage = pdfDoc.addPage([width, height]);
  
  const titleColor = rgb(31 / 255, 48 / 255, 130 / 255);
  const textColor = rgb(0, 0, 0);
  
  // Load the font using fetch and Blob
const fontUrl = "../../../assets/fonts/Bicyclette-Thin.woff";
const fontResponse = await fetch(fontUrl);
const fontBuffer = await fontResponse.arrayBuffer();


  // Customize these coordinates based on where you want the text in the page
  newPage.drawText('Nume persoana,', {
    x: 65,
    y: height - 115,
    size: 13,
    color: titleColor,
  });

  newPage.drawText('Va prezentam mai jos detaliile ofertei personalizate pe care', {
    x: 65,
    y: height - 140,
    size: 13,
      color: textColor
  });

  newPage.drawText('am creat-o special pentru dumneavoastra.', {
    x: 65,
    y: height - 160,
    size: 13,
      color: textColor
  });

  const headerBytes = await fetch("../../../assets/media/ofertaPDF/titlePDF2.png").then(res => res.arrayBuffer());
  const patternBytes = await fetch("../../../assets/media/ofertaPDF/patternPDF.png").then(res => res.arrayBuffer());

  // Draw the image on the header of each page
  for (const page of pdfDoc.getPages()) {
    const { width } = page.getSize();
    const image = await pdfDoc.embedPng(headerBytes);

    newPage.drawImage(image, {
      x: width - 567, // Adjust the x-coordinate to position the image as needed
      y: 773,          // Adjust the y-coordinate to position the image as needed
      width: 210,      // Adjust the width of the image
      height: 40,     // Adjust the height of the image
    });
  }

   // Draw the image on the pattern of each page
   for (const page of pdfDoc.getPages()) {
    const { width } = page.getSize();
    const image = await pdfDoc.embedPng(patternBytes);

    newPage.drawImage(image, {
      x: width - 170, // Adjust the x-coordinate to position the image as needed
      y: 200,          // Adjust the y-coordinate to position the image as needed
      width: 170,      // Adjust the width of the image
      height: 450,     // Adjust the height of the image
    });
  }


  newPage.drawText('Viitor propietar: ' + this.selectedBuilding,  {
    x: 65,
    y: height - 210,
    size: 13,
    color: textColor
});

  newPage.drawText('Cladirea: ' + this.selectedBuilding,  {
    x: 65,
    y: height - 245,
    size: 13,
    color: textColor
});

newPage.drawText('Numar Apartament: ' + this.apartmentNumber,{
    x: 65,
    y: height - 280,
    size: 13,
    color: textColor
});

newPage.drawText('Data expirare oferta: ' + this.datePipe.transform(this.expiryDate, 'dd/MM/yyyy'),{
    x: 65,
    y: height - 315,
    size: 13, 
    color: textColor
});

newPage.drawText('Parcare: ' + this.parcaredanu, {
    x: 65,
    y: height - 350,
    size: 13, 
    color: textColor
});

newPage.drawText('Plata avans: ' + this.downPaymentAmount + '€', {
    x: 65,
    y: height - 385,
    size: 13, 
    color: textColor
});

newPage.drawText('Discount: ' + this.discount + '%', {
    x: 65,
    y: height - 420,
    size: 13, 
    color: textColor
});

newPage.drawText('TVA: ' + this.selectedTvaValue + '%',  {
    x: 65,
    y: height - 455,
    size: 13, 
    color: textColor
});

newPage.drawText('Plata Ramasa: €' + this.plataRamasa.toFixed(2), {
    x: 65,
    y: height - 490,
    size: 13, 
    color: textColor
});

newPage.drawText('Modalitate Plata: ' + this.selectedModalitatePlata, {
    x: 65,
    y: height - 525,
    size: 13, 
    color: textColor
});

newPage.drawText('Cate Rate: ' + (this.cateRate || 'REPLACE VARIABLE I DONT KNOW IT'), {
    x: 65,
    y: height - 560,
    size: 13, 
    color: textColor
});

newPage.drawText( 'Timp Plata Rate: ' + this.timpPlataRate,   {
    x: 65,
    y: height - 595,
    size: 13,   
    color: textColor
});
newPage.drawText( 'Valoare Rata: € ' + this.valoareRata.toFixed(2) + ' Lunar', {
  x: 65,
  y: height - 630,
  size: 13, 
  color: textColor
});
newPage.drawText(    'Observatii: ' + this.observations,{
  x: 65,
  y: height - 665,
  size: 13, 
  color: textColor
});

  const imageBytes = await fetch("../../../assets/media/ofertaPDF/footerPDF.png").then(res => res.arrayBuffer());

  // Draw the image on the footer of each page
  for (const page of pdfDoc.getPages()) {
    const { width } = page.getSize();
    const image = await pdfDoc.embedPng(imageBytes);

    newPage.drawImage(image, {
      x: width - 565, // Adjust the x-coordinate to position the image as needed
      y: 20,          // Adjust the y-coordinate to position the image as needed
      width: 540,      // Adjust the width of the image
      height: 60,     // Adjust the height of the image
    });
  }

  const modifiedPdfBytes = await pdfDoc.save();

  // Use the bytes to facilitate download
  const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = 'modified_apartment.pdf';
  link.click();

}



async DescarcaPlanAltfel() {
  
  if(this.selectedParkingValue ==5000){
    this.parcaredanu='da'
  }
  else{
    this.parcaredanu='nu'
  }
  console.log(this.selectedParkingValue)
  console.log(this.parcaredanu)

  const existingPdfBytes = await fetch("../../../assets/pdf/ApartmentsPDF/" + this.ApartmentPDF).then(res => res.arrayBuffer());

  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const pages = pdfDoc.getPages();
  const firstPage = pages[0];
  const { width, height } = firstPage.getSize();

  // Add new page with the content

  
  const newPage = pdfDoc.addPage([width, height]);
  
  const titleColor = rgb(31 / 255, 48 / 255, 130 / 255);
  const textColor = rgb(0, 0, 0);
  
  // Load the font using fetch and Blob
const fontUrl = "../../../assets/fonts/Bicyclette-Thin.woff";
const fontResponse = await fetch(fontUrl);
const fontBuffer = await fontResponse.arrayBuffer();

const headerBytes = await fetch("../../../assets/media/ofertaPDF/titlePDF2.png").then(res => res.arrayBuffer());
const patternBytes = await fetch("../../../assets/media/ofertaPDF/patternPDF.png").then(res => res.arrayBuffer());

// Draw the image on the header of each page
for (const page of pdfDoc.getPages()) {
  const { width } = page.getSize();
  const image = await pdfDoc.embedPng(headerBytes);

  newPage.drawImage(image, {
    x: width - 567, // Adjust the x-coordinate to position the image as needed
    y: 773,          // Adjust the y-coordinate to position the image as needed
    width: 210,      // Adjust the width of the image
    height: 40,     // Adjust the height of the image
  });
}

 // Draw the image on the pattern of each page
 for (const page of pdfDoc.getPages()) {
  const { width } = page.getSize();
  const image = await pdfDoc.embedPng(patternBytes);

  newPage.drawImage(image, {
    x: width - 170, // Adjust the x-coordinate to position the image as needed
    y: 200,          // Adjust the y-coordinate to position the image as needed
    width: 170,      // Adjust the width of the image
    height: 450,     // Adjust the height of the image
  });
}

  newPage.drawText('Cladirea: ' + this.selectedBuilding,  {
    x: 65,
    y: height - 140,
    size: 13,
    color: textColor
});

newPage.drawText('Numar Apartament: ' + this.apartmentNumber,{
    x: 65,
    y: height - 175,
    size: 13,
    color: textColor
});

newPage.drawText('Data expirare oferta: ' + this.datePipe.transform(this.expiryDate, 'dd/MM/yyyy'),{
    x: 65,
    y: height - 210,
    size: 13, 
    color: textColor
});

newPage.drawText('Parcare: ' + this.parcaredanu, {
    x: 65,
    y: height - 245,
    size: 13, 
    color: textColor
});

newPage.drawText('Plata avans: ' + this.downPaymentAmount + '€', {
    x: 65,
    y: height - 280,
    size: 13, 
    color: textColor
});

newPage.drawText('Discount: ' + this.discount + '%', {
    x: 65,
    y: height - 315,
    size: 13, 
    color: textColor
});

newPage.drawText('TVA: ' + this.selectedTvaValue + '%',  {
    x: 65,
    y: height - 350,
    size: 13, 
    color: textColor
});

newPage.drawText('Plata Ramasa: €' + this.plataRamasa.toFixed(2), {
    x: 65,
    y: height - 385,
    size: 13, 
    color: textColor
});

newPage.drawText('Modalitate Plata: ' + this.selectedModalitatePlata, {
    x: 65,
    y: height - 420,
    size: 13, 
    color: textColor
});

newPage.drawText(    'Observatii: ' + this.observations,{
    x: 65,
    y: height - 455,
    size: 13, 
    color: textColor
});

  const imageBytes = await fetch("../../../assets/media/ofertaPDF/footerPDF.png").then(res => res.arrayBuffer());

  // Draw the image on the footer of each page
  for (const page of pdfDoc.getPages()) {
    const { width } = page.getSize();
    const image = await pdfDoc.embedPng(imageBytes);

    newPage.drawImage(image, {
      x: width - 565, // Adjust the x-coordinate to position the image as needed
      y: 20,          // Adjust the y-coordinate to position the image as needed
      width: 540,      // Adjust the width of the image
      height: 60,     // Adjust the height of the image
    });
  }

  const modifiedPdfBytes = await pdfDoc.save();

  // Use the bytes to facilitate download
  const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = 'modified_apartment.pdf';
  link.click();



}



public selectedModalitatePlata: string = ''; // to store the selected payment method
public cateRate!: number | null; // to store the number of rates
public timpPlataRate!: string; // to store the payment time for rates
public showRateFields: boolean = false; // controls whether to display rate fields or not

public timpPlataRateOptions: string[] = ['Lunar', 'Trimestrial', 'Semestrial', 'Anual'];
public valoareRata: number = 0; // to store the value of each payment




// This method is called whenever the Modalitate Plata dropdown value changes.
onModalitatePlataChange(): void {
  if (this.selectedModalitatePlata === 'rate') {
      this.showRateFields = true;
  } else {
      this.showRateFields = false;
      this.cateRate = null;
      this.timpPlataRate = ''; // Reset the timpPlataRate value
  }
  this.calculateValoareRata();  // Calculate the valoareRata after changing modalitatePlata
}


calculateValoareRata(): void {
  if (this.cateRate && this.cateRate > 0) {
    this.valoareRata = this.plataRamasa / this.cateRate;
  } else {
    this.valoareRata = 0;
  }
}


}
