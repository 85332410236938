import { Component, OnInit } from '@angular/core';
import {SharedService} from 'src/app/shared.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-invest',
  templateUrl: './invest.component.html',
  styleUrls: ['./invest.component.css']
})
export class InvestComponent implements OnInit {

  constructor(private service:SharedService,private router: Router) { }

  ngOnInit(): void {
    this.service.invest=true 
    this.router.navigateByUrl('/')
  }

}
