<body>
  <div class="title">
    <img class="festivalLogo" src="../../assets/media/logos/Festival_lake.svg"
      alt="Festival Lake Sport&Culture Xperience ">
  </div>

  <div class="mapelements">
    <img class="maplake" src="../../assets/media/map/Festival Lake_map web 1.png" alt="Festival Lake">

    <!-- Simple clickable div to test modal functionality -->


    <div class="spots">
      <!-- Images that trigger modals -->
      <img class="spot1" src="../../assets/media/map/1_Zona Dinamica_m.png" alt="Zona Dinamica" (click)="spot1()">
      <img class="spot2" src="../../assets/media/map/2_Zona Artistica_m.png" alt="Zona Artistica" (click)="spot2()">
      <img class="spot3" src="../../assets/media/map/3_Zona Relaxare_m.png" alt="Zona Relaxare" (click)="spot3()">
      <img class="spot4" src="../../assets/media/map/4_Zona Competitie_m.png" alt="Zona Competitie" (click)="spot4()">
      <img class="spot5" src="../../assets/media/map/5_Zona Divertisment_m.png" alt="Zona Divertisment"
        (click)="spot5()">
      <img class="spot6" src="../../assets/media/map/6_Zona Adrenalina_m.png" alt="Zona Adrenalina" (click)="spot6()">
      <img class="spot7" src="../../assets/media/map/7_Zona Targ_m.png" alt="Zona Targ" (click)="spot7()">
      <img class="spot8" src="../../assets/media/map/8_Zona Creativa_m.png" alt="Zona Creativa" (click)="spot8()">

      <li class="square1" (click)="spot1()"></li>
      <li class="square2" (click)="spot2()"></li>
      <li class="square3" (click)="spot3()"></li>
      <li class="square4" (click)="spot4()"></li>
      <li class="square5" (click)="spot5()"></li>
      <li class="square6" (click)="spot6()"></li>
      <li class="square7" (click)="spot7()"></li>
      <li class="square8" (click)="spot8()"></li>
    </div>
  </div>

  <div class="colaboratori">
    <p>Organizatori</p>
    <div class="logos">
      <img src="../../assets/media/logos/TLH_logo.png" alt="The Lake Home Living Xperience">
      <img src="../../assets/media/logos/novarion.svg" alt="NOVARION Xperience the Future">
      <img src="../../assets/media/logos/CreativAct.png" alt="CreativAct">
      <img src="../../assets/media/logos/EcoSportsDome.png" alt="Eco Sports Dome">
      <img src="../../assets/media/logos/topichub.svg" alt="Topic Hub">

    </div>

  </div>

  <!-- Modal Structure -->
  <div class="modal fade" tabindex="-1" role="dialog" [ngClass]="{'show': modaldetalii}"
    [ngStyle]="{'display': modaldetalii ? 'block' : 'none'}">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{zonatitle}}</h5>
          <button type="button" class="close" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div *ngFor="let content of modalContents">
            <p>{{content}}</p>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
        </div>
      </div>
    </div>
  </div>

</body>