import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css']
})
export class EmailComponent implements OnInit {

  @ViewChild('contentToCopy', { static: true }) contentToCopy!: ElementRef;

  constructor() { }

  ngOnInit(): void {
    window.focus();

  }

  copyContent() {
    // Create a range and select the content in the DOM
    const range = document.createRange();
    range.selectNode(this.contentToCopy.nativeElement);

    // Clear the previous selection, if any
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
    }

    // Add the new range to the selection
    if (selection) {
      selection.addRange(range);
    }

    // Execute the copy command
    document.execCommand('copy');

    // Remove the range from the selection
    if (selection) {
      selection.removeAllRanges();
    }
  }
  imgToBase64(imgSrc: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(img, 0, 0);
          const dataURL = canvas.toDataURL('image/png');
          resolve(dataURL);
        } else {
          reject(new Error('Context not found.'));
        }
      };
      img.onerror = (e) => reject(new Error('Image load failed.'));
      img.src = imgSrc;
    });
  }

  async copyContent2() {
    try {
      // Focus the document
      window.focus();
      // Convert all images to base64 before copying
      const images = this.contentToCopy.nativeElement.querySelectorAll('img');
      for (const img of images) {
        const imgSrc = img.getAttribute('src') || '';
        const dataURL = await this.imgToBase64(imgSrc);
        img.setAttribute('src', dataURL);
      }

      // Get the outerHTML of the element, which includes styles and markup
      const htmlToCopy = this.contentToCopy.nativeElement.outerHTML;

      // Create a ClipboardItem object
      const clipboardItem = new ClipboardItem({
        'text/html': new Blob([htmlToCopy], { type: 'text/html' }),
        'text/plain': new Blob([htmlToCopy], { type: 'text/plain' })
      });

      // Write the ClipboardItem to the clipboard
      await navigator.clipboard.write([clipboardItem]);
    // Notify the user that the copy was successful
    alert('Content successfully copied.');

    } catch (err) {
      alert('Failed to copy content')
      // Handle the error
      console.error('Failed to copy content: ', err);
    }
  }


  investairbnb(){
    window.open('/optiuni-de-investitie/ro', '_blank');
  }
}
