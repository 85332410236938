<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->


<head>
    <link rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />

    
    </head>

<body>


    <header class="headerGrid">
        <video loop onloadedmetadata="this.muted=true" autoplay oncanplay="this.play()" class="bg-vid">
            <source
                src="https://the-lake-live.s3.eu-central-1.amazonaws.com/2022.11.30_TRACTORULUI+-+ANIMATIE+FAZA+I+-+SCENA+1+%26+2_.mp4">
        </video>
        <div class="headerGrid-wrapper">

            <div class="header-titles">
                <img src="../../../assets/media/logos/the_lake_logo_white.png"
                    alt="The Lake home - Living Xperience logo" (click)="seehome()">

                <div class="all-nav-elements">
                    <div class="contact-nav">

                        <div class="flexRow">
                            <a (click)="changeEnRo()">RO</a>
                        </div>

                        <div class="header-phone"><span id="header-icon-phone"
                                class="material-symbols-outlined">phone_iphone <a href="tel:+40721229999">+4 0721 229
                                    999</a> </span></div>
                        <span class="vertical-line"></span>
                        <div class="header-mail"><span id="header-icon-mail"
                                class="material-symbols-outlined">chat_bubble <a
                                    href="mailto:sales@thelake.ro">sales@thelake.ro</a></span></div>
                        <!-- Button trigger modal vizionare-->
                        <button class="header-button" type="button" data-toggle="modal"
                            data-target="#brosuraModal">SEE&nbsp;THE&nbsp;BROCHURE</button>



                        <!-- Modal Vizionare-->
                        <div class="modal fade" id="vizionareModal" tabindex="-1" role="dialog"
                            aria-labelledby="vizionareModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                            aria-hidden="true">
                                            <span aria-hidden="true" class="close-icon material-symbols-outlined">
                                                close
                                            </span>
                                        </button>
                                        <h5 class="modal-title" id="vizionareModalLabel">Schedule a viewing</h5>
                                    </div>
                                    <div class="modal-body">
                                        <div class="p">
                                            <p>Leave your contact details so our team can get in touch with you.</p>
                                        </div>
                                        <form>
                                            <input type="text" class="modal-form" [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="Name" placeholder="Name" required>
                                            <input type="email" class="modal-form" [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="Email" id="exampleFormControlInput1" placeholder="Email"
                                                required>
                                            <input type="tel" class="modal-form" [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="Phone" placeholder="Phone" required>
                                            <textarea id="modal-mesaj" class="modal-form"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Message"
                                                placeholder="Leave us a message" rows="3"></textarea>

                                            <div class="privacylink1"><input (click)="checked()" id="checkbox1"
                                                    type="checkbox" required><label class="form-control1">I agree with
                                                    the <a id="ppLink" (click)="seetermeni()" data-dismiss="modal">data
                                                        processing policy.</a></label></div>
                                            <h6 *ngIf="acestCamp">This field is required</h6>

                                            <button class="deactive modal-button" type="button" (click)="sendFormOne()"
                                                data-dismiss="modal">SEND</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Modal Brosura-->
                        <div class="modal fade" id="brosuraModal" tabindex="-1" role="dialog"
                            aria-labelledby="brosuraModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                            aria-hidden="true">
                                            <span aria-hidden="true" class="close-icon material-symbols-outlined">
                                                close
                                            </span>
                                        </button>
                                        <h5 class="modal-title" id="brosuraModalLabel">See the brochure</h5>
                                    </div>
                                    <div class="modal-body-brosura">
                                        <div class="p">
                                            <p>Complete the form below to see The Lake Home brochure.</p>
                                        </div>
                                        <form>
                                            <input type="text" class="modal-form" placeholder="Name"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Name" required>
                                            <input type="email" class="modal-form" id="exampleFormControlInput1"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Email"
                                                placeholder="Email" required>
                                            <input type="tel" class="modal-form" placeholder="Phone"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Phone" required>
                                           
                                            
                                            <div class="privacylink2"><input (click)="checked2()" id="checkbox2"
                                                    type="checkbox" required><label class="form-control2">I agree with
                                                    the<a id="ppLink2" data-dismiss="modal" (click)="seetermeni()">data
                                                        processing policy.</a></label></div>
                                            <h6 *ngIf="acestCamp1">This field is required</h6>
                                            <button class="deactive modal-button-brosura" type="button"
                                                (click)="sendFormOne2()" data-dismiss="modal">See brochure</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- XMAS promo Modal-->
                        <div class="modal fade" id="XmasModal" tabindex="-1" role="dialog"
                            aria-labelledby="XmasModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content XmasContent">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                            aria-hidden="true">
                                            <span aria-hidden="true" class="close-icon material-symbols-outlined">
                                                close
                                            </span>
                                        </button>
                                        <h5 class="modal-title XmasTitle" id="OfertaModalLabel">HO, HO,
                                            <span>HOME</span>
                                        </h5>
                                    </div>
                                    <div class="modal-body-oferta">
                                        <div class="p">

                                            <p> <span class="xmasSubTitle">Serbează <strong>Crăciunul</strong> acasă și
                                                    fă loc
                                                    sub brad pentru <strong>un cadou în plus.</strong> </span><br><br>

                                                Achiziționează unul din ultimele apartamente disponibile
                                                în blocul C sau achită <strong>un avans de 50% </strong>pentru un
                                                apartament în blocul B1 și primești cadou de casă nouă
                                                un <strong>robot de bucătărie Thermomix TM6.</strong> <br><br>

                                                În plus, îți oferim un<strong> avantaj client</strong> cuprins între
                                                <strong>2,500 și
                                                    38,000 euro </strong>în baza avansului din prețul de listă pentru
                                                noua ta locuință în blocurile B1 și B2.<br><br>

                                                Lasă-ne datele tale și programează o vizionare.
                                            </p>
                                        </div>
                                        <form>
                                            <input type="text" class="modal-form" placeholder="Nume"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Name" required>
                                            <input type="email" class="modal-form" id="exampleFormControlInput1"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Email"
                                                placeholder="Email" required>
                                            <input type="tel" class="modal-form" placeholder="Telefon"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Phone" required>
                                            <textarea id="modal-mesaj" class="modal-form"
                                                placeholder="Scrie-ne un mesaj" [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="Message" rows="3"></textarea>

                                            <div class="privacylink2"><input (click)="checked4()" id="checkbox2"
                                                    type="checkbox" required><label class="form-control2">Sunt de acord
                                                    cu <a id="ppLink2" data-dismiss="modal"
                                                        (click)="seetermeni()">Consimțământul de prelucrare a
                                                        datelor.</a></label></div>
                                            <h6 *ngIf="acestCamp1">Acest câmp este obligatoriu</h6>
                                            <button class="deactive modal-button-oferta" type="button"
                                                (click)="sendFormOferta()" data-dismiss="modal">TRIMITE MESAJUL</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- Button trigger modal 
                        <button type="button" id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#OFERTA"> </button>
                    Modal Oferta    
                        <div class="modal fade" id="OFERTA" tabindex="-1" role="dialog" aria-labelledby="OFERTALabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                            <div class="modal-content o">
                                <div class="modal-header">

                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                </div>
                                <div class="modal-body-oferta" (click)="openOferta()">
                                 <img id="ofertaImg" src="../../../assets/media/blackfriday.jpg" alt="">
                                </div>
                                <div class="modal-footer">
                                </div>
                            </div>
                            </div>
                        </div>-->


                    <!-----------NAV BAR----------->
                    <div class="logo-navbar">

                        <div class="mob-navbar">
                            <div class="distance-top-navbar">
                                <a class="blue-border" (click)="seelifestyle()">LIFESTYLE</a>
                                <a class="blue-border" (click)="footerlinks1()">CHOOSE&nbsp;YOUR&nbsp;RESIDENCE </a>
                                <a class="blue-border" (click)="seelocatie()">LOCATION</a>
                                <a class="blue-border" href="https://thelake.ro/galerie-en/">GALLERY</a>
                                <a class="blue-border" (click)="seemedia()">MEDIA</a>
                                <a class="blue-border" (click)="seedezvoltator()">DEVELOPER</a>
                                <a (click)="seecontact()">CONTACT</a>
                            </div>

                        </div>
                        <div class="burger" (click)="navSlider()">
                            <div class="line1"></div>
                            <div class="line2"></div>
                            <div class="line3"></div>
                        </div>

                        <div class="navbar">
                            <a (click)="seelifestyle()">LIFESTYLE</a>
                            <a (click)="footerlinks1()">CHOOSE&nbsp;YOUR&nbsp;RESIDENCE</a>
                            <a (click)="seelocatie()">LOCATION</a>
                            <a href="https://thelake.ro/galerie-en/">GALLERY</a>
                            <a (click)="seemedia()">MEDIA</a>
                            <a (click)="seedezvoltator()">DEVELOPER</a>
                            <a (click)="seecontact()">CONTACT</a>
                        </div>
                    </div>
                </div>
                <div class="page-title" (click)="openyt()">
                    <img src="../../../assets/media/header-title-eng.svg" alt="">
                </div>
            </div>

            <div class="header-database">
                <div class="dataBase-wrapper">
                    <h5>See the plans</h5>
                    <div class="db-camere">
                        <div class="camere-cicle"><span id="db-camere-arrow"
                                class="material-symbols-outlined">chevron_right</span>
                        </div>
                        <div class="camere-names" id="moreCamere">
                            <h6 class="camere-arrow">Floors</h6>
                            <ul class="nrCamere">
                                <li (click)="makeEtajParter()">0</li>
                                <li (click)="makeEtaj1()">1</li>
                                <li (click)="makeEtaj2()">2</li>
                                <li (click)="makeEtaj3()">3</li>
                                <li (click)="makeEtaj4()">4</li>
                            </ul>
                        </div>
                    </div>


                    <!-- 
                      <div class="db-vila">
                          <div class="vila-cicle"><span id="db-vila-arrow" class="material-symbols-outlined">chevron_right</span></div>
                              <div class="vila-names" id="moreVila">
                                  <h6 class="vila-arrow">Vila </h6>
                              </div>    
                      </div>
                    -->
                    <div class="db-camere">
                        <div class="camere-cicle"><span id="db-camere-arrow"
                                class="material-symbols-outlined">chevron_right</span>
                        </div>
                        <div class="camere-names" id="moreCamere">
                            <h6 class="camere-arrow">Number of rooms </h6>
                            <ul class="nrCamere1">
                                <li class="deactive" (click)="makerooms1()">1</li>
                                <li class="deactive" (click)="makerooms2()">2</li>
                                <li class="deactive" (click)="makerooms3()">3</li>
                                <li class="deactive" (click)="makerooms4()">4</li>
                                <li class="deactive" (click)="makerooms5()">5</li>
                            </ul>
                        </div>
                    </div>
                    <div class="db-button" (click)="seemoreapartments()"><button class="deactive">SEE THE LIST</button>
                    </div>
                </div>
            </div>

            <!-- call to action buttons -->
            <div class="cta-btns">
                <div class="cta-visionare" type="button" data-toggle="modal" data-target="#vizionareModal">
                    <span id="cta-viz-icon" class="material-symbols-outlined">chat_bubble</span>
                    <p>Schedule a viewing</p>
                </div>
                <div class="cta-brosura" type="button" data-toggle="modal" data-target="#brosuraModal">
                    <img src="../../../assets/media/icons/brosuraIcon.svg" alt="download brosura icon">
                    <p>SEE THE BROCHURE </p>
                </div>
            </div>

            <div class="sticky-btn" type="button" data-toggle="modal" data-target="#vizionareModal"><span
                    id="cta-viz-icon" class="material-symbols-outlined">chat_bubble</span>
                <h5>Schedule <br> a viewing</h5>
            </div>

            <!--xmasPromo
            <div class="XMAS-sticky-btn" type="button" data-toggle="modal" data-target="#XmasModal"><img
                    src="../../../assets/media/icons/favpng_vector-graphics-gift-clip-art-box.png" alt="">
            </div>-->
        </div>
    </header>

    <section class="mob-tag-section">
        <div class="mob-tag-slider">
            <ul #slider class="mobBxslider">
                <li class="tagElements">
                    <div class="mob-tag">
                        <img class="blueCircle" src="../../../assets/media/icons/tag-comunitate.svg" alt="">
                        <div id="mobtag1content">
                            <h3>Community</h3>
                            <p>The Lake Home is located right next to Binder's Lake, an area specially selected to
                                provide easy access to various <span class="tag-moreDots"> ...</span> sports activities,
                                recreational areas, cultural and commercial spaces.
                                <br><br> În apropiere va fi
                                The new Regional Hospital will be completed nearby and the Old City Center is about 10
                                minutes away by car. The complex is defined by its tenants, people looking for an
                                exclusive place, but at the same time quiet, easily accessible and very well positioned
                                in relation to the "energy centers" of the city.

                            </p>
                            <div class="tag-buttons" id="mobtagBtn" (click)="setAllAccordians(0)">
                                <span id="mob-tag-icon1" class="material-symbols-outlined">arrow_circle_down</span>
                                <label class="tag-open"> READ MORE </label>
                                <label class="tag-close"> CLOSE </label>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="tagElements">
                    <div class="mob-tag">
                        <img class="blueCircle" src="../../../assets/media/icons/tag-casa.svg" alt="">
                        <div id="mobtag2content">
                            <h3>This is your home</h3>
                            <p>Sibiu is already a well-known brand from a cultural and economic point of view, a city
                                that arouses emotions, that <span class="tag-moreDots"> ... <br></span>makes you feel
                                like a local and a tourist at the same time. <br><br>
                                No matter what you choose to be, The Lake Home will make you feel like you can be you.
                                The forever young you, surrounded by friends and family, close to the most interesting
                                attractions. You can enjoy three restaurants, a cafe, a gym and spa with a swimming
                                pool.


                            </p>
                            <div class="tag-buttons" id="mobtagBtn2" (click)="setAllAccordians(1)">
                                <span id="mob-tag-icon2" class="material-symbols-outlined">arrow_circle_down</span>
                                <label class="tag-open"> READ MORE </label>
                                <label class="tag-close"> CLOSE </label>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="tagElements">
                    <div class="mob-tag">
                        <img class="blueCircle" src="../../../assets/media/icons/tag-gift.svg" alt="">
                        <div id="mobtag3content">
                            <h3>The dream place</h3>
                            <p>Comfort doesn't have to be an extra option. The Lake Home residential complex is the
                                place where <span class="tag-moreDots"> ...<br></span>comfort is the basis of the
                                contract between you and us. We guarantee your satisfaction and the uniqueness of this
                                place. We treat you with great respect, we provide you with the best specialists to
                                provide security, sanitation and maintenance services for all green spaces, including
                                planters, and at the same time, we can provide you with various other benefits,
                                according to your requirements.
                            </p>
                            <div class="tag-buttons" id="mobtagBtn3" (click)="setAllAccordians(2)">
                                <span id="mob-tag-icon3" class="material-symbols-outlined">arrow_circle_down</span>
                                <label class="tag-open"> READ MORE </label>
                                <label class="tag-close"> CLOSE </label>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="tagElements">
                    <div class="mob-tag">
                        <img class="blueCircle" src="../../../assets/media/icons/tag-dorit.svg" alt="">
                        <div id="mobtag4content">
                            <h3>The ideal community</h3>
                            <p>The residents will soon have at their disposal, in addition to their own apartments,
                                elaborated and <span class="tag-moreDots"> ... <br></span> rigorously built, three
                                restaurants, a cafe, gym, swimming pool, a spa center, and also an aparthotel. The Lake
                                Home is an audacious dream that came true thanks to great passion; passion for
                                aesthetics, sustainability, for architecture and top design. As we like to say, we
                                bundle safety, trust and special neighbors.
                            </p>
                            <div class="tag-buttons" id="mobtagBtn4" (click)="setAllAccordians(3)">
                                <span id="mob-tag-icon4" class="material-symbols-outlined">arrow_circle_down</span>
                                <label class="tag-open"> READ MORE </label>
                                <label class="tag-close"> CLOSE </label>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="tagElements">
                    <div class="mob-tag">
                        <img class="blueCircle" src="../../../assets/media/icons/tag-verde.svg" alt="">
                        <div id="mobtag5content">
                            <h3>Green spaces</h3>
                            <p>The Lake Home is the only large-scale neighborhood in Sibiu located next to a lake, with
                                a recreation area and <span class="tag-moreDots"> ...<br></span>water sports, and the
                                project also includes approx. 20,000 m2 of parks with playgrounds and restaurants. All
                                the buildings have paved road access, thus eliminating any potential inconvenience.
                            </p>
                            <div class="tag-buttons" id="mobtagBtn5" (click)="setAllAccordians(4)">
                                <span id="mob-tag-icon5" class="material-symbols-outlined">arrow_circle_down</span>
                                <label class="tag-open"> READ MORE </label>
                                <label class="tag-close"> CLOSE </label>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="tagElements">
                    <div class="mob-tag">
                        <img class="blueCircle" src="../../../assets/media/icons/tag-casaunica.svg" alt="">
                        <div id="mobtag6content">
                            <h3>A unique design</h3>
                            <p>This Premium project goes beyond expectations, for your delightment, amazes with an
                                original framework <span class="tag-moreDots"> ...<br></span>approach and architectural
                                design, and takes its industrial inserts, assumed in modernism, to a completely
                                different level.
                            </p>
                            <div class="tag-buttons" id="mobtagBtn6" (click)="setAllAccordians(5)">
                                <span id="mob-tag-icon6" class="material-symbols-outlined">arrow_circle_down</span>
                                <label class="tag-open"> READ MORE </label>
                                <label class="tag-close"> CLOSE </label>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="tagElements">
                    <div class="mob-tag">
                        <img class="blueCircle" src="../../../assets/media/pattern/BlueBox_B.svg" alt="">
                        <div id="mobtag7content">
                            <h3>The neighborhood</h3>
                            <p>We have named this community “The Neighborhood of Good Manners" and we even have internal
                                order regulations <span class="tag-moreDots"> ...<br></span>based on good manners,
                                respect and trust. In The Lake Home, you find quality neighbors and good friends.</p>
                            <div class="tag-buttons" id="mobtagBtn7" (click)="setAllAccordians(6)">
                                <span id="mob-tag-icon7" class="material-symbols-outlined">arrow_circle_down</span>
                                <label class="tag-open"> READ MORE </label>
                                <label class="tag-close"> CLOSE </label>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="tagElements">
                    <div class="mob-tag">
                        <img class="blueCircle" src="../../../assets/media/icons/tag-seguranta.svg" alt="">
                        <div id="mobtag8content">
                            <h3>100% safe</h3>
                            <p>Your neighborhood is also special because it will be managed by the developer for a
                                period of 25 years. <span class="tag-moreDots"> ...<br></span>You get a lifestyle of
                                comfort and confidence, a truly safe neighborhood experience.</p>
                            <div class="tag-buttons" id="mobtagBtn8" (click)="setAllAccordians(7)">
                                <span id="mob-tag-icon8" class="material-symbols-outlined">arrow_circle_down</span>
                                <label class="tag-open"> READ MORE </label>
                                <label class="tag-close"> CLOSE </label>
                            </div>
                        </div>
                    </div>
                </li>

            </ul>

            <div class="mob-tag-slider-controls" style="display: flex;">
                <span id="mob-tag-slide-prev" (click)="onLeftScroll()"
                    class="material-symbols-outlined">arrow_circle_left</span>
                <span id="mob-tag-slide-next" (click)="onRightScroll()"
                    class="material-symbols-outlined">arrow_circle_right</span>
            </div>

        </div>
    </section>

    <section class="tag-section">
        <div class="tag-section-wrapper" id="tagSectionWrapper">
            <div class="tag-wrapper">

                <div class="tag-1">
                    <img class="blueCircle" src="../../../assets/media/icons/tag-comunitate.svg" alt="">
                    <div id="tag1content">
                        <h3>Community</h3>
                        <p>The Lake Home is located right next to Binder's Lake, an area specially selected to provide
                            easy access to various <span class="tag-moreDots"> ... <br></span>sports activities,
                            recreational areas, cultural and commercial spaces.<br><br>
                            The new Regional Hospital will be completed nearby and the Old City Center is about 10
                            minutes away by car. The complex is defined by its tenants, people looking for an exclusive
                            place, but at the same time quiet, easily accessible and very well positioned in relation to
                            the "energy centers" of the city.
                        </p>
                        <div class="tag-buttons" id="tagBtn" (click)="setAllAccordians(8)">
                            <span id="tag-icon1" class="material-symbols-outlined">arrow_circle_down</span>
                            <label class="tag-open"> Read more </label>
                            <label class="tag-close"> Close </label>
                        </div>
                    </div>
                </div>

                <div class="tag-2">
                    <img class="blueCircle" src="../../../assets/media/icons/tag-casa.svg" alt="">
                    <div id="tag2content">
                        <h3>This is your home</h3>
                        <p>Sibiu is already a well-known brand from a cultural and economic point of view, a city that
                            arouses emotions, that <span class="tag-moreDots"> ... <br></span>makes you feel like a
                            local and a tourist at the same time.<br><br>
                            No matter what you choose to be, The Lake Home will make you feel like you can be you. The
                            forever young you, surrounded by friends and family, close to the most interesting
                            attractions. You can enjoy three restaurants, a cafe, a gym and spa with a swimming pool.
                        </p>
                        <div class="tag-buttons" id="tagBtn2" (click)="setAllAccordians(9)">
                            <span id="tag-icon2" class="material-symbols-outlined">arrow_circle_down</span>
                            <label class="tag-open"> Read more </label>
                            <label class="tag-close"> Close </label>
                        </div>
                    </div>
                </div>

                <div class="tag-3">
                    <img class="blueCircle" src="../../../assets/media/icons/tag-gift.svg" alt="">
                    <div id="tag3content">
                        <h3>The dream place</h3>
                        <p>Comfort doesn't have to be an extra option. The Lake Home residential complex is the place
                            where comfort <span class="tag-moreDots"> ...<br></span>is the basis of the contract between
                            you and us. We guarantee your satisfaction and the uniqueness of this place. We treat you
                            with great respect, we provide you with the best specialists to provide security, sanitation
                            and maintenance services for all green spaces, including planters, and at the same time, we
                            can provide you with various other benefits, according to your requirements.
                        </p>
                        <div class="tag-buttons" id="tagBtn3" (click)="setAllAccordians(10)">
                            <span id="tag-icon3" class="material-symbols-outlined">arrow_circle_down</span>
                            <label class="tag-open"> Read more </label>
                            <label class="tag-close"> Close </label>
                        </div>
                    </div>
                </div>

                <div class="tag-4">
                    <img class="blueCircle" src="../../../assets/media/icons/tag-dorit.svg" alt="">
                    <div id="tag4content">
                        <h3>The ideal community</h3>
                        <p>The residents will soon have at their disposal, in addition to their own apartments,
                            elaborated and <span class="tag-moreDots"> ...<br></span>rigorously built, three
                            restaurants, a cafe, gym, swimming pool, a spa center, and also an aparthotel. The Lake Home
                            is an audacious dream that came true thanks to great passion; passion for aesthetics,
                            sustainability, for architecture and top design. As we like to say, we bundle safety, trust
                            and special neighbors.
                        </p>
                        <div class="tag-buttons" id="tagBtn4" (click)="setAllAccordians(11)">
                            <span id="tag-icon4" class="material-symbols-outlined">arrow_circle_down</span>
                            <label class="tag-open"> Read more </label>
                            <label class="tag-close"> Close </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tag-wrapper-row">
                <div class="tag-5">
                    <img class="blueCircle" src="../../../assets/media/icons/tag-verde.svg" alt="">
                    <div id="tag5content">
                        <h3>Green spaces</h3>
                        <p>The Lake Home is the only large-scale neighborhood in Sibiu located next to a lake, with a
                            recreation area and <span class="tag-moreDots"> ...<br></span>water sports, and the project
                            also includes approx. 20,000 m2 of parks with playgrounds and restaurants. All the buildings
                            have paved road access, thus eliminating any potential inconvenience.
                        </p>
                        <div class="tag-buttons" id="tagBtn5" (click)="setAllAccordians(12)">
                            <span id="tag-icon5" class="material-symbols-outlined">arrow_circle_down</span>
                            <label class="tag-open"> Read more </label>
                            <label class="tag-close"> Close </label>
                        </div>
                    </div>
                </div>

                <div class="tag-6">
                    <img class="blueCircle" src="../../../assets/media/icons/tag-casaunica.svg" alt="">
                    <div id="tag6content">
                        <h3>A unique design</h3>
                        <p>This Premium project goes beyond expectations, for your delightment, amazes with an original
                            framework <span class="tag-moreDots"> ...<br></span>approach and architectural design, and
                            takes its industrial inserts, assumed in modernism, to a completely different level.
                        </p>
                        <div class="tag-buttons" id="tagBtn6" (click)="setAllAccordians(13)">
                            <span id="tag-icon6" class="material-symbols-outlined">arrow_circle_down</span>
                            <label class="tag-open"> Read more </label>
                            <label class="tag-close"> Close </label>
                        </div>
                    </div>
                </div>

                <div class="tag-7">
                    <img class="blueCircle" src="../../../assets/media/pattern/BlueBox_B.svg" alt="">
                    <div id="tag7content">
                        <h3>The neighborhood</h3>
                        <p>We have named this community “The Neighborhood of Good Manners" and we even have internal
                            order regulations <span class="tag-moreDots"> ...<br></span>based on good manners, respect
                            and trust. In The Lake Home, you find quality neighbors and good friends.
                        </p>
                        <div class="tag-buttons" id="tagBtn7" (click)="setAllAccordians(14)">
                            <span id="tag-icon7" class="material-symbols-outlined">arrow_circle_down</span>
                            <label class="tag-open"> Read more </label>
                            <label class="tag-close"> Close </label>
                        </div>
                    </div>
                </div>

                <div class="tag-8">
                    <img class="blueCircle" src="../../../assets/media/icons/tag-seguranta.svg" alt="">
                    <div id="tag8content">
                        <h3>100% safe</h3>
                        <p>Your neighborhood is also special because it will be managed by the developer for a period of
                            25 years. <span class="tag-moreDots"> ...<br></span>You get a lifestyle of comfort and
                            confidence, a truly safe neighborhood experience.
                        </p>
                        <div class="tag-buttons" id="tagBtn8" (click)="setAllAccordians(15)">
                            <span id="tag-icon8" class="material-symbols-outlined">arrow_circle_down</span>
                            <label class="tag-open"> Read more </label>
                            <label class="tag-close"> Close </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tag-slider-controls">
            <span id="tagSlidePrev1" class="material-symbols-outlined">arrow_circle_left</span>
            <span id="tagSlidePrev" class="material-symbols-outlined"
                (click)="buttonSlidePREV()">arrow_circle_left</span>
            <span id="tagSlideNext" class="material-symbols-outlined"
                (click)="buttonSlideNEXT()">arrow_circle_right</span>
            <span id="tagSlideNext1" class="material-symbols-outlined">arrow_circle_right</span>
        </div>
    </section>

    <section class="mob-alege-locuinta">
        <h2>Find your home</h2>
        <div class="mob-alege-locuinta-wrapper">
            <div class="mob-choose-bloc">

                <h3>Building {{BuildingNameFilter}}</h3>
                <div class="mob-select-blocs">
                    <div class="blue-block"></div>

                    <div id="carouselExampleIndicators1" class="carousel slide" data-ride="0" data-interval="false">
                        <div class="carousel-inner" id="selectBlocs-inner">
                            <div class="carousel-item active">
                                <img class="d-block w-100" [src]="'../../../assets/media/blocs/' + buildingFotoB" [class]="buildingFotoClass" alt="First slide">
                            </div>
                          
                            <div class="carousel-item ">   
                                <img class="d-block w-100" [src]="'../../../assets/media/blocs/' + SecondPhoto" [class]="SecondPhotoClass" alt="Second slide">
                            </div>

                        </div>

                        <a class="carousel-control-prev" href="#carouselExampleIndicators1" role="button"
                            data-slide="prev" (click)="substractNumber()">
                            <span id="mob-bloc-prev" class="material-symbols-outlined">arrow_circle_left</span>
                        </a>
                        <a class="carousel-control-next" href="#carouselExampleIndicators1" role="button"
                            data-slide="next" (click)="addNumber()">
                            <span id="mob-bloc-next" class="material-symbols-outlined">arrow_circle_right</span>
                        </a>
                    </div>


                    <div class="mob-dropdown">
                        <div class="etaj-dropdown">
                            <div class="blocs-cicle"><span id="etaj-big-arrow"
                                    class="material-symbols-outlined">chevron_right</span>
                            </div>
                            <div class="etaj-names" id="moreetaj">
                                <h6 class="etaj-arrow">Floors</h6>
                                <ul class="nrEtaj">
                                    <li (click)="makeEtajParter()">0</li>
                                    <li (click)="makeEtaj1()">1</li>
                                    <li (click)="makeEtaj2()">2</li>
                                    <li (click)="makeEtaj3()">3</li>
                                    <li (click)="makeEtaj4()">4</li>
                                </ul>
                            </div>
                        </div>
                        <div class="cam-dropdown">
                            <div class="blocs-cicle"><span id="cam-big-arrow"
                                    class="material-symbols-outlined">chevron_right</span></div>
                            <div class="cam-names" id="moreCam">
                                <h6 class="cam-arrow">Number of rooms</h6>
                                <ul class="nrCam">
                                    <li class="deactive" (click)="makerooms1()">1</li>
                                    <li class="deactive" (click)="makerooms2()">2</li>
                                    <li class="deactive" (click)="makerooms3()">3</li>
                                    <li class="deactive" (click)="makerooms4()">4</li>
                                    <li class="deactive" (click)="makerooms5()">5</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="mob-flats-plans">
                <div class="border-horiz"></div>
                <div class="plans-details">
                    <div class="roomsNo">
                        <h5>{{hollowNrOfRooms}} / {{hollowFloor}}</h5>
                        <p>{{hollowApartmentNumb}}</p>
                    </div>
                    <div class="split-line"></div>
                    <div class="s-utila">
                        <h5>Surface</h5>
                        <p>{{hollowSurface}}</p>
                    </div>
                </div>
                <div class="plans-imgs">
                    <img src="{{hollowPhoto}}" alt="">
                </div>
                <button class="detail-btn" type="button" data-toggle="modal" (click)="seeApartment()" data-target="#detailModal">DETAILS</button>
                <div class="choose-plans-btns">
                    <p>previous apartment</p>
                    <span id="mob-plans-prev1" class="material-symbols-outlined">arrow_circle_left</span>
                    <span id="mob-plans-prev" (click)="slidetoprevappartment()"
                        class="material-symbols-outlined">arrow_circle_left</span>
                    <span id="mob-plans-next" (click)="slidetonextappartment()"
                        class="material-symbols-outlined">arrow_circle_right</span>
                    <span id="mob-plans-next1" class="material-symbols-outlined">arrow_circle_right</span>

                    <p>next apartment</p>
                </div>
            </div>
        </div>

    </section>

    <section class="alege-locuinta">
        <h2>Find your home</h2>
        <div class="gray-block"><span id="gray-arrow" class="material-symbols-outlined">trending_flat</span>
        </div>
        <div class="alege-locuinta-wrapper">
            <div class="choose-bloc">
                <h3>Building {{BuildingNameFilter}}</h3>
                <p>Select the floor<br>on&nbsp;the&nbsp;image</p>

                <div class="select-blocs">
                    <div id="filtercarusel" class="carousel slide" data-ride="carousel " data-interval="false">
                        <div class="carousel-inner" id="selectBlocsInner">

                            <div class="carousel-item active">
                                <img class="d-block w-100" [src]="'../../../assets/media/blocs/' + buildingFotoB" [class]="buildingFotoClass" alt="First slide">
                            </div>
                          
                            <div class="carousel-item ">   
                                <img class="d-block w-100" [src]="'../../../assets/media/blocs/' + SecondPhoto" [class]="SecondPhotoClass" alt="Second slide">
                            </div>

                        </div>
                        <a class="carousel-control-prev" href="#filtercarusel" role="button" data-slide="prev"
                            (click)="substractNumber()">
                            <span id="bloc-prev" class="material-symbols-outlined">arrow_circle_left</span>
                        </a>
                        <a class="carousel-control-next" href="#filtercarusel" role="button" data-slide="next"
                            (click)=" addNumber()">
                            <span id="bloc-next" class="material-symbols-outlined">arrow_circle_right</span>
                        </a>
                    </div>


                    <ul id="blC-hover-btns">
                        <li class="mansarda" (click)="makeEtaj4()"></li>
                        <li class="etaj3" (click)="makeEtaj3()"></li>
                        <li class="etaj2" (click)="makeEtaj2()"></li>
                        <li class="etaj1" (click)="makeEtaj1()"></li>
                        <li class="parter" (click)="makeEtajParter()"></li>
                    </ul>

                    <ul id="blB1-hover-btns">
                        <li class="mansarda" (click)="makeEtaj4()"></li>
                        <li class="etaj3" (click)="makeEtaj3()"></li>
                        <li class="etaj2" (click)="makeEtaj2()"></li>
                        <li class="etaj1" (click)="makeEtaj1()"></li>
                        <li class="parter" (click)="makeEtajParter()"></li>
                    </ul>


                </div>
                <button class="deactive" ></button>
            </div>


            <div class="choose-etajFlat">
                <div class="etaj-flat">
                    <div class="choose-etaj">
                        <h4>Floors</h4>
                        <ul class="etaj-btns" id="etajBtns">
                            <li (click)="makeEtajParter()">0</li>
                            <li (click)="makeEtaj1()">1</li>
                            <li (click)="makeEtaj2()">2</li>
                            <li (click)="makeEtaj3()">3</li>
                            <li (click)="makeEtaj4()">4</li>

                        </ul>
                    </div>
                    <div class="choose-room">
                        <h4>Number of rooms</h4>
                        <ul class="camere-btns">
                            <li class="deactive" (click)="makerooms1()">1</li>
                            <li class="deactive" (click)="makerooms2()">2</li>
                            <li class="deactive" (click)="makerooms3()">3</li>
                            <li class="deactive" (click)="makerooms4()">4</li>
                            <li class="deactive" (click)="makerooms5()">5</li>
                        </ul>
                    </div>
                    
                </div>

                <div class="flats-plans">
                    <div class="plans-imgs">
                        <ul>
                            <img src={{hollowPhoto}} alt="">
                        </ul>

                    </div>
                    <div class="border-horiz"></div>
                    <div class="plans-details">
                        <div class="roomsNo">
                            <h5>{{hollowNrOfRooms}} / {{hollowFloor}}</h5>
                            <p>{{hollowApartmentNumb}}</p>
                        </div>
                        <div class="split-line"></div>
                        <div class="s-utila">
                            <h5>Surface</h5>
                            <p>{{hollowSurface}}</p>
                        </div>
                        <button type="button" data-toggle="modal" data-target="#detailModal" (click)="seeApartment()">DETAILS</button>
                    </div>
                    <ul class="plan-indicator">
                        <!--  <li *ngFor="dataItem of BuildingList"></li>  -->
                    </ul>
                    <div class="choose-plans-btns">
                        <span id="plans-prev1" class="material-symbols-outlined">arrow_circle_left</span>
                        <span id="plans-prev" (click)="slidetoprevappartment()"
                            class="material-symbols-outlined">arrow_circle_left</span>
                        <span id="plans-next" (click)="slidetonextappartment()"
                            class="material-symbols-outlined">arrow_circle_right</span>
                        <span id="plans-next1" class="material-symbols-outlined">arrow_circle_right</span>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Modal Detail plan -->
    <div class="modal fade" data-backdrop="static" id="detailModal" tabindex="-1" role="dialog"
        aria-labelledby="modaldetailModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header" id="detailHeader">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" aria-hidden="true" (click)="close()">
                        <span aria-hidden="true" class="close-icon material-symbols-outlined" >
                            close
                        </span>
                    </button>
                    <h2 class="modal-title" id="vizionareModalLabel">Apartment {{numarulapartamentuluiselectat}} </h2>
                </div>

                <div class="modal-body">
                    <app-detali-modal-home *ngIf="modaldetalii"></app-detali-modal-home>
                </div>
                <div class="modal-footer">
                </div>
            </div>
        </div>
    </div>

    <section class="mob-timeline">
        <div class="mob-blue-block"></div>
        <div id="mob-timeline-image-wrapper">
            <div class="mob-white-block"></div>
            <img src="../../../assets/media/the-lake-timeline.png" alt="">

            <!--top infos-->
            <div class="blocB2">
                <h5>Block&nbsp;B2</h5>
                <h6>Phase&nbsp;NR.&nbsp;2.2</h6>
                <p>Sep.&nbsp;2023 <br> Sep.&nbsp;2024</p>
                <div class="pointers-top">
                    <div class="tl-lines-top"></div>
                    <div class="tl-circles-top"></div>
                </div>
            </div>

            <div class="blocD">
                <h5>Block&nbsp;D</h5>
                <h6>Phase&nbsp;NR.&nbsp;4</h6>
                <p>Oct.&nbsp;2025<br> Jan.&nbsp;2026</p>
                <div class="pointers-top">
                    <div class="tl-lines-top"></div>
                    <div class="tl-circles-top"></div>
                </div>
            </div>

            <div class="blocE">
                <h5>Block&nbsp;E</h5>
                <h6>Phase&nbsp;NR.&nbsp;4</h6>
                <p>Oct.&nbsp;2025 <br> Dec.&nbsp;2026</p>
                <div class="pointers-top">
                    <div class="tl-lines-top"></div>
                    <div class="tl-circles-top"></div>
                </div>
            </div>

            <div class="venue-spa">
                <h5>Gym,&nbsp;Pool<br>&&nbsp;Spa</h5>
                <h6>Phase&nbsp;NR.&nbsp;3.2</h6>
                <p>Jan.&nbsp;2024 <br> Dec.&nbsp;2024</p>
                <div class="pointers-top">
                    <div class="tl-lines-top"></div>
                    <div class="tl-circles-top"></div>
                </div>
            </div>

            <div class="vile">
                <h5>Villas</h5>
                <h6>Phase&nbsp;NR.&nbsp;5</h6>
                <p>Jan.&nbsp;2025 <br> Jan.&nbsp;2026</p>
                <div class="pointers-top">
                    <div class="tl-lines-top"></div>
                    <div class="tl-circles-top"></div>
                </div>
            </div>
            <!--down infos-->
            <div class="blocA">
                <div class="pointers-down">
                    <div class="tl-circles-down"></div>
                    <div class="tl-lines-down"></div>
                </div>
                <h5>Block&nbsp;A</h5>
                <h6>Phase&nbsp;NR.&nbsp;3.1</h6>
                <p>Jan.&nbsp;2024 <br> Jan.&nbsp;2026</p>
            </div>

            <div class="blocB1">
                <div class="pointers-down">
                    <div class="tl-circles-down"></div>
                    <div class="tl-lines-down"></div>
                </div>
                <h5>Block&nbsp;B1</h5>
                <h6>Phase&nbsp;NR.&nbsp;2.1</h6>
                <p>Completed</p>
            </div>

            <div class="blocC">
                <div class="pointers-down">
                    <div class="tl-circles-down"></div>
                    <div class="tl-lines-down"></div>
                </div>
                <h5>Block&nbsp;C</h5>
                <h6>Phase&nbsp;NR.&nbsp;1</h6>
                <p>Completed</p>
            </div>

            <div class="blocF">
                <div class="pointers-down">
                    <div class="tl-circles-down"></div>
                    <div class="tl-lines-down"></div>
                </div>
                <h5>Block&nbsp;F</h5>
                <h6>Phase&nbsp;NR.&nbsp;4</h6>
                <p>Jan.&nbsp;2025 <br> Dec.&nbsp;2026</p>
            </div>

            <div class="blocG">
                <div class="pointers-down">
                    <div class="tl-circles-down"></div>
                    <div class="tl-lines-down"></div>
                </div>
                <h5>Block&nbsp;G</h5>
                <h6>on&nbsp;hold</h6>
                <p>ApartHotel</p>
            </div>

            <div class="blocH">
                <div class="pointers-down">
                    <div class="tl-circles-down"></div>
                    <div class="tl-lines-down"></div>
                </div>
                <h5>Block&nbsp;H</h5>
                <h6>Phase&nbsp;NR.&nbsp;5</h6>
                <p>Jan.&nbsp;2025 <br> Jan.&nbsp;2026</p>
            </div>

            <div class="mob-restaurant">
                <div class="pointers-down">
                    <div class="tl-circles-down"></div>
                    <div class="tl-lines-down"></div>
                </div>
                <h5>Restaurant</h5>
                <h6>Phase&nbsp;NR.&nbsp;3.3</h6>
                <p>Jan.&nbsp;2024 <br> Dec.&nbsp;2025</p>
            </div>

            <div class="blocI">
                <div class="pointers-down">
                    <div class="tl-circles-down"></div>
                    <div class="tl-lines-down"></div>
                </div>
                <h5>Block&nbsp;I</h5>
                <h6>Phase&nbsp;NR.&nbsp;5</h6>
                <p>Jan.&nbsp;2025 <br> Jan.&nbsp;2026</p>
            </div>
        </div>

        <div class="mob-timeline-btns">
            <span id="timeline-prev1" class="material-symbols-outlined">arrow_circle_left</span>
            <span id="timeline-prev" (click)="buttonSlidePREV1()"
                class="material-symbols-outlined">arrow_circle_left</span>
            <span id="timeline-next" (click)="buttonSlideNEXT1()"
                class="material-symbols-outlined">arrow_circle_right</span>
            <span id="timeline-next1" class="material-symbols-outlined">arrow_circle_right</span>
        </div>
    </section>

    <section class="timeline">
        <div class="blue-block"></div>
        <div class="timeline-title">
            <div class="timeline-title-wrapper">
                <div class="title-dots"></div>
                <h3>Construction phases timeline</h3>
                <div class="title-dots"></div>
            </div>
            <div class="timeline-image-wrapper">
                <div class="white-block"></div>
                <img src="../../../assets/media/the-lake-timeline.png" alt="">

                <!--top infos-->
                <div class="blocB2">
                    <h5>Block B2</h5>
                    <h6>Phase NR. 2.2</h6>
                    <p>Mar.&nbsp;2024 <br> Mar.&nbsp;2025</p>
                    <div class="pointers-top">
                        <div class="tl-lines-top"></div>
                        <div class="tl-circles-top"></div>
                    </div>
                </div>

                <div class="blocD">
                    <h5>Block D</h5>
                    <h6>Phase NR. 4</h6>
                    <p>Oct.&nbsp;2025<br> Jan.&nbsp;2026</p>
                    <div class="pointers-top">
                        <div class="tl-lines-top"></div>
                        <div class="tl-circles-top"></div>
                    </div>
                </div>

                <div class="blocE">
                    <h5>Block E</h5>
                    <h6>Phase NR. 4</h6>
                    <p>Oct.&nbsp;2025 <br> Dec.&nbsp;2026</p>
                    <div class="pointers-top">
                        <div class="tl-lines-top"></div>
                        <div class="tl-circles-top"></div>
                    </div>
                </div>

                <div class="venue-spa">
                    <h5>Gym,&nbsp;Pool<br>&&nbsp;Spa</h5>
                    <h6>Phase&nbsp;NR.&nbsp;3.2</h6>
                    <p>Jan.&nbsp;2025 <br> Dec.&nbsp;2026</p>
                    <div class="pointers-top">
                        <div class="tl-lines-top"></div>
                        <div class="tl-circles-top"></div>
                    </div>
                </div>

                <div class="vile">
                    <h5>Villas</h5>
                    <h6>Phase NR. 4</h6>
                    <p>Jan.&nbsp;2026 <br> Jan.&nbsp;2028</p>
                    <div class="pointers-top">
                        <div class="tl-lines-top"></div>
                        <div class="tl-circles-top"></div>
                    </div>
                </div>
                <!--down infos-->
                <div class="blocA">
                    <div class="pointers-down">
                        <div class="tl-circles-down"></div>
                        <div class="tl-lines-down"></div>
                    </div>
                    <h5>Block A</h5>
                    <h6>Phase NR. 3.1</h6>
                    <p>Iul.&nbsp;2024 <br> Dec.&nbsp;2026</p>
                </div>

                <div class="blocB1">
                    <div class="pointers-down">
                        <div class="tl-circles-down"></div>
                        <div class="tl-lines-down"></div>
                    </div>
                    <h5>Block B1</h5>
                    <h6>Phase NR. 2.1</h6>
                    <p>Completed</p>
                </div>

                <div class="blocC">
                    <div class="pointers-down">
                        <div class="tl-circles-down"></div>
                        <div class="tl-lines-down"></div>
                    </div>
                    <h5>Block C</h5>
                    <h6>Phase NR. 1</h6>
                    <p>Completed</p>
                </div>

                <div class="blocF">
                    <div class="pointers-down">
                        <div class="tl-circles-down"></div>
                        <div class="tl-lines-down"></div>
                    </div>
                    <h5>Block F</h5>
                    <h6>Phase NR. 4</h6>
                    <p>Iun.&nbsp;2025 <br> Dec.&nbsp;2026</p>
                </div>

                <div class="blocG">
                    <div class="pointers-down">
                        <div class="tl-circles-down"></div>
                        <div class="tl-lines-down"></div>
                    </div>
                    <h5>Block G</h5>
                    <h6>OH HOLD</h6>
                    <p>ApartHotel</p>
                </div>

                <div class="blocH">
                    <div class="pointers-down">
                        <div class="tl-circles-down"></div>
                        <div class="tl-lines-down"></div>
                    </div>
                    <h5>Block H</h5>
                    <h6>Phase NR. 5</h6>
                    <p>Jan.&nbsp;2025 <br> Jan.&nbsp;2026</p>
                </div>

                <div class="restaurant">
                    <div class="pointers-down">
                        <div class="tl-circles-down"></div>
                        <div class="tl-lines-down"></div>
                    </div>
                    <h5>Restaurant</h5>
                    <h6>Phase&nbsp;NR.&nbsp;3.3</h6>
                    <p>Jan.&nbsp;2025 <br> Dec.&nbsp;2026</p>
                </div>

                <div class="blocI">
                    <div class="pointers-down">
                        <div class="tl-circles-down"></div>
                        <div class="tl-lines-down"></div>
                    </div>
                    <h5>Block I</h5>
                    <h6>Phase NR. 5</h6>
                    <p>Jan.&nbsp;2026 <br> Jan.&nbsp;2028</p>
                </div>

            </div>
        </div>
    </section>

    <section class="investitia-wrapper">
        <div class="inv-wrapper-wrapper">
            <div class="investitia">
                <h2>What is the ideal investment for&nbsp;you?</h2>
                <div class="investitia-blueBar"></div>
                <p>Smart real estate investing has been, is, and will be one of the best ways to transport money over
                    time.</p>
            </div>
            <div class="calculator">

                <div class="bigdiv">
                    <h3>Investment calculator</h3>
                    <p class="suma">Invested amount </p>
                    <input class="calcone" id="first" min="7000" placeholder="amount in €" type="number">
                    <p class="termen">Term <br></p>
                    <div class="labelandimput">
                        <div class="labelandimput2">
                            <input checked="checked" type="radio" id="age2" name="age" value="15" required>
                            <label class="tag" for="age2">18&nbsp;months</label>
                        </div>
                        <div class="labelandimput2">
                            <input type="radio" id="age3" name="age" value="35">
                            <label class="tag" for="age3">36&nbsp;months</label>
                        </div>
                        <div class="labelandimput2">
                            <input type="radio" id="age4" name="age" value="55">
                            <label class="tag" for="age4">54&nbsp;months</label>
                        </div>
                    </div>
                    <button onclick="add()" class="calc-btn">Calculate</button>
                    <!--<div onclick="add()" class="changeBtn">
                      <a class="calc-btn">CALCULEAZĂ-ȚI INVESTITIA</a>
                      </div>-->
                </div>
                <div class="rectangle">
                    <p class="procentul">TERM PROFIT&nbsp;%
                        <input class="firstresult" id="answer3" disabled="disabled" placeholder="_ %">
                    </p>

                    <p class="dobanda">TERM PROFIT&nbsp;€
                        <input class="firstresult" id="answer2" disabled="disabled" placeholder="_ €">
                    </p>

                    <p class="return">AMOUNT RETURNED ON TERM&nbsp;€
                        <input class="firstresult" id="answer" disabled="disabled" placeholder="_ €">
                    </p>
                </div>

            </div>
        </div>
    </section>

    <section class="lifestyle">
        <h2>Invest Smart</h2>
        <div class="lifestyleWrapper">
            <div class="lifesText">
                <h3>Real Estate + Hospitality = Passive&nbsp;Income</h3>
            </div>
            <img class="lifesImg" src="../../../assets/media/pattern/Artwork29.svg" alt="">
            <p class="lifesTxt">TBuy an apartment until November 1st 2024 and you benefit from 7 nights of vacation as bonus. Average price of €100,000, with parking space included.<br>
                &#8226; Total profit of<strong> 40,470 € </strong> in the first 3&nbsp;years.  <br>
                &#8226; Yield 13,49% per year, <span>40,47% </span>over 3&nbsp;years. <br>
                &#8226; 15% of property appreciation, with a minimum of 5%&nbsp;a&nbsp;year. <br>
            </p>
            <button class="lifesBtn" (click)="investairbnb()">DISCOVER THE OFFER</button>
        </div>
        <div class="vertLine"></div>
        <div class="lifestyleImg"></div>
    </section>


    <div class="demo">
        <div style="margin: 20px; text-align: -webkit-center;">
            <carousel [images]="images" [width]="900" [height]="600" [loop]="true" [cellsToShow]="1" [cellWidth]="1000"
                [overflowCellsLimit]="1" (next)='myNextFun()'>
            </carousel>
        </div>
    </div>

    <div class="demo-mob">
        <div style="margin: 20px; text-align: -webkit-center;">
            <carousel [images]="images" [width]="330" [height]="200" [loop]="true" [cellsToShow]="1" [cellWidth]="1000"
                [overflowCellsLimit]="1" (next)='myNextFun1()'>
            </carousel>
        </div>
    </div>

    <section class="locatia">
        <h2>Location</h2>
        <div class="locatiaWrapper">
            <div class="locatiaText">
                <h3>Discover the potential of a Premium&nbsp;location</h3>
            </div>
            <img class="locImg" src="../../../assets/media/pattern/Artwork30.svg" alt="">
            <p class="locatiaTxt">In real estate, there are 3 essential factors: location, location and… location. The
                complex is located in the western area of ​​Sibiu, benefiting from direct access to the A1 highway and
                fast road connections with the airport, but also with the economic center of the city.<br><br> Moreover,
                the project is built on the shore of the only commercial lake in the city, an area recently developed by
                Sibiu City Hall for leisure activities.</p>
            <button class="locatiaBtn" (click)="seelocatie()">DISCOVER THE LOCATION</button>
        </div>
        <div class="vertLine2"></div>
        <div class="locatiaImgs"></div>
    </section>

    <div class="locatia-accordion">
        <div id="panel1" (click)="locatiaAccordion(0)">
            <button id="accord1" class="loc-accordion">EDUCATION<span id="loc-icon1"
                    class="material-symbols-outlined">arrow_downward</span></button>
            <p>Kindergarten no.19, School no.12, School no. 13. School no. 18, School no. 23</p>
        </div>

        <div id="panel2" (click)="locatiaAccordion(1)">
            <button id="accord2" class="loc-accordion">shopping<span id="loc-icon2"
                    class="material-symbols-outlined">arrow_downward</span></button>
            <p>Prima Shopping Center, Promenada Mall, Cibin agricultural market</p>
        </div>

        <div id="panel3" (click)="locatiaAccordion(2)">
            <button id="accord3" class="loc-accordion">health<span id="loc-icon3"
                    class="material-symbols-outlined">arrow_downward</span></button>
            <p>The new regional hospital in Sibiu will be built in the neighborhood's vicinity.</p>
        </div>

        <div id="panel4" (click)="locatiaAccordion(3)">
            <button id="accord4" class="loc-accordion">recreation<span id="loc-icon4"
                    class="material-symbols-outlined">arrow_downward</span></button>
            <p>Depending on the weather or your mood, you can do indoor or outdoor sports here.</p>
        </div>
    </div>

    <section class="testemonials">
        <h2>Testimonials</h2>

        <app-testimonials></app-testimonials>

        <div class="bg-img-testm"></div>
    </section>

    <footer>
        <div class="footer-wrapper">
            <div class="footer-logo">
                <p>Developed by</p>
                <img src="../../../assets/media/logos/sigla_negative-02.png" alt="Novarion Logo"
                    onclick="NewTabNovarion()">
            </div>
            <div class="footer1">
                <h5>Sales office</h5>
                <p class="footer1-bold">The Lake Home</p>
                <a href="https://goo.gl/maps/u8YrSsReceFjCgq37" target=”_blank”>
                    <p>Tractorului street, 2-12<br>Sibiu, Romania</p>
                </a>
                <div>
                    <span>T.</span><a href="tel:+40721229999">+40 721 229 999</a> <br><br>
                    <span>T.</span><a href="mailto:sales@thelake.ro">sales@thelake.ro</a>
                </div>
                <img src="../../../assets/media/pattern/brand_icon.svg" alt="">
            </div>
            <div class="footer2">
                <h5>Choose your residence</h5>
                <div class="footer2-rezidentie">
                    <div class="rezidentie1">
                        <a (click)="footerlinks1()">STUDIO</a> <br>
                        <a (click)="footerlinks2()">2 room apartment</a><br>
                        <a (click)="footerlinks3()">3 room apartment</a><br>
                        <a (click)="footerlinks4()">5 room apartment</a><br>
                    </div>
                    <!--
                      <div class="rezidentie2">
                          <a href="">VILA TIP A</a><br>
                          <a href="">VILA TIP B</a><br>
                          <a href="">VILA TIP C</a><br>
                      </div>-->
                </div>
                <div class="footer2-pages">
                    <div class="pages1">
                        <a (click)="seehome()">HOME</a> <br>
                        <a (click)="seelifestyle()">LIFESTYLE</a><br>
                        <a (click)="seelocatie()">LOCATION</a><br>
                    </div>
                    <div class="pages2">
                        <a href="https://thelake.ro/galerie-en/">GALLERY</a><br>
                        <a (click)="seemedia()">MEDIA</a><br>
                        <a (click)="seedezvoltator()">DEVELOPER</a><br>
                    </div>
                </div>
                <div class="footer2-socialMedia">
                    <img src="../../../assets/media/icons/facebook_logo_icon.svg" alt="Facebook link"
                        onclick="NewTabFb()">
                    <img src="../../../assets/media/icons/linkedin_logo_icon.svg" alt="LinkedIn link"
                        onclick="NewTabLi()">
                    <img src="../../../assets/media/icons/instagram_logo_icon.svg" alt="Instagram link"
                        onclick="NewTabIg()">
                    <img src="../../../assets/media/icons/youtube_icon.svg" alt="Youtube link" onclick="NewTabYt()">
                </div>
                <div class="footer2-end">
                    <a (click)="seetermeni()">Privacy policy | Cookies</a>
                    <p>Brand & Web Design : <a href="https://www.mugurelfrincu.ro/" target="_blank"> Mugurel Frîncu</a>
                    </p>
                </div>
            </div>
            <div class="footer3">
                <h4>We will get back to you</h4>
                <p>Fill out the form below to get a fast reply from our sales representatives</p>
                <input type="text" placeholder="Name" [ngModelOptions]="{standalone: true}" [(ngModel)]="Name" required>
                <input type="email" placeholder="Email" [ngModelOptions]="{standalone: true}" [(ngModel)]="Email"
                    required>
                <input type="tel" placeholder="Phone" [ngModelOptions]="{standalone: true}" [(ngModel)]="Phone"
                    required>
                <textarea id="mesaj" placeholder="Leave us a message" [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="Message"></textarea>
                <div class="privacylink3"><input (click)="checked3()" id="checkbox3" type="checkbox" required><label
                        class="form-control3">I agree with the <a id="ppLink3" (click)="seetermeni()"> data processing
                            policy.</a></label></div>
                <h6 *ngIf="acestCamp2">This field is required</h6>
                <button class="deactive" type="button" (click)="sendFormOne3()">SEND</button>
            </div>
        </div>
    </footer>
</body>

<!-- * * * * * * * * * * End of Placeholder * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->

<router-outlet></router-outlet>

<script src="src/assets/scripts.js"></script>
