<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
    <title>Locuințe</title>
</head>


<body>
    <div class="header-bcground"></div>

    <header class="headerGrid">
        <div class="headerGrid-wrapper">
            <div class="header-titles">
                <img src="../../assets/media/logos/the_lake_logo_white.png" alt="The Lake home - Living Xperience logo"
                    (click)="seehome()">

                <div class="all-nav-elements">
                    <div class="contact-nav">

                        <div class="flexRow">
                        </div>

                        <div class="header-phone"><span id="header-icon-phone"
                                class="material-symbols-outlined">phone_iphone <a href="tel:+40721229999">+4 0721 229
                                    999</a> </span></div>
                        <span class="vertical-line"></span>
                        <div class="header-mail"><span id="header-icon-mail"
                                class="material-symbols-outlined">chat_bubble <a
                                    href="mailto:sales@thelake.ro">sales@thelake.ro</a></span></div>
                        <!-- Button trigger modal vizionare-->
                        <button class="header-button" type="button" data-toggle="modal"
                            data-target="#brosuraModal">vezi&nbsp;broșura</button>


                        <!-- Modal Vizionare-->
                        <div class="modal fade" id="vizionareModal" tabindex="-1" role="dialog"
                            aria-labelledby="vizionareModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                            aria-hidden="true">
                                            <span aria-hidden="true" class="close-icon material-symbols-outlined">
                                                close
                                            </span>
                                        </button>
                                        <h5 class="modal-title" id="vizionareModalLabel">Programează o vizionare</h5>
                                    </div>
                                    <div class="modal-body">
                                        <div class="p">
                                            <p>Trimite-ne datele tale de contact pentru a putea fi preluate de către
                                                echipa noastră.</p>
                                        </div>
                                        <form>
                                            <input type="text" class="modal-form" [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="Name" placeholder="Nume" required>
                                            <input type="email" class="modal-form" [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="Email" id="exampleFormControlInput1" placeholder="Email"
                                                required>
                                            <input type="tel" class="modal-form" [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="Phone" placeholder="Telefon" required>
                                            <textarea id="modal-mesaj" class="modal-form"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Message"
                                                placeholder="Scrie-ne un mesaj" rows="3"></textarea>

                                            <div class="privacylink1"><input (click)="checked()" id="checkbox1"
                                                    type="checkbox" required><label class="form-control1">Sunt de acord
                                                    cu <a id="ppLink" (click)="seetermeni()"
                                                        data-dismiss="modal">Consimțământul de prelucrare a
                                                        datelor.</a></label></div>
                                            <h6 *ngIf="acestCamp"> Acest câmp este obligatoriu</h6>

                                            <button class="deactive modal-button" type="button" (click)="sendFormOne()"
                                                data-dismiss="modal">TRIMITE MESAJUL</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Modal Brosura-->
                        <div class="modal fade" id="brosuraModal" tabindex="-1" role="dialog"
                            aria-labelledby="brosuraModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                            aria-hidden="true">
                                            <span aria-hidden="true" class="close-icon material-symbols-outlined">
                                                close
                                            </span>
                                        </button>
                                        <h5 class="modal-title" id="brosuraModalLabel">Vezi broșura</h5>
                                    </div>
                                    <div class="modal-body-brosura">
                                        <div class="p">
                                            <p>Completează formularul de mai jos pentru a descărca broșura The Lake
                                                Home.</p>
                                        </div>
                                        <form>
                                            <input type="text" class="modal-form" placeholder="Nume"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Name" required>
                                            <input type="email" class="modal-form" id="exampleFormControlInput1"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Email"
                                                placeholder="Email" required>
                                            <input type="tel" class="modal-form" placeholder="Telefon"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Phone" required>
                                            <!--<textarea id="modal-mesaj" class="modal-form"
                                                placeholder="Scrie-ne un mesaj" [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="Message" rows="3"></textarea>-->

                                            <div class="privacylink2"><input (click)="checked2()" id="checkbox2"
                                                    type="checkbox" required><label class="form-control2">Sunt de acord
                                                    cu <a id="ppLink2" data-dismiss="modal"
                                                        (click)="seetermeni()">Consimțământul de prelucrare a
                                                        datelor.</a></label></div>
                                            <h6 *ngIf="acestCamp1">Acest câmp este obligatoriu</h6>
                                            <button class="deactive modal-button-brosura" type="button"
                                                (click)="sendFormOne2()" data-dismiss="modal">Vezi broșura</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="logo-navbar">

                        <div class="mob-navbar">
                            <div class="distance-top-navbar">
                                <a class="blue-border" (click)="seelifestyle()">LIFESTYLE</a>
                                <a class="blue-border" (click)="footerlinks1()">ALEGE-ȚI&nbsp;LOCUINȚA</a>
                                <a class="blue-border" (click)="seelocatie()">LOCAȚIE</a>
                                <a class="blue-border" href="https://thelake.ro/galerie/">GALERIE</a>
                                <a class="blue-border" (click)="seemedia()">MEDIA</a>
                                <a class="blue-border" (click)="seedezvoltator()">DEZVOLTATOR</a>
                                <a (click)="seecontact()">CONTACT</a>
                            </div>

                        </div>
                        <div class="burger" (click)="navSlider()">
                            <div class="line1"></div>
                            <div class="line2"></div>
                            <div class="line3"></div>
                        </div>

                        <div class="navbar">
                            <a (click)="seelifestyle()">LIFESTYLE</a>
                            <a (click)="footerlinks1()">ALEGE-ȚI&nbsp;LOCUINȚA</a>
                            <a (click)="seelocatie()">LOCAȚIE</a>
                            <a href="https://thelake.ro/galerie/">GALERIE</a>
                            <a (click)="seemedia()">MEDIA</a>
                            <a (click)="seedezvoltator()">DEZVOLTATOR</a>
                            <a (click)="seecontact()">CONTACT</a>
                        </div>
                    </div>
                </div>
            </div>


            <!-- call to action buttons -->
            <div class="cta-btns">
                <div class="cta-visionare" type="button" data-toggle="modal" data-target="#vizionareModal">
                    <span id="cta-viz-icon" class="material-symbols-outlined">chat_bubble</span><a>programează o
                        vizionare</a>
                </div>
                <div class="cta-brosura" type="button" data-toggle="modal" data-target="#brosuraModal">
                    <img src="../../assets/media/icons/brosuraIcon.svg" alt="download brosura icon"><a>Vezi broșura</a>
                </div>

            </div>


            <div class="sticky-btn" type="button" data-toggle="modal" data-target="#vizionareModal"><span
                    id="cta-viz-icon" class="material-symbols-outlined">chat_bubble</span>
                <h5>programează <br> o vizionare</h5>
            </div>

        </div>
    </header>



    <section class="page-content-wrapper">
        <div class="page-content">

            <div class="newArticle">
                <img class="newArticle-img" src="../../assets/{{BlogList.blogphoto}}">

                <div class="newArticle-txt">
                    <h6>{{BlogList.blogcategorie}}</h6>

                    <h4>{{BlogList.blogtitlu}}</h4>

                    <div class="info">
                        <h3>{{BlogList.blogdata}}</h3>
                        <h3>{{BlogList.blogsursa}}</h3>
                    </div>


                </div>
            </div>

            <div class="newArticle-body">
                <p class="newArticle-dscption" [innerHTML]="displayCuprins"></p>


            </div>

        </div>
        <div class="backbtn"><button (click)="seeBlog()">Vezi alte articole</button></div>

    </section>


    <footer>
        <div class="footer-wrapper">
            <div class="footer-logo">
                <p>Proiect dezvoltat de:</p>
                <img src="../../assets/media/logos/sigla_negative-02.png" alt="Novarion Logo"
                    onclick="NewTabNovarion()">
            </div>
            <div class="footer1">
                <h5>Birou vânzări:</h5>
                <p class="footer1-bold">The Lake Home</p>
                <a href="https://goo.gl/maps/u8YrSsReceFjCgq37" target=”_blank”>
                    <p>Strada Tractorului, nr. 12- 28,<br>Sibiu, România</p>
                </a>
                <div>
                    <span>T.</span><a href="tel:+40721229999">+40 721 229 999</a> <br>
                    <span>T.</span><a href="tel:+40721229999">+40 721 229 999</a> <br><br>
                    <span>T.</span><a href="mailto:sales@thelake.ro">sales@thelake.ro</a>
                </div>
                <img src="../../assets/media/pattern/brand_icon.svg" alt="">
            </div>
            <div class="footer2">
                <h5>Alege-ți rezidența</h5>
                <div class="footer2-rezidentie">
                    <div class="rezidentie1">
                        <a (click)="footerlinks1()">APARTAMENT STUDIO DUBLU</a> <br>
                        <a (click)="footerlinks2()">APARTAMENT CU 2 CAMERE</a><br>
                        <a (click)="footerlinks3()">APARTAMENT CU 3 CAMERE</a><br>
                        <a (click)="footerlinks4()">APARTAMENT CU 5 CAMERE</a><br>

                    </div>
                    <!-- 
                    <div class="rezidentie2">
                        <a href="">VILA TIP A</a><br>
                        <a href="">VILA TIP B</a><br>
                        <a href="">VILA TIP C</a><br>
                    </div>
                    -->
                </div>
                <div class="footer2-pages">
                    <div class="pages1">
                        <a (click)="seehome()">HOME</a> <br>
                        <a (click)="seelifestyle()">LIFESTYLE</a><br>
                        <a (click)="seelocatie()">LOCAȚIE</a><br>
                    </div>
                    <div class="pages2">
                        <a href="https://locuintamea.novarion.ro/">INVESTESTE</a><br>
                        <a (click)="seemedia()">MEDIA</a><br>
                        <a (click)="seedezvoltator()">DEZVOLTATOR</a><br>
                    </div>
                </div>
                <div class="footer2-socialMedia">
                    <img src="../../assets/media/icons/facebook_logo_icon.svg" alt="Facebook link" onclick="NewTabFb()">
                    <img src="../../assets/media/icons/linkedin_logo_icon.svg" alt="LinkedIn link" onclick="NewTabLi()">
                    <img src="../../assets/media/icons/instagram_logo_icon.svg" alt="Instagram link"
                        onclick="NewTabIg()">
                    <img src="../../assets/media/icons/youtube_icon.svg" alt="Youtube link" onclick="NewTabYt()">
                </div>
                <div class="footer2-end">
                    <a (click)="seetermeni()">Politica de confidențialitate | Cookies</a>
                    <p>Brand & Web Design : <a href="https://www.mugurelfrincu.ro/" target="_blank"> Mugurel Frîncu</a>
                    </p>

                </div>
            </div>
            <div class="footer3">
                <h4>Te contactăm noi</h4>
                <p>Completează formularul de mai jos pentru a primi în cel mai scurt timp un răspuns din partea echipei
                    de vânzări</p>
                <input type="text" placeholder="Nume" [ngModelOptions]="{standalone: true}" [(ngModel)]="Name" required>
                <input type="email" placeholder="Email" [ngModelOptions]="{standalone: true}" [(ngModel)]="Email"
                    required>
                <input type="tel" placeholder="Telefon" [ngModelOptions]="{standalone: true}" [(ngModel)]="Phone"
                    required>
                <textarea id="mesaj" placeholder="Scrie-ne un mesaj" [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="Message"></textarea>
                <div class="privacylink3"><input (click)="checked3()" id="checkbox3" type="checkbox" required><label
                        class="form-control3">Sunt de acord cu <a id="ppLink3" (click)="seetermeni()"> Consimțământul de
                            prelucrare a datelor.</a></label></div>
                <h6 *ngIf="acestCamp2">Acest câmp este obligatoriu</h6>
                <button class="deactive" type="button" (click)="sendFormOne3()">TRIMITE MESAJUL</button>
            </div>
        </div>
    </footer>








    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js"></script>
    <script src="apartament.scripts.js"></script>
</body>