<button type="button" class="btn btn-primary mr-1"
              (click)="addClick()"
              style="margin:5px;">
              adauga blog
</button>

<table class="table table-striped">
    <thead>
      <tr>

        <th>titlu</th>
        <th>editeaza</th>
        <th>sterge</th>

    </tr>
</thead>
<tbody>
    <tr *ngFor="let dataItem of ToolList"  >

        <td>{{dataItem.blogtitlu}}</td>
        <td>
            <button type="button" class="btn btn-warning mr-1"
            (click)="editClick(dataItem)"
            style="margin:5px;">
            edit
            </button>
        </td>
        <td>
          <button type="button" class="btn btn-danger mr-1"
              (click)="deleteClick(dataItem)"
              style="margin:5px;">
              Sterge
              </button>
        </td>
        </tr>
    </tbody>
</table>
