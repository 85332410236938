<header class="headerGrid">
  <div class="headerGrid-wrapper">
      <div class="header-titles">
          <div class="header-logos">
              <img class="tlh" src="../../assets/media/logos/TLH_logo_white.png"
                  alt="logo of The Lake home - Living Xperience logo" (click)="seefestival()">

              <img class="nvrn" src="../../assets/media/logos/sigla_negative-02.png"
                  alt="logo of Novarion - Xperience the Future" onclick="NewTabNovarion()">
          </div>


          <div class="all-nav-elements">
              <div class="contact-nav">

                  <div class="flexRow">
                  </div>
              </div>
          </div>
          
          <div class="mob-page-title">
            <div *ngIf="!choosing">
              <p>{{winnername}}</p>
            </div>
            
            <div *ngIf="choosing">
              <p>{{randomNamea}}</p>
            </div>
            
            <button (click)="chose()">alege</button>
          </div>
      </div>
  </div>
</header>


  