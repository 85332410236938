
<head>
    <link rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />

</head>

<body>
    
    <section class="mob-alege-locuinta">
        <h2>Alege-ți locuința</h2>
        <div class="mob-alege-locuinta-wrapper">
            <div class="mob-choose-bloc">

                <h3>Bloc {{BuildingNameFilter}}</h3>
                <div class="mob-select-blocs">
                    <div class="blue-block"></div>

                    <div id="carouselExampleIndicators1" class="carousel slide" data-ride="0" data-interval="false">
                        <div class="carousel-inner" id="selectBlocs-inner">
                            <div class="carousel-item active">
                                <img class="d-block w-100" [src]="'../../assets/media/blocs/' + buildingFotoB"
                                    [class]="buildingFotoClass" alt="First slide">
                            </div>

                            <div class="carousel-item ">
                                <img class="d-block w-100" [src]="'../../assets/media/blocs/' + SecondPhoto"
                                    [class]="SecondPhotoClass" alt="Second slide">
                            </div>

                        </div>

                        <a class="carousel-control-prev" href="#carouselExampleIndicators1" role="button"
                            data-slide="prev" (click)="substractNumber()">
                            <span id="mob-bloc-prev" class="material-symbols-outlined">arrow_circle_left</span>
                        </a>
                        <a class="carousel-control-next" href="#carouselExampleIndicators1" role="button"
                            data-slide="next" (click)="addNumber()">
                            <span id="mob-bloc-next" class="material-symbols-outlined">arrow_circle_right</span>
                        </a>
                    </div>


                    <div class="mob-dropdown">
                        <div class="etaj-dropdown">
                            <div class="blocs-cicle"><span id="etaj-big-arrow"
                                    class="material-symbols-outlined">chevron_right</span>
                            </div>
                            <div class="etaj-names" id="moreetaj">
                                <h6 class="etaj-arrow">Etaj</h6>
                                <ul class="nrEtaj">
                                    <li (click)="makeEtajParter()">0</li>
                                    <li (click)="makeEtaj1()">1</li>
                                    <li (click)="makeEtaj2()">2</li>
                                    <li (click)="makeEtaj3()">3</li>
                                    <li (click)="makeEtaj4()">4</li>
                                </ul>
                            </div>
                        </div>
                        <div class="cam-dropdown">
                            <div class="blocs-cicle"><span id="cam-big-arrow"
                                    class="material-symbols-outlined">chevron_right</span></div>
                            <div class="cam-names" id="moreCam">
                                <h6 class="cam-arrow">Camere</h6>
                                <ul class="nrCam">
                                    <li class="deactive" (click)="makerooms1()">1</li>
                                    <li class="deactive" (click)="makerooms2()">2</li>
                                    <li class="deactive" (click)="makerooms3()">3</li>
                                    <li class="deactive" (click)="makerooms4()">4</li>
                                    <li class="deactive" (click)="makerooms5()">5</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="mob-flats-plans">
                <div class="border-horiz"></div>
                <div class="plans-details">
                    <div class="roomsNo">
                        <h5>{{hollowNrOfRooms}} / {{hollowFloor}}</h5>
                        <p>{{hollowApartmentNumb}}</p>
                    </div>
                    <div class="split-line"></div>
                    <div class="s-utila">
                        <h5>SUPRAFAȚĂ</h5>
                        <p>{{hollowSurface}}</p>
                    </div>
                </div>
                <div class="plans-imgs">
                    <img src="{{hollowPhoto}}" alt="">
                </div>

                <div class="choose-plans-btns">
                    <p>APARTAMENTUL ANTERIOR</p>
                    <span id="mob-plans-prev1" class="material-symbols-outlined">arrow_circle_left</span>
                    <span id="mob-plans-prev" (click)="slidetoprevappartment()"
                        class="material-symbols-outlined">arrow_circle_left</span>
                    <span id="mob-plans-next" (click)="slidetonextappartment()"
                        class="material-symbols-outlined">arrow_circle_right</span>
                    <span id="mob-plans-next1" class="material-symbols-outlined">arrow_circle_right</span>

                    <p>APARTAMENTUL URMATOR</p>
                </div>
            </div>
        </div>
    </section>

    <section class="alege-locuinta">
        <h2>Alege-ți locuința</h2>
        <div class="gray-block"><span id="gray-arrow" class="material-symbols-outlined">trending_flat</span>
        </div>
        <div class="alege-locuinta-wrapper">
            <div class="choose-bloc">
                <h3>Bloc {{BuildingNameFilter}}</h3>
                <p>Selectează etajul <br> pe&nbsp;imagine</p>

                <div class="select-blocs">
                    <div id="filtercarusel" class="carousel slide" data-ride="carousel " data-interval="false">
                        <div class="carousel-inner" id="selectBlocsInner">
                            <div class="carousel-item active">
                                <img class="d-block w-100" [src]="'../../assets/media/blocs/' + buildingFotoB"
                                    [class]="buildingFotoClass" alt="First slide">
                            </div>

                            <div class="carousel-item ">
                                <img id="B2" class="d-block w-100" [src]="'../../assets/media/blocs/' + SecondPhoto"
                                    [class]="SecondPhotoClass" alt="Second slide">
                            </div>

                        </div>
                        <a class="carousel-control-prev" href="#filtercarusel" role="button" data-slide="prev"
                            (click)="substractNumber()">
                            <span id="bloc-prev" class="material-symbols-outlined">arrow_circle_left</span>
                        </a>
                        <a class="carousel-control-next" href="#filtercarusel" role="button" data-slide="next"
                            (click)=" addNumber()">
                            <span id="bloc-next" class="material-symbols-outlined">arrow_circle_right</span>
                        </a>
                    </div>
                    <div #myDiv style="height: 20px; background-color: rgba(183, 55, 55, 0);"></div>

                    <ul id="blC-hover-btns">
                        <li class="mansarda" (click)="makeEtaj4()"></li>
                        <li class="etaj3" (click)="makeEtaj3()"></li>
                        <li class="etaj2" (click)="makeEtaj2()"></li>
                        <li class="etaj1" (click)="makeEtaj1()"></li>
                        <li class="parter" (click)="makeEtajParter()"></li>
                    </ul>

                    <ul id="blA-hover-btns">
                        <li class="mansarda" (click)="makeEtaj4()"></li>
                        <li class="etaj3" (click)="makeEtaj3()"></li>
                        <li class="etaj2" (click)="makeEtaj2()"></li>
                        <li class="etaj1" (click)="makeEtaj1()"></li>
                        <li class="parter" (click)="makeEtajParter()"></li>
                    </ul>
                </div>
                <button class="deactive"></button>
            </div>

            <div class="choose-etajFlat">
                <div class="etaj-flat">
                    <div class="choose-etaj">
                        <h4>Etaj</h4>
                        <ul class="etaj-btns" id="etajBtns">
                            <li (click)="makeEtajParter()">0</li>
                            <li (click)="makeEtaj1()">1</li>
                            <li (click)="makeEtaj2()">2</li>
                            <li (click)="makeEtaj3()">3</li>
                            <li (click)="makeEtaj4()">4</li>

                        </ul>
                    </div>
                    <div class="choose-room">
                        <h4>NR.CAMERE</h4>
                        <ul class="camere-btns">
                            <li class="deactive" (click)="makerooms1()">1</li>
                            <li class="deactive" (click)="makerooms2()">2</li>
                            <li class="deactive" (click)="makerooms3()">3</li>
                            <li class="deactive" (click)="makerooms4()">4</li>
                            <li class="deactive" (click)="makerooms5()">5</li>
                        </ul>
                    </div>
                </div>

                <div class="flats-plans">
                    <div class="plans-imgs">
                        <div class="plan-image ">
                            <img src={{hollowPhotoFloor}}>
                            
                         <div class="ifBlockB2">
                            <ul [ngStyle]="{'display': BuildingFloorFilter === '0' ? 'block' : 'none'}" class="b2parter">
                                
                                <li class="blocB2ap1" (click)="filterByApartmentNumberAndBuilding('1', 'B2')"></li>
                                <li class="blocB2ap2 sold"></li>
                                <li class="blocB2ap3 sold"></li>
                                <li class="blocB2ap4 sold"></li>
                            </ul>
                            <ul [ngStyle]="{'display': BuildingFloorFilter === '1' ? 'block' : 'none'}" class="b2etaj1">
                                <li class="blocB2ap5 sold"></li>
                                <li class="blocB2ap6 sold"></li>
                                <li class="blocB2ap7 sold" ></li>
                                <li class="blocB2ap8" (click)="filterByApartmentNumberAndBuilding('8','B2')"></li>
                                <li class="blocB2ap9" (click)="filterByApartmentNumberAndBuilding('9','B2')"></li>
                                <li class="blocB2ap10 sold" ></li>
                            </ul>
                            <ul [ngStyle]="{'display': BuildingFloorFilter === '2' ? 'block' : 'none'}" class="b2etaj2">
                                <li class="blocB2ap11 sold"></li>
                                <li class="blocB2ap12 sold"></li>
                                <li class="blocB2ap13 sold"></li>
                                <li class="blocB2ap14 sold"></li>
                                <li class="blocB2ap15" (click)="filterByApartmentNumberAndBuilding('15', 'B2')"></li>
                                <li class="blocB2ap16 sold"></li>
                            </ul>
                            <ul [ngStyle]="{'display': BuildingFloorFilter === '3' ? 'block' : 'none'}" class="b2etaj3">
                                <li class="blocB2ap17 sold"></li>
                                <li class="blocB2ap18 sold"></li>
                                <li class="blocB2ap19"(click)="filterByApartmentNumberAndBuilding('19', 'B2')"></li>
                                <li class="blocB2ap20 sold"></li>
                                <li class="blocB2ap21"(click)="filterByApartmentNumberAndBuilding('21', 'B2')"></li>
                            </ul>
                            <ul [ngStyle]="{'display': BuildingFloorFilter === '4' ? 'block' : 'none'}" class="b2etaj4">
                                <li class="blocB2ap22"(click)="filterByApartmentNumberAndBuilding('22', 'B2')"></li>
                                <li class="blocB2ap23"(click)="filterByApartmentNumberAndBuilding('23', 'B2')"></li>

                            </ul>
                         </div>
                        </div>
                        
                            
                    </div>
                    <div class="border-horiz"></div>
                    <div class="plans-details">
                        <div class="roomsNo">
                            <h5>{{hollowNrOfRooms}} / {{hollowFloor}}</h5>
                            <p>{{hollowApartmentNumb}}</p>
                        </div>
                        <div class="split-line"></div>
                        <div class="s-utila">
                            <h5>SUPRAFAȚĂ
                            </h5>
                            <p>{{hollowSurface}}</p>
                        </div>

                    </div>
                    <ul class="plan-indicator">
                        <!--  <li *ngFor="dataItem of BuildingList"></li>  -->
                    </ul>
                    <div class="choose-plans-btns">
                        <span id="plans-prev1" class="material-symbols-outlined">arrow_circle_left</span>
                        <span id="plans-prev" (click)="slidetoprevappartment()"
                            class="material-symbols-outlined">arrow_circle_left</span>
                        <span id="plans-next" (click)="slidetonextappartment()"
                            class="material-symbols-outlined">arrow_circle_right</span>
                        <span id="plans-next1" class="material-symbols-outlined">arrow_circle_right</span>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Modal Detail plan -->
    <div class="modal fade" id="detailModal" tabindex="-1" role="dialog" aria-labelledby="modaldetailModal" aria-hidden="true">

        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header" id="detailHeader">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" aria-hidden="true"
                        (click)="close()">
                        <span aria-hidden="true" class="close-icon material-symbols-outlined">
                            close
                        </span>
                    </button>
                    <h2 class="modal-title" id="vizionareModalLabel">Apartamentul {{numarulapartamentuluiselectat}}</h2>
                </div>

                <div class="modal-body">
                    <app-detali-modal *ngIf="modaldetalii"></app-detali-modal>
                </div>
                <div class="modal-footer">
                </div>
            </div>
        </div>
    </div>
</body>
