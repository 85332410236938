import { Component, OnInit } from '@angular/core';
import {SharedService} from 'src/app/shared.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-add-b',
  templateUrl: './add-b.component.html',
  styleUrls: ['./add-b.component.css']
})
export class AddBComponent implements OnInit {

  constructor(private service:SharedService, private router: Router) { }
  ToolList:any=[];
  tol:any;

  ngOnInit(): void {
    this.refreshTolList();

  }


  deleteClick(item: { BuildingId: any; }){
    if(confirm('Are you sure??')){
    this.service.deleteform(item.BuildingId).subscribe(data=>{
      (data.toString());
        this.refreshTolList();
      })
      this.refreshTolList();
    }
  }

  refreshTolList(){
    this.service.getformList().subscribe(data=>{
        this.ToolList = data.filter(item => item.blogtitlu);
    });
}

addClick(){
  this.service.editBlogSelect={
    BuildingId:0,
  }

  this.router.navigateByUrl('/add-b/add' )

}

editClick(item: any){
  this.service.editBlogSelect=item;
  this.router.navigateByUrl('/add-b/add' )
}
}
