<body>  
    <!--<img id="bckgimg" src="../../../assets/media/thank-you.jpg" alt="">-->
    <div class="typage">
        <div class="typage-wrapper">
            <div class="idvlogos">
                <img class="tlh" src="../../assets/media/logos/the_lake_logo_white.png"
                    alt="logo of The Lake home - Living Xperience logo" (click)="seehome()">

                <img class="nvrn" src="../../assets/media/logos/sigla_negative-02.png"
                    alt="logo of Novarion - Xperience the Future" (click)="seehome()">
            </div>

            <div class="title">
                <h1>Mulțumim!</h1>
                <p>Participarea ta a fost confirmată, iar acesta este numărul tău de înregistrare: <br> <br></p>
                <H2>{{raffleNumber}}</H2>
                <!--<H2>{{raffleNumber}}</H2>-->
                <p>Te rugăm să salvezi acest număr, deoarece va fi biletul tău pentru tombolă. Nu-ți fă griji, vom anunța și numele câștigătorilor în timpul evenimentului. <br><br>

                   Doar pentru a-ți reaminti, extragerea va avea loc pe: <br>
                   <strong> 27 aprillie <br>
                    La 19:00 <br>
                    Iar câștigătorii vor fi anunțați prin email.   <br> <br></strong>
                    
                    Dacă ai întrebări sau întâmpini probleme legate de concurs, nu ezita să ne contactezi la adresa <a href="mailto:info@novarion.ro">info@novarion.ro</a> pentru asistență. <br><br>
                    
                    <a (click)="goEvent()">Te așteptăm cu nerăbdare la tombola Festival Lake pentru o experiență de neuitat!</a>

                    <!--Ține minte, dacă ești în căutarea locuinței perfecte, te invităm să consulți cele mai recente proiecte ale noastre de la  <a
                    (click)="seehome()">The Lake Home</a>. Apartamentele noastre premium combină luxul, confortul și comoditatea, astfel încât suntem convinși că vei găsi aici casa visurilor tale.
                    <br><br>

                    <a (click)="seehome()">Vizitează-ne astăzi și descoperă o lume a posibilităților! </a>
                    <br><br>-->

                </p>
            </div>
        </div>
        

    </div>
</body>