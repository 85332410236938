<!-- Login container -->
<div class="login-container" *ngIf="!isLoggedIn">
    <h2>Login</h2>
    <form (submit)="onSubmit()">
        <div class="input-group">
            <label for="username">Username:</label>
            <input type="text" id="username" [(ngModel)]="username" name="username" required>
        </div>
        
        <div class="input-group">
            <label for="password">Password:</label>
            <input type="password" id="password" [(ngModel)]="password" name="password" required>
        </div>

        <button type="submit">Login</button>
    </form>
</div>


<!-- Page container (This will be displayed once the user is logged in) -->
<div class="page-container" *ngIf="isLoggedIn">
    <div class="offer-container" *ngIf="isLoggedIn">
        <!-- Title and subtitle for choosing the apartment -->
        <h1>Crează Ofertă</h1>
        <h2>Alege apartamentul</h2>
        
        <label for="buildingSelect">Alege Clădirea:</label>
        <select id="buildingSelect" [(ngModel)]="selectedBuilding" (change)="filterBuildingApartments()">
            <option value="B">B1</option>
            <option value="B2">B2</option>
            <option value="C">C</option>

            <!-- Add more options if needed -->
        </select>
        
        <div>
            <label for="apartmentSelect">Număr Apartament:</label>
            <select id="apartmentSelect" [(ngModel)]="apartmentNumber" (change)="filterApartments()">
                <option *ngFor="let apt of filteredApartments" [value]="apt.ApartmentNumb">
                    {{apt.ApartmentNumb}}
                </option>
            </select>
        </div>
        
        
    
        <!-- Subtitle and input for person's name -->
        <h2>Persoana ofertată</h2>
        <div>
            <label for="personName">Nume:</label>
            <input type="text" id="personName" [(ngModel)]="personName">
        </div>
        <label for="expiryDate">Data expirare ofertă:</label>
        <input type="date" id="expiryDate" [(ngModel)]="expiryDate">

    
        <!-- Subtitle and inputs for offer details -->
        <h2>Detalii ofertă</h2>
        <div>
            <label for="price">Preț lista:</label>
            <input type="text" [(ngModel)]="price" placeholder="Price">


            <label for="parking">Parcare:</label>
            <select id="parking" [(ngModel)]="selectedParkingValue"  (change)="calculateFinalPrice()">
                <option *ngFor="let option of parkingOptions" [value]="option.value">{{ option.label }}</option>
            </select>
            
            
            <label for="downPaymentAmount">Plată avans:</label>
            <input type="number" [(ngModel)]="downPaymentAmount" (input)="updateDiscount(); calculateFinalPrice();" placeholder="Enter amount">
            
            
            
            
            
            <label for="discount">Discount %:</label>
            <input type="number" id="discount" [(ngModel)]="discount" readonly> 
            
            
    
            <label for="tva">TVA:</label>
            <select id="tva" [(ngModel)]="selectedTvaValue" (change)="calculateFinalPrice()">
            <option *ngFor="let option of tvaOptions" [value]="option.value">{{ option.label }}</option>
            </select>

            <label for="finalPrice">Preț final:</label>
            <input type="number" id="finalPrice" [(ngModel)]="finalPrice">

            
        </div>


        
        <div>
            Plata Ramasa: {{ plataRamasa | currency }}
        </div>
        
        <div>
            <label for="modalitatePlata">Modalitate Plata:</label>
            <select id="modalitatePlata" [(ngModel)]="selectedModalitatePlata" (change)="onModalitatePlataChange()">
                <option value="rate">Rate</option>
                <option value="altfel">Altfel</option>
            </select>
        </div>
        
        
        <!-- Conditionally showing Cate Rate & Timp Plata Rate -->
        <div *ngIf="showRateFields">
            <label for="cateRate">Cate Rate:</label>
            <input type="number" id="cateRate" [(ngModel)]="cateRate" (change)="calculateValoareRata()">
            
            <label for="timpPlataRate">Timp Plata Rate:</label>
            <select [(ngModel)]="timpPlataRate" *ngIf="showRateFields" (change)="calculateValoareRata()">
                <option *ngFor="let timeOption of timpPlataRateOptions" [value]="timeOption">
                  {{ timeOption }}
                </option>
            </select>
        
            <div>
                Valoare Rata: {{ valoareRata | currency }} {{ timpPlataRate }}
            </div>
        </div>
        

        
        <!-- Textarea for observations -->
        <div>
            <label for="observations">Observații:</label>
            <textarea id="observations" rows="4" [(ngModel)]="observations"></textarea>
        </div>
    
        <!-- Add a button to submit the offer or further actions if needed -->
    <!-- Show this button when 'rate' is selected -->
    <button (click)="DescarcaPlanRate()" *ngIf="selectedModalitatePlata === 'rate'">Trimite Ofertă</button>

    <!-- Show this button when 'altfel' is selected -->
    <button (click)="DescarcaPlanAltfel()" *ngIf="selectedModalitatePlata === 'altfel'">Trimite Ofertă</button>


    </div>
    
</div>
