<head>
    <link rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
</head>

<body>
    <div class="header-bcground"></div>

    <header class="headerGrid">
        <div class="headerGrid-wrapper">
            <div class="header-titles">
                <img src="../../../assets/media/logos/the_lake_logo_white.png"
                    alt="The Lake home - Living Xperience logo" (click)="seehome()">

                <div class="all-nav-elements">
                    <div class="contact-nav">

                        <div class="flexRow">
                            <a (click)="changeRoEn()">RO</a>
                        </div>

                        <div class="header-phone"><span id="header-icon-phone"
                                class="material-symbols-outlined">phone_iphone <a href="tel:+40721229999">+4 0721 229
                                    999</a> </span></div>
                        <span class="vertical-line"></span>
                        <div class="header-mail"><span id="header-icon-mail"
                                class="material-symbols-outlined">chat_bubble <a
                                    href="mailto:sales@thelake.ro">sales@thelake.ro</a></span></div>
                        <!-- Button trigger modal vizionare-->
                        <button class="header-button" type="button" data-toggle="modal"
                            data-target="#brosuraModal">SEE&nbsp;THE&nbsp;BROCHURE</button>


                        <!-- Modal Vizionare-->
                        <div class="modal fade" id="vizionareModal" tabindex="-1" role="dialog"
                            aria-labelledby="vizionareModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                            aria-hidden="true">
                                            <span aria-hidden="true" class="close-icon material-symbols-outlined">
                                                close
                                            </span>
                                        </button>
                                        <h5 class="modal-title" id="vizionareModalLabel">Schedule a viewing</h5>
                                    </div>
                                    <div class="modal-body">
                                        <div class="p">
                                            <p>Leave your contact details so our team can get in touch with you.</p>
                                        </div>
                                        <form>
                                            <input type="text" class="modal-form" [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="Name" placeholder="Name" required>
                                            <input type="email" class="modal-form" [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="Email" id="exampleFormControlInput1" placeholder="Email"
                                                required>
                                            <input type="tel" class="modal-form" [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="Phone" placeholder="Phone" required>
                                            <textarea id="modal-mesaj" class="modal-form"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Message"
                                                placeholder="Leave us a message" rows="3"></textarea>

                                            <div class="privacylink1"><input (click)="checked()" id="checkbox1"
                                                    type="checkbox" required><label class="form-control1">I agree with
                                                    the <a id="ppLink" (click)="seetermeni()" data-dismiss="modal">data
                                                        processing policy.</a></label></div>
                                            <h6 *ngIf="acestCamp">This field is required</h6>

                                            <button class="deactive modal-button" type="button" (click)="sendFormOne()"
                                                data-dismiss="modal">SEND</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Modal Brosura-->
                        <div class="modal fade" id="brosuraModal" tabindex="-1" role="dialog"
                            aria-labelledby="brosuraModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                            aria-hidden="true">
                                            <span aria-hidden="true" class="close-icon material-symbols-outlined">
                                                close
                                            </span>
                                        </button>
                                        <h5 class="modal-title" id="brosuraModalLabel">See the brochure</h5>
                                    </div>
                                    <div class="modal-body-brosura">
                                        <div class="p">
                                            <p>Complete the form below to see The Lake Home brochure.</p>
                                        </div>
                                        <form>
                                            <input type="text" class="modal-form" placeholder="Name"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Name" required>
                                            <input type="email" class="modal-form" id="exampleFormControlInput1"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Email"
                                                placeholder="Email" required>
                                            <input type="tel" class="modal-form" placeholder="Phone"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Phone" required>

                                            <div class="privacylink2"><input (click)="checked2()" id="checkbox2"
                                                    type="checkbox" required><label class="form-control2">I agree with
                                                    the<a id="ppLink2" data-dismiss="modal" (click)="seetermeni()">data
                                                        processing policy.</a></label></div>
                                            <h6 *ngIf="acestCamp1">This field is required</h6>
                                            <button class="deactive modal-button-brosura" type="button"
                                                (click)="sendFormOne2()" data-dismiss="modal">See brochure</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- XMAS promo Modal-->
                        <div class="modal fade" id="XmasModal" tabindex="-1" role="dialog"
                            aria-labelledby="XmasModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content XmasContent">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                            aria-hidden="true">
                                            <span aria-hidden="true" class="close-icon material-symbols-outlined">
                                                close
                                            </span>
                                        </button>
                                        <h5 class="modal-title XmasTitle" id="OfertaModalLabel">HO, HO,
                                            <span>HOME</span>
                                        </h5>
                                    </div>
                                    <div class="modal-body-oferta">
                                        <div class="p">

                                            <p> <span class="xmasSubTitle">Serbează <strong>Crăciunul</strong> acasă și
                                                    fă loc
                                                    sub brad pentru <strong>un cadou în plus.</strong> </span><br><br>

                                                Achiziționează unul din ultimele apartamente disponibile
                                                în blocul C sau achită <strong>un avans de 50% </strong>pentru un
                                                apartament în blocul B1 și primești cadou de casă nouă
                                                un <strong>robot de bucătărie Thermomix TM6.</strong> <br><br>

                                                În plus, îți oferim un<strong> avantaj client</strong> cuprins între
                                                <strong>2,500 și
                                                    38,000 euro </strong>în baza avansului din prețul de listă pentru
                                                noua ta locuință în blocurile B1 și B2.<br><br>

                                                Lasă-ne datele tale și programează o vizionare.
                                            </p>
                                        </div>
                                        <form>
                                            <input type="text" class="modal-form" placeholder="Nume"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Name" required>
                                            <input type="email" class="modal-form" id="exampleFormControlInput1"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Email"
                                                placeholder="Email" required>
                                            <input type="tel" class="modal-form" placeholder="Telefon"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Phone" required>
                                            <textarea id="modal-mesaj" class="modal-form"
                                                placeholder="Scrie-ne un mesaj" [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="Message" rows="3"></textarea>

                                            <div class="privacylink2"><input (click)="checked4()" id="checkbox2"
                                                    type="checkbox" required><label class="form-control2">Sunt de acord
                                                    cu <a id="ppLink2" data-dismiss="modal"
                                                        (click)="seetermeni()">Consimțământul de prelucrare a
                                                        datelor.</a></label></div>
                                            <h6 *ngIf="acestCamp1">Acest câmp este obligatoriu</h6>
                                            <button class="deactive modal-button-oferta" type="button"
                                                (click)="sendFormOferta()" data-dismiss="modal">TRIMITE MESAJUL</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="logo-navbar">

                        <div class="mob-navbar">
                            <div class="distance-top-navbar">
                                <a class="blue-border" (click)="seelifestyle()">LIFESTYLE</a>
                                <a class="blue-border" (click)="footerlinks1()">CHOOSE&nbsp;YOUR&nbsp;RESIDENCE</a>
                                <a class="blue-border" (click)="seelocatie()">LOCATION</a>
                                <a class="blue-border" href="https://thelake.ro/galerie-en/">GALLERY</a>
                                <a class="blue-border" (click)="seemedia()">MEDIA</a>
                                <a class="blue-border" (click)="seedezvoltator()">DEVELOPER</a>
                                <a (click)="seecontact()">CONTACT</a>
                            </div>

                        </div>
                        <div class="burger" (click)="navSlider()">
                            <div class="line1"></div>
                            <div class="line2"></div>
                            <div class="line3"></div>
                        </div>

                        <div class="navbar">
                            <a (click)="seelifestyle()">LIFESTYLE</a>
                            <a (click)="footerlinks1()">CHOOSE&nbsp;YOUR&nbsp;RESIDENCE</a>
                            <a (click)="seelocatie()">LOCATION</a>
                            <a href="https://thelake.ro/galerie-en/">GALLERY</a>
                            <a (click)="seemedia()">MEDIA</a>
                            <a (click)="seedezvoltator()">DEVELOPER</a>
                            <a (click)="seecontact()">CONTACT</a>
                        </div>
                    </div>
                </div>
                <div class="mob-page-title">
                    <h1>Media</h1>
                </div>
            </div>

            <div class="header-slider">
                <div id="carouselExampleControls" class="carousel slide" data-ride="carousel" data-interval="false">
                    <div class="carousel-inner" id="headerSlider-inner">
                        <div class="carousel-item active">
                            <img class="d-block w-100" src="../../../assets/media/headerSliders/media_4.jpg" alt="">
                        </div>
                        <div class="carousel-item">
                            <img class="d-block w-100 testImg" src="../../../assets/media/headerSliders/media_1.jpg"
                                alt="">
                        </div>
                        <div class="carousel-item">
                            <img class="d-block w-100" src="../../../assets/media/headerSliders/media_2.jpg" alt="">
                        </div>
                        <div class="carousel-item">
                            <img class="d-block w-100" src="../../../assets/media/headerSliders/media_3.jpg" alt="">
                        </div>
                    </div>
                    <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span id="bloc-prev" class="material-symbols-outlined">arrow_circle_left</span>
                    </a>
                    <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span id="bloc-next" class="material-symbols-outlined">arrow_circle_right</span>
                    </a>
                </div>
            </div>


            <!-- call to action buttons -->
            <div class="cta-btns">
                <div class="cta-visionare" type="button" data-toggle="modal" data-target="#vizionareModal">
                    <span id="cta-viz-icon"
                        class="material-symbols-outlined">chat_bubble</span><a>SCHEDULE&nbsp;A&nbsp;VIEWING</a>
                </div>
                <div class="cta-brosura" type="button" data-toggle="modal" data-target="#brosuraModal">
                    <img src="../../../assets/media/icons/brosuraIcon.svg" alt="download brosura icon"><a>SEE THE
                        BROCHURE</a>
                </div>
                
            </div>

            <div class="sticky-btn" type="button" data-toggle="modal" data-target="#vizionareModal"><span id="cta-viz-icon" class="material-symbols-outlined">chat_bubble</span>
                <h5>Schedule <br> a viewing</h5>
            </div>ca


            <!--xmasPromo
            <div class="XMAS-sticky-btn" type="button" data-toggle="modal" data-target="#XmasModal"><img
                src="../../../assets/media/icons/favpng_vector-graphics-gift-clip-art-box.png" alt="">
        </div>-->
        </div>
        <div class="page-title">
            <h1>Media</h1>
        </div>
    </header>

    <div class="mob-header-slider">
        <div class="header-slider-bcground"></div>
        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
                <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
            </ol>
            <div class="carousel-inner" id="mobSlider-inner">
                <div class="carousel-item active">
                    <img class="d-block w-100" src="../../../assets/media/headerSliders/media_4.jpg" alt="">
                </div>
                <div class="carousel-item">
                    <img class="d-block w-100" src="../../../assets/media/headerSliders/media_1.jpg" alt="">
                </div>
                <div class="carousel-item">
                    <img class="d-block w-100" src="../../../assets/media/headerSliders/media_2.jpg" alt="">
                </div>
                <div class="carousel-item">
                    <img class="d-block w-100" src="../../../assets/media/headerSliders/media_3.jpg" alt="">
                </div>
            </div>
        </div>
    </div>

    <section class="page-content-wrapper">
        <div class="page-content">
            <h2 id="content-1">We have a constant media presence</h2>

            <img id="content-2" class="lifestyle-icon" src="../../../assets/media/pattern/Artwork29.svg" alt="">

            <p id="content-3">We are happy to share with you the latest news and the most interesting information in
                real estate, economy and smart investments.</p>

            <div class="division"></div>
        </div>

        <div class="blue-bckg"></div>

    </section>

    <section class="video-tl-mob">
        <div class="tl-bars">
            <div class="circle cirlc1"></div>
            <div class="actualBar"></div>
            <div class="circle"></div>
            <div class="actualBar"></div>
            <div class="circle"></div>
        </div>
        <div class="tl-videos">
            <div class="box">
                <h3>March 17, 2023</h3>
                <h2>The Lake Home - George Butunoiu visits The Neighbourhood of Good&nbsp;Manners</h2>
                <iframe src="https://www.youtube.com/embed/2FPFrILrC6U" title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </div>
            <div class="box">
                <h3>May 9, 2022</h3>
                <h2>ZF Invest in Romania - Sibiu</h2>
                <iframe src="https://www.youtube.com/embed/Q6x3Qnd5AuE" title="YouTube video player" frameborder="0"
                    allow="encrypted-media; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </div>
            <div class="box lastBox">
                <h3>April 8, 2022</h3>
                <h2>Legile Afacerilor - Raul Țărnaru, Founder of Novarion</h2>
                <iframe src="https://www.youtube.com/embed/imCf_W7rs6c" title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </div>

            <button class="mobMoreVideos" (click)="NewTabYT()">VEZI MAI MULT</button>
        </div>
    </section>

    <section class="video-tl-wrapper">
        <div class="video-tl">
            <div class="leftBar">
                <div class="box">
                    <h3>March 17, 2023</h3>
                    <h2>The Lake Home - George Butunoiu visits The Neighbourhood of Good Manners</h2>
                    <p>An in-depth review of all the special facilities offered by the most original residential complex in Sibiu.</p>
                </div>
                <div class="box">
                    <iframe src="https://www.youtube.com/embed/Q6x3Qnd5AuE" title="YouTube video player" frameborder="0"
                        allow="encrypted-media; autoplay; clipboard-write; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </div>
                <div class="box lastbox">
                    <h3>April 8, 2022</h3>
                    <h2>Legile Afacerilor - Raul Țărnaru, Founder of Novarion</h2>
                    <p>
                        International Property Awards brings excellence to Romania. The Lake Home brings it to Sibiu, as
                        one of the award-winning projects and the successful vision of Raul Țărnaru, founder of
                        Novarion.
                    </p>
                </div>
            </div>

            <div class="tl-bars">
                <div class="circle cirlc1"></div>
                <div class="actualBar"></div>
                <div class="circle"></div>
                <div class="actualBar"></div>
                <div class="circle"></div>
                <div class="actualBar lastBar"></div>
            </div>

            <div class="rightBar">
                <div class="box">
                    <iframe src="https://www.youtube.com/embed/2FPFrILrC6U" title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </div>
                <div class="box">
                    <h3>May 9, 2022</h3>
                    <h2>ZF Invest in Romania - Sibiu</h2>
                    <p>How is the banking system involved in supporting the economy? Opportunities, European Funds and
                        PNRR.</p>
                </div>
                <div class="box lastbox">
                    <iframe src="https://www.youtube.com/embed/imCf_W7rs6c" title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </div>
            </div>
        </div>
        <div class="more-btn">
            <button class="MoreVideos" (click)="NewTabYT2()">SEE MORE</button>
        </div>
    </section>

    <section class="articles-slider">
        <div id="carouselExampleIndicators2" class="carousel slide" data-ride="carousel" data-interval="false">
            <ol class="carousel-indicators" id="articles-indicators">
                <li data-target="#carouselExampleIndicators2" data-slide-to="0" class="active"></li>
                <li data-target="#carouselExampleIndicators2" data-slide-to="1"></li>
                <li data-target="#carouselExampleIndicators2" data-slide-to="2"></li>
                <li data-target="#carouselExampleIndicators2" data-slide-to="3"></li>
                <li data-target="#carouselExampleIndicators2" data-slide-to="4"></li>
                <li data-target="#carouselExampleIndicators2" data-slide-to="5"></li>
                <li data-target="#carouselExampleIndicators2" data-slide-to="6"></li>
            </ol>

            <div class="carousel-inner" id="article-inner">
                <div class="article-item carousel-item active">
                    <div class="art-img">
                        <img src="../../assets/media/articles/turnul-sfatului2.jpeg" alt="">
                    </div>
                    <div class="art-text">
                        <h4>Turnul Sfatului</h4>
                        <p>Novarion, an urban regeneration project developed around the Binder Lake, has attracted over 1,000 investors.
                        </p>
                        <a (click)="NewTabTurnul2()">Read more</a>
                    </div>
                </div>
                <div class="article-item carousel-item">
                    <div class="art-img">
                        <img src="../../assets/media/articles/ziarul-financiar2.png" alt="">
                    </div>
                    <div class="art-text">
                        <h4> ZF Real Estate by storia.ro</h4>
                        <p>The Novarion project in Sibiu has raised investments of 7 million euros so far.                        
                        </p>
                        <a (click)="NewTabZF()">Read more</a>
                    </div>
                </div>
                <div class="article-item carousel-item">
                    <div class="art-img">
                        <img src="../../assets/media/articles/bani-azi.png" alt="">
                    </div>
                    <div class="art-text">
                        <h4>TVR Info</h4>
                        <p>Raul Țărnaru was a guest on the show "Banii, azi" hosted by Mădălina Chițu.
                        </p>
                        <a (click)="NewTabTVR()">Read more</a>
                    </div>
                </div>
                <div class="article-item carousel-item">
                    <div class="art-img">
                        <img src="../../../assets/media/articles/adevarul.png" alt="">
                    </div>
                    <div class="art-text">
                        <h4>Adevărul.ro</h4>
                        <p>Non-cultural tourism with good food and beautiful mothers.</p>
                        <a (click)="NewTabAdevarul()">Read more</a>
                    </div>
                </div>
                <div class="article-item carousel-item">
                    <div class="art-img">
                        <img src="../../../assets/media/articles/anuala.ro.jpeg" alt="">
                    </div>
                    <div class="art-text">
                        <h4>Anuala.ro</h4>
                        <p>Nomination in the "Built architecture / collective housing architecture" section. The best
                            architecture client.</p>
                        <a (click)="NewTabAnuala()">Read more</a>
                    </div>
                </div>
                <div class="article-item carousel-item">
                    <div class="art-img">
                        <img src="../../../assets/media/articles/profit.ro.jpeg" alt="">
                    </div>
                    <div class="art-text">
                        <h4>Profit.ro</h4>
                        <p>Awarded on the real estate market in Romania. The Good Manners Neighbohood is being built in
                            Sibiu.</p>
                        <a (click)="NewTabProfit()">Read more</a>
                    </div>
                </div>
                <div class="article-item carousel-item">
                    <div class="art-img">
                        <img src="../../../assets/media/articles/forbes.png" alt="">
                    </div>
                    <div class="art-text">
                        <h4>Forbes România</h4>
                        <p>80 M Euros invested in The Lake Home residential complex in Sibiu.</p>
                        <a (click)="NewTabForbes()">Read more</a>
                    </div>
                </div>
                <div class="article-item carousel-item">
                    <div class="art-img">
                        <img src="../../../assets/media/articles/leaderstalk.webp" alt="">
                    </div>
                    <div class="art-text">
                        <h4>Leaders Talk</h4>
                        <p>Raul Ţărnaru, founder of Novarion.</p>
                        <a (click)="NewTabLeaders()">Read more</a>
                    </div>
                </div>
                <div class="article-item carousel-item">
                    <div class="art-img">
                        <img src="../../../assets/media/articles/turnul-sfatului.jpeg" alt="">
                    </div>
                    <div class="art-text">
                        <h4>Turnul Sfatului</h4>
                        <p>The first building in The Lake Home - The Good Manners Neighborhood - was inaugurated.</p>
                        <a (click)="NewTabTurnul()">Read more</a>
                    </div>
                </div>
                <div class="article-item carousel-item">
                    <div class="art-img">
                        <img src="../../../assets/media/articles/produs-in-ardeal.jpeg" alt="">
                    </div>
                    <div class="art-text">
                        <h4>Produs&nbsp;în&nbsp;Ardeal</h4>
                        <p>Meta Estate Trust invested 500,000 euros in the first phase of The Lake Home residential
                            complex in Sibiu.</p>
                        <a (click)="NewTabProdus()">Read more</a>
                    </div>
                </div>
                <div class="article-item carousel-item">
                    <div class="art-img">
                        <img src="../../../assets/media/articles/ziarul-financiar.jpg" alt="">
                    </div>
                    <div class="art-text">
                        <h4>Ziarul Financiar</h4>
                        <p>Raul Ţărnaru,CEO, Novarion: I see a stabilization on the construction materials market. Sibiu
                            is becoming a real estate pole.</p>
                        <a (click)="NewTabZiarul()">Read more</a>
                    </div>
                </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleIndicators2" role="button" data-slide="prev"
                id="artic-arrow1">
                <span id="article-prev" class="material-symbols-outlined">arrow_circle_left</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleIndicators2" role="button" data-slide="next"
                id="artic-arrow2">
                <span id="article-next" class="material-symbols-outlined">arrow_circle_right</span>
            </a>
        </div>
    </section>


    <!-- new slider test
        <section class="articles-slider">
        <div id="carouselExampleIndicators2" class="carousel slide" data-ride="carousel" data-interval="false">
            <ol class="carousel-indicators">
              <li data-target="#carouselExampleIndicators2" data-slide-to="0" class="active"></li>
              <li data-target="#carouselExampleIndicators2" data-slide-to="1"></li>
              <li data-target="#carouselExampleIndicators2" data-slide-to="2"></li>
              <li data-target="#carouselExampleIndicators2" data-slide-to="3"></li>
              <li data-target="#carouselExampleIndicators2" data-slide-to="4"></li>
              <li data-target="#carouselExampleIndicators2" data-slide-to="5"></li>
            </ol>
            <div class="carousel-inner" id="article-inner">
                <div class="article-item carousel-item active">
                  <div class="art-img">
                    <img src="../../../assets/media/articles/profit.ro.jpeg" alt="">
                  </div>
                  <div class="art-text">
                      <h4>Profit.ro</h4>
                      <p>Premieră pe piața imobiliară din România. La Sibiu se construiește Cartierul Bunelor Maniere.</p>
                      <a (click)="NewTabProfit()">CITESTE MAI MULT</a>
                  </div>
                </div>
              <div class="article-item carousel-item">
                <div class="art-img">
                    <img src="../../../assets/media/articles/forbes.png" alt="">
                  </div>
                  <div class="art-text">
                      <h4>Forbes România</h4>
                      <p>Investiție de 80 milioane de euro în complexul rezidențial The Lake Home din Sibiu.</p>
                      <a (click)="NewTabForbes()">CITESTE MAI MULT</a>
                  </div>
              </div>
              <div class="article-item carousel-item">
                <div class="art-img">
                    <img src="../../../assets/media/articles/leaderstalk.webp" alt="">
                  </div>
                  <div class="art-text">
                      <h4>Leaders Talk</h4>
                      <p>Ce citeşte Raul Ţărnaru, fondatorul Novarion.</p>
                      <a (click)="NewTabLeaders()">CITESTE MAI MULT</a>
                  </div>
              </div>
              <div class="article-item carousel-item">
                <div class="art-img">
                    <img src="../../../assets/media/articles/turnul-sfatului.jpeg" alt="">
                  </div>
                  <div class="art-text">
                      <h4>Turnul Sfatului</h4>
                      <p>A fost inaugurat primul imobil din The Lake Home – Cartierul Bunelor Maniere.</p>
                      <a (click)="NewTabTurnul()">CITESTE MAI MULT</a>
                  </div>
              </div>
              <div class="article-item carousel-item">
                <div class="art-img">
                    <img src="../../../assets/media/articles/produs-in-ardeal.jpeg" alt="">
                  </div>
                  <div class="art-text">
                      <h4>Produs&nbsp;în&nbsp;Ardeal</h4>
                      <p>Meta Estate Trust a investit 500.000 de euro în prima fază a ansamblului rezidențial The Lake Home din Sibiu.</p>
                      <a (click)="NewTabProdus()">CITESTE MAI MULT</a>
                  </div>
              </div>
              <div class="article-item carousel-item">
                <div class="art-img">
                    <img src="../../../assets/media/articles/ziarul-financiar.jpg" alt="">
                  </div>
                  <div class="art-text">
                      <h4>Ziarul Financiar</h4>
                      <p>Raul Ţărnaru, CEO, Novarion: Văd o stabilizare pe piaţa materialelor de construcţii. Sibiul devine un pol imobiliar.</p>
                      <a (click)="NewTabZiarul()">CITESTE MAI MULT</a>
                  </div>
              </div>
            </div>

            <a class="carousel-control-prev" href="#carouselExampleIndicators2" role="button" data-slide="prev">
              <span id="article-prev" class="material-symbols-outlined" aria-hidden="true">arrow_circle_left</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleIndicators2" role="button" data-slide="next">
              <span id="article-next" class="material-symbols-outlined" aria-hidden="true">arrow_circle_right</span>
            </a>
          </div>
    </section>-->

    <section class="allpages-bottom">
        <section class="investitia-wrapper" id="INVESTEȘTE">

            <div class="inv-wrapper-wrapper">
                <div class="investitia">
                    <h2>What is the ideal investment for&nbsp;you?</h2>
                    <div class="investitia-blueBar"></div>
                    <p>Smart real estate investing has been, is, and will be one of the best ways to transport money
                        over time.</p>
                </div>
                <div class="calculator">

                    <div class="bigdiv">
                        <h3>Investment calculator</h3>
                        <p class="suma">Invested amount</p>
                        <input class="calcone" id="first" min="7000" placeholder="amount in €" type="number">
                        <p class="termen">Term <br></p>
                        <div class="labelandimput">
                            <div class="labelandimput2">
                                <input type="radio" id="age2" name="age" value="15" checked="checked">
                                <label class="tag" for="age2">18&nbsp;months</label>
                            </div>
                            <div class="labelandimput2">
                                <input type="radio" id="age3" name="age" value="35">
                                <label class="tag" for="age3">36&nbsp;months</label>
                            </div>
                            <div class="labelandimput2">
                                <input type="radio" id="age4" name="age" value="55">
                                <label class="tag" for="age4">54&nbsp;months</label>
                            </div>
                        </div>
                        <button onclick="add()" class="calc-btn">Calculate</button>
                    </div>
                    <div class="rectangle">
                        <p class="procentul">TERM PROFIT&nbsp;%
                            <input class="firstresult" id="answer3" disabled="disabled" placeholder="_ %">
                        </p>

                        <p class="dobanda">TERM PROFIT&nbsp;€
                            <input class="firstresult" id="answer2" disabled="disabled" placeholder="_ €">
                        </p>

                        <p class="return">AMOUNT RETURNED ON TERM&nbsp;€
                            <input class="firstresult" id="answer" disabled="disabled" placeholder="_ €">
                        </p>
                    </div>

                </div>
            </div>
        </section>

        <section class="testemonials">
            <h2>Testimonials</h2>

            <app-testimonials></app-testimonials>

            <div class="bg-img-testm"></div>
        </section>
    </section>

    <footer>
        <div class="footer-wrapper">
            <div class="footer-logo">
                <p>Developed by</p>
                <img src="../../../assets/media/logos/sigla_negative-02.png" alt="Novarion Logo"
                    onclick="NewTabNovarion()">
            </div>
            <div class="footer1">
                <h5>Sales office</h5>
                <p class="footer1-bold">The Lake Home</p>
                <a href="https://goo.gl/maps/u8YrSsReceFjCgq37" target=”_blank”>
                    <p>Tractorului street, 2-12<br>Sibiu, Romania</p>
                </a>
                <div>
                    <span>T.</span><a href="tel:+40721229999">+40 721 229 999</a> <br><br>
                    <span>T.</span><a href="mailto:sales@thelake.ro">sales@thelake.ro</a>
                </div>
                <img src="../../../assets/media/pattern/brand_icon.svg" alt="">
            </div>
            <div class="footer2">
                <h5>Choose your residence</h5>
                <div class="footer2-rezidentie">
                    <div class="rezidentie1">
                        <a (click)="footerlinks1()">STUDIO</a> <br>
                        <a (click)="footerlinks2()">2 room apartment</a><br>
                        <a (click)="footerlinks3()">3 room apartment</a><br>
                        <a (click)="footerlinks4()">5 room apartment</a><br>
                    </div>
                    <!--
                    <div class="rezidentie2">
                        <a href="">VILA TIP A</a><br>
                        <a href="">VILA TIP B</a><br>
                        <a href="">VILA TIP C</a><br>
                    </div>-->
                </div>
                <div class="footer2-pages">
                    <div class="pages1">
                        <a (click)="seehome()">HOME</a> <br>
                        <a (click)="seelifestyle()">LIFESTYLE</a><br>
                        <a (click)="seelocatie()">LOCATION</a><br>
                    </div>
                    <div class="pages2">
                        <a href="https://thelake.ro/galerie-en/">GALLERY</a><br>
                        <a (click)="seemedia()">MEDIA</a><br>
                        <a (click)="seedezvoltator()">DEVELOPER</a><br>
                    </div>
                </div>
                <div class="footer2-socialMedia">
                    <img src="../../../assets/media/icons/facebook_logo_icon.svg" alt="Facebook link"
                        onclick="NewTabFb()">
                    <img src="../../../assets/media/icons/linkedin_logo_icon.svg" alt="LinkedIn link"
                        onclick="NewTabLi()">
                    <img src="../../../assets/media/icons/instagram_logo_icon.svg" alt="Instagram link"
                        onclick="NewTabIg()">
                    <img src="../../../assets/media/icons/youtube_icon.svg" alt="Youtube link" onclick="NewTabYt()">
                </div>
                <div class="footer2-end">
                    <a (click)="seetermeni()">Privacy policy | Cookies</a>
                    <p>Brand & Web Design : <a href="https://www.mugurelfrincu.ro/" target="_blank"> Mugurel Frîncu</a>
                    </p>
                </div>
            </div>
            <div class="footer3">
                <h4>We will get back to you</h4>
                <p>Fill out the form below to get a fast reply from our sales representatives</p>
                <input type="text" placeholder="Name" [ngModelOptions]="{standalone: true}" [(ngModel)]="Name" required>
                <input type="email" placeholder="Email" [ngModelOptions]="{standalone: true}" [(ngModel)]="Email"
                    required>
                <input type="tel" placeholder="Phone" [ngModelOptions]="{standalone: true}" [(ngModel)]="Phone"
                    required>
                <textarea id="mesaj" placeholder="Leave us a message" [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="Message"></textarea>
                <div class="privacylink3"><input (click)="checked3()" id="checkbox3" type="checkbox" required><label
                        class="form-control3">I agree with the <a id="ppLink3" (click)="seetermeni()"> data processing
                            policy.</a></label></div>
                <h6 *ngIf="acestCamp2">This field is required</h6>
                <button class="deactive" type="button" (click)="sendFormOne3()">SEND</button>
            </div>
        </div>
    </footer>
</body>