import { Component, Input, OnInit } from '@angular/core';
import {SharedService} from 'src/app/shared.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-detali-modal',
  templateUrl: './detali-modal.component.html',
  styleUrls: ['./detali-modal.component.css']
})
export class DetaliModalComponent implements OnInit {

  constructor(private service:SharedService,  private router: Router, private datePipe: DatePipe) { }

  @Input() adm2:any;
  userselectedroom : any


  BuildingListWithoutFilter:any=[];
  BuildingId!: string;
  BuildingName!: string;
  BuildingPhoto!: string;
  BuildingFloor!: string;
  ApartmentNumb!: string;
  ApartmentPhoto!: string;
  ApartmentSurface!: string;
  ApartmentRooms!: string;
  ApartmentPrice!: string;
  ApartmentBalconSurface!: string;
  ApartmentOrentation!: string;
  ApartmentBathrooms!: string;
  SurfaceDormitor!: string;
  SurfaceBaie!: string;
  SurfaceHol!: string;
  SurfaceRoom!: string;
  hollowfloor!: string;
  hollowfloorENG!: string;
  hollowPhoto!: string;
  ListForFilter:any=[];
  BuildingNameFilter!: string;
  BuildingFloorFilter!: string;
  BuildingRoomsFilter!: string;
  camere!: string;
  ApartmentPDF!: string;
  roDetalii:boolean=false;
  enDetalii:boolean=false;

  pretlace!: string;

  ngOnInit(): void {
    if(this.service.serviceRoDetalii==true){
      this.roDetalii=true;
      this.enDetalii=false;
    }else if( this.service.serviceEnDetalii==true)
    {
      this.enDetalii=true;
      this.roDetalii=false;
    }

    this.userselectedroom = this.service.selectedApartment


      this.userselectedroom = this.service.selectedApartment
      this.BuildingId=this.userselectedroom.BuildingId;
      this.BuildingName=this.userselectedroom.BuildingName;
      this.BuildingPhoto=this.userselectedroom.BuildingPhoto
      this.BuildingFloor=this.userselectedroom.BuildingFloor;
      this.ApartmentNumb=this.userselectedroom.ApartmentNumb;
      this.ApartmentPhoto=this.userselectedroom.ApartmentPhoto
      this.ApartmentSurface=this.userselectedroom.ApartmentSurface;
      this.ApartmentRooms=this.userselectedroom.ApartmentRooms;
      this.ApartmentPrice=this.userselectedroom.ApartmentPrice;
      this.ApartmentBalconSurface=this.userselectedroom.ApartmentBalconSurface;
      this.ApartmentOrentation=this.userselectedroom.ApartmentOrentation;
      this.ApartmentBathrooms=this.userselectedroom.ApartmentBathrooms;
      this.SurfaceDormitor=this.userselectedroom.SurfaceDormitor;
      this.SurfaceBaie=this.userselectedroom.SurfaceBaie;
      this.SurfaceHol=this.userselectedroom.SurfaceHol;
      this.SurfaceRoom=this.userselectedroom.SurfaceRoom;
      this.ApartmentPDF=this.userselectedroom.ApartmentPDF;
      this.hollowPhoto =  "../../../assets/" + this.userselectedroom.BuildingPhoto    
    


    this.ListForFilter = [1,2,3,4,5]
    if(this.BuildingFloor == "0"){
      this.hollowfloor = "parter"
    }
    else{
      this.hollowfloor = this.BuildingFloor
    }
    if(this.BuildingFloor == "0"){
      this.hollowfloorENG = "ground floor"
    }
    else{
      this.hollowfloorENG = this.BuildingFloor
    }

    this.BuildingNameFilter="B"
    this.BuildingRoomsFilter="1"
    this.BuildingFloorFilter="0"
    if(this.userselectedroom.ApartmentRooms == 1){
      this.camere="cameră"
    }
    else{
      this.camere="camere"
    }

    if(this.userselectedroom.ApartmentPrice<120000){
      if (+this.userselectedroom.ApartmentNumb === 27) {
        this.pretlace = "PREȚ LA ALB";
      } 
      else {
        this.pretlace = "PREȚ LA CHEIE";
      }
    }
      else{
      this.pretlace="PREȚ LA ALB"
    }
  
  }


  DescarcaPlan(){
    window.open("../../../assets/pdf/ApartmentsPDF/"+this.ApartmentPDF)

  }


}
