import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-checklist-pg2',
  templateUrl: './checklist-pg2.component.html',
  styleUrls: ['./checklist-pg2.component.css']
})
export class ChecklistPg2Component implements OnInit {

  constructor(private service:SharedService) { }

  ngOnInit(): void {
    this.initializeAdvanced(31); 

    this.service.advanced1=this.advanced[1]
    this.startScoring();
  }

  nrparcare!: number;


  advanced: number[] = [];
  totalsum : number = 0

  makeparking(nr: number, index: number) {
    this.advanced[nr] = index;
    this.makeballparcareblue(nr, index);
    console.log(this.advanced[1])
    console.log(this.advanced[29])


    
  this.service.advanced1 = this.advanced[1];
  this.service.advanced2 = this.advanced[2];
  this.service.advanced3 = this.advanced[3];
  this.service.advanced4 = this.advanced[4];
  this.service.advanced5 = this.advanced[5];
  this.service.advanced6 = this.advanced[6];
  this.service.advanced7 = this.advanced[7];
  this.service.advanced8 = this.advanced[8];
  this.service.advanced9 = this.advanced[9];
  this.service.advanced10 = this.advanced[10];
  this.service.advanced11 = this.advanced[11];
  this.service.advanced12 = this.advanced[12];
  this.service.advanced13 = this.advanced[13];
  this.service.advanced14 = this.advanced[14];
  this.service.advanced15 = this.advanced[15];
  this.service.advanced16 = this.advanced[16];
  this.service.advanced17 = this.advanced[17];
  this.service.advanced18 = this.advanced[18];
  this.service.advanced19 = this.advanced[19];
  this.service.advanced20 = this.advanced[20];
  this.service.advanced21 = this.advanced[21];
  this.service.advanced22 = this.advanced[22];
  this.service.advanced23 = this.advanced[23];
  this.service.advanced24 = this.advanced[24];
  this.service.advanced25 = this.advanced[25];
  this.service.advanced26 = this.advanced[26];
  this.service.advanced27 = this.advanced[27];
  this.service.advanced28 = this.advanced[28];
  this.service.advanced29 = this.advanced[29];
  this.service.advanced30 = this.advanced[30];



  this.service.checklist2 = (
    this.advanced[1] + " advanced1" +
    this.advanced[2] + " advanced2" +
    this.advanced[3] + " advanced3" +
    this.advanced[4] + " advanced4" +
    this.advanced[5] + " advanced5" +
    this.advanced[6] + " advanced6" +
    this.advanced[7] + " advanced7" +
    this.advanced[8] + " advanced8" +
    this.advanced[9] + " advanced9" +
    this.advanced[10] + " advanced10" +
    this.advanced[11] + " advanced11" +
    this.advanced[12] + " advanced12" +
    this.advanced[13] + " advanced13" +
    this.advanced[14] + " advanced14" +
    this.advanced[15] + " advanced15" +
    this.advanced[16] + " advanced16" +
    this.advanced[17] + " advanced17" +
    this.advanced[18] + " advanced18" +
    this.advanced[19] + " advanced19" +
    this.advanced[20] + " advanced20" +
    this.advanced[21] + " advanced21" +
    this.advanced[22] + " advanced22" +
    this.advanced[23] + " advanced23" +
    this.advanced[24] + " advanced24" +
    this.advanced[25] + " advanced25" +
    this.advanced[26] + " advanced26" +
    this.advanced[27] + " advanced27" +
    this.advanced[28] + " advanced28" +
    this.advanced[29] + " advanced29" +
    this.advanced[30] + " advanced30"
  );
  

  }

  makeballparcareblue(nr: number, index: number) {
    this.makeparcarenormal(nr);
    const blueparcare = Array.from(document.querySelectorAll(`.nrCamere-${nr} li`));
    blueparcare[index].className = "active";

  }
  
  makeparcarenormal(nr: number) {
    const parcare = document.querySelectorAll(`.nrCamere-${nr} li`);
    for (let i = 0; i < parcare.length; i++) {
      this.checkballparcarefromactivetonormal(i, nr);
    }
  }
  
  checkballparcarefromactivetonormal(index: number, nr: number) {
    const parcare = document.querySelectorAll(`.nrCamere-${nr} li`);
    if (parcare[index].classList.contains("active")) {
      parcare[index].classList.remove("active");
    }
  }
  startScoring() {
    setInterval(() => {
      this.makesum();
    }, 500);
  }
makesum() {
  this.totalsum = 0;
  for (let i = 1; i <= 30; i++) {
    this.totalsum += this.advanced[i];
  }
  console.log(this.totalsum);
}

initializeAdvanced(size: number) {
  for (let i = 0; i < size; i++) {
    this.advanced[i] = 0;
  }
}



}