import { Component, OnInit, Input} from '@angular/core';
import {SharedService} from 'src/app/shared.service';

@Component({
  selector: 'app-add-admin',
  templateUrl: './add-admin.component.html',
  styleUrls: ['./add-admin.component.css']
})
export class AddAdminComponent implements OnInit {

  constructor(private service:SharedService) { }

  @Input() adm:any;
  BuildingId!: string;
  BuildingName!: string;
  BuildingPhoto!: string;
  BuildingFloor!: string;
  ApartmentNumb!: string;
  ApartmentPhoto!: string;
  ApartmentSurface!: string;
  ApartmentRooms!: string;
  ApartmentPrice!: string;
  ApartmentBalconSurface!: string;
  ApartmentOrentation!: string;
  ApartmentBathrooms!: string;
  SurfaceDormitor!: string;
  SurfaceBaie!: string;
  SurfaceHol!: string;
  SurfaceRoom!: string;
  PhotoFilePath!: string;
  DepartmentsList:any=[];
  ApartmentPDF!: string;
  

  ngOnInit(): void {
    
    this.BuildingFloor=this.adm.BuildingId;
    this.BuildingId=this.adm.BuildingId;
    this.BuildingName=this.adm.BuildingName;
    this.BuildingPhoto=this.adm.BuildingPhoto
    this.PhotoFilePath=this.service.PhotoUrl+this.PhotoFilePath;
    this.BuildingFloor=this.adm.BuildingFloor;
    this.ApartmentNumb=this.adm.ApartmentNumb;
    this.ApartmentPhoto=this.adm.ApartmentPhoto
    this.ApartmentSurface=this.adm.ApartmentSurface;
    this.ApartmentRooms=this.adm.ApartmentRooms;
    this.ApartmentPrice=this.adm.ApartmentPrice;
    this.ApartmentBalconSurface=this.adm.ApartmentBalconSurface;
    this.ApartmentOrentation=this.adm.ApartmentOrentation;
    this.ApartmentBathrooms=this.adm.ApartmentBathrooms;
    this.SurfaceDormitor=this.adm.SurfaceDormitor;
    this.SurfaceBaie=this.adm.SurfaceBaie;
    this.SurfaceHol=this.adm.SurfaceHol;
    this.SurfaceRoom=this.adm.SurfaceRoom;
    this.ApartmentPDF=this.adm.ApartmentPDF;

  }

  addEmployee(){
    var val = {BuildingId:this.BuildingId,
      BuildingName:this.BuildingName,
      BuildingPhoto:this.BuildingPhoto,
      BuildingFloor:this.BuildingFloor,
      ApartmentNumb:this.ApartmentNumb,
      ApartmentPhoto:this.ApartmentPhoto,
      ApartmentSurface:this.ApartmentSurface,
      ApartmentRooms:this.ApartmentRooms,
      ApartmentPrice:this.ApartmentPrice,
      ApartmentBalconSurface:this.ApartmentBalconSurface,
      ApartmentOrentation:this.ApartmentOrentation,
      ApartmentBathrooms:this.ApartmentBathrooms,
      SurfaceDormitor:this.SurfaceDormitor,
      SurfaceBaie:this.SurfaceBaie,
      SurfaceHol:this.SurfaceHol,
      SurfaceRoom:this.SurfaceRoom,
      ApartmentPDF:this.ApartmentPDF,

    };

    this.service.addBuilding(val).subscribe(
        res => console.log(res.toString()),
        err => console.log('HTTP Error', err),
        () => console.log('HTTP request completed.')
    );
}


  updateEmployee(){
    var val = {BuildingId:this.BuildingId,
      BuildingName:this.BuildingName,
      BuildingPhoto:this.BuildingPhoto,
      BuildingFloor:this.BuildingFloor,
      ApartmentNumb:this.ApartmentNumb,
      ApartmentPhoto:this.ApartmentPhoto,
      ApartmentSurface:this.ApartmentSurface,
      ApartmentRooms:this.ApartmentRooms,
      ApartmentPrice:this.ApartmentPrice,
      ApartmentBalconSurface:this.ApartmentBalconSurface,
      ApartmentOrentation:this.ApartmentOrentation,
      ApartmentBathrooms:this.ApartmentBathrooms,
      SurfaceDormitor:this.SurfaceDormitor,
      SurfaceBaie:this.SurfaceBaie,
      SurfaceHol:this.SurfaceHol,
      SurfaceRoom:this.SurfaceRoom,
      ApartmentPDF:this.ApartmentPDF,};

    this.service.updateBuilding(val).subscribe(res=>{
    alert(res.toString());
    });
  }


  uploadPhoto(event:any){
    var file=event.target.files[0];
    const formData:FormData=new FormData();
    formData.append('uploadedFile',file,file.name);
    this.service.UploadPhoto(formData).subscribe((data:any)=>{
      this.BuildingPhoto=data.toString();
      this.PhotoFilePath=this.service.PhotoUrl+this.BuildingPhoto;
    })
  }
}


