import { Component, OnInit, Input} from '@angular/core';
import {SharedService} from 'src/app/shared.service';

import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-addforms',
  templateUrl: './addforms.component.html',
  styleUrls: ['./addforms.component.css']
})
export class AddformsComponent implements OnInit {

  constructor(private service:SharedService, private datePipe: DatePipe) { }
  @Input() tol:any;
  BuildingId!: string;
  BuildingName!: string;
  BuildingPhoto!: string;
  BuildingFloor!: string;
  ApartmentNumb!: string;
  ApartmentPhoto!: string;
  ApartmentSurface!: string;
  ApartmentRooms!: string;
  ApartmentPrice!: string;
  ApartmentBalconSurface!: string;
  ApartmentOrentation!: string;
  ApartmentBathrooms!: string;

  ngOnInit(): void {
    window.scrollTo(0, 0)


  }
    
    

  }
  
  
  